import { boolean, mixed, number, object, string } from "yup";

import { CarFinancingFormTypes, CarUseFormTypes } from "src/api/graphql/types-and-hooks";

export default object({
  name: string().required(),
  total: number().required(),
  subtotal: number().required(),
  formOfUse: mixed<CarUseFormTypes>().oneOf(Object.values(CarUseFormTypes)).required(),
  isElectric: boolean().required(),
  financingForm: mixed<CarFinancingFormTypes>().oneOf(Object.values(CarFinancingFormTypes)).required(),
}).required();
