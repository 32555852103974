import React from "react";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "../form/AppButton";

import Text from "./Text";

type Props = {
  info?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
};

const Confirm: React.FC<Props> = ({ show, onConfirm, onCancel, loading, title, confirmText, cancelText, info }) => {
  const { t } = useTranslation();

  if (!show) return null;
  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center items-center">
          <div className="relative bg-gradient-to-r to-[#202B4F] from-[#352837] transform rounded-[42px] text-left shadow-xl transition-all p-11 max-w-[40%]">
            <Text className="2xl:text-38 xl:text-36 text-34 text-white">Uwaga</Text>
            {info ? (
              <div className="mt-2">
                <Text className="text-state-error">{info}</Text>
              </div>
            ) : null}
            <div className="mt-2">
              <Text className="2xl:text-18 xl:text-16 text-14 text-text-secondary">
                {title || "Czy na pewno chcesz usunąć rekord?"}
              </Text>
            </div>
            <div className="mt-5 flex flex-row space-x-4">
              <div className="basis-1/2">
                <AppButton
                  title={confirmText || t("Confirm")}
                  color={ButtonColorTypes.PRIMARY}
                  onClick={onConfirm}
                  customClass="rounded-[12px]"
                  isLoading={loading}
                  textClass="text-white font-sf-pro-medium"
                />
              </div>
              <div className="basis-1/2">
                <AppButton
                  title={cancelText || t("Cancel")}
                  color={ButtonColorTypes.SECONDARY}
                  onClick={onCancel}
                  customClass="rounded-[12px] border border-light-gray"
                  textClass="text-white font-sf-pro-medium"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
