import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import CloudTax, { CloudTaxColorEnum } from "src/components/ui/CloudTax";

import { VatTaxTypes } from "src/api/graphql/types-and-hooks";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { useVatTax } from "./cockpit.hooks";

type Props = {
  isVatPayer: boolean;
  vatTaxType: VatTaxTypes;
  companyTaxesLoading: boolean;
};

const VatTax: React.FC<Props> = ({ vatTaxType, isVatPayer, companyTaxesLoading }) => {
  const currentUser = useCurrentUser();
  const taxesCalculationEnabled = currentUser?.company?.subscription?.subscription?.taxesCalculationEnabled;

  const { t } = useTranslation();
  const { loading, vatTaxValue, getVatTax } = useVatTax();
  const previousVatTax = useVatTax();

  useEffect(() => {
    if (taxesCalculationEnabled && isVatPayer) {
      let currentMonthDates = { gte: moment().startOf("month").unix(), lte: moment().endOf("month").unix() };
      let lastMonthDates = {
        gte: moment().subtract(1, "month").startOf("month").unix(),
        lte: moment().subtract(1, "month").endOf("month").unix(),
      };

      if (vatTaxType === VatTaxTypes.Quarterly) {
        currentMonthDates = {
          gte: moment().startOf("quarter").unix(),
          lte: moment().endOf("quarter").unix(),
        };
        lastMonthDates = {
          gte: moment().subtract(1, "quarter").startOf("quarter").unix(),
          lte: moment().subtract(1, "quarter").endOf("quarter").unix(),
        };
      }

      getVatTax({
        filter: {
          date: currentMonthDates,
        },
      });
      previousVatTax.getVatTax({
        filter: {
          date: lastMonthDates,
        },
      });
    }
  }, [taxesCalculationEnabled, vatTaxType]);

  return (
    <CloudTax
      className={!isVatPayer || !taxesCalculationEnabled ? "opacity-50" : ""}
      title={`${t("Tax")} ${"VAT"}`}
      value={taxesCalculationEnabled ? vatTaxValue : "-"}
      color={CloudTaxColorEnum.purple}
      previousTaxValue={taxesCalculationEnabled ? previousVatTax.vatTaxValue : "-"}
      loading={companyTaxesLoading || loading || previousVatTax.loading}
    />
  );
};

export default VatTax;
