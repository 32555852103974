import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "src/components/ui/Table";
import Confirm from "src/components/ui/Confirm";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleWrapper from "src/components/ui/ModuleWrapper";
import ListContainer from "src/components/ui/ListContainer";

import { Budget, SortOptions } from "src/api/graphql/types-and-hooks";

import { getBudgetDetailsPath } from "src/common/router/routerPaths";

import BudgetAdd from "./BudgetAdd";
import BudgetTableRow from "./BudgetTableRow";
import { useBudgets, useDeleteBudget } from "./budget.hooks";

export type BudgetListType = Pick<Budget, "name" | "createdAt" | "dateFrom" | "dateTo" | "_id" | "isDefault">;

const Budgets = () => {
  const HEADERS = ["Nazwa", "Data utworzenia", "Budżet od", "Budżet do", ""];
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [budgetToDeleteId, setBudgetToDeleteId] = useState<string | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { budgets, loading } = useBudgets({
    filter: {},
    sort: { createdAt: SortOptions.Desc },
  });
  const { handleDeleteBudget, isDeleting } = useDeleteBudget();

  const renderRows = () => {
    if (!budgets) return null;
    return budgets.map((d: BudgetListType) => {
      return (
        <BudgetTableRow
          data={d}
          key={d._id}
          onDelete={() => {
            setBudgetToDeleteId(d._id);
            setShowConfirm(true);
          }}
          onClick={() => navigate(getBudgetDetailsPath({ budgetId: d._id }))}
        />
      );
    });
  };

  const onDeleteBudget = async () => {
    await handleDeleteBudget(budgetToDeleteId);
    setShowConfirm(false);
    setBudgetToDeleteId(null);
  };

  return (
    <>
      {showConfirm ? (
        <Confirm
          show={showConfirm}
          onConfirm={() => onDeleteBudget()}
          onCancel={() => setShowConfirm(false)}
          loading={isDeleting}
        />
      ) : undefined}
      {showAddModal ? <BudgetAdd onCancel={() => setShowAddModal(false)} /> : undefined}
      <ModuleWrapper>
        <ModuleHeader className="mb-0" title="Budget" addButtonTitle="Add-budget" onAdd={() => setShowAddModal(true)} />
        <ListContainer title={t("Saved-symulations")}>
          <Table headers={HEADERS} isLoading={loading} rows={renderRows()} />
        </ListContainer>
      </ModuleWrapper>
    </>
  );
};

export default Budgets;
