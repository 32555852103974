import React, { createContext, ReactNode, useMemo, useState } from "react";

import { useCheckAuthQuery } from "src/api/graphql/generated/gql-hooks";
import { CompanyIntegrationTypes, Permission, UserRoles } from "src/api/graphql/types-and-hooks";

import { useIdentifySocket } from "../Socket/socket.hooks";

export type LoggedUser = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: number;
  role: UserRoles;
  permissions: Permission[];
  company?: {
    _id: string;
    name: string;
    email: string;
    companyId: string;
    address: string;
    city: string;
    postalCode: string;
    country: string;
    subscription?: {
      trialDateEnd?: string;
      subscription: {
        _id: string;
        name: string;
        subtotal: number;
        permissionsEnabled?: boolean;
        maxUsersCount?: number;
        taxesCalculationEnabled?: boolean;
      };
      dateEnd?: string;
      dateFrom: string;
      isTrial: boolean;
    };
    integrationData?: {
      type: CompanyIntegrationTypes;
      apiKey: string;
      secretKey?: string;
    };
    paymentMethod?: {
      maskedCardNumber: string;
    };
  };
  subscribed: boolean;
};

type Props = {
  children: ReactNode;
};

type AuthenticationContext = {
  user: LoggedUser | null;
  setUser: React.Dispatch<React.SetStateAction<LoggedUser | null>>;
};

const initialState = {
  user: null,
  setUser: () => null,
};

export const AuthenticationContext = createContext<AuthenticationContext>(initialState);

export const AuthenticationProvider = ({ children }: Props) => {
  const [user, setUser] = useState<LoggedUser | null>(null);

  const { loading } = useCheckAuthQuery({
    onError: () => localStorage.removeItem("accessToken"),
    onCompleted: (data) => {
      localStorage.setItem("accessToken", data.checkAuth.accessToken);
      setUser(data.checkAuth.user);
      useIdentifySocket(data.checkAuth.user);
    },
  });

  const authenticationValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );

  if (loading) return <div></div>;

  return <AuthenticationContext.Provider value={authenticationValue}>{children}</AuthenticationContext.Provider>;
};
