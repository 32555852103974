import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddCarToCompanyInput = {
  financingForm: CarFinancingFormTypes;
  formOfUse: CarUseFormTypes;
  isElectric?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type AddDocumentsToCategoryInput = {
  categoryId: Scalars['ID']['input'];
  documentIds: Array<Scalars['String']['input']>;
  type: BudgetCategoryTypes;
};

export type AddSingleTransactionInput = {
  budgetId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  date: Scalars['DateTime']['input'];
  type: BudgetCategoryTypes;
  value: Scalars['Float']['input'];
};

export type AddUserToCompanyInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type BaseDeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type BillToFilterInput = {
  companyId?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
};

export type BudgetCategoriesFilterOptionsInput = {
  budget?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  type?: InputMaybe<FilterOperators>;
};

export type BudgetCategoriesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export enum BudgetCategoryTypes {
  Cost = 'COST',
  Income = 'INCOME'
}

export type BudgetDocumentsFilterOptionsInput = {
  billTo?: InputMaybe<BillToFilterInput>;
  budget?: InputMaybe<FilterOperators>;
  category?: InputMaybe<FilterOperators>;
  company?: InputMaybe<FilterOperators>;
  description?: InputMaybe<FilterOperators>;
  invoiceNumber?: InputMaybe<FilterOperators>;
  paymentDue?: InputMaybe<FilterOperators>;
  paymentStatus?: InputMaybe<FilterOperators>;
  type?: InputMaybe<FilterOperators>;
};

export type BudgetDocumentsSortOptionsInput = {
  paymentDue?: InputMaybe<SortOptions>;
};

export type BudgetsFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<FilterOperators>;
};

export type BudgetsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export enum CarFinancingFormTypes {
  LongTermRental = 'LONG_TERM_RENTAL',
  OperationalLeasing = 'OPERATIONAL_LEASING'
}

export enum CarUseFormTypes {
  Company = 'COMPANY',
  Mixed = 'MIXED'
}

export type CheckLiquidityAgainstFinancialMinimumOptionsInput = {
  filter?: InputMaybe<LiquidityAgainstFinancialMinimumOptionsInput>;
};

export enum CitTaxAmountTypes {
  Nine = 'NINE',
  Nineteen = 'NINETEEN',
  Zero = 'ZERO'
}

export type CompaniesFilterOptionsInput = {
  companyId?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
  subscribedFrom?: InputMaybe<FilterOperators>;
  subscribedTo?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type CompaniesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export type CompanyIntegrationDataInput = {
  apiKey: Scalars['String']['input'];
  secretKey?: InputMaybe<Scalars['String']['input']>;
  type: CompanyIntegrationTypes;
};

export enum CompanyIntegrationTypes {
  Infakt = 'INFAKT',
  Wfirma = 'WFIRMA'
}

export enum CompanyLegalFormTypes {
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  JointStockCompany = 'JOINT_STOCK_COMPANY',
  JointStockLimitedPartnership = 'JOINT_STOCK_LIMITED_PARTNERSHIP',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP'
}

export type CompanyPaymentMethodInput = {
  maskedCardNumber: Scalars['String']['input'];
  token: CompanyPaymentMethodTokenInput;
};

export type CompanyPaymentMethodTokenInput = {
  content: Scalars['String']['input'];
  iv: Scalars['String']['input'];
};

export type CompanySubscriptionInput = {
  dateFrom: Scalars['DateTime']['input'];
  isTrial: Scalars['Boolean']['input'];
  nextPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentFailures?: InputMaybe<Scalars['Float']['input']>;
  subscription: Scalars['ID']['input'];
  trialDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyTaxDetailsInput = {
  citTaxAmount?: InputMaybe<CitTaxAmountTypes>;
  flatRateTaxAmount?: InputMaybe<FlatRateTaxAmountTypes>;
  isVatPayer?: InputMaybe<Scalars['Boolean']['input']>;
  pitTaxType?: InputMaybe<PitTaxTypeTypes>;
  taxType: TaxTypes;
  vatType?: InputMaybe<VatTaxTypes>;
};

export type CreateBudgetCategoryInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  budget: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
  type: BudgetCategoryTypes;
};

export type CreateBudgetInput = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  importExistingInvoices: Scalars['Boolean']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  withDefaultCategories: Scalars['Boolean']['input'];
};

export type CreateCompanyInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type CreateInvoiceCategoryInput = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceInput = {
  billFrom?: InputMaybe<InvoiceCompanyInput>;
  billTo?: InputMaybe<InvoiceCompanyInput>;
  capital?: InputMaybe<Scalars['Float']['input']>;
  car?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Currency>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  genre: InvoiceGenres;
  interest?: InputMaybe<Scalars['Float']['input']>;
  invoiceNumber: Scalars['String']['input'];
  issueDate: Scalars['DateTime']['input'];
  leftToPay: Scalars['Float']['input'];
  paymentDue: Scalars['DateTime']['input'];
  paymentStatus: PaymentStatus;
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriod?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriodType?: InputMaybe<TaxSettlementPeriodType>;
  splitPayment?: InputMaybe<Scalars['Boolean']['input']>;
  subtotal: Scalars['Float']['input'];
  subtype?: InputMaybe<InvoiceSubtypes>;
  total: Scalars['Float']['input'];
  type: InvoiceTypes;
  vat: Scalars['Float']['input'];
};

export type CreateNotificationInput = {
  company: Scalars['String']['input'];
  description: Scalars['String']['input'];
  module?: InputMaybe<NotificationModuleTypes>;
  recordId?: InputMaybe<Scalars['String']['input']>;
  type: NotificationTypes;
};

export type CreateOrderInput = {
  companyEmail: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type CreatePackageOrderInput = {
  packageId: Scalars['ID']['input'];
};

export type CreateSubscriptionInput = {
  OCRPagesCount: Scalars['Float']['input'];
  budgetingEnabled: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  emailNotificationsEnabled: Scalars['Boolean']['input'];
  isActive: Scalars['Boolean']['input'];
  maxUsersCount: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  optionsExcluded: Array<Scalars['String']['input']>;
  optionsIncluded: Array<Scalars['String']['input']>;
  periodicity: Scalars['Float']['input'];
  permissionsEnabled: Scalars['Boolean']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  trialPeriod: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export enum Currency {
  Eur = 'EUR',
  Pln = 'PLN',
  Usd = 'USD'
}

export type DeleteBudgetCategoryInput = {
  budgetId: Scalars['ID']['input'];
  category: Scalars['String']['input'];
  categoryType: BudgetCategoryTypes;
};

export type DeleteUserFromCompanyInput = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type FilterOperators = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type FinancialMinimumInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  minimum: Scalars['Float']['input'];
  type: FinancialMinimumTypes;
};

export enum FinancialMinimumTypes {
  Constant = 'CONSTANT',
  Periodic = 'PERIODIC'
}

export enum FlatRateTaxAmountTypes {
  EightPointFive = 'EIGHT_POINT_FIVE',
  Fifteen = 'FIFTEEN',
  FivePointFive = 'FIVE_POINT_FIVE',
  Fourteen = 'FOURTEEN',
  Seventeen = 'SEVENTEEN',
  Ten = 'TEN',
  Three = 'THREE',
  Twelve = 'TWELVE',
  TwelvePointFive = 'TWELVE_POINT_FIVE',
  Two = 'TWO'
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type GetBudgetCategoriesOptionsInput = {
  filter?: InputMaybe<BudgetCategoriesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<BudgetCategoriesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetBudgetDocumentsOptionsInput = {
  filter?: InputMaybe<BudgetDocumentsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<BudgetDocumentsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetBudgetsOptionsInput = {
  filter?: InputMaybe<BudgetsFilterOptionsInput>;
  sort?: InputMaybe<BudgetsSortOptionsInput>;
};

export type GetCompaniesOptionsInput = {
  filter?: InputMaybe<CompaniesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<CompaniesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetIncomeTaxArgsOptionsInput = {
  filter?: InputMaybe<IncomeTaxFilterOptionsInput>;
};

export type GetInvoiceCategoriesOptionsInput = {
  filter?: InputMaybe<InvoiceCategoriesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoiceCategoriesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetInvoicesOptionsInput = {
  filter?: InputMaybe<InvoicesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoicesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetLiquidityAnalysisOptionsInput = {
  filter?: InputMaybe<LiquidityAnalysisFilterOptionsInput>;
};

export type GetNotificationsOptionsInput = {
  filter: NotificationsFilterOptionsInput;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<NotificationsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetOrdersOptionsInput = {
  filter?: InputMaybe<OrdersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<OrdersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetPackagesOptionsInput = {
  filter?: InputMaybe<PackagesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<PackagesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetSubscriptionsOptionsInput = {
  filter?: InputMaybe<SubscriptionsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<SubscriptionsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetTransactionsConditionOptionsInput = {
  filter?: InputMaybe<TransactionsConditionFilterOptionsInput>;
};

export type GetUsersOptionsInput = {
  filter?: InputMaybe<UsersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<UsersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetVatTaxArgsOptionsInput = {
  filter?: InputMaybe<VatTaxFilterOptionsInput>;
};

export type IncomeTaxFilterOptionsInput = {
  date?: InputMaybe<FilterOperators>;
};

export type InviteUserToCompanyInput = {
  email: Scalars['String']['input'];
  permissions?: Array<PermissionsInput>;
};

export type InvoiceCategoriesFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
};

export type InvoiceCategoriesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type InvoiceCompanyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export enum InvoiceGenres {
  Car = 'CAR',
  Employee = 'EMPLOYEE',
  Invoice = 'INVOICE',
  Loan = 'LOAN',
  Tax = 'TAX'
}

export enum InvoiceSubtypes {
  Cit = 'CIT',
  Fuel = 'FUEL',
  GroupCost = 'GROUP_COST',
  Insurance = 'INSURANCE',
  Leasing = 'LEASING',
  Pit = 'PIT',
  Service = 'SERVICE',
  UnitCost = 'UNIT_COST',
  Vat = 'VAT',
  Zus = 'ZUS'
}

export enum InvoiceTypes {
  Cost = 'COST',
  Income = 'INCOME'
}

export type InvoicesFilterOptionsInput = {
  billTo?: InputMaybe<BillToFilterInput>;
  company?: InputMaybe<FilterOperators>;
  date?: InputMaybe<FilterOperators>;
  description?: InputMaybe<FilterOperators>;
  genre?: InputMaybe<FilterOperators>;
  invoiceNumber?: InputMaybe<FilterOperators>;
  paymentDue?: InputMaybe<FilterOperators>;
  paymentStatus?: InputMaybe<FilterOperators>;
  type?: InputMaybe<FilterOperators>;
};

export type InvoicesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  paymentDue?: InputMaybe<SortOptions>;
};

export type LiquidityAgainstFinancialMinimumOptionsInput = {
  period?: InputMaybe<FilterOperators>;
};

export type LiquidityAnalysisFilterOptionsInput = {
  date?: InputMaybe<FilterOperators>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum NotificationModuleTypes {
  Invoices = 'INVOICES'
}

export enum NotificationTypes {
  FinancialMinimumExceeded = 'FINANCIAL_MINIMUM_EXCEEDED',
  LiquidityBelowZero = 'LIQUIDITY_BELOW_ZERO',
  OverdueCost = 'OVERDUE_COST',
  OverdueIncome = 'OVERDUE_INCOME'
}

export type NotificationsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
};

export type NotificationsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type OrdersFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  number?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type OrdersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  number?: InputMaybe<SortOptions>;
};

export enum PackageTypes {
  OcrPages = 'OCR_PAGES'
}

export type PackagesFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
};

export type PackagesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export enum PaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export type PermissionsInput = {
  actions?: Array<UserPermissionActionsEnum>;
  resource: UserPermissionResourcesEnum;
};

export enum PitTaxTypeTypes {
  FlatRate = 'FLAT_RATE',
  Linear = 'LINEAR'
}

export type RegisterInput = {
  companyAddress: Scalars['String']['input'];
  companyCity: Scalars['String']['input'];
  companyEmail: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyPhone: Scalars['String']['input'];
  companyPostalCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type SendFeedbackInput = {
  category: Scalars['String']['input'];
  content: Scalars['String']['input'];
  email: Scalars['String']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export enum SortOptions {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SubscriptionsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
};

export type SubscriptionsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export enum TaxSettlementPeriodType {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR'
}

export enum TaxTypes {
  Cit = 'CIT',
  Pit = 'PIT'
}

export type TransactionsConditionFilterOptionsInput = {
  paymentDue?: InputMaybe<FilterOperators>;
};

export type UpdateBudgetCategoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateCategoryTreeValueInput = {
  id: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  type: BudgetCategoryTypes;
  value: Scalars['Float']['input'];
};

export type UpdateCompanyInput = {
  accountBalance?: InputMaybe<Scalars['Float']['input']>;
  additionalOcrPages?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  integrationData?: InputMaybe<CompanyIntegrationDataInput>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  legalForm?: InputMaybe<CompanyLegalFormTypes>;
  name?: InputMaybe<Scalars['String']['input']>;
  newCompany?: InputMaybe<Scalars['Boolean']['input']>;
  newSubscription?: InputMaybe<Scalars['ID']['input']>;
  ocrPagesLeft?: InputMaybe<Scalars['Float']['input']>;
  paymentMethod?: InputMaybe<CompanyPaymentMethodInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  startAccountBalance?: InputMaybe<Scalars['Float']['input']>;
  startAccountValuesDate?: InputMaybe<Scalars['DateTime']['input']>;
  startVatAccountBalance?: InputMaybe<Scalars['Float']['input']>;
  subscription?: InputMaybe<CompanySubscriptionInput>;
  taxDetails?: InputMaybe<CompanyTaxDetailsInput>;
  trialUsed?: InputMaybe<Scalars['Boolean']['input']>;
  vatAccountBalance?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCompanyPaymentMethodInput = {
  token: Scalars['String']['input'];
};

export type UpdateCompanySubscriptionInput = {
  subscription: Scalars['ID']['input'];
};

export type UpdateInvoiceInput = {
  billFrom?: InputMaybe<InvoiceCompanyInput>;
  billTo?: InputMaybe<InvoiceCompanyInput>;
  capital?: InputMaybe<Scalars['Float']['input']>;
  car?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Currency>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalService?: InputMaybe<CompanyIntegrationTypes>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  genre?: InputMaybe<InvoiceGenres>;
  id: Scalars['String']['input'];
  interest?: InputMaybe<Scalars['Float']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['DateTime']['input']>;
  leftToPay?: InputMaybe<Scalars['Float']['input']>;
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentDue?: InputMaybe<Scalars['DateTime']['input']>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriod?: InputMaybe<Scalars['DateTime']['input']>;
  settlementPeriodType?: InputMaybe<TaxSettlementPeriodType>;
  splitPayment?: InputMaybe<Scalars['Boolean']['input']>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  subtype?: InputMaybe<InvoiceSubtypes>;
  total?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<InvoiceTypes>;
  valueFromAccount?: InputMaybe<Scalars['Float']['input']>;
  valueFromVatAccount?: InputMaybe<Scalars['Float']['input']>;
  vat?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInvoicesPaymentStatusDocumentsInput = {
  id: Scalars['ID']['input'];
  isSplitPayment?: InputMaybe<Scalars['Boolean']['input']>;
  paymentDate: Scalars['DateTime']['input'];
};

export type UpdateInvoicesPaymentStatusInput = {
  documents: Array<UpdateInvoicesPaymentStatusDocumentsInput>;
};

export type UpdateNotificationInput = {
  id: Scalars['ID']['input'];
  seen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSubscriptionInput = {
  OCRPagesCount?: InputMaybe<Scalars['Float']['input']>;
  _id: Scalars['String']['input'];
  budgetingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxUsersCount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionsExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  optionsIncluded?: InputMaybe<Array<Scalars['String']['input']>>;
  periodicity?: InputMaybe<Scalars['Float']['input']>;
  permissionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
  trialPeriod?: InputMaybe<Scalars['Float']['input']>;
  vat?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  financialMinimum?: InputMaybe<Array<FinancialMinimumInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<PermissionsInput>>;
  phone?: InputMaybe<Scalars['Float']['input']>;
};

export type UploadInoviceToOrderInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['String']['input'];
};

export enum UserPermissionActionsEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Get = 'GET',
  Getlist = 'GETLIST',
  Update = 'UPDATE'
}

export enum UserPermissionResourcesEnum {
  Account = 'ACCOUNT',
  Budget = 'BUDGET',
  Cockpit = 'COCKPIT',
  FinancialDetails = 'FINANCIAL_DETAILS',
  LiquidityAnalysis = 'LIQUIDITY_ANALYSIS',
  PaymentsCalendar = 'PAYMENTS_CALENDAR',
  Transactions = 'TRANSACTIONS'
}

export enum UserRoles {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UsersFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  firstName?: InputMaybe<FilterOperators>;
  lastName?: InputMaybe<FilterOperators>;
};

export type UsersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  firstName?: InputMaybe<SortOptions>;
};

export type VatTaxFilterOptionsInput = {
  date?: InputMaybe<FilterOperators>;
};

export enum VatTaxTypes {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
};

export type AddCarToCompanyMutationVariables = Exact<{
  addCarToCompanyInput: AddCarToCompanyInput;
}>;


export type AddCarToCompanyMutation = { __typename?: 'Mutation', addCarToCompany: { __typename?: 'Company', _id: string } };

export type AddDocumentsToCategoryMutationVariables = Exact<{
  addDocumentsToCategoryInput: AddDocumentsToCategoryInput;
}>;


export type AddDocumentsToCategoryMutation = { __typename?: 'Mutation', addDocumentsToCategory: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type AddSingleBudgetTransactionMutationVariables = Exact<{
  addSingleTransactionInput: AddSingleTransactionInput;
}>;


export type AddSingleBudgetTransactionMutation = { __typename?: 'Mutation', addSingleBudgetTransaction: { __typename?: 'Budget', _id: string } };

export type AddUserToCompanyMutationVariables = Exact<{
  addUserToCompanyInput: AddUserToCompanyInput;
}>;


export type AddUserToCompanyMutation = { __typename?: 'Mutation', addUserToCompany: { __typename?: 'AddUserToCompanyResponse', _id: string } };

export type CancelCompanySubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelCompanySubscriptionMutation = { __typename?: 'Mutation', cancelCompanySubscription: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type UpdateCompanyPaymentMethodMutationVariables = Exact<{
  updateCompanyPaymentMethodInput: UpdateCompanyPaymentMethodInput;
}>;


export type UpdateCompanyPaymentMethodMutation = { __typename?: 'Mutation', updateCompanyPaymentMethod: { __typename?: 'UpdateCompanyPaymentMethodResponse', success: boolean, redirectUri: string, maskedCardNumber: string, token: { __typename?: 'EncryptedToken', iv: string, content: string } } };

export type CreateBudgetMutationVariables = Exact<{
  createBudgetInput: CreateBudgetInput;
}>;


export type CreateBudgetMutation = { __typename?: 'Mutation', createBudget: { __typename?: 'BaseCreateResponse', id: string } };

export type CreateBudgetCategoryMutationVariables = Exact<{
  createBudgetCategoryInput: CreateBudgetCategoryInput;
}>;


export type CreateBudgetCategoryMutation = { __typename?: 'Mutation', createBudgetCategory: { __typename?: 'BaseCreateResponse', id: string } };

export type CreateInvoiceMutationVariables = Exact<{
  createInvoiceInput: CreateInvoiceInput;
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice: { __typename?: 'CreateInvoiceResponse', id: string, paymentDue: any, type: string } };

export type CreateOrderMutationVariables = Exact<{
  createOrderInput: CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'CreateOrderResponse', id: string, redirectUri: string } };

export type CreatePackageOrderMutationVariables = Exact<{
  createPackageOrderInput: CreatePackageOrderInput;
}>;


export type CreatePackageOrderMutation = { __typename?: 'Mutation', createPackageOrder: { __typename?: 'CreateOrderResponse', id: string, redirectUri: string } };

export type DeleteBudgetMutationVariables = Exact<{
  deleteBudgetInput: BaseDeleteDocumentInput;
}>;


export type DeleteBudgetMutation = { __typename?: 'Mutation', deleteBudget: { __typename?: 'BaseDeleteResponse', success: boolean } };

export type DeleteBudgetCategoryMutationVariables = Exact<{
  deleteBudgetCategoryInput: DeleteBudgetCategoryInput;
}>;


export type DeleteBudgetCategoryMutation = { __typename?: 'Mutation', deleteBudgetCategory: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type DeleteInvoiceMutationVariables = Exact<{
  deleteInvoiceInput: BaseDeleteDocumentInput;
}>;


export type DeleteInvoiceMutation = { __typename?: 'Mutation', deleteInvoice: { __typename?: 'BaseDeleteResponse', success: boolean } };

export type DeleteNotificationMutationVariables = Exact<{
  deleteNotificationInput: BaseDeleteDocumentInput;
}>;


export type DeleteNotificationMutation = { __typename?: 'Mutation', deleteNotification: { __typename?: 'BaseDeleteResponse', success: boolean } };

export type DeleteUserFromCompanyMutationVariables = Exact<{
  deleteUserFromCompanyInput: DeleteUserFromCompanyInput;
}>;


export type DeleteUserFromCompanyMutation = { __typename?: 'Mutation', deleteUserFromCompany: { __typename?: 'BaseDeleteResponse', success: boolean } };

export type ForgotPasswordMutationVariables = Exact<{
  forgotPasswordInput: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordResponse', success: boolean } };

export type InviteUserToCompanyMutationVariables = Exact<{
  inviteUserToCompanyInput: InviteUserToCompanyInput;
}>;


export type InviteUserToCompanyMutation = { __typename?: 'Mutation', inviteUserToCompany: { __typename?: 'InviteUserToCompanyResponse', success: boolean } };

export type LoginMutationVariables = Exact<{
  loginInput: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', _id: string, email: string, firstName: string, lastName: string, phone: number, role: UserRoles, subscribed: boolean, permissions: Array<{ __typename?: 'Permission', resource: UserPermissionResourcesEnum, actions: Array<UserPermissionActionsEnum> }>, company?: { __typename?: 'Company', _id: string, name: string, email: string, companyId: string, address: string, city: string, postalCode: string, country: string, subscription?: { __typename?: 'CompanySubscription', trialDateEnd?: any | null, dateEnd?: any | null, dateFrom: any, isTrial: boolean, subscription: { __typename?: 'Subscription', _id: string, name: string, subtotal: number, permissionsEnabled: boolean, maxUsersCount: number, taxesCalculationEnabled: boolean } } | null, integrationData?: { __typename?: 'CompanyIntegrationData', type: CompanyIntegrationTypes, apiKey: string, secretKey?: string | null } | null, paymentMethod?: { __typename?: 'CompanyPaymentMethod', maskedCardNumber: string } | null } | null } } };

export type RegisterMutationVariables = Exact<{
  registerInput: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'RegisterResponse', _id: string } };

export type RemoveCompanyPaymentMethodMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveCompanyPaymentMethodMutation = { __typename?: 'Mutation', removeCompanyPaymentMethod: { __typename?: 'Company', _id: string } };

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordInput: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', success: boolean } };

export type ResumeCompanySubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type ResumeCompanySubscriptionMutation = { __typename?: 'Mutation', resumeCompanySubscription: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type UpdateBudgetCategoryMutationVariables = Exact<{
  updateBudgetCategoryInput: UpdateBudgetCategoryInput;
}>;


export type UpdateBudgetCategoryMutation = { __typename?: 'Mutation', updateBudgetCategory: { __typename?: 'BudgetCategory', _id: string } };

export type UpdateCategoryTreeValueMutationVariables = Exact<{
  updateCategoryTreeValueInput: UpdateCategoryTreeValueInput;
}>;


export type UpdateCategoryTreeValueMutation = { __typename?: 'Mutation', updateCategoryTreeValue: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type UpdateCompanyMutationVariables = Exact<{
  updateCompanyInput: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'Company', _id: string } };

export type UpdateCompanySubscriptionMutationVariables = Exact<{
  updateCompanySubscriptionInput: UpdateCompanySubscriptionInput;
}>;


export type UpdateCompanySubscriptionMutation = { __typename?: 'Mutation', updateCompanySubscription: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type UpdateInvoiceMutationVariables = Exact<{
  updateInvoiceInput: UpdateInvoiceInput;
}>;


export type UpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice: { __typename?: 'Invoice', _id: string, paymentDue?: any | null, type: InvoiceTypes } };

export type UpdateInvoicesPaymentStatusMutationVariables = Exact<{
  updateInvoicesPaymentsStatusInput: UpdateInvoicesPaymentStatusInput;
}>;


export type UpdateInvoicesPaymentStatusMutation = { __typename?: 'Mutation', updateInvoicesPaymentStatus: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type UpdateNotificationMutationVariables = Exact<{
  updateNotificationInput: UpdateNotificationInput;
}>;


export type UpdateNotificationMutation = { __typename?: 'Mutation', updateNotification: { __typename?: 'Notification', _id: string } };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', _id: string } };

export type VerifyAccountMutationVariables = Exact<{
  verifyAccountInput: VerifyAccountInput;
}>;


export type VerifyAccountMutation = { __typename?: 'Mutation', verifyAccount: { __typename?: 'VerifyAccountResponse', success: boolean, companyEmail: string } };

export type BudgetQueryVariables = Exact<{
  budgetId: Scalars['ID']['input'];
}>;


export type BudgetQuery = { __typename?: 'Query', budget: { __typename?: 'BudgetResponse', _id?: string | null, name?: string | null, dateFrom?: any | null, dateTo?: any | null, costCategoriesTree?: Array<{ __typename?: 'BudgetCategoryTree', value: number, category: { __typename?: 'BudgetCategory', _id: string, name?: string | null }, children: Array<{ __typename?: 'BudgetCategoryTree', value: number, category: { __typename?: 'BudgetCategory', _id: string, name?: string | null }, months?: Array<{ __typename?: 'BudgetMonth', month: string, value: number, weeks: Array<{ __typename?: 'BudgetWeek', week: number, value: number }> }> | null }>, months?: Array<{ __typename?: 'BudgetMonth', value: number, month: string, weeks: Array<{ __typename?: 'BudgetWeek', week: number, value: number }> }> | null }> | null, incomeCategoriesTree?: Array<{ __typename?: 'BudgetCategoryTree', value: number, category: { __typename?: 'BudgetCategory', _id: string, name?: string | null }, children: Array<{ __typename?: 'BudgetCategoryTree', value: number, category: { __typename?: 'BudgetCategory', _id: string, name?: string | null }, months?: Array<{ __typename?: 'BudgetMonth', month: string, value: number, weeks: Array<{ __typename?: 'BudgetWeek', week: number, value: number }> }> | null }>, months?: Array<{ __typename?: 'BudgetMonth', value: number, month: string, weeks: Array<{ __typename?: 'BudgetWeek', week: number, value: number }> }> | null }> | null } };

export type BudgetCategoriesQueryVariables = Exact<{
  options: GetBudgetCategoriesOptionsInput;
}>;


export type BudgetCategoriesQuery = { __typename?: 'Query', budgetCategories: { __typename?: 'BudgetCategoriesList', documents: Array<{ __typename?: 'BudgetCategory', _id: string, isLeaf: boolean, name?: string | null, type: BudgetCategoryTypes, parent?: { __typename?: 'BudgetCategory', _id: string, name?: string | null } | null }> } };

export type BudgetCostsAndIncomesPerMonthQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BudgetCostsAndIncomesPerMonthQuery = { __typename?: 'Query', budgetCostsAndIncomesPerMonth: { __typename?: 'GetBudgetCostsAndIncomesPerMonthResponse', costsAndIncomesPerMonth: Array<{ __typename?: 'BudgetCostsAndIncomesPerMonth', isPrognosis: boolean, month: string, totalCosts: number, totalIncomes: number }> } };

export type BudgetDocumentsQueryVariables = Exact<{
  options: GetBudgetDocumentsOptionsInput;
}>;


export type BudgetDocumentsQuery = { __typename?: 'Query', budgetDocuments: { __typename?: 'BudgetDocumentsList', total: number, documents: Array<{ __typename?: 'BudgetDocument', _id: string, subtotal?: number | null, total?: number | null, paymentStatus?: PaymentStatus | null, type: InvoiceTypes, genre: InvoiceGenres, description?: string | null, invoiceNumber: string, paymentDue?: any | null, billFrom?: { __typename?: 'InvoiceCompany', name: string } | null, billTo?: { __typename?: 'InvoiceCompany', name: string } | null, category?: { __typename?: 'BudgetCategory', _id: string, name?: string | null, parent?: { __typename?: 'BudgetCategory', _id: string, name?: string | null } | null } | null }> } };

export type BudgetsQueryVariables = Exact<{
  options: GetBudgetsOptionsInput;
}>;


export type BudgetsQuery = { __typename?: 'Query', budgets: { __typename?: 'BudgetsList', documents: Array<{ __typename?: 'Budget', _id: string, createdAt?: any | null, dateFrom?: any | null, dateTo?: any | null, name: string, isDefault: boolean }> } };

export type CheckAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAuthQuery = { __typename?: 'Query', checkAuth: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, phone: number, role: UserRoles, subscribed: boolean, permissions: Array<{ __typename?: 'Permission', resource: UserPermissionResourcesEnum, actions: Array<UserPermissionActionsEnum> }>, company?: { __typename?: 'Company', _id: string, name: string, email: string, companyId: string, address: string, city: string, postalCode: string, country: string, subscription?: { __typename?: 'CompanySubscription', trialDateEnd?: any | null, dateEnd?: any | null, dateFrom: any, isTrial: boolean, subscription: { __typename?: 'Subscription', _id: string, name: string, subtotal: number, permissionsEnabled: boolean, maxUsersCount: number, taxesCalculationEnabled: boolean } } | null, integrationData?: { __typename?: 'CompanyIntegrationData', type: CompanyIntegrationTypes, apiKey: string, secretKey?: string | null } | null, paymentMethod?: { __typename?: 'CompanyPaymentMethod', maskedCardNumber: string } | null } | null } } };

export type CheckLiquidityAganistFinancialMinimumQueryVariables = Exact<{
  options: CheckLiquidityAgainstFinancialMinimumOptionsInput;
}>;


export type CheckLiquidityAganistFinancialMinimumQuery = { __typename?: 'Query', checkLiquidityAganistFinancialMinimum: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type CheckOrderStatusQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;


export type CheckOrderStatusQuery = { __typename?: 'Query', checkOrderStatus: { __typename?: 'CheckOrderStatusResponse', status: OrderStatus } };

export type CheckOverdueInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckOverdueInvoicesQuery = { __typename?: 'Query', checkOverdueInvoices: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type CompanyQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', _id: string, users: Array<{ __typename?: 'User', _id: string, email: string, firstName: string, lastName: string, phone: number, role: UserRoles }> } };

export type CompanyAccountBalanceQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyAccountBalanceQuery = { __typename?: 'Query', company: { __typename?: 'Company', _id: string, accountBalance?: number | null, vatAccountBalance?: number | null, startAccountBalance?: number | null, startVatAccountBalance?: number | null, startAccountValuesDate?: any | null, taxDetails?: { __typename?: 'CompanyTaxDetails', isVatPayer?: boolean | null } | null } };

export type CompanyCarsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyCarsQuery = { __typename?: 'Query', company: { __typename?: 'Company', cars: Array<{ __typename?: 'CompanyCar', subtotal: number, name: string, formOfUse: CarUseFormTypes, financingForm: CarFinancingFormTypes, _id: string, deletedAt?: any | null }> } };

export type CompanyLegalFormQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyLegalFormQuery = { __typename?: 'Query', company: { __typename?: 'Company', legalForm?: CompanyLegalFormTypes | null } };

export type CompanyOcrPagesLeftQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyOcrPagesLeftQuery = { __typename?: 'Query', company: { __typename?: 'Company', ocrPagesLeft: number, additionalOcrPages: number } };

export type CompanyPaymentMethodQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyPaymentMethodQuery = { __typename?: 'Query', company: { __typename?: 'Company', paymentMethod?: { __typename?: 'CompanyPaymentMethod', maskedCardNumber: string } | null } };

export type CompanySubscriptionQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanySubscriptionQuery = { __typename?: 'Query', company: { __typename?: 'Company', subscription?: { __typename?: 'CompanySubscription', trialDateEnd?: any | null, dateEnd?: any | null, dateFrom: any, isTrial: boolean, subscription: { __typename?: 'Subscription', _id: string, name: string, subtotal: number } } | null, newSubscription?: { __typename?: 'Subscription', _id: string, name: string } | null } };

export type CompanyTaxDetailsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyTaxDetailsQuery = { __typename?: 'Query', company: { __typename?: 'Company', taxDetails?: { __typename?: 'CompanyTaxDetails', vatType?: VatTaxTypes | null, isVatPayer?: boolean | null, taxType?: TaxTypes | null, pitTaxType?: PitTaxTypeTypes | null, flatRateTaxAmount?: FlatRateTaxAmountTypes | null, citTaxAmount?: CitTaxAmountTypes | null } | null } };

export type IncomeTaxQueryVariables = Exact<{
  options: GetIncomeTaxArgsOptionsInput;
}>;


export type IncomeTaxQuery = { __typename?: 'Query', incomeTax: { __typename?: 'IncomeTaxResponse', incomeTaxValue?: number | null } };

export type InvoiceQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;


export type InvoiceQuery = { __typename?: 'Query', invoice: { __typename?: 'Invoice', _id: string, description?: string | null, invoiceNumber: string, paymentDue?: any | null, issueDate?: any | null, saleDate?: any | null, subtotal?: number | null, total?: number | null, genre: InvoiceGenres, type: InvoiceTypes, subtype?: InvoiceSubtypes | null, filePath?: string | null, paymentStatus?: PaymentStatus | null, interest?: number | null, capital?: number | null, settlementPeriodType?: TaxSettlementPeriodType | null, settlementPeriod?: any | null, splitPayment?: boolean | null, paymentDate?: any | null, externalService?: CompanyIntegrationTypes | null, billFrom?: { __typename?: 'InvoiceCompany', address?: string | null, city?: string | null, country?: string | null, name: string, postcode?: string | null, companyId?: string | null } | null, billTo?: { __typename?: 'InvoiceCompany', postcode?: string | null, name: string, country?: string | null, companyId?: string | null, city?: string | null, address?: string | null } | null, car?: { __typename?: 'CompanyCar', _id: string, name: string } | null } };

export type InvoicesQueryVariables = Exact<{
  options: GetInvoicesOptionsInput;
}>;


export type InvoicesQuery = { __typename?: 'Query', invoices: { __typename?: 'InovicesList', total: number, documents: Array<{ __typename?: 'InvoicesListDocument', _id: string, invoiceNumber: string, paymentDue?: any | null, paymentStatus?: PaymentStatus | null, subtotal?: number | null, total?: number | null, type: InvoiceTypes, genre: InvoiceGenres, externalService?: CompanyIntegrationTypes | null, billTo?: { __typename?: 'BillTo', name: string } | null, billFrom?: { __typename?: 'BillFrom', name: string } | null }> } };

export type LiquidityAnalysisQueryVariables = Exact<{
  options: GetLiquidityAnalysisOptionsInput;
}>;


export type LiquidityAnalysisQuery = { __typename?: 'Query', liquidityAnalysis: { __typename?: 'LiquidityAnalysisResponse', startValue: number, liquidity: Array<{ __typename?: 'LiquidityAnalysis', d: number, date: string, liquidity?: number | null }> } };

export type LiquidityAnalysisInvoicesQueryVariables = Exact<{
  options: GetInvoicesOptionsInput;
}>;


export type LiquidityAnalysisInvoicesQuery = { __typename?: 'Query', liquidityAnalysisInvoices: { __typename?: 'InovicesList', total: number, documents: Array<{ __typename?: 'InvoicesListDocument', _id: string, externalService?: CompanyIntegrationTypes | null, genre: InvoiceGenres, invoiceNumber: string, paymentDate?: any | null, paymentDue?: any | null, paymentStatus?: PaymentStatus | null, subtotal?: number | null, total?: number | null, type: InvoiceTypes, billFrom?: { __typename?: 'BillFrom', name: string } | null, billTo?: { __typename?: 'BillTo', name: string } | null }> } };

export type MonthlyLiquiditiesQueryVariables = Exact<{
  options: GetLiquidityAnalysisOptionsInput;
}>;


export type MonthlyLiquiditiesQuery = { __typename?: 'Query', monthlyLiquidities: { __typename?: 'MonthlyLiquiditiesResponse', documents: Array<{ __typename?: 'MonthlyLiquidity', month: string, liquidity: number }> } };

export type NotificationsQueryVariables = Exact<{
  options: GetNotificationsOptionsInput;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationsList', total: number, documents: Array<{ __typename?: 'Notification', _id: string, createdAt?: any | null, description: string, type: NotificationTypes, seen: boolean }> } };

export type OrdersQueryVariables = Exact<{
  options: GetOrdersOptionsInput;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrdersList', total: number, documents: Array<{ __typename?: 'Order', _id: string, createdAt?: any | null, number: string, status: OrderStatus, subtotal: number, total: number, invoice?: string | null, company: { __typename?: 'Company', name: string, companyId: string }, subscription?: { __typename?: 'Subscription', name: string } | null, package?: { __typename?: 'Package', name: string } | null }> } };

export type PackagesQueryVariables = Exact<{
  options: GetPackagesOptionsInput;
}>;


export type PackagesQuery = { __typename?: 'Query', packages: { __typename?: 'PackagesList', total: number, documents: Array<{ __typename?: 'Package', OCRPagesCount: number, _id: string, createdAt?: any | null, deletedAt?: any | null, isActive: boolean, name: string, optionsIncluded: Array<string>, subtotal: number, total: number, type: PackageTypes, updatedAt?: any | null, vat: number }> } };

export type ReadInvoiceDataQueryVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type ReadInvoiceDataQuery = { __typename?: 'Query', readInvoiceData: { __typename?: 'ReadInvoiceDataResponse', invoiceNumber?: string | null, issueDate?: any | null, saleDate?: any | null, paymentDue?: any | null, subtotal?: number | null, total?: number | null, vat?: number | null, interest?: number | null, billFrom?: { __typename?: 'ReadInvoiceDataCompany', postcode?: string | null, name?: string | null, city?: string | null, address?: string | null, companyId?: string | null } | null, billTo?: { __typename?: 'ReadInvoiceDataCompany', postcode?: string | null, name?: string | null, city?: string | null, address?: string | null, companyId?: string | null } | null } };

export type SendFeedbackMutationVariables = Exact<{
  sendFeedbackInput: SendFeedbackInput;
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', sendFeedback: { __typename?: 'BaseSuccessResponse', success: boolean } };

export type SubscriptionsQueryVariables = Exact<{
  options: GetSubscriptionsOptionsInput;
}>;


export type SubscriptionsQuery = { __typename?: 'Query', userSubscriptions: { __typename?: 'SubscriptionsList', total: number, documents: Array<{ __typename?: 'Subscription', _id: string, OCRPagesCount: number, budgetingEnabled: boolean, createdAt?: any | null, deletedAt?: any | null, description: string, emailNotificationsEnabled: boolean, isActive: boolean, maxUsersCount: number, name: string, optionsExcluded: Array<string>, optionsIncluded: Array<string>, periodicity: number, permissionsEnabled: boolean, taxesCalculationEnabled: boolean, subtotal: number, total: number, trialPeriod?: number | null, updatedAt?: any | null, vat: number }> } };

export type TransactionsConditionQueryVariables = Exact<{
  options: GetTransactionsConditionOptionsInput;
}>;


export type TransactionsConditionQuery = { __typename?: 'Query', transactionsCondition: { __typename?: 'TransactionsConditionResponse', percentageCosts: number, percentageIncomes: number, totalCosts: number, totalIncomes: number } };

export type UnpaidInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnpaidInvoicesQuery = { __typename?: 'Query', unpaidInvoices: { __typename?: 'UnpaidInvoicesResponse', unpaidCosts: number, unpaidCostsCount: number, unpaidIncomes: number, unpaidIncomesCount: number } };

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', _id: string, email: string, firstName: string, lastName: string, phone: number, company?: { __typename?: 'Company', _id: string, address: string, city: string, companyId: string, country: string, email: string, name: string, phone: string, postalCode: string, subscription?: { __typename?: 'CompanySubscription', trialDateEnd?: any | null, dateEnd?: any | null, dateFrom: any, isTrial: boolean, subscription: { __typename?: 'Subscription', name: string, permissionsEnabled: boolean, maxUsersCount: number, taxesCalculationEnabled: boolean } } | null, paymentMethod?: { __typename?: 'CompanyPaymentMethod', maskedCardNumber: string } | null } | null, permissions: Array<{ __typename?: 'Permission', resource: UserPermissionResourcesEnum, actions: Array<UserPermissionActionsEnum> }>, settings: { __typename?: 'UserSettings', financialMinimum: Array<{ __typename?: 'FinancialMinimum', minimum: number, dateFrom?: any | null, dateTo?: any | null, type: FinancialMinimumTypes }> } } };

export type UserPermissionsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserPermissionsQuery = { __typename?: 'Query', user: { __typename?: 'User', _id: string, permissions: Array<{ __typename?: 'Permission', resource: UserPermissionResourcesEnum }> } };

export type VatTaxQueryVariables = Exact<{
  options: GetVatTaxArgsOptionsInput;
}>;


export type VatTaxQuery = { __typename?: 'Query', vatTax: { __typename?: 'VatTaxResponse', vatTaxValue?: number | null } };


export const AddCarToCompanyDocument = gql`
    mutation AddCarToCompany($addCarToCompanyInput: AddCarToCompanyInput!) {
  addCarToCompany(addCarToCompanyInput: $addCarToCompanyInput) {
    _id
  }
}
    `;
export type AddCarToCompanyMutationFn = Apollo.MutationFunction<AddCarToCompanyMutation, AddCarToCompanyMutationVariables>;

/**
 * __useAddCarToCompanyMutation__
 *
 * To run a mutation, you first call `useAddCarToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCarToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCarToCompanyMutation, { data, loading, error }] = useAddCarToCompanyMutation({
 *   variables: {
 *      addCarToCompanyInput: // value for 'addCarToCompanyInput'
 *   },
 * });
 */
export function useAddCarToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCarToCompanyMutation, AddCarToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCarToCompanyMutation, AddCarToCompanyMutationVariables>(AddCarToCompanyDocument, options);
      }
export type AddCarToCompanyMutationHookResult = ReturnType<typeof useAddCarToCompanyMutation>;
export type AddCarToCompanyMutationResult = Apollo.MutationResult<AddCarToCompanyMutation>;
export type AddCarToCompanyMutationOptions = Apollo.BaseMutationOptions<AddCarToCompanyMutation, AddCarToCompanyMutationVariables>;
export const AddDocumentsToCategoryDocument = gql`
    mutation AddDocumentsToCategory($addDocumentsToCategoryInput: AddDocumentsToCategoryInput!) {
  addDocumentsToCategory(
    addDocumentsToCategoryInput: $addDocumentsToCategoryInput
  ) {
    success
  }
}
    `;
export type AddDocumentsToCategoryMutationFn = Apollo.MutationFunction<AddDocumentsToCategoryMutation, AddDocumentsToCategoryMutationVariables>;

/**
 * __useAddDocumentsToCategoryMutation__
 *
 * To run a mutation, you first call `useAddDocumentsToCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentsToCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentsToCategoryMutation, { data, loading, error }] = useAddDocumentsToCategoryMutation({
 *   variables: {
 *      addDocumentsToCategoryInput: // value for 'addDocumentsToCategoryInput'
 *   },
 * });
 */
export function useAddDocumentsToCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddDocumentsToCategoryMutation, AddDocumentsToCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocumentsToCategoryMutation, AddDocumentsToCategoryMutationVariables>(AddDocumentsToCategoryDocument, options);
      }
export type AddDocumentsToCategoryMutationHookResult = ReturnType<typeof useAddDocumentsToCategoryMutation>;
export type AddDocumentsToCategoryMutationResult = Apollo.MutationResult<AddDocumentsToCategoryMutation>;
export type AddDocumentsToCategoryMutationOptions = Apollo.BaseMutationOptions<AddDocumentsToCategoryMutation, AddDocumentsToCategoryMutationVariables>;
export const AddSingleBudgetTransactionDocument = gql`
    mutation AddSingleBudgetTransaction($addSingleTransactionInput: AddSingleTransactionInput!) {
  addSingleBudgetTransaction(
    addSingleTransactionInput: $addSingleTransactionInput
  ) {
    _id
  }
}
    `;
export type AddSingleBudgetTransactionMutationFn = Apollo.MutationFunction<AddSingleBudgetTransactionMutation, AddSingleBudgetTransactionMutationVariables>;

/**
 * __useAddSingleBudgetTransactionMutation__
 *
 * To run a mutation, you first call `useAddSingleBudgetTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSingleBudgetTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSingleBudgetTransactionMutation, { data, loading, error }] = useAddSingleBudgetTransactionMutation({
 *   variables: {
 *      addSingleTransactionInput: // value for 'addSingleTransactionInput'
 *   },
 * });
 */
export function useAddSingleBudgetTransactionMutation(baseOptions?: Apollo.MutationHookOptions<AddSingleBudgetTransactionMutation, AddSingleBudgetTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSingleBudgetTransactionMutation, AddSingleBudgetTransactionMutationVariables>(AddSingleBudgetTransactionDocument, options);
      }
export type AddSingleBudgetTransactionMutationHookResult = ReturnType<typeof useAddSingleBudgetTransactionMutation>;
export type AddSingleBudgetTransactionMutationResult = Apollo.MutationResult<AddSingleBudgetTransactionMutation>;
export type AddSingleBudgetTransactionMutationOptions = Apollo.BaseMutationOptions<AddSingleBudgetTransactionMutation, AddSingleBudgetTransactionMutationVariables>;
export const AddUserToCompanyDocument = gql`
    mutation AddUserToCompany($addUserToCompanyInput: AddUserToCompanyInput!) {
  addUserToCompany(addUserToCompanyInput: $addUserToCompanyInput) {
    _id
  }
}
    `;
export type AddUserToCompanyMutationFn = Apollo.MutationFunction<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>;

/**
 * __useAddUserToCompanyMutation__
 *
 * To run a mutation, you first call `useAddUserToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToCompanyMutation, { data, loading, error }] = useAddUserToCompanyMutation({
 *   variables: {
 *      addUserToCompanyInput: // value for 'addUserToCompanyInput'
 *   },
 * });
 */
export function useAddUserToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>(AddUserToCompanyDocument, options);
      }
export type AddUserToCompanyMutationHookResult = ReturnType<typeof useAddUserToCompanyMutation>;
export type AddUserToCompanyMutationResult = Apollo.MutationResult<AddUserToCompanyMutation>;
export type AddUserToCompanyMutationOptions = Apollo.BaseMutationOptions<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>;
export const CancelCompanySubscriptionDocument = gql`
    mutation CancelCompanySubscription {
  cancelCompanySubscription {
    success
  }
}
    `;
export type CancelCompanySubscriptionMutationFn = Apollo.MutationFunction<CancelCompanySubscriptionMutation, CancelCompanySubscriptionMutationVariables>;

/**
 * __useCancelCompanySubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelCompanySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCompanySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCompanySubscriptionMutation, { data, loading, error }] = useCancelCompanySubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelCompanySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelCompanySubscriptionMutation, CancelCompanySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCompanySubscriptionMutation, CancelCompanySubscriptionMutationVariables>(CancelCompanySubscriptionDocument, options);
      }
export type CancelCompanySubscriptionMutationHookResult = ReturnType<typeof useCancelCompanySubscriptionMutation>;
export type CancelCompanySubscriptionMutationResult = Apollo.MutationResult<CancelCompanySubscriptionMutation>;
export type CancelCompanySubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelCompanySubscriptionMutation, CancelCompanySubscriptionMutationVariables>;
export const UpdateCompanyPaymentMethodDocument = gql`
    mutation UpdateCompanyPaymentMethod($updateCompanyPaymentMethodInput: UpdateCompanyPaymentMethodInput!) {
  updateCompanyPaymentMethod(
    updateCompanyPaymentMethodInput: $updateCompanyPaymentMethodInput
  ) {
    success
    redirectUri
    token {
      iv
      content
    }
    maskedCardNumber
  }
}
    `;
export type UpdateCompanyPaymentMethodMutationFn = Apollo.MutationFunction<UpdateCompanyPaymentMethodMutation, UpdateCompanyPaymentMethodMutationVariables>;

/**
 * __useUpdateCompanyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPaymentMethodMutation, { data, loading, error }] = useUpdateCompanyPaymentMethodMutation({
 *   variables: {
 *      updateCompanyPaymentMethodInput: // value for 'updateCompanyPaymentMethodInput'
 *   },
 * });
 */
export function useUpdateCompanyPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyPaymentMethodMutation, UpdateCompanyPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyPaymentMethodMutation, UpdateCompanyPaymentMethodMutationVariables>(UpdateCompanyPaymentMethodDocument, options);
      }
export type UpdateCompanyPaymentMethodMutationHookResult = ReturnType<typeof useUpdateCompanyPaymentMethodMutation>;
export type UpdateCompanyPaymentMethodMutationResult = Apollo.MutationResult<UpdateCompanyPaymentMethodMutation>;
export type UpdateCompanyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyPaymentMethodMutation, UpdateCompanyPaymentMethodMutationVariables>;
export const CreateBudgetDocument = gql`
    mutation CreateBudget($createBudgetInput: CreateBudgetInput!) {
  createBudget(createBudgetInput: $createBudgetInput) {
    id
  }
}
    `;
export type CreateBudgetMutationFn = Apollo.MutationFunction<CreateBudgetMutation, CreateBudgetMutationVariables>;

/**
 * __useCreateBudgetMutation__
 *
 * To run a mutation, you first call `useCreateBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetMutation, { data, loading, error }] = useCreateBudgetMutation({
 *   variables: {
 *      createBudgetInput: // value for 'createBudgetInput'
 *   },
 * });
 */
export function useCreateBudgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateBudgetMutation, CreateBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBudgetMutation, CreateBudgetMutationVariables>(CreateBudgetDocument, options);
      }
export type CreateBudgetMutationHookResult = ReturnType<typeof useCreateBudgetMutation>;
export type CreateBudgetMutationResult = Apollo.MutationResult<CreateBudgetMutation>;
export type CreateBudgetMutationOptions = Apollo.BaseMutationOptions<CreateBudgetMutation, CreateBudgetMutationVariables>;
export const CreateBudgetCategoryDocument = gql`
    mutation CreateBudgetCategory($createBudgetCategoryInput: CreateBudgetCategoryInput!) {
  createBudgetCategory(createBudgetCategoryInput: $createBudgetCategoryInput) {
    id
  }
}
    `;
export type CreateBudgetCategoryMutationFn = Apollo.MutationFunction<CreateBudgetCategoryMutation, CreateBudgetCategoryMutationVariables>;

/**
 * __useCreateBudgetCategoryMutation__
 *
 * To run a mutation, you first call `useCreateBudgetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetCategoryMutation, { data, loading, error }] = useCreateBudgetCategoryMutation({
 *   variables: {
 *      createBudgetCategoryInput: // value for 'createBudgetCategoryInput'
 *   },
 * });
 */
export function useCreateBudgetCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateBudgetCategoryMutation, CreateBudgetCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBudgetCategoryMutation, CreateBudgetCategoryMutationVariables>(CreateBudgetCategoryDocument, options);
      }
export type CreateBudgetCategoryMutationHookResult = ReturnType<typeof useCreateBudgetCategoryMutation>;
export type CreateBudgetCategoryMutationResult = Apollo.MutationResult<CreateBudgetCategoryMutation>;
export type CreateBudgetCategoryMutationOptions = Apollo.BaseMutationOptions<CreateBudgetCategoryMutation, CreateBudgetCategoryMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {
  createInvoice(createInvoiceInput: $createInvoiceInput) {
    id
    paymentDue
    type
  }
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      createInvoiceInput: // value for 'createInvoiceInput'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($createOrderInput: CreateOrderInput!) {
  createOrder(createOrderInput: $createOrderInput) {
    id
    redirectUri
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      createOrderInput: // value for 'createOrderInput'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreatePackageOrderDocument = gql`
    mutation CreatePackageOrder($createPackageOrderInput: CreatePackageOrderInput!) {
  createPackageOrder(createPackageOrderInput: $createPackageOrderInput) {
    id
    redirectUri
  }
}
    `;
export type CreatePackageOrderMutationFn = Apollo.MutationFunction<CreatePackageOrderMutation, CreatePackageOrderMutationVariables>;

/**
 * __useCreatePackageOrderMutation__
 *
 * To run a mutation, you first call `useCreatePackageOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageOrderMutation, { data, loading, error }] = useCreatePackageOrderMutation({
 *   variables: {
 *      createPackageOrderInput: // value for 'createPackageOrderInput'
 *   },
 * });
 */
export function useCreatePackageOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackageOrderMutation, CreatePackageOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackageOrderMutation, CreatePackageOrderMutationVariables>(CreatePackageOrderDocument, options);
      }
export type CreatePackageOrderMutationHookResult = ReturnType<typeof useCreatePackageOrderMutation>;
export type CreatePackageOrderMutationResult = Apollo.MutationResult<CreatePackageOrderMutation>;
export type CreatePackageOrderMutationOptions = Apollo.BaseMutationOptions<CreatePackageOrderMutation, CreatePackageOrderMutationVariables>;
export const DeleteBudgetDocument = gql`
    mutation DeleteBudget($deleteBudgetInput: BaseDeleteDocumentInput!) {
  deleteBudget(deleteBudgetInput: $deleteBudgetInput) {
    success
  }
}
    `;
export type DeleteBudgetMutationFn = Apollo.MutationFunction<DeleteBudgetMutation, DeleteBudgetMutationVariables>;

/**
 * __useDeleteBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetMutation, { data, loading, error }] = useDeleteBudgetMutation({
 *   variables: {
 *      deleteBudgetInput: // value for 'deleteBudgetInput'
 *   },
 * });
 */
export function useDeleteBudgetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBudgetMutation, DeleteBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBudgetMutation, DeleteBudgetMutationVariables>(DeleteBudgetDocument, options);
      }
export type DeleteBudgetMutationHookResult = ReturnType<typeof useDeleteBudgetMutation>;
export type DeleteBudgetMutationResult = Apollo.MutationResult<DeleteBudgetMutation>;
export type DeleteBudgetMutationOptions = Apollo.BaseMutationOptions<DeleteBudgetMutation, DeleteBudgetMutationVariables>;
export const DeleteBudgetCategoryDocument = gql`
    mutation DeleteBudgetCategory($deleteBudgetCategoryInput: DeleteBudgetCategoryInput!) {
  deleteBudgetCategory(deleteBudgetCategoryInput: $deleteBudgetCategoryInput) {
    success
  }
}
    `;
export type DeleteBudgetCategoryMutationFn = Apollo.MutationFunction<DeleteBudgetCategoryMutation, DeleteBudgetCategoryMutationVariables>;

/**
 * __useDeleteBudgetCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetCategoryMutation, { data, loading, error }] = useDeleteBudgetCategoryMutation({
 *   variables: {
 *      deleteBudgetCategoryInput: // value for 'deleteBudgetCategoryInput'
 *   },
 * });
 */
export function useDeleteBudgetCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBudgetCategoryMutation, DeleteBudgetCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBudgetCategoryMutation, DeleteBudgetCategoryMutationVariables>(DeleteBudgetCategoryDocument, options);
      }
export type DeleteBudgetCategoryMutationHookResult = ReturnType<typeof useDeleteBudgetCategoryMutation>;
export type DeleteBudgetCategoryMutationResult = Apollo.MutationResult<DeleteBudgetCategoryMutation>;
export type DeleteBudgetCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteBudgetCategoryMutation, DeleteBudgetCategoryMutationVariables>;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($deleteInvoiceInput: BaseDeleteDocumentInput!) {
  deleteInvoice(deleteInvoiceInput: $deleteInvoiceInput) {
    success
  }
}
    `;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      deleteInvoiceInput: // value for 'deleteInvoiceInput'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($deleteNotificationInput: BaseDeleteDocumentInput!) {
  deleteNotification(deleteNotificationInput: $deleteNotificationInput) {
    success
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      deleteNotificationInput: // value for 'deleteNotificationInput'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const DeleteUserFromCompanyDocument = gql`
    mutation DeleteUserFromCompany($deleteUserFromCompanyInput: DeleteUserFromCompanyInput!) {
  deleteUserFromCompany(deleteUserFromCompanyInput: $deleteUserFromCompanyInput) {
    success
  }
}
    `;
export type DeleteUserFromCompanyMutationFn = Apollo.MutationFunction<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>;

/**
 * __useDeleteUserFromCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromCompanyMutation, { data, loading, error }] = useDeleteUserFromCompanyMutation({
 *   variables: {
 *      deleteUserFromCompanyInput: // value for 'deleteUserFromCompanyInput'
 *   },
 * });
 */
export function useDeleteUserFromCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>(DeleteUserFromCompanyDocument, options);
      }
export type DeleteUserFromCompanyMutationHookResult = ReturnType<typeof useDeleteUserFromCompanyMutation>;
export type DeleteUserFromCompanyMutationResult = Apollo.MutationResult<DeleteUserFromCompanyMutation>;
export type DeleteUserFromCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
  forgotPassword(forgotPasswordInput: $forgotPasswordInput) {
    success
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      forgotPasswordInput: // value for 'forgotPasswordInput'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const InviteUserToCompanyDocument = gql`
    mutation InviteUserToCompany($inviteUserToCompanyInput: InviteUserToCompanyInput!) {
  inviteUserToCompany(inviteUserToCompanyInput: $inviteUserToCompanyInput) {
    success
  }
}
    `;
export type InviteUserToCompanyMutationFn = Apollo.MutationFunction<InviteUserToCompanyMutation, InviteUserToCompanyMutationVariables>;

/**
 * __useInviteUserToCompanyMutation__
 *
 * To run a mutation, you first call `useInviteUserToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToCompanyMutation, { data, loading, error }] = useInviteUserToCompanyMutation({
 *   variables: {
 *      inviteUserToCompanyInput: // value for 'inviteUserToCompanyInput'
 *   },
 * });
 */
export function useInviteUserToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserToCompanyMutation, InviteUserToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserToCompanyMutation, InviteUserToCompanyMutationVariables>(InviteUserToCompanyDocument, options);
      }
export type InviteUserToCompanyMutationHookResult = ReturnType<typeof useInviteUserToCompanyMutation>;
export type InviteUserToCompanyMutationResult = Apollo.MutationResult<InviteUserToCompanyMutation>;
export type InviteUserToCompanyMutationOptions = Apollo.BaseMutationOptions<InviteUserToCompanyMutation, InviteUserToCompanyMutationVariables>;
export const LoginDocument = gql`
    mutation Login($loginInput: LoginInput!) {
  login(loginInput: $loginInput) {
    user {
      _id
      email
      firstName
      lastName
      phone
      role
      permissions {
        resource
        actions
      }
      company {
        _id
        name
        email
        companyId
        address
        city
        postalCode
        country
        subscription {
          trialDateEnd
          subscription {
            _id
            name
            subtotal
            permissionsEnabled
            maxUsersCount
            taxesCalculationEnabled
          }
          dateEnd
          dateFrom
          isTrial
        }
        integrationData {
          type
          apiKey
          secretKey
        }
        paymentMethod {
          maskedCardNumber
        }
      }
      subscribed
    }
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($registerInput: RegisterInput!) {
  register(registerInput: $registerInput) {
    _id
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      registerInput: // value for 'registerInput'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RemoveCompanyPaymentMethodDocument = gql`
    mutation RemoveCompanyPaymentMethod {
  removeCompanyPaymentMethod {
    _id
  }
}
    `;
export type RemoveCompanyPaymentMethodMutationFn = Apollo.MutationFunction<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>;

/**
 * __useRemoveCompanyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyPaymentMethodMutation, { data, loading, error }] = useRemoveCompanyPaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveCompanyPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>(RemoveCompanyPaymentMethodDocument, options);
      }
export type RemoveCompanyPaymentMethodMutationHookResult = ReturnType<typeof useRemoveCompanyPaymentMethodMutation>;
export type RemoveCompanyPaymentMethodMutationResult = Apollo.MutationResult<RemoveCompanyPaymentMethodMutation>;
export type RemoveCompanyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
  resetPassword(resetPasswordInput: $resetPasswordInput) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResumeCompanySubscriptionDocument = gql`
    mutation ResumeCompanySubscription {
  resumeCompanySubscription {
    success
  }
}
    `;
export type ResumeCompanySubscriptionMutationFn = Apollo.MutationFunction<ResumeCompanySubscriptionMutation, ResumeCompanySubscriptionMutationVariables>;

/**
 * __useResumeCompanySubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeCompanySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeCompanySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeCompanySubscriptionMutation, { data, loading, error }] = useResumeCompanySubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useResumeCompanySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ResumeCompanySubscriptionMutation, ResumeCompanySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeCompanySubscriptionMutation, ResumeCompanySubscriptionMutationVariables>(ResumeCompanySubscriptionDocument, options);
      }
export type ResumeCompanySubscriptionMutationHookResult = ReturnType<typeof useResumeCompanySubscriptionMutation>;
export type ResumeCompanySubscriptionMutationResult = Apollo.MutationResult<ResumeCompanySubscriptionMutation>;
export type ResumeCompanySubscriptionMutationOptions = Apollo.BaseMutationOptions<ResumeCompanySubscriptionMutation, ResumeCompanySubscriptionMutationVariables>;
export const UpdateBudgetCategoryDocument = gql`
    mutation UpdateBudgetCategory($updateBudgetCategoryInput: UpdateBudgetCategoryInput!) {
  updateBudgetCategory(updateBudgetCategoryInput: $updateBudgetCategoryInput) {
    _id
  }
}
    `;
export type UpdateBudgetCategoryMutationFn = Apollo.MutationFunction<UpdateBudgetCategoryMutation, UpdateBudgetCategoryMutationVariables>;

/**
 * __useUpdateBudgetCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetCategoryMutation, { data, loading, error }] = useUpdateBudgetCategoryMutation({
 *   variables: {
 *      updateBudgetCategoryInput: // value for 'updateBudgetCategoryInput'
 *   },
 * });
 */
export function useUpdateBudgetCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetCategoryMutation, UpdateBudgetCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetCategoryMutation, UpdateBudgetCategoryMutationVariables>(UpdateBudgetCategoryDocument, options);
      }
export type UpdateBudgetCategoryMutationHookResult = ReturnType<typeof useUpdateBudgetCategoryMutation>;
export type UpdateBudgetCategoryMutationResult = Apollo.MutationResult<UpdateBudgetCategoryMutation>;
export type UpdateBudgetCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetCategoryMutation, UpdateBudgetCategoryMutationVariables>;
export const UpdateCategoryTreeValueDocument = gql`
    mutation UpdateCategoryTreeValue($updateCategoryTreeValueInput: UpdateCategoryTreeValueInput!) {
  updateCategoryTreeValue(
    updateCategoryTreeValueInput: $updateCategoryTreeValueInput
  ) {
    success
  }
}
    `;
export type UpdateCategoryTreeValueMutationFn = Apollo.MutationFunction<UpdateCategoryTreeValueMutation, UpdateCategoryTreeValueMutationVariables>;

/**
 * __useUpdateCategoryTreeValueMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryTreeValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryTreeValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryTreeValueMutation, { data, loading, error }] = useUpdateCategoryTreeValueMutation({
 *   variables: {
 *      updateCategoryTreeValueInput: // value for 'updateCategoryTreeValueInput'
 *   },
 * });
 */
export function useUpdateCategoryTreeValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryTreeValueMutation, UpdateCategoryTreeValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryTreeValueMutation, UpdateCategoryTreeValueMutationVariables>(UpdateCategoryTreeValueDocument, options);
      }
export type UpdateCategoryTreeValueMutationHookResult = ReturnType<typeof useUpdateCategoryTreeValueMutation>;
export type UpdateCategoryTreeValueMutationResult = Apollo.MutationResult<UpdateCategoryTreeValueMutation>;
export type UpdateCategoryTreeValueMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryTreeValueMutation, UpdateCategoryTreeValueMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($updateCompanyInput: UpdateCompanyInput!) {
  updateCompany(updateCompanyInput: $updateCompanyInput) {
    _id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      updateCompanyInput: // value for 'updateCompanyInput'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateCompanySubscriptionDocument = gql`
    mutation UpdateCompanySubscription($updateCompanySubscriptionInput: UpdateCompanySubscriptionInput!) {
  updateCompanySubscription(
    updateCompanySubscriptionInput: $updateCompanySubscriptionInput
  ) {
    success
  }
}
    `;
export type UpdateCompanySubscriptionMutationFn = Apollo.MutationFunction<UpdateCompanySubscriptionMutation, UpdateCompanySubscriptionMutationVariables>;

/**
 * __useUpdateCompanySubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySubscriptionMutation, { data, loading, error }] = useUpdateCompanySubscriptionMutation({
 *   variables: {
 *      updateCompanySubscriptionInput: // value for 'updateCompanySubscriptionInput'
 *   },
 * });
 */
export function useUpdateCompanySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanySubscriptionMutation, UpdateCompanySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanySubscriptionMutation, UpdateCompanySubscriptionMutationVariables>(UpdateCompanySubscriptionDocument, options);
      }
export type UpdateCompanySubscriptionMutationHookResult = ReturnType<typeof useUpdateCompanySubscriptionMutation>;
export type UpdateCompanySubscriptionMutationResult = Apollo.MutationResult<UpdateCompanySubscriptionMutation>;
export type UpdateCompanySubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateCompanySubscriptionMutation, UpdateCompanySubscriptionMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($updateInvoiceInput: UpdateInvoiceInput!) {
  updateInvoice(updateInvoiceInput: $updateInvoiceInput) {
    _id
    paymentDue
    type
  }
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      updateInvoiceInput: // value for 'updateInvoiceInput'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const UpdateInvoicesPaymentStatusDocument = gql`
    mutation UpdateInvoicesPaymentStatus($updateInvoicesPaymentsStatusInput: UpdateInvoicesPaymentStatusInput!) {
  updateInvoicesPaymentStatus(
    updateInvoicesPaymentsStatusInput: $updateInvoicesPaymentsStatusInput
  ) {
    success
  }
}
    `;
export type UpdateInvoicesPaymentStatusMutationFn = Apollo.MutationFunction<UpdateInvoicesPaymentStatusMutation, UpdateInvoicesPaymentStatusMutationVariables>;

/**
 * __useUpdateInvoicesPaymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicesPaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicesPaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicesPaymentStatusMutation, { data, loading, error }] = useUpdateInvoicesPaymentStatusMutation({
 *   variables: {
 *      updateInvoicesPaymentsStatusInput: // value for 'updateInvoicesPaymentsStatusInput'
 *   },
 * });
 */
export function useUpdateInvoicesPaymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoicesPaymentStatusMutation, UpdateInvoicesPaymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoicesPaymentStatusMutation, UpdateInvoicesPaymentStatusMutationVariables>(UpdateInvoicesPaymentStatusDocument, options);
      }
export type UpdateInvoicesPaymentStatusMutationHookResult = ReturnType<typeof useUpdateInvoicesPaymentStatusMutation>;
export type UpdateInvoicesPaymentStatusMutationResult = Apollo.MutationResult<UpdateInvoicesPaymentStatusMutation>;
export type UpdateInvoicesPaymentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInvoicesPaymentStatusMutation, UpdateInvoicesPaymentStatusMutationVariables>;
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($updateNotificationInput: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $updateNotificationInput) {
    _id
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      updateNotificationInput: // value for 'updateNotificationInput'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    _id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation VerifyAccount($verifyAccountInput: VerifyAccountInput!) {
  verifyAccount(verifyAccountInput: $verifyAccountInput) {
    success
    companyEmail
  }
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      verifyAccountInput: // value for 'verifyAccountInput'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const BudgetDocument = gql`
    query Budget($budgetId: ID!) {
  budget(id: $budgetId) {
    _id
    name
    dateFrom
    dateTo
    costCategoriesTree {
      value
      category {
        _id
        name
      }
      children {
        category {
          _id
          name
        }
        value
        months {
          month
          value
          weeks {
            week
            value
          }
        }
      }
      months {
        value
        month
        weeks {
          week
          value
        }
      }
    }
    incomeCategoriesTree {
      value
      category {
        _id
        name
      }
      children {
        category {
          _id
          name
        }
        value
        months {
          month
          value
          weeks {
            week
            value
          }
        }
      }
      months {
        value
        month
        weeks {
          week
          value
        }
      }
    }
  }
}
    `;

/**
 * __useBudgetQuery__
 *
 * To run a query within a React component, call `useBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetQuery({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *   },
 * });
 */
export function useBudgetQuery(baseOptions: Apollo.QueryHookOptions<BudgetQuery, BudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetQuery, BudgetQueryVariables>(BudgetDocument, options);
      }
export function useBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetQuery, BudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetQuery, BudgetQueryVariables>(BudgetDocument, options);
        }
export function useBudgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetQuery, BudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BudgetQuery, BudgetQueryVariables>(BudgetDocument, options);
        }
export type BudgetQueryHookResult = ReturnType<typeof useBudgetQuery>;
export type BudgetLazyQueryHookResult = ReturnType<typeof useBudgetLazyQuery>;
export type BudgetSuspenseQueryHookResult = ReturnType<typeof useBudgetSuspenseQuery>;
export type BudgetQueryResult = Apollo.QueryResult<BudgetQuery, BudgetQueryVariables>;
export const BudgetCategoriesDocument = gql`
    query BudgetCategories($options: GetBudgetCategoriesOptionsInput!) {
  budgetCategories(options: $options) {
    documents {
      _id
      isLeaf
      name
      parent {
        _id
        name
      }
      type
    }
  }
}
    `;

/**
 * __useBudgetCategoriesQuery__
 *
 * To run a query within a React component, call `useBudgetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetCategoriesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBudgetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>(BudgetCategoriesDocument, options);
      }
export function useBudgetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>(BudgetCategoriesDocument, options);
        }
export function useBudgetCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>(BudgetCategoriesDocument, options);
        }
export type BudgetCategoriesQueryHookResult = ReturnType<typeof useBudgetCategoriesQuery>;
export type BudgetCategoriesLazyQueryHookResult = ReturnType<typeof useBudgetCategoriesLazyQuery>;
export type BudgetCategoriesSuspenseQueryHookResult = ReturnType<typeof useBudgetCategoriesSuspenseQuery>;
export type BudgetCategoriesQueryResult = Apollo.QueryResult<BudgetCategoriesQuery, BudgetCategoriesQueryVariables>;
export const BudgetCostsAndIncomesPerMonthDocument = gql`
    query BudgetCostsAndIncomesPerMonth($name: String, $id: ID, $isDefault: Boolean) {
  budgetCostsAndIncomesPerMonth(name: $name, id: $id, isDefault: $isDefault) {
    costsAndIncomesPerMonth {
      isPrognosis
      month
      totalCosts
      totalIncomes
    }
  }
}
    `;

/**
 * __useBudgetCostsAndIncomesPerMonthQuery__
 *
 * To run a query within a React component, call `useBudgetCostsAndIncomesPerMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetCostsAndIncomesPerMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetCostsAndIncomesPerMonthQuery({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useBudgetCostsAndIncomesPerMonthQuery(baseOptions?: Apollo.QueryHookOptions<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>(BudgetCostsAndIncomesPerMonthDocument, options);
      }
export function useBudgetCostsAndIncomesPerMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>(BudgetCostsAndIncomesPerMonthDocument, options);
        }
export function useBudgetCostsAndIncomesPerMonthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>(BudgetCostsAndIncomesPerMonthDocument, options);
        }
export type BudgetCostsAndIncomesPerMonthQueryHookResult = ReturnType<typeof useBudgetCostsAndIncomesPerMonthQuery>;
export type BudgetCostsAndIncomesPerMonthLazyQueryHookResult = ReturnType<typeof useBudgetCostsAndIncomesPerMonthLazyQuery>;
export type BudgetCostsAndIncomesPerMonthSuspenseQueryHookResult = ReturnType<typeof useBudgetCostsAndIncomesPerMonthSuspenseQuery>;
export type BudgetCostsAndIncomesPerMonthQueryResult = Apollo.QueryResult<BudgetCostsAndIncomesPerMonthQuery, BudgetCostsAndIncomesPerMonthQueryVariables>;
export const BudgetDocumentsDocument = gql`
    query BudgetDocuments($options: GetBudgetDocumentsOptionsInput!) {
  budgetDocuments(options: $options) {
    documents {
      _id
      billFrom {
        name
      }
      billTo {
        name
      }
      subtotal
      total
      paymentStatus
      type
      genre
      description
      invoiceNumber
      paymentDue
      category {
        parent {
          _id
          name
        }
        _id
        name
      }
    }
    total
  }
}
    `;

/**
 * __useBudgetDocumentsQuery__
 *
 * To run a query within a React component, call `useBudgetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetDocumentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBudgetDocumentsQuery(baseOptions: Apollo.QueryHookOptions<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>(BudgetDocumentsDocument, options);
      }
export function useBudgetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>(BudgetDocumentsDocument, options);
        }
export function useBudgetDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>(BudgetDocumentsDocument, options);
        }
export type BudgetDocumentsQueryHookResult = ReturnType<typeof useBudgetDocumentsQuery>;
export type BudgetDocumentsLazyQueryHookResult = ReturnType<typeof useBudgetDocumentsLazyQuery>;
export type BudgetDocumentsSuspenseQueryHookResult = ReturnType<typeof useBudgetDocumentsSuspenseQuery>;
export type BudgetDocumentsQueryResult = Apollo.QueryResult<BudgetDocumentsQuery, BudgetDocumentsQueryVariables>;
export const BudgetsDocument = gql`
    query Budgets($options: GetBudgetsOptionsInput!) {
  budgets(options: $options) {
    documents {
      _id
      createdAt
      dateFrom
      dateTo
      name
      isDefault
    }
  }
}
    `;

/**
 * __useBudgetsQuery__
 *
 * To run a query within a React component, call `useBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBudgetsQuery(baseOptions: Apollo.QueryHookOptions<BudgetsQuery, BudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetsQuery, BudgetsQueryVariables>(BudgetsDocument, options);
      }
export function useBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetsQuery, BudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetsQuery, BudgetsQueryVariables>(BudgetsDocument, options);
        }
export function useBudgetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetsQuery, BudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BudgetsQuery, BudgetsQueryVariables>(BudgetsDocument, options);
        }
export type BudgetsQueryHookResult = ReturnType<typeof useBudgetsQuery>;
export type BudgetsLazyQueryHookResult = ReturnType<typeof useBudgetsLazyQuery>;
export type BudgetsSuspenseQueryHookResult = ReturnType<typeof useBudgetsSuspenseQuery>;
export type BudgetsQueryResult = Apollo.QueryResult<BudgetsQuery, BudgetsQueryVariables>;
export const CheckAuthDocument = gql`
    query CheckAuth {
  checkAuth {
    user {
      _id
      firstName
      lastName
      email
      phone
      role
      permissions {
        resource
        actions
      }
      company {
        _id
        name
        email
        companyId
        address
        city
        postalCode
        country
        subscription {
          trialDateEnd
          subscription {
            _id
            name
            subtotal
            permissionsEnabled
            maxUsersCount
            taxesCalculationEnabled
          }
          dateEnd
          dateFrom
          isTrial
        }
        integrationData {
          type
          apiKey
          secretKey
        }
        paymentMethod {
          maskedCardNumber
        }
      }
      subscribed
    }
    accessToken
  }
}
    `;

/**
 * __useCheckAuthQuery__
 *
 * To run a query within a React component, call `useCheckAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuthQuery(baseOptions?: Apollo.QueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
      }
export function useCheckAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
        }
export function useCheckAuthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
        }
export type CheckAuthQueryHookResult = ReturnType<typeof useCheckAuthQuery>;
export type CheckAuthLazyQueryHookResult = ReturnType<typeof useCheckAuthLazyQuery>;
export type CheckAuthSuspenseQueryHookResult = ReturnType<typeof useCheckAuthSuspenseQuery>;
export type CheckAuthQueryResult = Apollo.QueryResult<CheckAuthQuery, CheckAuthQueryVariables>;
export const CheckLiquidityAganistFinancialMinimumDocument = gql`
    query CheckLiquidityAganistFinancialMinimum($options: CheckLiquidityAgainstFinancialMinimumOptionsInput!) {
  checkLiquidityAganistFinancialMinimum(options: $options) {
    success
  }
}
    `;

/**
 * __useCheckLiquidityAganistFinancialMinimumQuery__
 *
 * To run a query within a React component, call `useCheckLiquidityAganistFinancialMinimumQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLiquidityAganistFinancialMinimumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLiquidityAganistFinancialMinimumQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCheckLiquidityAganistFinancialMinimumQuery(baseOptions: Apollo.QueryHookOptions<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>(CheckLiquidityAganistFinancialMinimumDocument, options);
      }
export function useCheckLiquidityAganistFinancialMinimumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>(CheckLiquidityAganistFinancialMinimumDocument, options);
        }
export function useCheckLiquidityAganistFinancialMinimumSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>(CheckLiquidityAganistFinancialMinimumDocument, options);
        }
export type CheckLiquidityAganistFinancialMinimumQueryHookResult = ReturnType<typeof useCheckLiquidityAganistFinancialMinimumQuery>;
export type CheckLiquidityAganistFinancialMinimumLazyQueryHookResult = ReturnType<typeof useCheckLiquidityAganistFinancialMinimumLazyQuery>;
export type CheckLiquidityAganistFinancialMinimumSuspenseQueryHookResult = ReturnType<typeof useCheckLiquidityAganistFinancialMinimumSuspenseQuery>;
export type CheckLiquidityAganistFinancialMinimumQueryResult = Apollo.QueryResult<CheckLiquidityAganistFinancialMinimumQuery, CheckLiquidityAganistFinancialMinimumQueryVariables>;
export const CheckOrderStatusDocument = gql`
    query CheckOrderStatus($orderId: ID!) {
  checkOrderStatus(id: $orderId) {
    status
  }
}
    `;

/**
 * __useCheckOrderStatusQuery__
 *
 * To run a query within a React component, call `useCheckOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOrderStatusQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckOrderStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>(CheckOrderStatusDocument, options);
      }
export function useCheckOrderStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>(CheckOrderStatusDocument, options);
        }
export function useCheckOrderStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>(CheckOrderStatusDocument, options);
        }
export type CheckOrderStatusQueryHookResult = ReturnType<typeof useCheckOrderStatusQuery>;
export type CheckOrderStatusLazyQueryHookResult = ReturnType<typeof useCheckOrderStatusLazyQuery>;
export type CheckOrderStatusSuspenseQueryHookResult = ReturnType<typeof useCheckOrderStatusSuspenseQuery>;
export type CheckOrderStatusQueryResult = Apollo.QueryResult<CheckOrderStatusQuery, CheckOrderStatusQueryVariables>;
export const CheckOverdueInvoicesDocument = gql`
    query CheckOverdueInvoices {
  checkOverdueInvoices {
    success
  }
}
    `;

/**
 * __useCheckOverdueInvoicesQuery__
 *
 * To run a query within a React component, call `useCheckOverdueInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOverdueInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOverdueInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckOverdueInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>(CheckOverdueInvoicesDocument, options);
      }
export function useCheckOverdueInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>(CheckOverdueInvoicesDocument, options);
        }
export function useCheckOverdueInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>(CheckOverdueInvoicesDocument, options);
        }
export type CheckOverdueInvoicesQueryHookResult = ReturnType<typeof useCheckOverdueInvoicesQuery>;
export type CheckOverdueInvoicesLazyQueryHookResult = ReturnType<typeof useCheckOverdueInvoicesLazyQuery>;
export type CheckOverdueInvoicesSuspenseQueryHookResult = ReturnType<typeof useCheckOverdueInvoicesSuspenseQuery>;
export type CheckOverdueInvoicesQueryResult = Apollo.QueryResult<CheckOverdueInvoicesQuery, CheckOverdueInvoicesQueryVariables>;
export const CompanyDocument = gql`
    query Company($companyId: ID!) {
  company(id: $companyId) {
    _id
    users {
      _id
      email
      firstName
      lastName
      phone
      role
    }
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export function useCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanySuspenseQueryHookResult = ReturnType<typeof useCompanySuspenseQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompanyAccountBalanceDocument = gql`
    query CompanyAccountBalance($companyId: ID!) {
  company(id: $companyId) {
    _id
    accountBalance
    vatAccountBalance
    startAccountBalance
    startVatAccountBalance
    startAccountValuesDate
    taxDetails {
      isVatPayer
    }
  }
}
    `;

/**
 * __useCompanyAccountBalanceQuery__
 *
 * To run a query within a React component, call `useCompanyAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAccountBalanceQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyAccountBalanceQuery(baseOptions: Apollo.QueryHookOptions<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>(CompanyAccountBalanceDocument, options);
      }
export function useCompanyAccountBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>(CompanyAccountBalanceDocument, options);
        }
export function useCompanyAccountBalanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>(CompanyAccountBalanceDocument, options);
        }
export type CompanyAccountBalanceQueryHookResult = ReturnType<typeof useCompanyAccountBalanceQuery>;
export type CompanyAccountBalanceLazyQueryHookResult = ReturnType<typeof useCompanyAccountBalanceLazyQuery>;
export type CompanyAccountBalanceSuspenseQueryHookResult = ReturnType<typeof useCompanyAccountBalanceSuspenseQuery>;
export type CompanyAccountBalanceQueryResult = Apollo.QueryResult<CompanyAccountBalanceQuery, CompanyAccountBalanceQueryVariables>;
export const CompanyCarsDocument = gql`
    query CompanyCars($companyId: ID!) {
  company(id: $companyId) {
    cars {
      subtotal
      name
      formOfUse
      financingForm
      _id
      deletedAt
    }
  }
}
    `;

/**
 * __useCompanyCarsQuery__
 *
 * To run a query within a React component, call `useCompanyCarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCarsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyCarsQuery(baseOptions: Apollo.QueryHookOptions<CompanyCarsQuery, CompanyCarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyCarsQuery, CompanyCarsQueryVariables>(CompanyCarsDocument, options);
      }
export function useCompanyCarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyCarsQuery, CompanyCarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyCarsQuery, CompanyCarsQueryVariables>(CompanyCarsDocument, options);
        }
export function useCompanyCarsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyCarsQuery, CompanyCarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyCarsQuery, CompanyCarsQueryVariables>(CompanyCarsDocument, options);
        }
export type CompanyCarsQueryHookResult = ReturnType<typeof useCompanyCarsQuery>;
export type CompanyCarsLazyQueryHookResult = ReturnType<typeof useCompanyCarsLazyQuery>;
export type CompanyCarsSuspenseQueryHookResult = ReturnType<typeof useCompanyCarsSuspenseQuery>;
export type CompanyCarsQueryResult = Apollo.QueryResult<CompanyCarsQuery, CompanyCarsQueryVariables>;
export const CompanyLegalFormDocument = gql`
    query CompanyLegalForm($companyId: ID!) {
  company(id: $companyId) {
    legalForm
  }
}
    `;

/**
 * __useCompanyLegalFormQuery__
 *
 * To run a query within a React component, call `useCompanyLegalFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLegalFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLegalFormQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyLegalFormQuery(baseOptions: Apollo.QueryHookOptions<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>(CompanyLegalFormDocument, options);
      }
export function useCompanyLegalFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>(CompanyLegalFormDocument, options);
        }
export function useCompanyLegalFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>(CompanyLegalFormDocument, options);
        }
export type CompanyLegalFormQueryHookResult = ReturnType<typeof useCompanyLegalFormQuery>;
export type CompanyLegalFormLazyQueryHookResult = ReturnType<typeof useCompanyLegalFormLazyQuery>;
export type CompanyLegalFormSuspenseQueryHookResult = ReturnType<typeof useCompanyLegalFormSuspenseQuery>;
export type CompanyLegalFormQueryResult = Apollo.QueryResult<CompanyLegalFormQuery, CompanyLegalFormQueryVariables>;
export const CompanyOcrPagesLeftDocument = gql`
    query CompanyOcrPagesLeft($companyId: ID!) {
  company(id: $companyId) {
    ocrPagesLeft
    additionalOcrPages
  }
}
    `;

/**
 * __useCompanyOcrPagesLeftQuery__
 *
 * To run a query within a React component, call `useCompanyOcrPagesLeftQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOcrPagesLeftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOcrPagesLeftQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyOcrPagesLeftQuery(baseOptions: Apollo.QueryHookOptions<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>(CompanyOcrPagesLeftDocument, options);
      }
export function useCompanyOcrPagesLeftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>(CompanyOcrPagesLeftDocument, options);
        }
export function useCompanyOcrPagesLeftSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>(CompanyOcrPagesLeftDocument, options);
        }
export type CompanyOcrPagesLeftQueryHookResult = ReturnType<typeof useCompanyOcrPagesLeftQuery>;
export type CompanyOcrPagesLeftLazyQueryHookResult = ReturnType<typeof useCompanyOcrPagesLeftLazyQuery>;
export type CompanyOcrPagesLeftSuspenseQueryHookResult = ReturnType<typeof useCompanyOcrPagesLeftSuspenseQuery>;
export type CompanyOcrPagesLeftQueryResult = Apollo.QueryResult<CompanyOcrPagesLeftQuery, CompanyOcrPagesLeftQueryVariables>;
export const CompanyPaymentMethodDocument = gql`
    query CompanyPaymentMethod($companyId: ID!) {
  company(id: $companyId) {
    paymentMethod {
      maskedCardNumber
    }
  }
}
    `;

/**
 * __useCompanyPaymentMethodQuery__
 *
 * To run a query within a React component, call `useCompanyPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPaymentMethodQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>(CompanyPaymentMethodDocument, options);
      }
export function useCompanyPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>(CompanyPaymentMethodDocument, options);
        }
export function useCompanyPaymentMethodSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>(CompanyPaymentMethodDocument, options);
        }
export type CompanyPaymentMethodQueryHookResult = ReturnType<typeof useCompanyPaymentMethodQuery>;
export type CompanyPaymentMethodLazyQueryHookResult = ReturnType<typeof useCompanyPaymentMethodLazyQuery>;
export type CompanyPaymentMethodSuspenseQueryHookResult = ReturnType<typeof useCompanyPaymentMethodSuspenseQuery>;
export type CompanyPaymentMethodQueryResult = Apollo.QueryResult<CompanyPaymentMethodQuery, CompanyPaymentMethodQueryVariables>;
export const CompanySubscriptionDocument = gql`
    query CompanySubscription($companyId: ID!) {
  company(id: $companyId) {
    subscription {
      trialDateEnd
      subscription {
        _id
        name
        subtotal
      }
      dateEnd
      dateFrom
      isTrial
    }
    newSubscription {
      _id
      name
    }
  }
}
    `;

/**
 * __useCompanySubscriptionQuery__
 *
 * To run a query within a React component, call `useCompanySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySubscriptionQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanySubscriptionQuery(baseOptions: Apollo.QueryHookOptions<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>(CompanySubscriptionDocument, options);
      }
export function useCompanySubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>(CompanySubscriptionDocument, options);
        }
export function useCompanySubscriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>(CompanySubscriptionDocument, options);
        }
export type CompanySubscriptionQueryHookResult = ReturnType<typeof useCompanySubscriptionQuery>;
export type CompanySubscriptionLazyQueryHookResult = ReturnType<typeof useCompanySubscriptionLazyQuery>;
export type CompanySubscriptionSuspenseQueryHookResult = ReturnType<typeof useCompanySubscriptionSuspenseQuery>;
export type CompanySubscriptionQueryResult = Apollo.QueryResult<CompanySubscriptionQuery, CompanySubscriptionQueryVariables>;
export const CompanyTaxDetailsDocument = gql`
    query CompanyTaxDetails($companyId: ID!) {
  company(id: $companyId) {
    taxDetails {
      vatType
      isVatPayer
      taxType
      pitTaxType
      flatRateTaxAmount
      citTaxAmount
    }
  }
}
    `;

/**
 * __useCompanyTaxDetailsQuery__
 *
 * To run a query within a React component, call `useCompanyTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTaxDetailsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyTaxDetailsQuery(baseOptions: Apollo.QueryHookOptions<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>(CompanyTaxDetailsDocument, options);
      }
export function useCompanyTaxDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>(CompanyTaxDetailsDocument, options);
        }
export function useCompanyTaxDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>(CompanyTaxDetailsDocument, options);
        }
export type CompanyTaxDetailsQueryHookResult = ReturnType<typeof useCompanyTaxDetailsQuery>;
export type CompanyTaxDetailsLazyQueryHookResult = ReturnType<typeof useCompanyTaxDetailsLazyQuery>;
export type CompanyTaxDetailsSuspenseQueryHookResult = ReturnType<typeof useCompanyTaxDetailsSuspenseQuery>;
export type CompanyTaxDetailsQueryResult = Apollo.QueryResult<CompanyTaxDetailsQuery, CompanyTaxDetailsQueryVariables>;
export const IncomeTaxDocument = gql`
    query IncomeTax($options: GetIncomeTaxArgsOptionsInput!) {
  incomeTax(options: $options) {
    incomeTaxValue
  }
}
    `;

/**
 * __useIncomeTaxQuery__
 *
 * To run a query within a React component, call `useIncomeTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomeTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomeTaxQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useIncomeTaxQuery(baseOptions: Apollo.QueryHookOptions<IncomeTaxQuery, IncomeTaxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomeTaxQuery, IncomeTaxQueryVariables>(IncomeTaxDocument, options);
      }
export function useIncomeTaxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomeTaxQuery, IncomeTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomeTaxQuery, IncomeTaxQueryVariables>(IncomeTaxDocument, options);
        }
export function useIncomeTaxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IncomeTaxQuery, IncomeTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IncomeTaxQuery, IncomeTaxQueryVariables>(IncomeTaxDocument, options);
        }
export type IncomeTaxQueryHookResult = ReturnType<typeof useIncomeTaxQuery>;
export type IncomeTaxLazyQueryHookResult = ReturnType<typeof useIncomeTaxLazyQuery>;
export type IncomeTaxSuspenseQueryHookResult = ReturnType<typeof useIncomeTaxSuspenseQuery>;
export type IncomeTaxQueryResult = Apollo.QueryResult<IncomeTaxQuery, IncomeTaxQueryVariables>;
export const InvoiceDocument = gql`
    query Invoice($invoiceId: ID!) {
  invoice(id: $invoiceId) {
    _id
    billFrom {
      address
      city
      country
      name
      postcode
      companyId
    }
    billTo {
      postcode
      name
      country
      companyId
      city
      address
    }
    description
    invoiceNumber
    paymentDue
    issueDate
    saleDate
    subtotal
    total
    genre
    type
    subtype
    filePath
    paymentStatus
    interest
    capital
    car {
      _id
      name
    }
    settlementPeriodType
    settlementPeriod
    splitPayment
    paymentDate
    externalService
  }
}
    `;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export function useInvoiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceSuspenseQueryHookResult = ReturnType<typeof useInvoiceSuspenseQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($options: GetInvoicesOptionsInput!) {
  invoices(options: $options) {
    total
    documents {
      _id
      invoiceNumber
      billTo {
        name
      }
      billFrom {
        name
      }
      paymentDue
      paymentStatus
      subtotal
      total
      type
      genre
      externalService
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export function useInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesSuspenseQueryHookResult = ReturnType<typeof useInvoicesSuspenseQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const LiquidityAnalysisDocument = gql`
    query LiquidityAnalysis($options: GetLiquidityAnalysisOptionsInput!) {
  liquidityAnalysis(options: $options) {
    startValue
    liquidity {
      d
      date
      liquidity
    }
  }
}
    `;

/**
 * __useLiquidityAnalysisQuery__
 *
 * To run a query within a React component, call `useLiquidityAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiquidityAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiquidityAnalysisQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useLiquidityAnalysisQuery(baseOptions: Apollo.QueryHookOptions<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>(LiquidityAnalysisDocument, options);
      }
export function useLiquidityAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>(LiquidityAnalysisDocument, options);
        }
export function useLiquidityAnalysisSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>(LiquidityAnalysisDocument, options);
        }
export type LiquidityAnalysisQueryHookResult = ReturnType<typeof useLiquidityAnalysisQuery>;
export type LiquidityAnalysisLazyQueryHookResult = ReturnType<typeof useLiquidityAnalysisLazyQuery>;
export type LiquidityAnalysisSuspenseQueryHookResult = ReturnType<typeof useLiquidityAnalysisSuspenseQuery>;
export type LiquidityAnalysisQueryResult = Apollo.QueryResult<LiquidityAnalysisQuery, LiquidityAnalysisQueryVariables>;
export const LiquidityAnalysisInvoicesDocument = gql`
    query LiquidityAnalysisInvoices($options: GetInvoicesOptionsInput!) {
  liquidityAnalysisInvoices(options: $options) {
    total
    documents {
      _id
      billFrom {
        name
      }
      billTo {
        name
      }
      externalService
      genre
      invoiceNumber
      paymentDate
      paymentDue
      paymentStatus
      subtotal
      total
      type
    }
  }
}
    `;

/**
 * __useLiquidityAnalysisInvoicesQuery__
 *
 * To run a query within a React component, call `useLiquidityAnalysisInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiquidityAnalysisInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiquidityAnalysisInvoicesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useLiquidityAnalysisInvoicesQuery(baseOptions: Apollo.QueryHookOptions<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>(LiquidityAnalysisInvoicesDocument, options);
      }
export function useLiquidityAnalysisInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>(LiquidityAnalysisInvoicesDocument, options);
        }
export function useLiquidityAnalysisInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>(LiquidityAnalysisInvoicesDocument, options);
        }
export type LiquidityAnalysisInvoicesQueryHookResult = ReturnType<typeof useLiquidityAnalysisInvoicesQuery>;
export type LiquidityAnalysisInvoicesLazyQueryHookResult = ReturnType<typeof useLiquidityAnalysisInvoicesLazyQuery>;
export type LiquidityAnalysisInvoicesSuspenseQueryHookResult = ReturnType<typeof useLiquidityAnalysisInvoicesSuspenseQuery>;
export type LiquidityAnalysisInvoicesQueryResult = Apollo.QueryResult<LiquidityAnalysisInvoicesQuery, LiquidityAnalysisInvoicesQueryVariables>;
export const MonthlyLiquiditiesDocument = gql`
    query MonthlyLiquidities($options: GetLiquidityAnalysisOptionsInput!) {
  monthlyLiquidities(options: $options) {
    documents {
      month
      liquidity
    }
  }
}
    `;

/**
 * __useMonthlyLiquiditiesQuery__
 *
 * To run a query within a React component, call `useMonthlyLiquiditiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyLiquiditiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyLiquiditiesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMonthlyLiquiditiesQuery(baseOptions: Apollo.QueryHookOptions<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>(MonthlyLiquiditiesDocument, options);
      }
export function useMonthlyLiquiditiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>(MonthlyLiquiditiesDocument, options);
        }
export function useMonthlyLiquiditiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>(MonthlyLiquiditiesDocument, options);
        }
export type MonthlyLiquiditiesQueryHookResult = ReturnType<typeof useMonthlyLiquiditiesQuery>;
export type MonthlyLiquiditiesLazyQueryHookResult = ReturnType<typeof useMonthlyLiquiditiesLazyQuery>;
export type MonthlyLiquiditiesSuspenseQueryHookResult = ReturnType<typeof useMonthlyLiquiditiesSuspenseQuery>;
export type MonthlyLiquiditiesQueryResult = Apollo.QueryResult<MonthlyLiquiditiesQuery, MonthlyLiquiditiesQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($options: GetNotificationsOptionsInput!) {
  notifications(options: $options) {
    total
    documents {
      _id
      createdAt
      description
      type
      seen
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const OrdersDocument = gql`
    query Orders($options: GetOrdersOptionsInput!) {
  orders(options: $options) {
    documents {
      _id
      company {
        name
        companyId
      }
      createdAt
      number
      status
      subscription {
        name
      }
      package {
        name
      }
      subtotal
      total
      invoice
    }
    total
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export function useOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const PackagesDocument = gql`
    query Packages($options: GetPackagesOptionsInput!) {
  packages(options: $options) {
    total
    documents {
      OCRPagesCount
      _id
      createdAt
      deletedAt
      isActive
      name
      optionsIncluded
      subtotal
      total
      type
      updatedAt
      vat
    }
  }
}
    `;

/**
 * __usePackagesQuery__
 *
 * To run a query within a React component, call `usePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function usePackagesQuery(baseOptions: Apollo.QueryHookOptions<PackagesQuery, PackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesQuery, PackagesQueryVariables>(PackagesDocument, options);
      }
export function usePackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesQuery, PackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesQuery, PackagesQueryVariables>(PackagesDocument, options);
        }
export function usePackagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PackagesQuery, PackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PackagesQuery, PackagesQueryVariables>(PackagesDocument, options);
        }
export type PackagesQueryHookResult = ReturnType<typeof usePackagesQuery>;
export type PackagesLazyQueryHookResult = ReturnType<typeof usePackagesLazyQuery>;
export type PackagesSuspenseQueryHookResult = ReturnType<typeof usePackagesSuspenseQuery>;
export type PackagesQueryResult = Apollo.QueryResult<PackagesQuery, PackagesQueryVariables>;
export const ReadInvoiceDataDocument = gql`
    query ReadInvoiceData($file: Upload) {
  readInvoiceData(file: $file) {
    billFrom {
      postcode
      name
      city
      address
      companyId
    }
    billTo {
      postcode
      name
      city
      address
      companyId
    }
    invoiceNumber
    issueDate
    saleDate
    paymentDue
    subtotal
    total
    vat
    interest
  }
}
    `;

/**
 * __useReadInvoiceDataQuery__
 *
 * To run a query within a React component, call `useReadInvoiceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvoiceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvoiceDataQuery({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useReadInvoiceDataQuery(baseOptions?: Apollo.QueryHookOptions<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>(ReadInvoiceDataDocument, options);
      }
export function useReadInvoiceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>(ReadInvoiceDataDocument, options);
        }
export function useReadInvoiceDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>(ReadInvoiceDataDocument, options);
        }
export type ReadInvoiceDataQueryHookResult = ReturnType<typeof useReadInvoiceDataQuery>;
export type ReadInvoiceDataLazyQueryHookResult = ReturnType<typeof useReadInvoiceDataLazyQuery>;
export type ReadInvoiceDataSuspenseQueryHookResult = ReturnType<typeof useReadInvoiceDataSuspenseQuery>;
export type ReadInvoiceDataQueryResult = Apollo.QueryResult<ReadInvoiceDataQuery, ReadInvoiceDataQueryVariables>;
export const SendFeedbackDocument = gql`
    mutation SendFeedback($sendFeedbackInput: SendFeedbackInput!) {
  sendFeedback(sendFeedbackInput: $sendFeedbackInput) {
    success
  }
}
    `;
export type SendFeedbackMutationFn = Apollo.MutationFunction<SendFeedbackMutation, SendFeedbackMutationVariables>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      sendFeedbackInput: // value for 'sendFeedbackInput'
 *   },
 * });
 */
export function useSendFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SendFeedbackMutation, SendFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(SendFeedbackDocument, options);
      }
export type SendFeedbackMutationHookResult = ReturnType<typeof useSendFeedbackMutation>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<SendFeedbackMutation, SendFeedbackMutationVariables>;
export const SubscriptionsDocument = gql`
    query Subscriptions($options: GetSubscriptionsOptionsInput!) {
  userSubscriptions(options: $options) {
    documents {
      _id
      OCRPagesCount
      budgetingEnabled
      createdAt
      deletedAt
      description
      emailNotificationsEnabled
      isActive
      maxUsersCount
      name
      optionsExcluded
      optionsIncluded
      periodicity
      permissionsEnabled
      taxesCalculationEnabled
      subtotal
      total
      trialPeriod
      updatedAt
      vat
    }
    total
  }
}
    `;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export function useSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsSuspenseQueryHookResult = ReturnType<typeof useSubscriptionsSuspenseQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const TransactionsConditionDocument = gql`
    query TransactionsCondition($options: GetTransactionsConditionOptionsInput!) {
  transactionsCondition(options: $options) {
    percentageCosts
    percentageIncomes
    totalCosts
    totalIncomes
  }
}
    `;

/**
 * __useTransactionsConditionQuery__
 *
 * To run a query within a React component, call `useTransactionsConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsConditionQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useTransactionsConditionQuery(baseOptions: Apollo.QueryHookOptions<TransactionsConditionQuery, TransactionsConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsConditionQuery, TransactionsConditionQueryVariables>(TransactionsConditionDocument, options);
      }
export function useTransactionsConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsConditionQuery, TransactionsConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsConditionQuery, TransactionsConditionQueryVariables>(TransactionsConditionDocument, options);
        }
export function useTransactionsConditionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TransactionsConditionQuery, TransactionsConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TransactionsConditionQuery, TransactionsConditionQueryVariables>(TransactionsConditionDocument, options);
        }
export type TransactionsConditionQueryHookResult = ReturnType<typeof useTransactionsConditionQuery>;
export type TransactionsConditionLazyQueryHookResult = ReturnType<typeof useTransactionsConditionLazyQuery>;
export type TransactionsConditionSuspenseQueryHookResult = ReturnType<typeof useTransactionsConditionSuspenseQuery>;
export type TransactionsConditionQueryResult = Apollo.QueryResult<TransactionsConditionQuery, TransactionsConditionQueryVariables>;
export const UnpaidInvoicesDocument = gql`
    query UnpaidInvoices {
  unpaidInvoices {
    unpaidCosts
    unpaidCostsCount
    unpaidIncomes
    unpaidIncomesCount
  }
}
    `;

/**
 * __useUnpaidInvoicesQuery__
 *
 * To run a query within a React component, call `useUnpaidInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnpaidInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnpaidInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnpaidInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>(UnpaidInvoicesDocument, options);
      }
export function useUnpaidInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>(UnpaidInvoicesDocument, options);
        }
export function useUnpaidInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>(UnpaidInvoicesDocument, options);
        }
export type UnpaidInvoicesQueryHookResult = ReturnType<typeof useUnpaidInvoicesQuery>;
export type UnpaidInvoicesLazyQueryHookResult = ReturnType<typeof useUnpaidInvoicesLazyQuery>;
export type UnpaidInvoicesSuspenseQueryHookResult = ReturnType<typeof useUnpaidInvoicesSuspenseQuery>;
export type UnpaidInvoicesQueryResult = Apollo.QueryResult<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables>;
export const UserDocument = gql`
    query User($userId: ID!) {
  user(id: $userId) {
    _id
    company {
      _id
      address
      city
      companyId
      country
      email
      name
      phone
      postalCode
      subscription {
        trialDateEnd
        subscription {
          name
          permissionsEnabled
          maxUsersCount
          taxesCalculationEnabled
        }
        dateEnd
        dateFrom
        isTrial
      }
      paymentMethod {
        maskedCardNumber
      }
    }
    email
    firstName
    lastName
    phone
    permissions {
      resource
      actions
    }
    settings {
      financialMinimum {
        minimum
        dateFrom
        dateTo
        type
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserPermissionsDocument = gql`
    query UserPermissions($userId: ID!) {
  user(id: $userId) {
    _id
    permissions {
      resource
    }
  }
}
    `;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserPermissionsQuery(baseOptions: Apollo.QueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, options);
      }
export function useUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, options);
        }
export function useUserPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, options);
        }
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsSuspenseQueryHookResult = ReturnType<typeof useUserPermissionsSuspenseQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<UserPermissionsQuery, UserPermissionsQueryVariables>;
export const VatTaxDocument = gql`
    query VatTax($options: GetVatTaxArgsOptionsInput!) {
  vatTax(options: $options) {
    vatTaxValue
  }
}
    `;

/**
 * __useVatTaxQuery__
 *
 * To run a query within a React component, call `useVatTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatTaxQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useVatTaxQuery(baseOptions: Apollo.QueryHookOptions<VatTaxQuery, VatTaxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VatTaxQuery, VatTaxQueryVariables>(VatTaxDocument, options);
      }
export function useVatTaxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VatTaxQuery, VatTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VatTaxQuery, VatTaxQueryVariables>(VatTaxDocument, options);
        }
export function useVatTaxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VatTaxQuery, VatTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VatTaxQuery, VatTaxQueryVariables>(VatTaxDocument, options);
        }
export type VatTaxQueryHookResult = ReturnType<typeof useVatTaxQuery>;
export type VatTaxLazyQueryHookResult = ReturnType<typeof useVatTaxLazyQuery>;
export type VatTaxSuspenseQueryHookResult = ReturnType<typeof useVatTaxSuspenseQuery>;
export type VatTaxQueryResult = Apollo.QueryResult<VatTaxQuery, VatTaxQueryVariables>;