import { extendTailwindMerge } from "tailwind-merge";

export const customTwMerge = extendTailwindMerge<any>({
  extend: {
    theme: {},
    classGroups: {
      colors: [
        "brand-primary",
        "brand-secondary",

        "bg-primary",
        "bg-secondary",
        "bg-light-gray",
        "bg-light-gray2",
        "bg-light-gray3",
        "bg-light-gray4",

        "green",
        "red",
        "blue",
        "light-blue",
        "light-green",
        "light-red",

        "state-success",
        "state-error",
        "state-alert",
        "state-info",

        "text-primary",
        "text-secondary",
        "text-gray",
        "text-white",
      ],
      fontSize: [
        "text-6",
        "text-8",
        "text-10",
        "text-12",
        "text-14",
        "text-16",
        "text-18",
        "text-20",
        "text-22",
        "text-24",
        "text-26",
        "text-28",
        "text-30",
        "text-32",
        "text-34",
        "text-36",
        "text-38",
        "text-42",
      ],
    },
  },
});
