import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Loader from "src/components/ui/Loader";
import AppCheckbox from "src/components/form/AppCheckbox";

import { Permission, UserPermissionActionsEnum, UserPermissionResourcesEnum } from "src/api/graphql/types-and-hooks";

import updateUserPermissionsSchema from "./updateUserPermissions.schema";
import { useEditUser, useUserPermissions } from "./settings.hooks";

type Props = {
  userId: string;
  formRef: React.MutableRefObject<HTMLFormElement>;
  onPermissionsUpdated: () => void;
};

const availablePermissions = [
  { resource: UserPermissionResourcesEnum.Cockpit, label: "Cockpit", checked: false },
  { resource: UserPermissionResourcesEnum.Transactions, label: "Transactions", checked: false },
  { resource: UserPermissionResourcesEnum.PaymentsCalendar, label: "Payments-calendar", checked: false },
  { resource: UserPermissionResourcesEnum.LiquidityAnalysis, label: "Liquidity-analysis", checked: false },
  { resource: UserPermissionResourcesEnum.Budget, label: "Budget", checked: false },
  { resource: UserPermissionResourcesEnum.FinancialDetails, label: "Financial-details", checked: false },
  { resource: UserPermissionResourcesEnum.Account, label: "Moje konto", checked: false },
];

const UpdateUserPermissions: React.FC<Props> = ({ formRef, onPermissionsUpdated, userId }) => {
  const { t } = useTranslation();
  const { user, loading } = useUserPermissions(userId);
  const { editUser, isUpdatingUser } = useEditUser();

  useEffect(() => {
    if (user?.permissions) {
      // Refresh default values after user permissions are fetched
      reset({
        permissions: availablePermissions.map((permission) => {
          permission.checked = false;
          if (user?.permissions?.find((p) => p.resource === permission.resource)) {
            permission.checked = true;
          }
          return permission;
        }),
      });
    }
  }, [user?.permissions]);

  const { control, register, handleSubmit, reset } = useForm({
    resolver: yupResolver(updateUserPermissionsSchema),
    defaultValues: {
      permissions: availablePermissions.map((permission) => {
        if (user?.permissions?.find((p) => p.resource === permission.resource)) {
          permission.checked = true;
        }
        return permission;
      }),
    },
    reValidateMode: "onChange",
  });

  const { fields } = useFieldArray({
    control,
    name: "permissions",
  });

  const handleInviteUser = async (data: any) => {
    const selectedPermissions: Permission[] = data.permissions
      .filter((permission) => permission.checked)
      .map((permission) => ({
        resource: permission.resource,
        actions: [
          UserPermissionActionsEnum.Get,
          UserPermissionActionsEnum.Getlist,
          UserPermissionActionsEnum.Create,
          UserPermissionActionsEnum.Update,
          UserPermissionActionsEnum.Delete,
        ],
      }));

    await editUser({ id: userId, permissions: selectedPermissions });
    onPermissionsUpdated();
  };

  if (loading || isUpdatingUser) return <Loader />;

  return (
    <>
      <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Update-permissions")}</Text>
      <form ref={formRef} onSubmit={handleSubmit(handleInviteUser)}>
        <div className="flex flex-col space-y-4">
          <Label>{t("Permissions")}</Label>
          {fields.map((permission, index) => (
            <Controller
              name={`permissions.${index}.resource`}
              control={control}
              key={permission.id}
              render={() => (
                <AppCheckbox
                  id={`permissions.${index}.checked`}
                  register={register}
                  label={t(permission.label)}
                  checked={permission.checked}
                />
              )}
            />
          ))}
        </div>
      </form>
    </>
  );
};
export default UpdateUserPermissions;
