import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Loader from "src/components/ui/Loader";
import AppSelect from "src/components/form/AppSelect";
import AccountBalanceInfo from "src/components/ui/AccountBalanceInfo";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getCockpitPath } from "src/common/router/routerPaths";

import { CompanyIntegrationTypes } from "src/api/graphql/types-and-hooks";

import SettingsWrapper from "./SettingsWrapper";
import integrationsSchema from "./integrations.schema";
import { useCompanyAccountBalance, useEditCompany } from "./settings.hooks";

const Integrations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAccountBalanceInfoModal, setShowAccountBalanceInfoModal] = useState(false);
  // const IntegrationTypeOptions = Object.values(CompanyIntegrationTypes).map((c) => ({ label: t(c), value: c }));
  const IntegrationTypeOptions = [{ label: "InFakt", value: CompanyIntegrationTypes.Infakt }];

  const loggedUser = useCurrentUser();
  const { loading, companyAccountBalance } = useCompanyAccountBalance(loggedUser?.company?._id);
  const { isUpdatingCompany, editCompany } = useEditCompany();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    values: {
      type: loggedUser?.company?.integrationData?.type,
      apiKey: loggedUser?.company?.integrationData?.apiKey,
      secretKey: loggedUser?.company?.integrationData?.secretKey,
    },
    resolver: yupResolver(integrationsSchema),
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    editCompany({ id: loggedUser?.company?._id, integrationData: data });
  };

  useEffect(() => {
    if (!isEmpty(companyAccountBalance)) {
      const { startAccountBalance, startVatAccountBalance, taxDetails } = companyAccountBalance;
      if (!startAccountBalance || (taxDetails?.isVatPayer && !startVatAccountBalance)) {
        setShowAccountBalanceInfoModal(true);
      }
    }
  }, [companyAccountBalance]);

  return (
    <>
      {showAccountBalanceInfoModal && <AccountBalanceInfo onCancel={() => navigate(getCockpitPath())} />}
      <SettingsWrapper>
        <div className="flex flex-col space-y-5">
          <div className="flex justify-end 2xl:h-10 xl:h-8 h-6">
            <div className="px-2">
              <AppButton
                title={t("Save")}
                onClick={handleSubmit(onSubmit)}
                color={ButtonColorTypes.PRIMARY}
                customClass="rounded-[8px]"
                textClass="text-white font-sf-pro-medium"
              />
            </div>
          </div>
          <div className="bg-white rounded-[20px] p-5">
            <div className="flex flex-col space-y-6">
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Integrations")}</Text>
              {isUpdatingCompany || loading ? (
                <Loader />
              ) : (
                <>
                  <div className="flex flex-col space-y-4">
                    <Label>{t("Select-integration-type")}</Label>
                    <div className="flex flex-row space-x-4">
                      <div className="w-[40%]">
                        <Controller
                          control={control}
                          name="type"
                          render={({ field }) => (
                            <AppSelect
                              options={IntegrationTypeOptions}
                              field={field}
                              placeholder="Wybierz..."
                              error={!!errors?.type}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-2 w-full">
                    {[CompanyIntegrationTypes.Infakt, CompanyIntegrationTypes.Wfirma].includes(watch("type")) ? (
                      <div className="flex flex-col space-y-2 w-[40%]">
                        <Label>{t("Apikey")}</Label>
                        <AppInput
                          id="apiKey"
                          register={register}
                          inputType={InputTypes.TEXT}
                          placeholder="Wpisz apkikey..."
                          error={!!errors.apiKey?.message}
                        />
                      </div>
                    ) : undefined}
                    {[CompanyIntegrationTypes.Wfirma].includes(watch("type")) ? (
                      <div className="flex flex-col space-y-2 w-[40%]">
                        <Label>{t("Secretkey")}</Label>
                        <AppInput
                          id="secretKey"
                          register={register}
                          inputType={InputTypes.TEXT}
                          placeholder="Wpisz secretkey..."
                          error={!!errors.secretKey?.message}
                        />
                      </div>
                    ) : undefined}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </SettingsWrapper>
    </>
  );
};
export default Integrations;
