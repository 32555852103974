export const buildCategoriesTree = (categories) => {
  const rootCategories = categories.filter(({ parent }) => !parent?._id);

  const categoriesTree = rootCategories.map((rootCategory) => {
    const children = categories.filter(({ parent }) => parent?._id === rootCategory._id);

    return {
      ...rootCategory,
      children,
    };
  });

  return categoriesTree;
};
