import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { User, UserRoles } from "src/api/graphql/types-and-hooks";

import PlusIcon from "src/assets/images/plus.svg";
import EditIcon from "src/assets/images/edit.svg";
import DeleteIcon from "src/assets/images/delete.svg";

import SettingsWrapper from "./SettingsWrapper";
import InviteUserToCompany from "./InviteUserToCompany";
import UpdateUserPermissions from "./UpdateUserPermissions";
import { useDeleteUserFromCompany, useLazyGetCompany } from "./settings.hooks";

type UserComponentProps = {
  user: Partial<User>;
};

const Users = () => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [userToDeleteId, setUserToDeleteId] = useState<string | null>(null);
  const [isUserInviting, setIsUserInviting] = useState<boolean>(false);
  const [userToEditId, setUserToEditId] = useState<string | null>(null);
  const loggedUser = useCurrentUser();
  const maxUsersCount = loggedUser?.company?.subscription?.subscription?.maxUsersCount;
  const permissionsEnabled = loggedUser?.company?.subscription?.subscription?.permissionsEnabled;

  const { t } = useTranslation();
  const { getCompany, company, loading } = useLazyGetCompany();
  const { handleDeleteUserFromCompany, isDeleting } = useDeleteUserFromCompany(company?._id);

  const inviteUserToCompanyRef = useRef<HTMLFormElement>();
  const updateUserPermissionsRef = useRef<HTMLFormElement>();

  useEffect(() => {
    if (isUserInviting) setUserToEditId(null);
  }, [isUserInviting]);

  useEffect(() => {
    if (userToEditId) setIsUserInviting(false);
  }, [userToEditId]);

  useEffect(() => {
    if (userToDeleteId) {
      setUserToEditId(null);
      setIsUserInviting(false);
    }
  }, [userToDeleteId]);

  useEffect(() => {
    if (loggedUser?.company?._id) getCompany(loggedUser?.company?._id);
  }, [loggedUser?.company?._id]);

  const UserComponent: React.FC<UserComponentProps> = ({ user: { _id, firstName, lastName, phone, email, role } }) => {
    return (
      <div className="bg-bg-light-gray2 rounded-[20px] flex flex-row px-5 2xl:py-5 xl:py-4 py-3 relative">
        <div className="flex flex-col 2xl:space-y-2 xl:space-y-1.5 space-y-1 justify-between">
          <Text className="2xl:text-18 xl:text-16 text-12 font-sf-pro-bold">{`${firstName} ${lastName}`}</Text>
          <Text className="2xl:text-18 xl:text-16 text-12 font-sf-pro-medium text-brand-primary">{phone}</Text>
          <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{email}</Text>
        </div>
        {role !== UserRoles.Admin && loggedUser.role === UserRoles.Admin ? (
          <>
            <div className="flex flex-row absolute right-3 cursor-pointer space-x-1">
              {permissionsEnabled ? (
                <img
                  src={EditIcon}
                  className="2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4"
                  onClick={() => {
                    setUserToEditId(_id);
                  }}
                />
              ) : undefined}
              <img
                src={DeleteIcon}
                className="2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4"
                onClick={() => {
                  setUserToDeleteId(_id);
                  setShowConfirm(true);
                }}
              />
            </div>
          </>
        ) : undefined}
      </div>
    );
  };

  const onDeleteUser = async () => {
    await handleDeleteUserFromCompany({ userId: userToDeleteId });
    setShowConfirm(false);
    setUserToDeleteId(null);
  };

  const onCloseModal = () => {
    setUserToDeleteId(null);
    setShowConfirm(false);
  };

  return (
    <>
      <Confirm
        show={showConfirm}
        onConfirm={onDeleteUser}
        onCancel={onCloseModal}
        loading={isDeleting}
        title={t("Delete-user-confirm")}
      />
      <SettingsWrapper>
        <div className="flex flex-col space-y-5">
          <div className={`flex justify-end ${!isUserInviting && "2xl:h-10 xl:h-8 h-6"}`}>
            {isUserInviting || userToEditId ? (
              <div className="px-2">
                <AppButton
                  title={t("Save")}
                  onClick={() =>
                    isUserInviting
                      ? inviteUserToCompanyRef.current.requestSubmit()
                      : updateUserPermissionsRef.current.requestSubmit()
                  }
                  color={ButtonColorTypes.PRIMARY}
                  customClass="rounded-[8px]"
                  textClass="text-white font-sf-pro-medium"
                />
              </div>
            ) : undefined}
          </div>
          <div className="bg-white rounded-[20px] p-5 flex flex-col space-y-5">
            <Text className="2xl:text-20 xl-text-18 text-16 font-sf-pro-bold">{t("Access-accounts")}</Text>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                  {company?.users?.map((user) => {
                    return <UserComponent key={user._id} user={user} />;
                  })}
                </div>
                {company?.users?.length < maxUsersCount && !isUserInviting && loggedUser.role === UserRoles.Admin ? (
                  <div
                    className="2xl:w-[230px] xl:w-[200px] w-[170px] 2xl:py-8 xl:py-6 py-4 border-dashed border-2 border-[#1C1C1C33] rounded-[16px] flex flex-row space-x-2 justify-center items-center cursor-pointer hover:border-blue"
                    onClick={() => setIsUserInviting(true)}
                  >
                    <img src={PlusIcon} />
                    <Text>{t("Add-user")}</Text>
                  </div>
                ) : undefined}
              </>
            )}
            {isUserInviting ? (
              <InviteUserToCompany formRef={inviteUserToCompanyRef} onUserInvited={() => setIsUserInviting(false)} />
            ) : undefined}
            {userToEditId ? (
              <UpdateUserPermissions
                formRef={updateUserPermissionsRef}
                onPermissionsUpdated={() => setUserToEditId(null)}
                userId={userToEditId}
              />
            ) : undefined}
          </div>
        </div>
      </SettingsWrapper>
    </>
  );
};

export default Users;
