import { date, object, string, number } from "yup";

export default object({
  value: number().required(),
  date: date().required(),
  type: string(),
  subtype: string(),
  category: string().required().default(undefined),
  subCategory: string().default(undefined),
}).required();
