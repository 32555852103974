import React from "react";
import { isEmpty } from "lodash";
import Loader from "./Loader";
import Text from "./Text";
import { customTwMerge } from "src/utils/twMerge";

type Props = {
  headers: string[];
  rows?: React.ReactNode[] | null;
  noDataMsg?: string;
  isLoading?: boolean;
  dark?: boolean;
};

const Table: React.FC<Props> = ({ headers, rows, noDataMsg = "Brak elementów", isLoading, dark = false }) => {
  const renderHeaders = () => {
    return headers.map((header: string, i: number) => {
      const roundFirst = i === 0 ? "rounded-l-[8px]" : undefined;
      const roundLast = i === headers?.length - 1 ? "rounded-r-[8px]" : undefined;
      const combinedHeaderClasses = customTwMerge(
        "flex text-start pr-2 text-10",
        i === 0 && "ml-1",
        dark && "text-white",
      );

      return (
        <th className={`${roundFirst} ${roundLast} 2xl:h-12 xl:h-10 h-8 font-normal`} key={Math.random()}>
          <Text className={combinedHeaderClasses}>{header}</Text>
        </th>
      );
    });
  };

  const renderRows = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={headers?.length}>
            <Loader />
          </td>
        </tr>
      );
    }

    if (!rows || isEmpty(rows)) {
      return (
        <tr>
          <td className="text-center" colSpan={headers.length}>
            <div className="mt-4">
              <Text className={`${dark && "text-white"}`}>{noDataMsg || "-"}</Text>
            </div>
          </td>
        </tr>
      );
    }

    return rows.map((row: React.ReactNode) => {
      return row;
    });
  };

  return (
    <div className="mx-2 my-5">
      <table className="w-full table-auto">
        <thead className={`${dark ? "bg-[#F8F9FC1A]" : "bg-bg-light-gray2"}`}>
          <tr>{renderHeaders()}</tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default Table;
