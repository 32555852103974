import React, { useEffect } from "react";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AuthWrapper from "src/components/ui/AuthWrapper";

import { getLoginPath } from "src/common/router/routerPaths";
import { useToastError } from "src/common/hooks/useToast";

import EyeIcon from "src/assets/images/eye.svg";

import registerUserSchema from "./registerUser.schema";
import { useAddUserToCompany } from "./registerUser.hooks";

const RegisterUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  const { handleAddUserToCompany, loading, errors } = useAddUserToCompany();

  const {
    register,
    handleSubmit,

    formState: { errors: formErrors },
  } = useForm({
    defaultValues: {
      code: searchParams.get("code") || null,
    },
    resolver: yupResolver(registerUserSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (formErrors?.code) {
      useToastError("Kod jest nieprawidłowy");
    }
  }, [formErrors]);

  return (
    <AuthWrapper>
      <div className="flex flex-col space-y-4 items-center w-full mt-4">
        <Text className="2xl:text-28 xl:text-24 text-20 text-white font-sf-pro-medium">Witaj</Text>
        <Text className="2xl:text-20 xl:text-18 text-16 text-text-secondary">
          Załóż konto i korzystaj z potencjału swojej firmy
        </Text>
        <div className="flex flex-col space-y-4 max-w-[85%] sm:max-w-[50%] w-full">
          <AppInput
            id="email"
            inputType={InputTypes.TEXT}
            placeholder={t("E-mail")}
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !formErrors?.email && !get(errors, "email") && "border-white/20 focus:border-white/60"
            }`}
            error={!!formErrors?.email || !!get(errors, "email")}
            errorMessage={get(errors, "email")}
          />
          <div className="flex flex-row space-x-4">
            <div className="basis-1/2">
              <AppInput
                id="firstName"
                inputType={InputTypes.TEXT}
                placeholder={t("First-name")}
                register={register}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !formErrors?.firstName && "border-white/20 focus:border-white/60"
                }`}
                error={!!formErrors?.firstName}
              />
            </div>
            <div className="basis-1/2">
              <AppInput
                id="lastName"
                inputType={InputTypes.TEXT}
                placeholder={t("Last-name")}
                register={register}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !formErrors?.lastName && "border-white/20 focus:border-white/60"
                }`}
                error={!!formErrors?.lastName}
              />
            </div>
          </div>
          <AppInput
            id="phone"
            inputType={InputTypes.TEXT}
            placeholder={t("Phone-number")}
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !formErrors?.phone && "border-white/20 focus:border-white/60"
            }`}
            error={!!formErrors?.phone}
          />
          <div className="flex flex-row space-x-4">
            <div className="basis-1/2">
              <AppInput
                iconEnd={EyeIcon}
                id="password"
                inputType={InputTypes.PASSWORD}
                placeholder={t("Password")}
                register={register}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !formErrors?.password && "border-white/20 focus:border-white/60"
                }`}
                error={!!formErrors?.password}
                errorMessage={formErrors?.password?.message}
              />
            </div>
            <div className="basis-1/2">
              <AppInput
                iconEnd={EyeIcon}
                id="passwordConfirmation"
                inputType={InputTypes.PASSWORD}
                placeholder={t("Repeat-password")}
                register={register}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !formErrors?.passwordConfirmation && "border-white/20 focus:border-white/60"
                }`}
                error={!!formErrors?.passwordConfirmation}
                errorMessage={formErrors?.passwordConfirmation?.message}
              />
            </div>
          </div>
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
            textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
            onClick={handleSubmit(handleAddUserToCompany)}
            title={t("Register")}
            isLoading={loading}
          />
        </div>
        <Text
          className="text-text-secondary cursor-pointer text-12 2xl:text-16 xl:text-14 sm:text-10"
          onClick={() => navigate(getLoginPath())}
        >
          Masz już konto?{" "}
          <Text className="font-sf-pro-medium text-white text-12 2xl:text-16 xl:text-14 sm:text-10">Zaloguj się</Text>
        </Text>
      </div>
    </AuthWrapper>
  );
};

export default RegisterUser;
