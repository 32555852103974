import { LoginInput } from "src/api/graphql/types-and-hooks";
import { useCheckOverdueInvoicesLazyQuery, useLoginMutation } from "src/api/graphql/generated/gql-hooks";

import { useIdentifySocket } from "src/common/Socket/socket.hooks";
import { useSetCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useLogin = () => {
  const setCurrentUser = useSetCurrentUser();
  const { checkOverdueInvoices } = useOverdueInvoices();

  const [login, { loading }] = useLoginMutation({
    onError: () => useToastError("Niepoprawne dane logowania"),
    onCompleted: (data) => {
      setCurrentUser(data.login);
      useIdentifySocket(data.login.user);
      useToastSuccess("Zalogowano");

      setTimeout(() => {
        checkOverdueInvoices();
      }, 5000);
    },
  });

  return {
    handleLogin: async (input: LoginInput) => {
      await login({
        variables: {
          loginInput: {
            email: input.email,
            password: input.password,
          },
        },
      });
    },
    loading,
  };
};

export const useOverdueInvoices = () => {
  const [checkOverdueInvoices] = useCheckOverdueInvoicesLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  return {
    checkOverdueInvoices: () => {
      checkOverdueInvoices({ variables: {} });
    },
  };
};
