import React from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Brush, Cell } from "recharts";

import Text from "src/components/ui/Text";

import { formatPrice } from "src/utils/currency";

export type ChartData = {
  name: string | number; // month name
  costs?: number; // month cost value
  incomes?: number; // month income value
  isPrognosis?: boolean; // is prognosis
  month: string; // month date
};

type Props = {
  data: ChartData[];
};

const Chart: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const brushEndIndex = data.length - 1 > 12 ? 12 : data.length - 1;

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload) {
      return (
        <div className="bg-white shadow-lg rounded-[12px] p-4">
          <div className="flex flex-row space-x-2">
            <Text className="text-text-secondary">{`${t(
              `${payload[0]?.payload?.isPrognosis ? "Prognosis-income" : "Income"}`,
            )}:`}</Text>
            <Text className="font-sf-pro-bold">{formatPrice(payload[0]?.value, "zł")}</Text>
          </div>
          <div className="flex flex-row space-x-2">
            <Text className="text-text-secondary">{`${t(
              `${payload[0]?.payload?.isPrognosis ? "Prognosis-cost" : "Cost"}`,
            )}:`}</Text>
            <Text className="font-sf-pro-bold">{formatPrice(payload[1]?.value, "zł")}</Text>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 70,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          strokeDasharray="0 0"
          stroke="#F6F7FB"
          verticalCoordinatesGenerator={({ width }) => {
            const segment = width / 7;
            return [segment, segment * 2, segment * 3, segment * 4, segment * 5, segment * 6];
          }}
          horizontalCoordinatesGenerator={({ height }) => {
            const segment = height / 4;
            return [segment, segment * 2, segment * 3, height];
          }}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "#f4f4f4" }} />
        <XAxis
          tick={{ fill: "#8E98A7" }}
          dataKey="name"
          tickLine={false}
          axisLine={{ stroke: "#F6F7FB" }}
          tickMargin={15}
          height={70}
          className="2xl:text-14 xl:text-12 text-10"
        />
        <YAxis
          tick={{ fill: "#8E98A7" }}
          tickLine={false}
          axisLine={{ stroke: "#F6F7FB" }}
          className="2xl:text-14 xl:text-12 text-10"
        />
        <Bar dataKey="incomes" radius={25} barSize={10}>
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.isPrognosis ? "#3183FF80" : "#3183FF"} />
          ))}
        </Bar>
        <Bar dataKey="costs" radius={25} barSize={10}>
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.isPrognosis ? "#ABE85F80" : "#ABE85F"} />
          ))}
        </Bar>
        <Brush
          dataKey="name"
          height={20}
          stroke="#3183FF"
          endIndex={brushEndIndex}
          travellerWidth={10}
          // fontSize="10px"
          className="2xl:text-14 xl:text-12 text-10"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
