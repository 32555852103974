export const getLoginPath = () => "/login";
export const getRegisterPath = () => "/register";
export const getRegisterUserPath = () => "/register-user";
export const getVerifyAccountPath = () => "/verify-account";

export const getResetPasswordPath = () => "/reset-password";
export const getForgotPasswordPath = () => "/forgot-password";

export const getCockpitPath = () => "/cockpit";

export const getInvoicesPath = ({
  showUnpaidCosts,
  showUnpaidIncomes,
}: {
  showUnpaidCosts?: boolean;
  showUnpaidIncomes?: boolean;
} = {}) => {
  let searchParams = "";
  if (showUnpaidCosts) {
    searchParams = `${searchParams ? `${searchParams}&` : ""}showUnpaidCosts=${showUnpaidCosts}`;
  }
  if (showUnpaidIncomes) {
    searchParams = `${searchParams ? `${searchParams}&` : ""}showUnpaidIncomes=${showUnpaidIncomes}`;
  }
  return searchParams !== "" ? `/invoices?${searchParams}` : "/invoices";
};
export const getInvoiceNewPath = ({ fromCalendar }: { fromCalendar?: boolean } = {}) => {
  let searchParams = "";
  if (fromCalendar) {
    searchParams = `fromCalendar=${fromCalendar}`;
  }

  return searchParams !== "" ? `/invoice/new?${searchParams}` : `/invoice/new`;
};
export const getInvoiceEditPath = ({ invoiceId = ":invoiceId" } = {}) => `/invoice/${invoiceId}`;
export const getInvoiceDetailsPath = ({ invoiceId = ":invoiceId" } = {}) => `/invoiceDetails/${invoiceId}`;

export const getSettingsUsersPath = () => "/settings/users";
export const getSettingsOrdersPath = () => "/settings/orders";
export const getSettingsAccountPath = () => "/settings/account";
export const getSettingsIntegrationsPath = () => "/settings/integrations";

export const getPaymentsCalendarPath = () => "/payments-calendar";

export const getLiquidityAnalysisPath = () => "/liquidity-analysis";

export const getBudgetsPath = () => "/budgets";
export const getBudgetNewPath = () => "/budget/new";
export const getBudgetDetailsPath = ({ budgetId = ":budgetId" } = {}) => `/budgetDetails/${budgetId}`;

export const getFeedbackPath = () => "/help";

export const getFinancialDetailsTaxesPath = () => "/financial-details/taxes";
export const getFinancialDetailsLegalFormPath = () => "/financial-details/legal-form";
export const getFinancialDetailsCarsLeasingPath = () => "/financial-details/cars-leasing";
export const getFinancialDetailsStartupDataPath = () => "/financial-details/startup-data";

export const getSelectSubscriptionPath = () => "/select-subscription";
export const getChangeSubscriptionPath = () => "/change-subscription";

export const getChangePaymentMethodPath = () => "/change-payment-method";

export const getSelectPackagePath = () => "/select-package";
