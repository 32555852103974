import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Confirm from "src/components/ui/Confirm";
import SubscriptionComponent from "src/components/ui/Subscription";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useToastError } from "src/common/hooks/useToast";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getLoginPath, getSettingsOrdersPath } from "src/common/router/routerPaths";

import LogoImage from "src/assets/images/logo.png";

import { useCompanySubscription, useSubscriptions, useUpdateCompanySubscription } from "./subscriptionChange.hooks";

const SubscriptionChange = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>("");

  const { t } = useTranslation();

  const { subscription } = useCompanySubscription(currentUser?.company._id);
  const currentSubscriptionId = subscription?.subscription._id;

  const { subscriptions } = useSubscriptions({ take: 0, skip: 0 });
  const { updateCompanySubscription, isUpdated, isUpdating } = useUpdateCompanySubscription();

  useEffect(() => {
    if (!currentUser) navigate(getLoginPath());
  }, [currentUser, navigate]);

  useEffect(() => {
    if (isUpdated) {
      setShowConfirm(false);
      navigate(getSettingsOrdersPath());
    }
  }, [isUpdated]);

  const renderSubscriptions = () => {
    return subscriptions.map((subscription) => (
      <SubscriptionComponent
        data={subscription}
        key={subscription._id}
        selectedSubscriptionId={selectedSubscriptionId}
        onSelectSubscription={setSelectedSubscriptionId}
        isCurrent={currentSubscriptionId === subscription._id}
      />
    ));
  };

  const onChangeSubscription = () => {
    if (selectedSubscriptionId === subscription?.subscription._id) {
      useToastError("Wybrany pakiet jest już aktywny");
      return setShowConfirm(false);
    }

    updateCompanySubscription(selectedSubscriptionId);
  };

  return (
    <>
      <Confirm
        show={showConfirm}
        title={t("Change-subscription-confirm")}
        onConfirm={onChangeSubscription}
        onCancel={() => setShowConfirm(false)}
        loading={isUpdating}
      />
      <div className="flex flex-col bg-gradient-to-r from-bg-secondary to-bg-primary h-screen overflow-y-auto">
        <div className="flex flex-row  py-12">
          <div className="flex flex-col items-center justify-center w-full">
            <img src={LogoImage} className="xl:w-[106px] w-[90px]" />
            <div className="flex flex-col mt-8 space-y-8 items-center 2xl:max-w-[900px] xl:max-w-[700px] max-w-[550px] w-full">
              <div className="flex flex-col items-center">
                <Text className="text-white 2xl:text-32 xl:text-30 text-28">Wybór pakietów</Text>
                <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">
                  Wybierz pakiet odpowiadający Twoim potrzebom.
                </Text>
              </div>
              <div className="flex flex-row space-x-8 items-center w-full">{renderSubscriptions()}</div>
              <div className="max-w-[30%] w-full">
                <AppButton
                  title={t("Change-subscription")}
                  color={ButtonColorTypes.PRIMARY}
                  customClass="rounded-[12px]"
                  textClass="text-white 2xl:text-18 xl:text-16 text-14 font-sf-pro-medium"
                  onClick={() => setShowConfirm(true)}
                  disabled={!selectedSubscriptionId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionChange;
