import React from "react";
import PacmanLoader from "react-spinners/PacmanLoader";

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full mt-6">
      <PacmanLoader color="#3183FF" className="flex items-center" />
    </div>
  );
};

export default Loader;
