import React from "react";

import Modal from "src/components/ui/Modal";
import Table from "src/components/ui/Table";

import { InvoicesListDocument } from "src/api/graphql/types-and-hooks";
import DocumentsListModalTableRow from "./DocumentsListModalTableRow";
import Text from "../Text";

type Data = {
  date: string;
  documents: InvoicesListDocument[];
};

type Props = {
  onCancel: () => void;
  data: Data;
};

const HEADERS = ["Numer/nazwa", "Kontrahent", "Termin płatności", "Kwota netto", "Kwota brutto", "Rozliczenie"];

const DocumentsListModal: React.FC<Props> = ({ onCancel, data: { date, documents } }) => {
  const renderRows = () => {
    if (!documents) return null;
    return documents.map((d, i) => {
      return <DocumentsListModalTableRow data={d} key={i} />;
    });
  };

  return (
    <Modal
      show={true}
      onCancel={onCancel}
      onConfirm={onCancel}
      confirmText="Close"
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
      onlyClose
    >
      <div className="flex flex-col h-full min-w-[500px]">
        <Text className="2xl:text-36 xl:text-30 text-28 text-white font-sf-pro-medium">
          Lista transakcji na dzień {date}
        </Text>
        <div className="shrink-0 xl:max-h-[350px] max-h-[200px] overflow-auto">
          <Table headers={HEADERS} rows={renderRows()} dark noDataMsg="Brak dokumentów" />
        </div>
      </div>
    </Modal>
  );
};

export default DocumentsListModal;
