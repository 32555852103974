import React, { useEffect, useState } from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { mixed, object } from "yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import AppSelect from "src/components/form/AppSelect";

import { formatPrice } from "src/utils/currency";

import { useTransactionsCondition } from "./cockpit.hooks";

enum TypeEnum {
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
}

const TransactionsCondition = () => {
  const [selectedDates, setSelectedDates] = useState<{ gte: number; lte: number }>({
    gte: moment().startOf("month").unix(),
    lte: moment().endOf("month").unix(),
  });
  const { t } = useTranslation();
  const { loading, transactionsCondition } = useTransactionsCondition({ filter: { paymentDue: selectedDates } });

  const { control, watch } = useForm({
    defaultValues: { type: TypeEnum.Month },
    resolver: yupResolver(object({ type: mixed<TypeEnum>().oneOf(Object.values(TypeEnum)) })),
  });

  useEffect(() => {
    switch (watch("type")) {
      case TypeEnum.Month:
        setSelectedDates({
          gte: moment().startOf("month").unix(),
          lte: moment().endOf("month").unix(),
        });
        break;
      case TypeEnum.Quarter:
        setSelectedDates({
          gte: moment().startOf("quarter").unix(),
          lte: moment().endOf("quarter").unix(),
        });
        break;
      case TypeEnum.Year:
        setSelectedDates({
          gte: moment().startOf("year").unix(),
          lte: moment().endOf("year").unix(),
        });
        break;
    }
  }, [watch("type")]);

  return (
    <div className="w-full h-full flex flex-col justify-between bg-white rounded-[20px] xl:p-5 p-3 shadow-md">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center">
            <Text className="2xl:text-18 xl:text-16 text-12 font-sf-pro-medium text-text-secondary">
              {t("Transaction-condition")}
            </Text>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <AppSelect
                  field={field}
                  options={[
                    { label: t("Month"), value: TypeEnum.Month },
                    { label: t("Quarter"), value: TypeEnum.Quarter },
                    { label: t("Year"), value: TypeEnum.Year },
                  ]}
                  color="#3183FF"
                  textColor="white"
                  indicatorColor="white"
                />
              )}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col basis-1/2">
              <div className="flex flex-row space-x-2 items-center mt-1">
                <Text className="font-sf-pro-medium">{t("Incomes")}</Text>
              </div>
              <Text className="font-sf-pro-bold">{`${transactionsCondition?.percentageIncomes?.toFixed(2)}%`}</Text>
              <Text className="text-blue">{formatPrice(transactionsCondition?.totalIncomes, "zł")}</Text>
            </div>
            <div className="basis-1/2 justify-end">
              <div className="flex flex-col justify-end">
                <div className="flex flex-row space-x-2 mt-1 justify-end">
                  <Text className="font-sf-pro-medium">{t("Costs")}</Text>
                </div>
                <Text className="font-sf-pro-bold text-right">{`${transactionsCondition?.percentageCosts?.toFixed(
                  2,
                )}%`}</Text>
                <Text className="text-right text-red">{formatPrice(transactionsCondition?.totalCosts, "zł")}</Text>
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-between">
            <div
              className="border-b border-4 border-blue rounded-[20px]"
              style={{ width: `${transactionsCondition?.percentageIncomes}%` }}
            ></div>
            <div
              className="border-b border-4 border-red rounded-[20px]"
              style={{ width: `${transactionsCondition?.percentageCosts}%` }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionsCondition;
