import { FinancialMinimumTypes } from "src/api/graphql/types-and-hooks";
import { date, mixed, object, number, array } from "yup";

export default object({
  type: mixed<FinancialMinimumTypes>().oneOf(Object.values(FinancialMinimumTypes)),
  minimum: number().default(undefined).required(),
  period: object({
    startDate: date(),
    endDate: date(),
  })
    .default(undefined)
    .when("type", {
      is: FinancialMinimumTypes.Periodic,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
}).required();
