import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import {
  usePackagesQuery,
  useUpdateCompanyMutation,
  useCompanyPaymentMethodQuery,
  useCheckOrderStatusLazyQuery,
  useCreatePackageOrderMutation,
} from "src/api/graphql/generated/gql-hooks";
import { CreatePackageOrderInput, GetPackagesOptionsInput, UpdateCompanyInput } from "src/api/graphql/types-and-hooks";

export const useOrderStatus = () => {
  const [getOrderStatus, { loading, data }] = useCheckOrderStatusLazyQuery({
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const status = data?.checkOrderStatus?.status;

  return {
    getOrderStatus: async (orderId: string) => {
      getOrderStatus({ variables: { orderId } });
    },
    loading,
    status,
  };
};

export const usePackages = (options: GetPackagesOptionsInput) => {
  const { loading, data } = usePackagesQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const packages = data?.packages?.documents || [];
  const total = data?.packages?.total;

  return {
    loading,
    packages,
    total,
  };
};

export const useCreatePackageOrder = () => {
  const [createPackageOrder, { loading: isCreatingOrder, data }] = useCreatePackageOrderMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano");
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  const order = data?.createPackageOrder;

  return {
    createPackageOrder: async (createPackageOrderInput: CreatePackageOrderInput) => {
      await createPackageOrder({ variables: { createPackageOrderInput } });
    },
    isCreatingOrder,
    order,
  };
};

export const useEditCompany = () => {
  const [editCompany, { loading: isUpdatingCompany }] = useUpdateCompanyMutation();

  return {
    editCompany: async (updateCompanyInput: UpdateCompanyInput) => {
      await editCompany({
        variables: {
          updateCompanyInput,
        },
      });
    },
    isUpdatingCompany,
  };
};

export const useCompanyPaymentMethod = (companyId: string) => {
  const { loading, data } = useCompanyPaymentMethodQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const paymentMethod = data?.company?.paymentMethod;

  return {
    paymentMethodLoading: loading,
    paymentMethod,
  };
};
