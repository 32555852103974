import { Placement } from "react-joyride";

export const MonthlyCalendarHints = [
  {
    target: ".toolbar-hint",
    title: "Lorem",
    content: "Lorem ipsum dolor sit amen",
    disableBeacon: true,
    placement: "right" as Placement,
  },
  {
    target: ".calendar-monthly-hint-2",
    title: "Lorem",
    content: "Lorem ipsum dolor sit amen",
    disableBeacon: true,
    placement: "right" as Placement,
  },
  {
    target: ".payment-calendar-summary-hints",
    title: "Lorem",
    content: "Lorem ipsum dolor sit amen",
    disableBeacon: true,
    placement: "right" as Placement,
  },
  {
    target: ".payment-calendar-summary-hints-2",
    title: "Lorem",
    content: "Lorem ipsum dolor sit amen",
    disableBeacon: true,
    placement: "top" as Placement,
  },
];
