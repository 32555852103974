import React from "react";

import { formatPrice } from "src/utils/currency";

import Text from "./Text";
import Loader from "./Loader";

export enum CloudColorEnum {
  red = "bg-[#FFF3F3]",
  green = "bg-[#F6FFF1]",
}

type Props = {
  color: string;
  title: string;
  value: number;
  subtitle?: string;
  loading?: boolean;
  onClick?: () => void;
};

const Cloud: React.FC<Props> = ({ color, title, value, subtitle, loading = false, onClick }) => {
  const getSubtitleColor = () => {
    if (color === CloudColorEnum.red) return "text-[#DA6D6D]";
    return "text-[#7DC3A3]";
  };

  const getPillColor = () => {
    if (color === CloudColorEnum.red) return "bg-[#FFE1E9]";
    return "bg-[#E7FFE2]";
  };

  return (
    <div
      className={`w-full h-full flex flex-col justify-between rounded-[20px] shadow-md xl:px-4 px-3 py-3 ${color} ${
        onClick && "cursor-pointer"
      }`}
      onClick={onClick}
    >
      <Text>{title}</Text>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-medium">{formatPrice(value, "zł")}</Text>
          <div className={`flex flex-row w-fit items-center space-x-2 px-4 py-1 rounded-[45px] ${getPillColor()}`}>
            <Text className={`2xl:text-12 xl:text-10 text-8 ${getSubtitleColor()}`}>{subtitle}</Text>
          </div>
        </>
      )}
    </div>
  );
};

export default Cloud;
