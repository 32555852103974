import React from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { formatPrice } from "src/utils/currency";

import BankAccountIcon from "src/assets/images/bank-account.svg";

import { useCompanyAccountBalance } from "./cockpit.hooks";

const AccountBalance = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { loading, companyAccountBalance } = useCompanyAccountBalance(user?.company?._id);

  return (
    <div className="w-full h-full rounded-[20px] bg-white shadow-md relative">
      {loading ? (
        <div className="absolute w-[90%]">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-row h-full xl:pt-5 pt-3 xl:pl-5 pl-3">
          <div className="relative flex flex-col space-y-1 basis-1/2">
            <Text className="2xl:text-18 xl:text-16 text-14 font-sf-pro-bold">{t("Cash-balance")}</Text>
            <Text className="2xl:text-18 xl:text-16 text-14 font-sf-pro-medium text-blue mt-2">
              {companyAccountBalance?.accountBalance ? formatPrice(companyAccountBalance?.accountBalance, "zł") : "-"}
            </Text>
            <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{t("Available-resources")}</Text>
          </div>
          <div className="relative basis-1/2">
            <div className="min-h-[60%] p-4 w-full bg-blue absolute bottom-0 left-0 rounded-tl-[20px] rounded-br-[20px]">
              <div className="flex flex-row items-center space-x-2">
                <img src={BankAccountIcon} />
                <Text className="font-sf-pro-bold text-white">
                  {companyAccountBalance?.taxDetails?.isVatPayer ? "Konto VAT" : "Konto firmowe"}
                </Text>
              </div>
              {companyAccountBalance?.taxDetails?.isVatPayer && (
                <Text className="font-sf-pro-medium text-white mt-2">
                  {companyAccountBalance?.vatAccountBalance
                    ? formatPrice(companyAccountBalance?.vatAccountBalance, "zł")
                    : "-"}
                </Text>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountBalance;
