import moment from "moment";

export const dateToTimestamp = (date: Date) => {
  return moment(date).unix();
};

export const formatDate = (date: string, format: string) => {
  return moment(date).format(format);
};

export const getMonthsWithWeeks = (dateFrom: string, dateTo: string) => {
  const startDate = moment(dateFrom);
  const endDate = moment(dateTo);
  const weeksInMonths = {};

  for (let date = startDate; date.isBefore(endDate); date.add(1, "days")) {
    const monthYear = date.format("MMMM YYYY");
    const week = date.week();

    if (!weeksInMonths[monthYear]) {
      weeksInMonths[monthYear] = new Set();
    }

    weeksInMonths[monthYear].add(week);
  }

  return Object.entries(weeksInMonths).map(([monthYear, weeks]) => ({
    month: monthYear,
    weeks: Array.from(weeks as Set<number>),
  }));
};

export const getWeekStartEnd = (monthWithYear: string, weekNumber: number) => {
  const startDate = moment(monthWithYear, "MMMM-YYYY").startOf("month");
  const endDate = moment(monthWithYear, "MMMM-YYYY").endOf("month");

  while (startDate.isBefore(endDate)) {
    if (startDate.isoWeek() === weekNumber) {
      let weekStart = startDate.clone().startOf("isoWeek"); // within the month
      let weekEnd = startDate.clone().endOf("isoWeek");

      if (weekStart.month() !== startDate.month()) {
        weekStart = startDate.clone().startOf("month"); // start of the month
      }
      if (weekEnd.month() !== startDate.month()) {
        weekEnd = startDate.clone().endOf("month"); // end of the month
      }

      return `${weekStart.format("DD")} - ${weekEnd.format("DD")}.${weekEnd.format("MM")}`;
    }

    startDate.add(1, "week");
  }

  return "";
};

export const addToDate = (date: Date, value: number, unit: moment.DurationInputArg2) => {
  return moment(date).add(value, unit).toDate();
};
