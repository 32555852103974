import React from "react";

import TableRow from "src/components/ui/TableRow";

import { formatDate } from "src/utils/time";

import DeleteIcon from "src/assets/images/delete.svg";

import { BudgetListType } from "./Budgets";
import Text from "src/components/ui/Text";

type Props = {
  data: BudgetListType;
  onClick: () => void;
  onDelete: () => void;
};

const BudgetTableRow: React.FC<Props> = ({
  data: { name, createdAt, dateFrom, dateTo, isDefault },
  onClick,
  onDelete,
}) => {
  return (
    <TableRow onClick={onClick}>
      <td>
        <Text className="ml-1">{name}</Text>
      </td>
      <td>
        <Text className="text-text-secondary">{formatDate(createdAt, "DD.MM.YYYY")}</Text>
      </td>
      <td>
        <Text className="text-text-secondary">{formatDate(dateFrom, "DD.MM.YYYY")}</Text>
      </td>
      <td>
        <Text className="text-text-secondary">{formatDate(dateTo, "DD.MM.YYYY")}</Text>
      </td>
      {!isDefault ? (
        <td>
          <div className="flex flex-row space-x-1 h-full justify-center z-100">
            <img
              src={DeleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              className="2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4"
            />
          </div>
        </td>
      ) : undefined}
    </TableRow>
  );
};

export default BudgetTableRow;
