import React from "react";
import { useTranslation } from "react-i18next";

import { formatPrice } from "src/utils/currency";
import { customTwMerge } from "src/utils/twMerge";

import ArrowGreenIcon from "src/assets/images/arrow-green.svg";
import ArrowPurpleIcon from "src/assets/images/arrow-purple.svg";

import Text from "./Text";
import Loader from "./Loader";

export enum CloudTaxColorEnum {
  purple = "bg-[#F5F3FF]",
  green = "bg-[#F6FFF1]",
}

type Props = {
  color: string;
  title: string;
  value: number | string;
  loading?: boolean;
  subtitle?: string;
  className?: string;
  previousTaxValue?: number | string;
};

const CloudTax: React.FC<Props> = ({ color, title, value, previousTaxValue, loading = false, className = "" }) => {
  const combinedClasses = customTwMerge(
    "w-full h-full flex flex-col justify-between rounded-[20px] shadow-md xl:px-4 px-3 py-3",
    color,
    className,
  );
  const { t } = useTranslation();

  const getTriangle = () => {
    if (color === CloudTaxColorEnum.purple) return ArrowPurpleIcon;
    return ArrowGreenIcon;
  };

  const getPillColor = () => {
    if (color === CloudTaxColorEnum.purple) return "bg-[#E7E2FF]";
    return "bg-[#E7FFE2]";
  };

  const getSubtitleColor = () => {
    if (color === CloudTaxColorEnum.purple) return "text-[#7D6FC3]";
    return "text-[#7DC3A3]";
  };

  const taxValue = typeof value === "number" ? formatPrice(value, "zł") : value;
  const taxPreviousValue =
    typeof previousTaxValue === "number" ? formatPrice(previousTaxValue, "zł") : previousTaxValue;

  return (
    <div className={combinedClasses}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text>{title}</Text>
          <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-medium">{taxValue}</Text>
          {previousTaxValue || previousTaxValue === 0 ? (
            <div className="flex flex-row items-center xl:space-x-4 space-x-2">
              <div
                className={`flex flex-row items-center xl:space-x-2 space-x-1 px-2 py-1 rounded-[45px] ${getPillColor()}`}
              >
                <img src={getTriangle()} className="xl:h-4 xl:w-4" />
                <Text className={`2xl:text-14 xl:text-12 text-10 ${getSubtitleColor()}`}>{taxPreviousValue}</Text>
              </div>
              <Text className={`2xl:text-12 xl:text-10 text-8 ${getSubtitleColor()}`}>
                {t("Previous-billing-period")}
              </Text>
            </div>
          ) : undefined}
        </>
      )}
    </div>
  );
};

export default CloudTax;
