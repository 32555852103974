import moment from "moment";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import AppSelect, { Option } from "src/components/form/AppSelect";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AppDatePicker from "src/components/form/AppDatePicker";
import FiltersWrapper from "src/components/ui/FiltersWrapper";

import { InvoiceTypes } from "src/api/graphql/types-and-hooks";

import { buildCategoriesTree } from "src/utils/buildCategoriesTree";

import documentFiltersSchema from "./documentFilters.schema";

type Props = {
  categories: any[];
  onChange: (data: any) => void;
};

const DocumentFilters: React.FC<Props> = ({ onChange, categories }) => {
  const [categoriesOptions, setCategoriesOptions] = useState<Option[]>();
  const { t } = useTranslation();
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: {
      type: InvoiceTypes.Income,
    },
    resolver: yupResolver(documentFiltersSchema),
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (categories?.length) {
      const categoriesTree = buildCategoriesTree(categories);
      const categoriesOptions = categoriesTree.map((c) => {
        if (c.children?.length) {
          return { label: c.name, value: c._id, options: c.children.map((sc) => ({ label: sc.name, value: sc._id })) };
        }
        return { label: c.name, value: c._id };
      });

      setCategoriesOptions(categoriesOptions);
    }
  }, [categories]);

  const parseData = (data: any) => {
    const { invoiceNumber, contractor, companyId, description, paymentDueFrom, paymentDueTo, type, category } = data;
    const filters = {};

    if (invoiceNumber) {
      filters["invoiceNumber"] = { regex: invoiceNumber };
    }

    if (contractor) {
      filters["billTo"] = { name: { regex: contractor } };
    }

    if (companyId) {
      filters["billTo"] = { companyId: { regex: companyId } };
    }

    if (description) {
      filters["description"] = { regex: description };
    }

    const paymentDue = {};
    if (paymentDueFrom || paymentDueTo) {
      if (paymentDueFrom) paymentDue["gte"] = moment(paymentDueFrom).unix();
      if (paymentDueTo) paymentDue["lte"] = moment(paymentDueTo).unix();

      filters["paymentDue"] = paymentDue;
    }

    if (type) {
      filters["type"] = { eq: type };
    }

    if (category) {
      filters["category"] = { eq: category };
    }

    onChange(filters);
  };

  const resetForm = () => {
    reset({
      invoiceNumber: null,
      contractor: null,
      companyId: null,
      description: null,
      paymentDueFrom: null,
      paymentDueTo: null,
      category: undefined,
      type: InvoiceTypes.Income,
    });

    onChange({ type: { eq: InvoiceTypes.Income } });
  };

  return (
    <FiltersWrapper>
      <div className="mt-4 mr-5">
        <div className="flex flex-col 2xl:space-y-4 space-y-2 border-b pb-5">
          <AppInput
            id="invoiceNumber"
            inputType={InputTypes.TEXT}
            placeholder={t("Invoice-number")}
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <AppInput
            id="contractor"
            inputType={InputTypes.TEXT}
            placeholder={t("Contractor")}
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <AppInput
            id="companyId"
            inputType={InputTypes.TEXT}
            placeholder="NIP"
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <AppInput
            id="description"
            inputType={InputTypes.TEXT}
            placeholder="Opis"
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <div className="flex flex-col space-y-2">
            <Text>Termin płatności</Text>
            <div className="flex 2xl:flex-row flex-col 2xl:space-x-3">
              <div className="w-full ">
                <Controller
                  control={control}
                  name="paymentDueFrom"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data od" />}
                />
              </div>
              <div className="w-full 2xl:mt-0 mt-2">
                <Controller
                  control={control}
                  name="paymentDueTo"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data do" />}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <Text>Kategoria</Text>
            <div className="w-full">
              <Controller
                control={control}
                name="category"
                render={({ field }) => <AppSelect options={categoriesOptions} field={field} placeholder="Wybierz..." />}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <Text>Typ</Text>
            <div className="w-full">
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <AppSelect
                    options={[
                      { value: InvoiceTypes.Income, label: t("Income") },
                      { value: InvoiceTypes.Cost, label: t("Cost") },
                    ]}
                    field={field}
                    placeholder="Wybierz..."
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <AppButton
            customClass="rounded-[10px]"
            title="Filtruj"
            onClick={handleSubmit(parseData)}
            color={ButtonColorTypes.PRIMARY}
            textClass="text-white font-sf-pro-medium"
          />
        </div>
        <div className="mt-3 flex justify-center cursor-pointer" onClick={resetForm}>
          <Text className="text-text-secondary">Wyczyść</Text>
        </div>
      </div>
    </FiltersWrapper>
  );
};

export default DocumentFilters;
