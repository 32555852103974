import {
  useDeleteNotificationMutation,
  useNotificationsQuery,
  useUpdateNotificationMutation,
} from "src/api/graphql/generated/gql-hooks";
import { GetNotificationsOptionsInput, UpdateNotificationInput } from "src/api/graphql/types-and-hooks";

import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useNotifications = (options: GetNotificationsOptionsInput) => {
  const { loading, data } = useNotificationsQuery({
    variables: { options },
    onError: () => useToastError(),
    fetchPolicy: "no-cache",
  });

  const notifications = data?.notifications?.documents || [];

  return { loading, notifications };
};

export const useDeleteNotification = () => {
  const [deleteNotification, { loading, data }] = useDeleteNotificationMutation({
    onError: () => useToastError(),
    onCompleted: () => useToastSuccess("Usunięto powiadomienie"),
    refetchQueries: ["Notifications"],
  });

  return {
    isDeleting: loading,
    handleDeleteNotification: async (id: string) => {
      await deleteNotification({ variables: { deleteNotificationInput: { id } } });
    },
    isDeleted: !!data,
  };
};
export const useEditNotification = () => {
  const [editInvoice, { loading: isUpdating, data }] = useUpdateNotificationMutation({
    onError: () => useToastError(),
    onCompleted: () => useToastSuccess(),
    refetchQueries: ["Notifications"],
  });

  return {
    handleEditNotification: async (updateNotificationInput: UpdateNotificationInput) => {
      await editInvoice({
        variables: {
          updateNotificationInput,
        },
      });
    },
    isUpdating,
    isUpdated: !!data,
  };
};
