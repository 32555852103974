import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { InferType } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppCheckbox from "src/components/form/AppCheckbox";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { UserPermissionActionsEnum, UserPermissionResourcesEnum } from "src/api/graphql/types-and-hooks";

import addUserToCompanySchema from "./inviteUserToCompany.schema";
import { useInviteUserToCompany } from "./settings.hooks";

type Props = {
  formRef: React.MutableRefObject<HTMLFormElement>;
  onUserInvited: () => void;
};

const availablePermissions = [
  { resource: UserPermissionResourcesEnum.Cockpit, label: "Cockpit", checked: true },
  { resource: UserPermissionResourcesEnum.Transactions, label: "Transactions", checked: true },
  { resource: UserPermissionResourcesEnum.PaymentsCalendar, label: "Payments-calendar", checked: true },
  { resource: UserPermissionResourcesEnum.LiquidityAnalysis, label: "Liquidity-analysis", checked: true },
  { resource: UserPermissionResourcesEnum.Budget, label: "Budget", checked: true },
  { resource: UserPermissionResourcesEnum.FinancialDetails, label: "Financial-details", checked: true },
  { resource: UserPermissionResourcesEnum.Account, label: "Moje konto", checked: true },
];

const InviteUserToCompany: React.FC<Props> = ({ formRef, onUserInvited }) => {
  const loggedUser = useCurrentUser();
  const permissionsEnabled = loggedUser?.company?.subscription?.subscription?.permissionsEnabled;

  const { t } = useTranslation();
  const { handleInviteUserToCompany, errors, data } = useInviteUserToCompany();

  useEffect(() => {
    if (data?.inviteUserToCompany?.success) {
      reset({
        email: "",
      });
      onUserInvited();
    }
  }, [data]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(addUserToCompanySchema),
    defaultValues: {
      permissions: availablePermissions,
    },
    reValidateMode: "onChange",
  });

  const { fields } = useFieldArray({
    control,
    name: "permissions",
  });

  const handleInviteUser = (data: InferType<typeof addUserToCompanySchema>) => {
    // for now, set actions to all possible
    const selectedPermissions = data.permissions
      .filter((permission) => permission.checked)
      .map((permission) => ({
        resource: permission.resource,
        actions: [
          UserPermissionActionsEnum.Get,
          UserPermissionActionsEnum.Getlist,
          UserPermissionActionsEnum.Create,
          UserPermissionActionsEnum.Update,
          UserPermissionActionsEnum.Delete,
        ],
      }));

    handleInviteUserToCompany({ email: data.email, permissions: selectedPermissions });
  };

  return (
    <>
      <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Invite-user")}</Text>
      <form ref={formRef} onSubmit={handleSubmit(handleInviteUser)}>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2 max-w-[50%] w-full">
            <Label>{t("Email")}</Label>
            <AppInput
              id="email"
              inputType={InputTypes.TEXT}
              placeholder={t("E-mail")}
              register={register}
              inputClasses="border border-bg-light-gray3"
              error={!!formErrors?.email || !!errors?.email}
              errorMessage={errors?.email}
            />
          </div>
          {permissionsEnabled ? (
            <div className="flex flex-col space-y-2 max-w-[50%] w-full">
              <Label>{t("Permissions")}</Label>
              {fields.map((permission, index) => (
                <Controller
                  name={`permissions.${index}.resource`}
                  control={control}
                  key={permission.id}
                  render={() => (
                    <AppCheckbox
                      id={`permissions.${index}.checked`}
                      register={register}
                      label={t(permission.label)}
                      checked={permission.checked}
                    />
                  )}
                />
              ))}
            </div>
          ) : undefined}
        </div>
      </form>
    </>
  );
};

export default InviteUserToCompany;
