import React from "react";
import { TooltipRenderProps } from "react-joyride";
import { useTranslation } from "react-i18next";

import { customTwMerge } from "src/utils/twMerge";

import Text from "./Text";

type ButtonProps = {
  title: string;
  color?: string;
  props: any;
  textClass?: string;
  customClass?: string;
};

const Button: React.FC<ButtonProps> = ({ customClass = "", title, color = "", props, textClass = "" }) => {
  const combinedClasses = customTwMerge(
    "w-full flex flex-row items-center justify-center 2xl:h-10 xl:h-8 h-6 font-sf-pro-bold px-2 min-w-[70px] rounded-[8px]",
    customClass,
    color,
  );
  return (
    <button className={combinedClasses} type="button" {...props}>
      <Text className={textClass}>{title}</Text>
    </button>
  );
};

const Hint: React.FC<TooltipRenderProps> = ({
  size,
  index,
  step: { title, content },
  skipProps,
  primaryProps,
  tooltipProps,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col justify-between xl:min-w-[300px] min-w-[250px] xl:min-h-[150px] min-h-[100px] bg-[#3B5883] rounded-[16px] p-4"
      {...tooltipProps}
    >
      <div className="flex flex-col">
        <Text className="text-white font-sf-pro-bold">{title}</Text>
        <Text className="2xl:text-14 xl:text-12 text-10 text-white">{content}</Text>
      </div>
      <div className="flex flex-row space-x-4 justify-between items-center">
        <Button title={t("Finish")} props={skipProps} textClass="text-white" customClass="w-[70px]" />
        <div className="flex flex-row space-x-2">
          {Array.from({ length: size }, (_, i) => (
            <div key={i} className={`w-[6px] h-[6px] rounded-full ${index === i ? "bg-white" : "bg-blue"}`}></div>
          ))}
        </div>
        {index + 1 !== size ? (
          <Button
            title={t("Next")}
            color="bg-white"
            props={primaryProps}
            textClass="text-blue"
            customClass="w-[70px]"
          />
        ) : (
          <div className="w-[70px]"></div>
        )}
      </div>
    </div>
  );
};

export default Hint;
