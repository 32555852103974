import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "src/components/ui/Table";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import Pagination from "src/components/ui/Pagination";
import ListContainer from "src/components/ui/ListContainer";

import { formatDate } from "src/utils/time";
import { getBudgetDetailsPath } from "src/common/router/routerPaths";
import {
  BudgetCategory,
  BudgetCategoryTypes,
  BudgetDocument,
  GetBudgetDocumentsOptionsInput,
  InvoiceCompany,
  InvoiceTypes,
  SortOptions,
} from "src/api/graphql/types-and-hooks";

import ShapesIcon from "src/assets/images/shapes.svg";
import ShapesActiveIcon from "src/assets/images/shapes-blue.svg";

import DocumentFilters from "./DocumentFilters";
import DocumentTableRow from "./DocumentTableRow";
import AddDocumentsToGroup from "./AddDocumentsToGroup";
import { useBudgetDocuments } from "./documents.hooks";
import { useBudgetCategories } from "../budget.hooks";
import Text from "src/components/ui/Text";

const HEADERS = ["Numer", "Kontrahent", "Grupa", "Termin płatności", "Kwota netto", "Kwota brutto"];

type Props = {
  budgetName: string;
  budgetDateTo: string;
  budgetDateFrom: string;
};

type Company = Pick<InvoiceCompany, "name">;
type Category = Pick<BudgetCategory, "name" | "_id"> & { parent?: Category };

export type Document = Pick<
  BudgetDocument,
  "_id" | "invoiceNumber" | "paymentDue" | "subtotal" | "total" | "paymentStatus" | "type" | "genre"
> & { billTo?: Company; billFrom?: Company; category?: Category };

const DocumentsTab: React.FC<Props> = ({ budgetName, budgetDateFrom, budgetDateTo }) => {
  const [filteringData, setFilteringData] = useState<GetBudgetDocumentsOptionsInput>({
    take: 10,
    skip: 0,
    filter: { type: { eq: InvoiceTypes.Income } },
    sort: { paymentDue: SortOptions.Desc },
  });
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [showAddDocumentsToGroupModal, setShowAddDocumentsToGroupModal] = useState(false);

  const { t } = useTranslation();
  const { budgetId } = useParams();
  const { loading, budgetDocuments, total } = useBudgetDocuments({
    ...filteringData,
    filter: { ...filteringData.filter, budget: { eq: budgetId } },
  });
  const { categories } = useBudgetCategories({
    filter: { budget: { eq: budgetId } },
    take: 0,
    skip: 0,
  });

  useEffect(() => {
    setSelectedDocuments([]);
  }, [filteringData?.filter]);

  const onDocumentClick = (doc: Document) => {
    if (selectedDocuments.some((d) => d._id === doc._id)) {
      setSelectedDocuments((prevState) => prevState.filter((d) => d._id !== doc._id));
    } else {
      setSelectedDocuments((prevState) => [...prevState, doc]);
    }
  };

  const renderRows = () => {
    if (!budgetDocuments) return null;
    return budgetDocuments.map((d) => {
      return (
        <DocumentTableRow
          data={d}
          key={d._id}
          onClick={() => onDocumentClick(d)}
          isSelected={selectedDocuments.some((doc) => doc._id === d._id)}
        />
      );
    });
  };

  return (
    <>
      {showAddDocumentsToGroupModal && (
        <AddDocumentsToGroup
          onCancel={() => setShowAddDocumentsToGroupModal(false)}
          documents={selectedDocuments}
          categories={categories.filter((c) => c.type === filteringData?.filter?.type?.eq)}
          type={filteringData?.filter?.type?.eq as BudgetCategoryTypes}
        />
      )}
      <div className="flex flex-row border-b pb-2 justify-between items-center">
        <div className="flex flex-row space-x-12">
          <Text className="2xl:text-20 xl:text-18 text-16">{budgetName}</Text>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{`${formatDate(
            budgetDateFrom,
            "DD.MM.YYYY",
          )} - ${formatDate(budgetDateTo, "DD.MM.YYYY")}`}</Text>
        </div>
        <div>
          <AppButton
            title="Przypisz do grupy"
            color={ButtonColorTypes.SECONDARY}
            textClass={`${selectedDocuments?.length ? "text-blue" : "text-text-secondary"}`}
            customClass={`border ${selectedDocuments?.length ? "border-blue" : "border-[#00000066]"} rounded-[12px]`}
            icon={selectedDocuments?.length ? ShapesActiveIcon : ShapesIcon}
            onClick={() => selectedDocuments.length && setShowAddDocumentsToGroupModal(true)}
          />
        </div>
      </div>
      <ListContainer
        title={t("Lista faktur ujętych w budżecie")}
        filters={
          <DocumentFilters
            categories={categories.filter((c) => c.type === filteringData?.filter?.type?.eq)}
            onChange={(filters) =>
              setFilteringData((prevState) => {
                return { ...prevState, filter: filters };
              })
            }
          />
        }
      >
        <Table headers={HEADERS} isLoading={loading} rows={renderRows()} />
        {!loading ? (
          <Pagination
            total={total}
            filteringData={filteringData}
            route={getBudgetDetailsPath({ budgetId })}
            onChange={setFilteringData}
          />
        ) : undefined}
      </ListContainer>
    </>
  );
};

export default DocumentsTab;
