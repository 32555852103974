import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import Table from "src/components/ui/Table";
import AppSwitch from "src/components/form/AppSwitch";
import AppDatePicker from "src/components/form/AppDatePicker";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { FinancialMinimum, FinancialMinimumTypes } from "src/api/graphql/types-and-hooks";

import { useEditUser, useGetUser } from "../settings/settings.hooks";
import financialMinimumSchema from "./financialMinimum.schema";
import FinancialMinimumTableRow from "./FinancialMinimumTableRow";

type Props = {
  show: boolean;
  onCancel: () => void;
};

const HEADERS = ["", "Limit", "Data od", "Data do", ""];

const FinancialMinimumModal: React.FC<Props> = ({ show, onCancel }) => {
  const { t } = useTranslation();

  const loggedUser = useCurrentUser();
  const { user } = useGetUser(loggedUser?._id);
  const { editUser, isUpdatingUser } = useEditUser();

  const [financialMinimum, setFinancialMinimum] = useState<FinancialMinimum[]>([]);
  const [type, setType] = useState<FinancialMinimumTypes>(FinancialMinimumTypes.Constant);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(financialMinimumSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (user?.settings?.financialMinimum) setFinancialMinimum(user.settings.financialMinimum);
  }, [user]);

  useEffect(() => {
    setValue("type", type);
  }, [type]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "period" && value.period !== undefined && (!value?.period?.startDate || !value?.period?.endDate)) {
        setValue("period", undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleRemove = (index: number) => {
    const newFinancialMinimum = [...financialMinimum];
    newFinancialMinimum.splice(index, 1);

    editUser({ id: loggedUser?._id, financialMinimum: newFinancialMinimum });
  };

  const renderRows = () => {
    if (!financialMinimum) return null;
    return financialMinimum.map((d, i) => {
      return <FinancialMinimumTableRow data={d} key={i} index={i + 1} onRemove={handleRemove} />;
    });
  };

  const onSubmit = (data: any) => {
    const parsedData = { minimum: data.minimum, type };
    if (type === FinancialMinimumTypes.Periodic) {
      Object.assign(parsedData, { dateTo: data.period.endDate, dateFrom: data.period.startDate });
    }
    const input = {
      id: loggedUser?._id,
      financialMinimum: [...(financialMinimum || []), parsedData],
    };

    editUser(input);
  };

  return (
    <Modal
      show={show}
      onCancel={onCancel}
      onConfirm={onCancel}
      confirmText="Close"
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
      onlyClose
    >
      <div className="flex flex-col h-full">
        <Text className="2xl:text-48 xl:text-32 text-28 text-white font-sf-pro-medium">
          {t("Set-new-financial-minimum")}
        </Text>
        <Text className="text-text-secondary mt-3">{t("Set-minimum-financial-level-for-period")}</Text>
        <div className="mt-4">
          <AppSwitch
            bgColor="bg-[#F4F4F41A]"
            activeColor="bg-white"
            activeTextClass="text-blue font-sf-pro-medium"
            textClass="text-white"
            options={[
              { value: FinancialMinimumTypes.Constant, label: `${t("Constant-limit")}` },
              { value: FinancialMinimumTypes.Periodic, label: `${t("Periodic-limit")}` },
            ]}
            onChange={(val) => setType(val as FinancialMinimumTypes)}
          />
        </div>
        <div className="mt-4 flex flex-row space-x-2 w-full">
          <div className="w-1/2 flex flex-col space-y-2">
            <Text className="text-white">{t("Set-limit")}</Text>
            <AppInput
              id="minimum"
              register={register}
              inputType={InputTypes.TEXT}
              inputClasses={`text-white ${!formErrors.minimum?.message && "focus:border-white"}`}
              placeholder="Limit"
              error={!!formErrors.minimum?.message}
            />
          </div>
          {type === FinancialMinimumTypes.Periodic && (
            <div className="w-1/2 flex flex-col space-y-2">
              <Text className="text-white">{t("Set-date-range")}</Text>
              <Controller
                control={control}
                name="period"
                render={({ field }) => (
                  <AppDatePicker
                    field={field}
                    placeholder="Data od - do"
                    asSingle={false}
                    containerClasses="hover:border-white"
                    inputClasses="text-white"
                    error={!!formErrors.period?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="w-1/4 mt-2">
          <AppButton
            title={t("Save")}
            color={ButtonColorTypes.PRIMARY}
            onClick={handleSubmit(onSubmit)}
            customClass="rounded-[12px]"
            isLoading={isUpdatingUser}
            textClass="text-white"
          />
        </div>
        <div className="mt-4">
          <Text className="2xl:text-38 xl:text-32 text-28 text-white font-sf-pro-medium">{t("Saved")}</Text>
          <div className="shrink-0 max-h-[240px] overflow-auto">
            <Table headers={HEADERS} rows={renderRows()} dark />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FinancialMinimumModal;
