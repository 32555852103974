import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleWrapper from "src/components/ui/ModuleWrapper";

import {
  getFinancialDetailsTaxesPath,
  getFinancialDetailsLegalFormPath,
  getFinancialDetailsCarsLeasingPath,
  getFinancialDetailsStartupDataPath,
} from "src/common/router/routerPaths";

import { customTwMerge } from "src/utils/twMerge";

import UserIcon from "src/assets/images/user.svg";
import UsersIcon from "src/assets/images/users.svg";
import WalletIcon from "src/assets/images/wallet.svg";
import UserActiveIcon from "src/assets/images/user-blue.svg";
import UsersActiveIcon from "src/assets/images/users-blue.svg";
import WalletActiveIcon from "src/assets/images/wallet-blue.svg";
import StartupDataIcon from "src/assets/images/startup-data.svg";
import StartupDataBlueIcon from "src/assets/images/startup-data-blue.svg";

type LinkProps = {
  title: string;
  icon: any;
  activeIcon: any;
  subtitle?: string;
  route?: string;
  isRouteActive?: boolean;
};

type Props = {
  children: ReactNode;
};

const Link: React.FC<LinkProps> = ({ title, subtitle, route, isRouteActive, icon, activeIcon }) => {
  const combinedClasses = customTwMerge(
    "flex flex-row 2xl:space-x-4 xl:space-x-3 space-x-3 justify-center items-center 2xl:h-[80px] xl:h-[70px] h-[60px]",
    isRouteActive && "bg-light-blue rounded-[12px]",
  );
  return (
    <NavLink to={route}>
      <div className={combinedClasses}>
        {isRouteActive ? (
          <div className="h-full py-3">
            <div className="bg-blue w-1 h-full rounded-r-full"></div>
          </div>
        ) : (
          <div className="w-1"></div>
        )}
        <div className="shrink-0 grow-0 rounded-full bg-bg-light-gray2 xl:w-[40px] xl:h-[40px] w-[30px] h-[30px] flex justify-center items-center">
          <div>
            <img src={isRouteActive ? activeIcon : icon} className="2xl:h-6 xl:h-5 h-3 2xl:w-6 xl:w-5 w-3" />
          </div>
        </div>
        <div className="basis-10/12 flex flex-col justify-between">
          <Text className="2xl:text-14 xl:text-12 text-10">{title}</Text>
          <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary mt-1">{subtitle}</Text>
        </div>
      </div>
    </NavLink>
  );
};

const FinancialDetailsWrapper: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ModuleWrapper>
      <div className="flex flex-row space-x-5">
        <div className="basis-2/8 flex flex-col space-y-5">
          <div className="2xl:h-10 xl:h-8 h-6">
            <ModuleHeader title="My-financial-details" />
          </div>
          <div className="bg-white rounded-[20px] p-5 flex flex-col 2xl:space-y-5 xl:space-y-4 space-y-3">
            <Link
              title={t("Legal-form")}
              subtitle={t("Business-information")}
              icon={UserIcon}
              activeIcon={UserActiveIcon}
              route={getFinancialDetailsLegalFormPath()}
              isRouteActive={location.pathname.includes(getFinancialDetailsLegalFormPath())}
            />
            <Link
              title={t("Taxes")}
              subtitle={t("Tax-settlements")}
              icon={WalletIcon}
              activeIcon={WalletActiveIcon}
              route={getFinancialDetailsTaxesPath()}
              isRouteActive={location.pathname.includes(getFinancialDetailsTaxesPath())}
            />
            <Link
              title={t("Startup-data")}
              subtitle={t("Account-balance-info")}
              icon={StartupDataIcon}
              activeIcon={StartupDataBlueIcon}
              route={getFinancialDetailsStartupDataPath()}
              isRouteActive={location.pathname.includes(getFinancialDetailsStartupDataPath())}
            />
            <Link
              title={t("Car-leasing")}
              subtitle={t("Manage-your-fleet")}
              icon={UsersIcon}
              activeIcon={UsersActiveIcon}
              route={getFinancialDetailsCarsLeasingPath()}
              isRouteActive={location.pathname.includes(getFinancialDetailsCarsLeasingPath())}
            />
          </div>
        </div>
        <div className="basis-6/8">{children}</div>
      </div>
    </ModuleWrapper>
  );
};

export default FinancialDetailsWrapper;
