import React, { useRef } from "react";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useToastError } from "src/common/hooks/useToast";
import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import PlusWhiteIcon from "src/assets/images/plus-white.svg";

import { useCreateBudgetCategory } from "../budget.hooks";

type Props = {
  type: BudgetCategoryTypes;
  parent?: string;
  onAddCategory: () => void;
};

const AddNewCategory: React.FC<Props> = ({ type, onAddCategory, parent = null }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { budgetId } = useParams();
  const { t } = useTranslation();
  const { handleCreateBudgetCategory, loading } = useCreateBudgetCategory();

  const onAddNewCategory = async () => {
    if (isEmpty(inputRef.current?.value)) {
      useToastError(t("Enter-group-name"));
    } else {
      await handleCreateBudgetCategory({ name: inputRef.current?.value, budget: budgetId, type, parent });
      onAddCategory();
    }
  };

  return (
    <div className="w-[90%] py-4 rounded-[16px] border border-dashed border-blue flex flex-col items-center justify-center space-y-2 cursor-pointer">
      <input
        ref={inputRef}
        type="text"
        className="2xl:text-16 xl:text-14 text-12 border border-[#DFE5F1] w-[80%] 2xl:h-11 xl:h-10 h-9 rounded-[7px] pl-2 focus:border-[#233145] focus:outline-none"
        placeholder="Wpisz nazwę"
      />
      <div className="w-[70%]">
        <AppButton
          title={t("Save")}
          color={ButtonColorTypes.PRIMARY}
          icon={PlusWhiteIcon}
          customClass="rounded-[12px]"
          textClass="font-sf-pro-medium text-white"
          isLoading={loading}
          onClick={onAddNewCategory}
        />
      </div>
    </div>
  );
};

export default AddNewCategory;
