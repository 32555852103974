import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Pill from "src/components/ui/Pill";
import TableRow from "src/components/ui/TableRow";

import { formatPrice } from "src/utils/currency";
import {
  InvoiceTypes,
  PaymentStatus,
  InvoicesListDocument,
  CompanyIntegrationTypes,
} from "src/api/graphql/types-and-hooks";

import InfaktIcon from "src/assets/images/infakt.svg";

type Props = {
  data: InvoicesListDocument;
  onClick: () => void;
  onClickCheckbox?: () => void;
  isSelected?: boolean;
};

const InvoicesTableRow: React.FC<Props> = ({
  data: { invoiceNumber, billTo, paymentDue, subtotal, total, paymentStatus, type, genre, billFrom, externalService },
  isSelected = false,
  onClick,
  onClickCheckbox,
}) => {
  const { t } = useTranslation();

  let name = type === InvoiceTypes.Income ? billTo?.name : billFrom?.name || "-";
  if (name.length > 30) {
    name = `${name.substring(0, 30)}...`;
  }

  const getPaymentInfo = () => {
    const isOverdue = moment(paymentDue).isBefore(moment(), "day");

    if (paymentStatus === PaymentStatus.Unpaid) {
      let title = "";
      if (type === InvoiceTypes.Income) {
        title = `${t(`${isOverdue ? "Overdue" : "Unpaid"}`)}`;
      }
      if (type === InvoiceTypes.Cost) {
        title = `${t(`${isOverdue ? "Overdue" : "To-pay"}`)}`;
      }
      return <Pill title={title} titleColor="text-red" bgColor="bg-light-red" customClass="max-w-[160px]" />;
    }
    return <Pill title={t("Paid")} titleColor="text-green" bgColor="bg-light-green" customClass="max-w-[160px]" />;
  };

  const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onClickCheckbox();
  };

  const renderCheckbox = () => {
    return paymentStatus === PaymentStatus.Unpaid ? (
      <input
        className="form-checkbox 2xl:w-[18px] xl:w-[16px] w-[14px] 2xl:h-[18px] xl:h-[16px] h-[14px] rounded-[4px] cursor-pointer checked:bg-blue ring-0 focus:ring-0 border border-[#DFE5F1] 2xl:ml-0 ml-1"
        type="checkbox"
        checked={isSelected}
        readOnly
        onClick={handleCheckboxClick}
      />
    ) : (
      <div className="w-[18px] h-[18px]"></div>
    );
  };

  return (
    <TableRow onClick={onClick}>
      <td>
        {externalService === CompanyIntegrationTypes.Infakt ? (
          <img src={InfaktIcon} className="2xl:w-10 xl:w-8 w-7" />
        ) : undefined}
      </td>
      <td>
        <div className="flex flex-col">
          <Text className="2xl:text-14 xl:text-12 text-10">{invoiceNumber}</Text>
          <Text className="2xl:text-12 xl:text-10 text-8 text-text-secondary">{t(genre)}</Text>
        </div>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10">{name}</Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 font-sf-pro-medium text-text-secondary">
          {paymentDue ? moment(paymentDue).format("DD-MM-YYYY") : "-"}
        </Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 font-sf-pro-medium text-text-secondary">
          {formatPrice(subtotal, "zł")}
        </Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 font-sf-pro-medium text-text-secondary">
          {formatPrice(total, "zł")}
        </Text>
      </td>
      <td>
        <div className="max-w-[130px]">{getPaymentInfo()}</div>
      </td>
      <td>{renderCheckbox()}</td>
    </TableRow>
  );
};

export default InvoicesTableRow;
