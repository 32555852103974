import { object, ref, string } from "yup";

export default object({
  code: string().required(),
  email: string().email().required(),
  firstName: string().required(),
  lastName: string().required(),
  phone: string().required(),
  password: string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$/, {
    message: "Hasło musi zawierać minimum 6 znaków, jedną cyfrę, jedną małą i jedną dużą literę",
  }),
  passwordConfirmation: string().oneOf([ref("password"), null], "Podane hasła różnią się od siebie"),
}).required();
