import React from "react";
import moment from "moment";

import { InvoiceTypes, InvoicesListDocument, PaymentStatus } from "src/api/graphql/types-and-hooks";

import Text from "./Text";

type Props = {
  document?: InvoicesListDocument;
};

const CalendarMonthlyEvent: React.FC<Props> = ({
  document: { type, paymentDue, paymentStatus, billTo, billFrom, invoiceNumber },
}) => {
  const isOverdue = paymentDue && moment(paymentDue).isBefore(moment(), "day");

  const getBgColor = () => {
    if (isOverdue && paymentStatus !== PaymentStatus.Paid) return "bg-light-red";
    if (paymentStatus === PaymentStatus.Paid) return "bg-light-green";

    return "bg-[#9A9EA90F]";
  };

  const name = (type === InvoiceTypes.Cost ? billFrom?.name : billTo?.name) || invoiceNumber;

  return (
    <div
      className={`flex flex-row items-center xl:space-x-2 space-x-1 xl:px-2 px-1 xl:h-7 h-5 mx-1 my-1 rounded-[12px] ${getBgColor()}`}
    >
      <div
        className={`rounded-full xl:w-2.5 xl:h-2.5 w-2 h-2 shrink-0 ${
          type === InvoiceTypes.Cost ? "bg-red" : "bg-green"
        }`}
      ></div>
      <div className="flex flex-row truncate">
        <Text className="2xl:text-12 xl:text-10 text-8 truncate" title={name}>
          {name}
        </Text>
      </div>
    </div>
  );
};

export default CalendarMonthlyEvent;
