import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { getChangePaymentMethodPath } from "src/common/router/routerPaths";

import VisaIcon from "src/assets/images/visa.png";
import MasterCardIcon from "src/assets/images/mastercard.png";

import Loader from "./Loader";
import Text from "./Text";

enum CardTypes {
  VISA = "visa",
  MASTERCARD = "mastercard",
}

type Props = {
  maskedCardNumber: string;
  isLoading: boolean;
  onRemovePaymentMethod: () => void;
};

const Card: React.FC<Props> = ({ maskedCardNumber = "", isLoading = false, onRemovePaymentMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cardType = maskedCardNumber.startsWith("4") ? CardTypes.VISA : CardTypes.MASTERCARD;

  return (
    <div className="flex w-full h-full rounded-[20px] bg-white shadow-md items-center px-5">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex basis-1/5">
            <img src={cardType === CardTypes.VISA ? VisaIcon : MasterCardIcon} className="2xl:w-12 xl:w-8 w-6" />
          </div>
          <div className="flex flex-row basis-3/5">
            <div className="flex flex-col justify-between">
              <Text className="font-sf-pro-bold">*** *** *** {maskedCardNumber.slice(-4)}</Text>
              <Text className="font-sf-pro-bold">{cardType === CardTypes.VISA ? "Visa" : "Mastercard"}</Text>
            </div>
          </div>
          <div className="flex flex-row basis-1/5 space-x-2">
            <AppButton
              title={t("Change")}
              color={ButtonColorTypes.PRIMARY}
              textClass="text-white"
              customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-5 min-w-[60px]"
              onClick={() => navigate(getChangePaymentMethodPath())}
            />
            <AppButton
              title={t("Remove")}
              color={ButtonColorTypes.DANGER}
              textClass="text-white"
              customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-5 min-w-[60px]"
              onClick={onRemovePaymentMethod}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
