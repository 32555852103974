import { useNavigate } from "react-router-dom";

import { UpdateCompanyInput } from "src/api/graphql/types-and-hooks";
import { getSettingsOrdersPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";
import { useUpdateCompanyMutation, useUpdateCompanyPaymentMethodMutation } from "src/api/graphql/generated/gql-hooks";

export const useUpdateCompanyPaymentMethod = () => {
  const [updateCompanyPaymentMethod, { data, loading: isUpdating }] = useUpdateCompanyPaymentMethodMutation({
    onError: () => useToastError("Wystąpił błąd"),
    onCompleted: () => useToastSuccess("Zaktualizowano subskrypcję"),
  });

  return {
    updateCompanyPaymentMethod: async (token: string) => {
      await updateCompanyPaymentMethod({
        variables: { updateCompanyPaymentMethodInput: { token } },
      });
    },
    data: data?.updateCompanyPaymentMethod,
    isUpdating,
  };
};

export const useEditCompany = () => {
  const navigate = useNavigate();

  const [editCompany, { loading: isUpdatingCompany }] = useUpdateCompanyMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano");
      localStorage.removeItem("tokenIv");
      localStorage.removeItem("tokenContent");
      localStorage.removeItem("maskedCardNumber");
      navigate(getSettingsOrdersPath());
    },
    onError: () => useToastError("Wystąpił błąd podczas zapisywania danych."),
  });

  return {
    editCompany: async (updateCompanyInput: UpdateCompanyInput) => {
      await editCompany({
        variables: {
          updateCompanyInput,
        },
      });
    },
    isUpdatingCompany,
  };
};
