import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import {
  useCheckOrderStatusLazyQuery,
  useCreateOrderMutation,
  useSubscriptionsQuery,
  useUpdateCompanyMutation,
} from "src/api/graphql/generated/gql-hooks";
import { CreateOrderInput, GetSubscriptionsOptionsInput, UpdateCompanyInput } from "src/api/graphql/types-and-hooks";

export const useOrderStatus = () => {
  const [getOrderStatus, { loading, data }] = useCheckOrderStatusLazyQuery({
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const status = data?.checkOrderStatus?.status;

  return {
    getOrderStatus: async (orderId: string) => {
      getOrderStatus({ variables: { orderId } });
    },
    loading,
    status,
  };
};

export const useSubscriptions = (options: GetSubscriptionsOptionsInput) => {
  const { loading, data } = useSubscriptionsQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const subscriptions = data?.userSubscriptions?.documents || [];
  const total = data?.userSubscriptions?.total;

  return {
    loading,
    subscriptions,
    total,
  };
};

export const useCreateOrder = () => {
  const [createOrder, { loading: isCreatingOrder, data }] = useCreateOrderMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano");
    },
    refetchQueries: () => ["User"],
    onError: () => useToastError("Wystąpił błąd"),
  });

  const order = data?.createOrder;

  return {
    createOrder: async (createOrderInput: CreateOrderInput) => {
      await createOrder({ variables: { createOrderInput } });
    },
    isCreatingOrder,
    order,
  };
};

export const useEditCompany = () => {
  const [editCompany, { loading: isUpdatingCompany }] = useUpdateCompanyMutation();

  return {
    editCompany: async (updateCompanyInput: UpdateCompanyInput) => {
      await editCompany({
        variables: {
          updateCompanyInput,
        },
      });
    },
    isUpdatingCompany,
  };
};
