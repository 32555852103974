import { InvoiceTypes } from "src/api/graphql/types-and-hooks";
import { date, mixed, object, string } from "yup";

export default object({
  invoiceNumber: string().nullable(),
  contractor: string().nullable(),
  companyId: string().nullable(),
  description: string().nullable(),
  paymentDueFrom: date().nullable(),
  paymentDueTo: date().nullable(),
  type: mixed<InvoiceTypes>().oneOf(Object.values(InvoiceTypes)).required(),
  category: mixed().nullable(),
}).required();
