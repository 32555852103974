import { useToastError } from "src/common/hooks/useToast";

import { useBudgetDocumentsQuery } from "src/api/graphql/generated/gql-hooks";
import { GetBudgetDocumentsOptionsInput } from "src/api/graphql/types-and-hooks";

export const useBudgetDocuments = (options: GetBudgetDocumentsOptionsInput) => {
  const { loading, data } = useBudgetDocumentsQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const budgetDocuments = data?.budgetDocuments?.documents || [];
  const total = data?.budgetDocuments?.total;

  return {
    loading,
    budgetDocuments,
    total,
  };
};
