import axios from "axios";
import { Buffer } from "buffer";
import { fileTypeFromBuffer } from "file-type";

import { API_URL } from "src/api/apollo/client";

export const useGetInvoiceFile = () => {
  const getInvoiceFile = async (id: string): Promise<Blob> => {
    const file = await getFile(id, "/invoice/download");
    return file;
  };

  return { handleGetInvoiceInvoice: getInvoiceFile };
};

export const useGetOrderInvoiceFile = () => {
  const getOrderInvoiceFile = async (id: string): Promise<Blob> => {
    const file = await getFile(id, "/order/download");
    return file;
  };

  return { handleGetOrderInvoice: getOrderInvoiceFile };
};

const getFile = async (id: string, path: string): Promise<Blob> => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await axios.get(`${API_URL}${path}/${id}/`, {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const blob = new Blob([response.data], {
    type: response.data.type || "application/octet-stream",
  });

  // Convert blob to buffer
  const buffer = await blob.arrayBuffer().then((buf) => Buffer.from(buf));

  // Determine the file type
  const fileType = await fileTypeFromBuffer(buffer);
  const mimeType = fileType?.mime || "application/octet-stream";

  // Check if the file is a PDF or an image
  const isPdf = mimeType === "application/pdf";
  const isImage = mimeType.startsWith("image/");

  if (!isPdf && !isImage) {
    // console.error("Unsupported file type:", mimeType);
    throw new Error("Unsupported file type");
  }

  // Create a new blob with the correct MIME type
  const validatedBlob = new Blob([buffer], { type: mimeType });

  return validatedBlob;
};
