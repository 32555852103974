import { GraphQLErrors } from "@apollo/client/errors";
import { get } from "lodash";

export const getFormErrors = (errors: GraphQLErrors): any => {
  const parsedErrors = {};
  if (!errors) return parsedErrors;

  errors.forEach((e) => Object.assign(parsedErrors, get(e, "extensions.originalError.errors", {})));
  return parsedErrors;
};
