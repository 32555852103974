import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import Label from "src/components/ui/Label";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppSelect, { Option } from "src/components/form/AppSelect";
import AppDatePicker from "src/components/form/AppDatePicker";

import { useToastError } from "src/common/hooks/useToast";

import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import { useAddSingleBudgetTransaction } from "../../budget.hooks";

import addSingleTransactionSchema from "./addSingleTransaction.schema";

type Props = {
  dateTo: string;
  onClose: () => void;
  onCancel: () => void;
  dateFrom: string;
  categories: any[];
  transactionType: BudgetCategoryTypes;
};

const AddSingleTransaction: React.FC<Props> = ({
  dateTo,
  onClose,
  onCancel,
  dateFrom,
  categories,
  transactionType,
}) => {
  const [subCategoryOptions, setSubCategoryOptions] = useState<Option[]>([]);
  const [rootCategoryOptions, setRootCategoryOptions] = useState<Option[]>([]);

  const { t } = useTranslation();
  const { budgetId } = useParams();
  const { loading, isAdded, addSingleBudgetTransaction } = useAddSingleBudgetTransaction();
  const {
    watch,
    control,
    register,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    values: {
      type: transactionType,
    },
    resolver: yupResolver(addSingleTransactionSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isAdded) onClose();
  }, [isAdded]);

  useEffect(() => {
    if (categories?.length) {
      const rootCategoriesOptions = categories
        .filter((c) => !c.parent?._id)
        .map((c) => ({ label: c.name, value: c._id }));
      setRootCategoryOptions(rootCategoriesOptions);
    }
  }, [categories]);

  useEffect(() => {
    if (watch("category")) {
      const category = categories.find((c) => c._id === watch("category"));
      if (category?.isLeaf) return setSubCategoryOptions([]);

      const subCategoriesOptions = categories
        .filter((c) => c.parent?._id === watch("category"))
        .map((c) => ({ label: c.name, value: c._id }));
      setSubCategoryOptions(subCategoriesOptions);
    }
  }, [watch("category")]);

  const onSubmit = async (data: any) => {
    const { subCategory } = data;
    if (!subCategory) {
      useToastError("Grupa musi zawierać podkategorię");
      return;
    }

    await addSingleBudgetTransaction({
      categoryId: subCategory,
      budgetId,
      value: data.value,
      date: data.date,
      type: transactionType,
    });
  };

  return (
    <Modal
      show={true}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmText="Save"
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
      loading={loading}
    >
      <div className="flex flex-col h-full xl:max-w-[500px] max-w-[350px] min-w-[300px] xl:min-w-[400px]">
        <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">
          {t(transactionType === BudgetCategoryTypes.Cost ? "Add-cost" : "Add-income")}
        </Text>
        <Text className="text-12 text-text-secondary mt-1">{t("Select-transaction-amount-and-date")}</Text>
        <div className="2xl:mt-10 xl:mt-8 mt-6 flex flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row space-x-4">
              <div className={`flex flex-col space-y-1 ${subCategoryOptions?.length ? "w-full" : "basis-1/2"}`}>
                <Label className="font-sf-pro-regular text-white">{t("Assign-to-group")}</Label>
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <AppSelect
                      options={rootCategoryOptions}
                      field={field}
                      placeholder="Wybierz..."
                      dark
                      error={!!formErrors.category}
                    />
                  )}
                />
              </div>
              {subCategoryOptions?.length && (
                <div className="flex flex-col space-y-1 w-full">
                  <Label className="font-sf-pro-regular text-white">&nbsp;</Label>
                  <Controller
                    control={control}
                    name="subCategory"
                    render={({ field }) => (
                      <AppSelect options={subCategoryOptions} field={field} placeholder="Wybierz..." dark />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <Label className="font-sf-pro-regular text-white">{t("Transaction-value")}</Label>
              <AppInput
                id="value"
                register={register}
                inputType={InputTypes.NUMBER}
                inputClasses={`text-white ${!formErrors.value?.message && "focus:border-white"}`}
                placeholder="Wpisz kwotę"
                error={!!formErrors.value?.message}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <Label className="font-sf-pro-regular text-white">{t("Payment-date")}</Label>
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <AppDatePicker
                    field={field}
                    placeholder="YYYY-MM-DD"
                    range={false}
                    containerClasses="hover:border-white"
                    inputClasses="text-white"
                    error={!!formErrors.date?.message}
                    minDate={new Date(dateFrom)}
                    maxDate={new Date(dateTo)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSingleTransaction;
