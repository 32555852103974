import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "src/components/ui/Sidebar";
import AppHeader from "src/components/ui/AppHeader";

import { socket } from "src/common/Socket/socket";
import { useToast } from "src/common/hooks/useNotifications";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

const HomeWrapper = () => {
  const { showToast } = useToast();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (socket.connected && !socket.hasListeners("notification") && currentUser) {
      socket.on("notification", (data) => {
        showToast(data?.message);
      });
    }

    return () => {
      socket.off("connect", () => console.log("off connected"));
      socket.off("disconnect", () => console.log("disconnected"));
      socket.off("notification", () => console.log("off message"));
    };
  }, [currentUser]);

  return (
    <>
      <div className="flex flex-row 2xl:h-[calc(100%-91px)] xl:h-[calc(100%-81px)] h-[calc(100%-71px)]">
        <div className="basis-1/7 shrink-0">
          <Sidebar />
        </div>
        <div className="basis-6/7 max-w-[85.7142857%]">
          <AppHeader />
          <div className="bg-bg-light-gray flex flex-col h-screen max-h-full max-w-full min-h-full overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeWrapper;
