import React from "react";

import { customTwMerge } from "src/utils/twMerge";
import Text from "./Text";

type Props = {
  title: string | number;
  bgColor: string;
  titleColor: string;
  customClass?: string;
  textClassName?: string;
};

const Pill: React.FC<Props> = ({ title, bgColor, titleColor, textClassName = "", customClass = "" }) => {
  const combinedClasses = customTwMerge(
    "flex justify-center items-center px-2 py-2 2xl:h-8 xl:h-6 h-4 w-full rounded-[36px]",
    customClass,
    bgColor,
  );
  const combinedTextClasses = customTwMerge("2xl:text-14 xl:text-12 text-10", titleColor, textClassName);

  return (
    <div className={combinedClasses}>
      <Text className={combinedTextClasses}>{title}</Text>
    </div>
  );
};

export default Pill;
