import React from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Pill from "src/components/ui/Pill";
import TableRow from "src/components/ui/TableRow";

import { useGetOrderInvoiceFile } from "src/common/hooks/useGetInvoiceFile";

import { formatDate } from "src/utils/time";
import { formatPrice } from "src/utils/currency";
import { handleDownloadDocument } from "src/utils/downloadDocument";

import InvoiceIcon from "src/assets/images/invoice-blue.svg";

type Props = {
  data: any;
  onClick?: () => void;
};

enum OrderType {
  SUBSCRIPTION = "Subscription",
  PACKAGE = "Package",
}

const OrdersTableRow: React.FC<Props> = ({
  data: { _id, number, createdAt, subscription, package: pkg, total, invoice, status },
  onClick,
}) => {
  const { t } = useTranslation();
  const { handleGetOrderInvoice } = useGetOrderInvoiceFile();

  const handleDownloadInvoice = async () => {
    if (invoice) {
      const invoiceFile = await handleGetOrderInvoice(_id);
      handleDownloadDocument(invoiceFile, invoice.split("/")[2]);
    }
  };

  return (
    <TableRow onClick={onClick}>
      <td>
        <Text className="ml-2">{number}</Text>
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">{formatDate(createdAt, "DD-MM-YYYY")}</Text>
      </td>
      <td>
        <Text>{subscription?.name || pkg?.name}</Text>
      </td>
      <td>
        <Text>{subscription ? t(OrderType.SUBSCRIPTION) : t(OrderType.PACKAGE)}</Text>
      </td>
      <td>
        <Text>{t(status)}</Text>
      </td>
      <td>
        <div className="2xl:max-w-[150px] xl:max-w-[130px] max-w-[100px]">
          <Pill title={formatPrice(total, "zł")} bgColor="bg-light-green" titleColor="text-green" />
        </div>
      </td>
      <td>
        <img
          className={`${invoice && "cursor-pointer"} ${!invoice && "opacity-50"} 2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4`}
          src={InvoiceIcon}
          onClick={handleDownloadInvoice}
        />
      </td>
    </TableRow>
  );
};

export default OrdersTableRow;
