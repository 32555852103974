import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthWrapper from "src/components/ui/AuthWrapper";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { getRegisterPath } from "src/common/router/routerPaths";

import forgotPasswordSchema from "./forgotPassword.schema";
import { useForgotPassword } from "./resetPassword.hooks";
import Text from "src/components/ui/Text";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { loading, handleForgotPassword } = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    reValidateMode: "onChange",
  });

  return (
    <AuthWrapper>
      <div className="mt-12 flex flex-col items-center space-y-4 w-full">
        <Text className="2xl:text-28 xl:text-24 text-20 text-white font-sf-pro-medium">Witaj</Text>
        <Text className="mx-4 text-center 2xl:text-20 xl:text-18 text-14 text-text-secondary">
          Wpisz adres e-mail, na który wyślemy Ci link do zresetowania hasła
        </Text>
        <div className="max-w-[75%] sm:max-w-[50%] w-full">
          <AppInput
            id="email"
            inputType={InputTypes.TEXT}
            placeholder="Adres e-mail"
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !errors?.email && "border-white/20 focus:border-white/60"
            }`}
            error={!!errors?.email}
          />
          <div className="mt-4">
            <AppButton
              color={ButtonColorTypes.PRIMARY}
              onClick={handleSubmit(handleForgotPassword)}
              customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
              textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
              title="Przypomnij hasło"
              isLoading={loading}
            />
          </div>
        </div>
        <Text
          className="text-text-secondary text-center cursor-pointer text-12 2xl:text-16 xl:text-14 sm:text-10"
          onClick={() => navigate(getRegisterPath())}
        >
          Nie masz konta? &nbsp;
          <Text className="font-sf-pro-medium text-white text-12 2xl:text-16 xl:text-14 sm:text-10">
            Zarejestruj się
          </Text>
        </Text>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
