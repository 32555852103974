import { mixed, object, string } from "yup";

export default object({
  firstName: string().required(),
  lastName: string().required(),
  email: string().required(),
  category: string().required(),
  subject: string().required(),
  content: string().required(),
  file: mixed<Blob>().nullable(),
}).required();
