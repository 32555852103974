import { SendFeedbackInput } from "src/api/graphql/types-and-hooks";
import { useSendFeedbackMutation } from "src/api/graphql/generated/gql-hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useSendFeedback = () => {
  const [handleSendFeedback, { loading, data }] = useSendFeedbackMutation({
    onCompleted: () => useToastSuccess("Wysłano"),
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    isSended: !!data,
    isSending: loading,
    handleSendFeedback: async (sendFeedbackInput: SendFeedbackInput) => {
      await handleSendFeedback({ variables: { sendFeedbackInput } });
    },
  };
};
