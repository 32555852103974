import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import AppSwitch from "src/components/form/AppSwitch";

import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import ChartTab from "./chartTab/ChartTab";
import CostsTab from "./costsTab/CostsTab";
import { useBudget } from "./budget.hooks";
import DocumentsTab from "./documentsTab/DocumentsTab";

enum Views {
  INCOMES = "incomes",
  COSTS = "costs",
  DOCUMENTS = "documents",
  CHART = "chart",
}

const BudgetDetails = () => {
  const [view, setView] = useState<Views>(Views.INCOMES);

  const { t } = useTranslation();
  const { budgetId } = useParams();
  const { budget, loading } = useBudget(budgetId);

  return (
    <>
      <div className="m-6 flex flex-col 2xl:space-y-6 xl:space-y-4 space-y-2">
        <div className="2xl:h-10 xl:h-9 h-8 flex flex-row justify-between">
          <div>
            <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary mr-2.5">{t("Budget")}</Text>
            <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary">/</Text>
            <Text className="2xl:text-28 xl:text-24 text-20 ml-2.5 font-sf-pro-bold">{t("Details")}</Text>
          </div>
        </div>
        <AppSwitch
          bgColor="bg-bg-light-gray4"
          activeColor="bg-blue"
          optInactiveWidth="min-w-[100px]"
          optActiveWidth="min-w-[100px]"
          activeTextClass="text-white font-sf-pro-bold"
          options={(Object.keys(Views) as Array<keyof typeof Views>).map((key) => {
            return { value: Views[key], label: t(key) };
          })}
          onChange={(val) => setView(val as Views)}
        />
      </div>
      <div className="mx-6">
        {view === Views.INCOMES && (
          <CostsTab
            type={BudgetCategoryTypes.Income}
            budgetName={budget?.name}
            budgetDateFrom={budget?.dateFrom}
            budgetDateTo={budget?.dateTo}
            categoriesTree={budget?.incomeCategoriesTree}
            isLoading={loading}
          />
        )}
        {view === Views.COSTS && (
          <CostsTab
            type={BudgetCategoryTypes.Cost}
            budgetName={budget?.name}
            budgetDateFrom={budget?.dateFrom}
            budgetDateTo={budget?.dateTo}
            categoriesTree={budget?.costCategoriesTree}
            isLoading={loading}
          />
        )}
        {view === Views.DOCUMENTS && (
          <DocumentsTab budgetName={budget?.name} budgetDateFrom={budget?.dateFrom} budgetDateTo={budget?.dateTo} />
        )}
        {view === Views.CHART && (
          <ChartTab
            budgetName={budget?.name}
            budgetDateFrom={budget?.dateFrom}
            budgetDateTo={budget?.dateTo}
            incomesCategoriesTree={budget?.incomeCategoriesTree}
            costsCategoriesTree={budget?.costCategoriesTree}
          />
        )}
      </div>
    </>
  );
};

export default BudgetDetails;
