import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";

import WeeklyEvent from "src/components/ui/CalendarWeeklyEvent";

import { InvoicesListDocument } from "src/api/graphql/types-and-hooks";
import { customTwMerge } from "src/utils/twMerge";

import Text from "./Text";

type Props = {
  documents?: InvoicesListDocument[];
  momentDate: Moment;
};

const DAYS = ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Ndz"];

const CalendarWeekly: React.FC<Props> = ({ momentDate, documents = [] }) => {
  const [daysInWeek, setDaysInWeek] = useState([]);

  useEffect(() => {
    const weekStart = momentDate.clone().startOf("isoWeek");
    const days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days").format("D"));
    }

    setDaysInWeek(days);
  }, [momentDate]);

  const renderEvents = (day: number) => {
    const events = documents.filter((d) => moment(d.paymentDue).date() === day);
    return events?.map((e) => {
      return <WeeklyEvent document={e} key={e._id} />;
    });
  };

  const renderDays = () => {
    return daysInWeek.map((day, i) => {
      const isToday = moment().date() === Number(day) && momentDate.clone().month() === moment().month();
      const combinedClasses = customTwMerge(
        "text-text-secondary",
        isToday && "bg-blue rounded-[4px] text-white px-2 py-1",
      );

      return (
        <div key={day} className="flex flex-col space-y-2 border-r border-[Black/5%] pb-2">
          <div className="h-[15px] flex justify-center items-center mb-2">
            <Text className={combinedClasses}>{`${DAYS[i]} ${day}`}</Text>
          </div>
          {renderEvents(Number(day))}
        </div>
      );
    });
  };

  return <div className="grid grid-cols-7 h-full">{renderDays()}</div>;
};

export default CalendarWeekly;
