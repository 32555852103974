import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { formatDate } from "src/utils/time";
import { formatPrice } from "src/utils/currency";

import { InvoiceTypes, PaymentStatus } from "src/api/graphql/types-and-hooks";

import CostIcon from "src/assets/images/unpaid.svg";
import IncomeIcon from "src/assets/images/paid.svg";

import Text from "./Text";

type Props = {
  document: Partial<any>;
};

const DailyEvent: React.FC<Props> = ({
  document: { type, total, subtotal, paymentDue, paymentStatus, invoiceNumber, billTo },
}) => {
  const { t } = useTranslation();
  const isOverdue = paymentDue && moment(paymentDue).isBefore(moment(), "day");

  const getBgColor = () => {
    if (isOverdue && paymentStatus !== PaymentStatus.Paid) return "bg-light-red";
    if (paymentStatus === PaymentStatus.Paid) return "bg-light-green";

    return "bg-[#9A9EA90F]";
  };

  const getPaymentStatus = () => {
    if (paymentStatus === PaymentStatus.Paid) return <Text className="text-green">{t("Paid")}</Text>;
    return <Text className={`${isOverdue ? "text-red" : "text-text-secondary"}`}>{t("Unpaid")}</Text>;
  };

  const getPaymentTextColor = () => {
    if (paymentStatus === PaymentStatus.Paid) return "text-green";
    return "text-red";
  };

  return (
    <div className={`w-full rounded-[20px] ${getBgColor()} shadow-md 2xl:pt-6 pt-4`}>
      <div className="flex flex-row h-full">
        <div className="relative basis-2/3 pl-5">
          <div className="flex flex-col">
            <Text className="2xl:text-18 xl:text-16 text-14 font-sf-pro-bold">{invoiceNumber}</Text>
            <Text className={`font-sf-pro-medium ${getPaymentTextColor()} mt-2`}>{formatPrice(subtotal, "zł")}</Text>
            <Text className="font-sf-pro-regular text-text-secondary mt-1">{formatPrice(total, "zł")} z VAT</Text>
            <div className="flex flex-row items-center xl:space-x-4 space-x-2 mt-4 pb-2">
              {<img className="xl:w-7 xl:h-7 w-4 h-4" src={type === InvoiceTypes.Cost ? CostIcon : IncomeIcon} />}{" "}
              {getPaymentStatus()}
            </div>
          </div>
        </div>
        <div className="relative basis-1/3">
          <div className="flex flex-col h-full">
            <div className="flex flex-col basis-1/3">
              <Text
                className={`font-sf-pro-medium ${
                  isOverdue && paymentStatus === PaymentStatus.Unpaid ? "text-red" : "text-text-secondary"
                } text-end pr-4`}
              >
                {t("Due-date")}
              </Text>
              <Text
                className={`font-sf-pro-medium ${
                  isOverdue && paymentStatus === PaymentStatus.Unpaid ? "text-red" : "text-text-secondary"
                } text-end pr-4`}
              >
                {paymentDue ? formatDate(paymentDue, "DD.MM.YYYY") : "-"}
              </Text>
            </div>
            <div className="relative basis-2/3 h-full bg-blue p-5 rounded-tl-[20px] rounded-br-[20px]">
              <div className="absolute top-3 left-4 flex flex-col">
                <Text className="2xl:text-18 xl:text-16 text-12 text-white font-sf-pro-bold">
                  {billTo?.name || invoiceNumber}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyEvent;
