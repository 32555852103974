import React, { useEffect, useState } from "react";
import moment from "moment";
import { InferType } from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Loader from "src/components/ui/Loader";
import AppSelect from "src/components/form/AppSelect";
import UploadFile from "src/components/ui/UploadFile";
import AppTextArea from "src/components/form/AppTextarea";
import AppCheckbox from "src/components/form/AppCheckbox";
import AppDatePicker from "src/components/form/AppDatePicker";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import {
  InvoiceTypes,
  PaymentStatus,
  InvoiceGenres,
  InvoiceSubtypes,
  CreateInvoiceInput,
} from "src/api/graphql/types-and-hooks";

import { useToastError } from "src/common/hooks/useToast";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getInvoicesPath } from "src/common/router/routerPaths";
import { useCreateThumbnail } from "src/common/hooks/useCreateThumbnail";

import { subtractPrices } from "src/utils/currency";
import { getPdfPagesCount } from "src/utils/getPdfPagesCount";

import MagnifierIcon from "src/assets/images/magnifier.svg";
import UploadBlackIcon from "src/assets/images/upload-black.svg";

import invoiceSchema from "./invoice.schema";
import {
  useCompanyCars,
  useCompanyOcrPagesLeft,
  useCreateInvoice,
  useEditInvoice,
  useReadDataFromInvoice,
} from "../invoice.hooks";

type Props = {
  formValues?: InferType<typeof invoiceSchema>;
  onGoBack?: () => void;
  showUploadModal?: boolean;
  startAccountValuesDate?: string;
};

const InvoiceForm: React.FC<Props> = ({ formValues, showUploadModal = false, onGoBack, startAccountValuesDate }) => {
  const [useOcr, setUseOcr] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileUpdated, setFileUpdated] = useState(false);
  const [filePagesCount, setFilePagesCount] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const companyId = currentUser?.company?._id;
  const { invoiceId } = useParams();
  const { createThumbnail } = useCreateThumbnail();
  const { cars } = useCompanyCars(companyId);
  const { handleEditInvoice, isUpdating } = useEditInvoice(invoiceId);
  const { handleCreateInvoice, isCreating } = useCreateInvoice();
  const { ocrPagesLeft, companyOcrPagesLeftLoading } = useCompanyOcrPagesLeft(companyId);
  const { handleReadDataFromInvoice, isReading, invoiceData } = useReadDataFromInvoice();

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    setError,
    getValues,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    values: { ...formValues, paymentStatus: invoiceId ? formValues?.paymentStatus : PaymentStatus.Unpaid },
    resolver: yupResolver(invoiceSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (showUploadModal) setShowModal(true);
  }, [showUploadModal]);

  useEffect(() => {
    const parsedFormValues = Object.assign(formValues, invoiceData, {
      file: getValues("file"),
      ...(formValues.type === InvoiceTypes.Income ? { billFrom: formValues.billFrom } : { billTo: formValues.billTo }),
    });
    reset({
      ...parsedFormValues,
      paymentStatus: invoiceId ? formValues?.paymentStatus : PaymentStatus.Unpaid,
    });
  }, [formValues, invoiceData]);

  useEffect(() => {
    if (getValues("file")) {
      createThumbnail(getValues("file"), "thumbnail");

      const handleFilePagesCount = async () => {
        const file = getValues("file") as File;
        if (file.type === "application/pdf") {
          const pagesCount = await getPdfPagesCount(file);
          setFilePagesCount(pagesCount);
        } else {
          setFilePagesCount(1);
        }
      };

      handleFilePagesCount();
    }
  }, [watch("file"), formValues, invoiceData]);

  useEffect(() => {
    const postCode = parsePostCode(watch("billFrom.postcode"));
    setValue("billFrom.postcode", postCode, { shouldDirty: true });
  }, [watch("billFrom.postcode")]);

  useEffect(() => {
    const postCode = parsePostCode(watch("billTo.postcode"));
    setValue("billTo.postcode", postCode, { shouldDirty: true });
  }, [watch("billTo.postcode")]);

  useEffect(() => {
    if (watch("paymentStatus") === PaymentStatus.Unpaid) {
      setValue("splitPayment", false, { shouldDirty: true });
    } else {
      setValue("paymentDate", new Date(), { shouldDirty: true });
    }
  }, [watch("paymentStatus")]);

  const parsePostCode = (value: string) => {
    value = value?.replace(/[^0-9]/g, "");
    if (value?.length > 2) {
      value = value.slice(0, 2) + "-" + value.slice(2);
    }
    if (value?.length > 6) {
      value = value.slice(0, 6);
    }
    return value;
  };

  const openInvoice = () => {
    if (getValues("file")) {
      const fileURL = URL.createObjectURL(getValues("file"));
      window.open(fileURL);
    }
  };

  const parseData = async (data: any) => {
    data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null)) as CreateInvoiceInput;
    data.vat = subtractPrices([data.total, data.subtotal]);
    data.leftToPay = data.paymentStatus === PaymentStatus.Paid ? 0 : data.total;

    if (data.paymentDue && moment(data.paymentDue).isSameOrBefore(moment(startAccountValuesDate))) {
      setError("paymentDue", {
        type: "manual",
        message: "Payment-due-start-account-values-date-error",
      });
      return;
    }

    if (data.paymentDate) data.paymentDate = moment(data.paymentDate).startOf("day").toDate();
    if (data.billTo?.country === null) delete data.billTo.country;
    if (data.billFrom?.country === null) delete data.billFrom.country;

    if (invoiceId) {
      if (!fileUpdated) {
        delete data.file;
      }
      handleEditInvoice({ ...data, id: invoiceId });
    } else {
      handleCreateInvoice(data);
    }
  };

  const onDropFile = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer?.files?.[0];

    if (!file) return;
    if (file.type !== "application/pdf") useToastError("Niepoprawny format pliku");
    setValue("file", file, { shouldDirty: true });
    if (invoiceId) setFileUpdated(true);
    setShowModal(false);
  };

  const onFileUpload = (file: File) => {
    setValue("file", file, { shouldDirty: true });
    if (invoiceId) setFileUpdated(true);
  };

  const onCloseModal = () => {
    const file = getValues("file") as File;

    if (file && useOcr) {
      handleReadDataFromInvoice(file);
    }

    setShowModal(false);
  };

  return (
    <>
      <UploadFile
        show={showModal}
        loading={companyOcrPagesLeftLoading}
        ocrPagesLeft={ocrPagesLeft}
        fileName={(getValues("file") as File)?.name}
        isOcrDisabled={!getValues("file") || !ocrPagesLeft || ocrPagesLeft < filePagesCount}
        onCancel={() => {
          setShowModal(false);
          onGoBack();
        }}
        onConfirm={onCloseModal}
        onUseOcr={setUseOcr}
        onUploadFile={onFileUpload}
        onDropFile={onDropFile}
      />
      {isReading ? (
        <Loader />
      ) : (
        <div className="bg-white rounded-[20px] 2xl:p-6 xl:p-4 p-2">
          <div className="flex flex-row space-x-4 pb-4 items-center border-b">
            <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-1/5 min-w-[150px] h-full">
              <Label>{t("Number")}</Label>
              <AppInput
                id="invoiceNumber"
                register={register}
                inputType={InputTypes.TEXT}
                placeholder={t("Invoice-number")}
                inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                error={!!formErrors.invoiceNumber}
              />
            </div>
            {watch("genre") === InvoiceGenres.Car ? (
              <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-1/5 min-w-[150px] h-full">
                <Label>{t("Car")}</Label>
                <Controller
                  control={control}
                  name="car"
                  render={({ field }) => (
                    <AppSelect
                      field={field}
                      placeholder={t("Car")}
                      options={cars?.map((car) => ({ label: car.name, value: car._id })) || []}
                      error={!!formErrors.car}
                    />
                  )}
                />
              </div>
            ) : undefined}
            <div className="flex flex-col 2xl:space-y-2 space-y-1">
              <Label>{t("Type")}</Label>
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">
                {t(getValues("type") === InvoiceTypes.Cost ? "Cost" : "Income")}
              </Text>
            </div>
            <div className="flex flex-col 2xl:space-y-2 space-y-1">
              <Label>{t("Genre")}</Label>
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t(getValues("genre"))}</Text>
            </div>
            {getValues("subtype") ? (
              <div className="flex flex-col 2xl:space-y-2 space-y-1">
                <Label>{t("Subtype")}</Label>
                <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t(getValues("subtype"))}</Text>
              </div>
            ) : undefined}
          </div>
          <div className="pt-5 flex flex-row space-x-7">
            <div className="flex flex-col space-y-3 basis-1/8 max-w-[140px]">
              <div id="thumbnail" className="relative h-[190px] cursor-pointer drop-shadow-xl" onClick={openInvoice}>
                <div className="absolute flex w-full h-full justify-center items-center">
                  <img src={MagnifierIcon} />
                </div>
              </div>
              {invoiceId && (
                <div
                  className="flex flex-row space-x-2 justify-center items-center cursor-pointer"
                  onClick={() => setShowModal(true)}
                >
                  <img src={UploadBlackIcon} />
                  <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">Zastąp plik</Text>
                </div>
              )}
            </div>
            <div className="basis-7/8">
              <div className="flex flex-row space-x-4 items-center">
                <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-4/12 h-full">
                  <Label>{t("Payment-status")}</Label>
                  {!invoiceId && (
                    <Text className="2xl:text-14 xl:text-12 text-10 text-brand-primary">
                      {t("Payment-status-change-info")}
                    </Text>
                  )}
                  <Controller
                    control={control}
                    name="paymentStatus"
                    render={({ field }) => (
                      <AppSelect
                        field={field}
                        placeholder="Status płatności"
                        options={[
                          { label: t("Paid"), value: PaymentStatus.Paid },
                          { label: t("Unpaid"), value: PaymentStatus.Unpaid },
                        ]}
                        error={!!formErrors.paymentStatus}
                        disabled={!invoiceId}
                      />
                    )}
                  />
                </div>
                {watch("paymentStatus") === PaymentStatus.Paid ? (
                  <>
                    <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-4/12">
                      <Label>{t("Payment-date")}</Label>
                      <Controller
                        control={control}
                        name="paymentDate"
                        render={({ field }) => <AppDatePicker field={field} error={!!formErrors.paymentDate} />}
                      />
                    </div>
                    <div className="flex flex-col 2xl:space-y-2 space-y-1 basis-4/12">
                      <Label> </Label>
                      <AppCheckbox id="splitPayment" register={register} label="Split payment" />
                    </div>
                  </>
                ) : undefined}
              </div>
              <div className="mt-8 flex flex-row space-x-4">
                <div className="basis-4/12 flex flex-col space-y-2">
                  <Label>{t("Seller")}</Label>
                  <AppInput
                    id="billFrom.name"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("Company-name")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billFrom?.name}
                  />
                  <AppInput
                    id="billFrom.address"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("Address")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billFrom?.address}
                  />
                  <div className="flex flex-row space-x-2">
                    <div className="basis-1/2">
                      <AppInput
                        id="billFrom.postcode"
                        register={register}
                        inputType={InputTypes.TEXT}
                        placeholder={t("Code")}
                        inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                        error={!!formErrors.billFrom?.postcode}
                      />
                    </div>
                    <div className="basis-1/2">
                      <AppInput
                        id="billFrom.city"
                        register={register}
                        inputType={InputTypes.TEXT}
                        placeholder={t("City")}
                        inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                        error={!!formErrors.billFrom?.city}
                      />
                    </div>
                  </div>
                  <AppInput
                    id="billFrom.country"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("Country")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billFrom?.country}
                  />
                  <AppInput
                    id="billFrom.companyId"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("VAT-ID")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billFrom?.companyId}
                  />
                </div>
                <div className="basis-4/12 flex flex-col space-y-2">
                  <Label>{t("Buyer")}</Label>
                  <AppInput
                    id="billTo.name"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("Company-name")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billTo?.name}
                  />
                  <AppInput
                    id="billTo.address"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("Address")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billTo?.address}
                  />
                  <div className="flex flex-row space-x-2 w-full">
                    <div className="basis-1/2">
                      <AppInput
                        id="billTo.postcode"
                        register={register}
                        inputType={InputTypes.TEXT}
                        placeholder={t("Code")}
                        inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                        error={!!formErrors.billTo?.postcode}
                      />
                    </div>
                    <div className="basis-1/2">
                      <AppInput
                        id="billTo.city"
                        register={register}
                        inputType={InputTypes.TEXT}
                        placeholder={t("City")}
                        inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                        error={!!formErrors.billTo?.city}
                      />
                    </div>
                  </div>
                  <AppInput
                    id="billTo.country"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("Country")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billTo?.country}
                  />
                  <AppInput
                    id="billTo.companyId"
                    register={register}
                    inputType={InputTypes.TEXT}
                    placeholder={t("VAT-ID")}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.billTo?.companyId}
                  />
                </div>
                <div className="basis-4/12 flex flex-col space-y-2">
                  <Label>{`${t("Description")}/${t("Service-name")}`}</Label>
                  <AppTextArea id="description" register={register} rows={8} />
                </div>
              </div>
              <div className="mt-8 flex flex-row space-x-4">
                <div className="flex flex-col space-y-2 basis-1/5">
                  <Label>{t("Issue-date")}</Label>
                  <Controller
                    control={control}
                    name="issueDate"
                    render={({ field }) => <AppDatePicker field={field} error={!!formErrors.issueDate} />}
                  />
                </div>
                <div className="flex flex-col space-y-2 basis-1/5">
                  <Label>{t("Sale-date")}</Label>
                  <Controller
                    control={control}
                    name="saleDate"
                    render={({ field }) => <AppDatePicker field={field} error={!!formErrors.saleDate} />}
                  />
                </div>
                <div
                  className={`flex flex-col space-y-2 basis-1/5 ${
                    formErrors?.paymentDue?.message &&
                    formErrors.paymentDue.message !== "paymentDue is a required field"
                      ? "mb-4"
                      : ""
                  }`}
                >
                  <Label>{t("Due-date")}</Label>
                  <Controller
                    control={control}
                    name="paymentDue"
                    render={({ field }) => (
                      <AppDatePicker
                        field={field}
                        error={!!formErrors.paymentDue}
                        errorMessage={
                          formErrors?.paymentDue?.message &&
                          formErrors.paymentDue.message !== "paymentDue is a required field"
                            ? t(formErrors?.paymentDue?.message)
                            : null
                        }
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col space-y-2 basis-1/5">
                  <Label>{t("Net-amount")}</Label>
                  <AppInput
                    id="subtotal"
                    register={register}
                    inputType={InputTypes.TEXT}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.subtotal}
                    disabled={getValues("paymentStatus") === PaymentStatus.Paid && !!invoiceId}
                  />
                  {[InvoiceSubtypes.Insurance].includes(watch("subtype")) ? (
                    <>
                      <Label>{t("Capital")}</Label>
                      <AppInput
                        id="capital"
                        register={register}
                        inputType={InputTypes.NUMBER}
                        inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                        error={!!formErrors.capital}
                        disabled={getValues("paymentStatus") === PaymentStatus.Paid && !!invoiceId}
                      />
                    </>
                  ) : undefined}
                </div>
                <div className="flex flex-col space-y-2 basis-1/5">
                  <Label>{t("Gross-amount")}</Label>
                  <AppInput
                    id="total"
                    register={register}
                    inputType={InputTypes.TEXT}
                    inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                    error={!!formErrors.total}
                    disabled={getValues("paymentStatus") === PaymentStatus.Paid && !!invoiceId}
                  />
                  {[InvoiceSubtypes.Leasing, InvoiceSubtypes.Insurance].includes(watch("subtype")) ? (
                    <>
                      <Label>{t("Interest")}</Label>
                      <AppInput
                        id="interest"
                        register={register}
                        inputType={InputTypes.NUMBER}
                        inputClasses="font-sf-pro-medium border border-bg-light-gray3"
                        error={!!formErrors.interest}
                        disabled={getValues("paymentStatus") === PaymentStatus.Paid && !!invoiceId}
                      />
                    </>
                  ) : undefined}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 flex flex-row space-x-4 justify-center items-center">
            <div className="min-w-[100px]">
              <AppButton
                color={ButtonColorTypes.PRIMARY}
                title={t("Save")}
                customClass="rounded-[8px]"
                textClass="text-white"
                onClick={handleSubmit(parseData)}
                isLoading={isCreating || isUpdating}
              />
            </div>
            <div>
              <AppButton
                color={ButtonColorTypes.SECONDARY}
                title={t("Cancel")}
                textClass="text-text-secondary"
                onClick={() => navigate(getInvoicesPath())}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceForm;
