import React from "react";
import Joyride, { STATUS, Placement, TooltipRenderProps } from "react-joyride";

import Hint from "./Hint";

type Step = {
  title: string;
  target: string;
  content: string;
  placement: Placement;
  disableBeacon: boolean;
};

type Props = {
  steps: Step[];
  onGuideEnd: () => void;
};

const Guide: React.FC<Props> = ({ steps, onGuideEnd }) => {
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      onGuideEnd();
    }
  };

  return (
    <Joyride
      steps={steps}
      run={true}
      callback={handleJoyrideCallback}
      continuous
      floaterProps={{ placement: "top" }}
      showProgress
      tooltipComponent={(props: TooltipRenderProps) => <Hint {...props} />}
      styles={{
        options: {
          arrowColor: "#3B5883",
        },
      }}
    />
  );
};

export default Guide;
