import { useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { AuthenticationContext, LoggedUser } from "./AuthProvider";
import { getLoginPath } from "../router/routerPaths";
import { useToastSuccess } from "../hooks/useToast";

export const useCurrentUser = () => {
  const { user } = useContext(AuthenticationContext);

  return user;
};

export const useSetCurrentUser = () => {
  const { setUser } = useContext(AuthenticationContext);
  return useCallback(
    ({ user, accessToken }: { user: LoggedUser; accessToken: string }) => {
      localStorage.accessToken = accessToken;
      return setUser(user);
    },
    [setUser],
  );
};

export const useLogout = (showConfirm = true) => {
  const { setUser } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  return useCallback(() => {
    localStorage.removeItem("accessToken");
    setUser(null);
    navigate(getLoginPath());
    if (showConfirm) useToastSuccess("Wylogowano");
  }, [setUser]);
};
