import { useToastSuccess } from "src/common/hooks/useToast";

export const handleDownloadDocument = (doc: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(doc);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  useToastSuccess("Pobrano dokument");
};
