import React, { useEffect, useState } from "react";
import { times } from "lodash";
import moment, { Moment } from "moment";

import { InvoicesListDocument } from "src/api/graphql/types-and-hooks";

import Text from "./Text";
import DocumentsListModal from "./documentsListModal/DocumentsListModal";
import CalendarMonthlyEvent from "./CalendarMonthlyEvent";

type Props = {
  momentDate: Moment;
  documents?: InvoicesListDocument[];
};

type SelectedData = {
  date: string;
  documents: InvoicesListDocument[];
};

const DAYS = ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Ndz"];

const CalendarMonthly: React.FC<Props> = ({ momentDate, documents = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedData, setSelectedData] = useState<SelectedData>();
  const [firstMonthDay, setFirstMonthDay] = useState<number>();

  useEffect(() => {
    setFirstMonthDay(momentDate.clone().startOf("month").weekday());

    const monthDate = momentDate.clone().startOf("month");
    const monthDays = [];
    times(monthDate.daysInMonth(), () => {
      monthDays.push(monthDate.format("DD"));
      monthDate.add(1, "day");
    });
    setDaysInMonth(monthDays);
  }, [momentDate]);

  const renderEvents = (day: number) => {
    const events = documents.filter((d) => moment(d.paymentDue).date() === day);
    return events?.map((e) => {
      return <CalendarMonthlyEvent document={e} key={e._id} />;
    });
  };

  const onDayClick = (day: number) => {
    const events = documents.filter((d) => moment(d.paymentDue).date() === day);
    setSelectedData({ documents: events, date: momentDate.clone().date(day).format("DD.MM.YYYY") });
    setShowModal(true);
  };

  const renderDays = () => {
    return Array.from(Array(42).keys()).map((day, i) => {
      const isToday = moment().date() === i + 1 - firstMonthDay + 1 && momentDate.clone().month() === moment().month();
      if (i + 1 >= firstMonthDay && i + 1 < daysInMonth?.length + firstMonthDay) {
        const dayNumber = daysInMonth[day + 1 - firstMonthDay];

        return (
          <div
            key={day}
            className={`flex flex-col cursor-pointer space-y-1 relative h-24 border-r border-b border-[Black/5%] ${
              isToday && "bg-[#3183FF1A]"
            }`}
            onClick={() => onDayClick(Number(dayNumber))}
          >
            <div className="h-18 max-h-18 overflow-auto">{renderEvents(Number(dayNumber))}</div>
            <Text
              className={`calendar-monthly-hint-2 2xl:text-18 xl:text-16 text-12 absolute left-0 bottom-0 ${
                isToday ? "text-blue" : "text-text-secondary"
              }`}
            >
              {dayNumber}
            </Text>
          </div>
        );
      }
      if (i + 1 <= firstMonthDay) {
        return <div key={day} className="flex h-24 border-r border-b border-[Black/5%]"></div>;
      }
      return undefined;
    });
  };

  return (
    <>
      {showModal && <DocumentsListModal onCancel={() => setShowModal(false)} data={selectedData} />}
      <div className="grid grid-cols-7">
        {DAYS.map((day) => (
          <div key={day} className="flex justify-center border-r border-[Black/5%]">
            <Text>{day}</Text>
          </div>
        ))}
        {renderDays()}
      </div>
    </>
  );
};

export default CalendarMonthly;
