import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";

import { getFinancialDetailsStartupDataPath } from "src/common/router/routerPaths";

type Props = {
  onCancel: () => void;
};

const AccountBalanceInfo: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      show={true}
      onCancel={onCancel}
      onConfirm={() => navigate(getFinancialDetailsStartupDataPath())}
      confirmText="Go"
      cancelTextClass="text-white"
      confirmTextClass="text-white"
    >
      <div className="flex flex-col h-full">
        <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Uwaga")}</Text>
        <Text className="text-text-secondary mt-4">
          {t("Przed dodaniem płatności konieczne jest zaktualizowanie danych finansowych.")}
        </Text>
        <Text className="text-text-secondary">{t("Aby to zrobić, kliknij przycisk poniżej.")}</Text>
      </div>
    </Modal>
  );
};

export default AccountBalanceInfo;
