import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import Table from "src/components/ui/Table";

import { InvoicesListDocument } from "src/api/graphql/types-and-hooks";

import ChangeDocumentsPaymentStatusTableRow from "./ChangeDocumentsPaymentStatusTableRow";
import { useUpdateInvoicesPaymentStatus } from "./invoice.hooks";

type Props = {
  documents: InvoicesListDocument[];
  onCancel: () => void;
};

type DocumentToSave = InvoicesListDocument & { isSplitPayment: boolean; paymentDate: Date };

const HEADERS = [
  "Numer",
  "Kontrahent",
  "Termin płatności",
  "Kwota netto",
  "Kwota brutto",
  "Data płatności",
  "Split payment",
];

const ChangeDocumentsPaymentStatus: React.FC<Props> = ({ documents, onCancel }) => {
  const [documentsToSave, setDocumentsToSave] = useState<DocumentToSave[]>(
    documents.map((d) => ({ ...d, isSplitPayment: false, paymentDate: new Date() })),
  );

  const { t } = useTranslation();
  const { loading, handleUpdateInvoicesPaymentStatus } = useUpdateInvoicesPaymentStatus();

  const onSubmit = async () => {
    const data = documentsToSave.map((d) => {
      return { id: d._id, isSplitPayment: d.isSplitPayment, paymentDate: d.paymentDate };
    });
    if (!data.length) return;

    await handleUpdateInvoicesPaymentStatus(data);
    onCancel();
  };

  const handleSplitPaymentChange = (id: string, checked: boolean) => {
    setDocumentsToSave((prev) => {
      return prev.map((d) => (d._id === id ? { ...d, isSplitPayment: checked } : d));
    });
  };

  const handlePaymentDateChange = (id: string, date: Date) => {
    setDocumentsToSave((prev) => {
      return prev.map((d) => {
        if (d._id === id) {
          return { ...d, paymentDate: date };
        }
        return d;
      });
    });
  };

  const renderRows = () => {
    if (!documentsToSave) return null;
    return documentsToSave.map((d, i) => {
      return (
        <ChangeDocumentsPaymentStatusTableRow
          data={d}
          key={i}
          isSplitPayment={d.isSplitPayment}
          onSplitPaymentChange={handleSplitPaymentChange}
          paymentDate={d.paymentDate}
          onPaymentDateChange={handlePaymentDateChange}
        />
      );
    });
  };

  return (
    <Modal
      show={true}
      onCancel={onCancel}
      onConfirm={() => onSubmit()}
      confirmText="Save"
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
      loading={loading}
    >
      <div className="flex flex-col h-full 2xl:min-w-[800px] xl:min-w-[650px] min-w-[500px]">
        <Text className="2xl:text-36 xl:text-32 text-28 text-white font-sf-pro-medium">
          {t("Payment-status-change")}
        </Text>
        <Text className="2xl:text-16 xl:text-14 text-12 text-text-secondary 2xl:mt-3 xl:mt-2 mt-1">
          {t("Change-payment-status-for-selected-documents")}
        </Text>
        <div className="2xl:mt-6 xl:mt-5 mt-4 flex flex-col space-y-1">
          <Text className="2xl:text-18 xl:text-16 text-14 text-white font-sf-pro-medium">
            {t("Selected-documents")}
          </Text>
          <div className="shrink-0 max-h-[500px] min-h-[250px] overflow-auto">
            <Table headers={HEADERS} rows={renderRows()} dark />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeDocumentsPaymentStatus;
