import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import SubscriptionComponent from "src/components/ui/Subscription";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useSubscriptions } from "./subscription.hooks";

type Props = {
  onChangeStep: () => void;
  onSelectSubscription: (id: string) => void;
  selectedSubscriptionId: string;
};

const SelectSubscription: React.FC<Props> = ({ selectedSubscriptionId, onSelectSubscription, onChangeStep }) => {
  const { t } = useTranslation();
  const { subscriptions } = useSubscriptions({ take: 0, skip: 0 });

  useEffect(() => {
    if (subscriptions?.length) {
      onSelectSubscription(subscriptions[0]._id);
    }
  }, [subscriptions]);

  const renderSubscriptions = () => {
    if (!subscriptions) return null;

    return (
      <div className="flex flex-col space-y-12 items-center w-full">
        <div className="flex flex-col space-y-2 items-center">
          <Text className="text-white 2xl:text-32 xl:text-30 text-28">Wybór pakietów</Text>
          <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">
            Wybierz pakiet odpowiadający Twoim potrzebom i przejdź do płatności.
          </Text>
        </div>
        <div className="flex flex-row space-x-8 items-center w-full">
          {subscriptions.map((subscription) => (
            <SubscriptionComponent
              data={subscription}
              key={subscription._id}
              selectedSubscriptionId={selectedSubscriptionId}
              onSelectSubscription={onSelectSubscription}
            />
          ))}
        </div>
        <div className="max-w-[30%] w-full">
          <AppButton
            title={t("Go-to-payment")}
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px]"
            textClass="text-white 2xl:text-18 xl:text-16 text-14 font-sf-pro-medium"
            onClick={onChangeStep}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center 2xl:max-w-[900px] xl:max-w-[700px] max-w-[550px] w-full">
      {renderSubscriptions()}
    </div>
  );
};

export default SelectSubscription;
