import { boolean, number, object, date } from "yup";

export default object({
  accountBalance: number().required(),
  isVatPayer: boolean().required(),
  vatAccountBalance: number().when("isVatPayer", (isVatPayer: any, schema: any) => {
    return isVatPayer[0] ? schema.required() : schema.nullable();
  }),
  startAccountValuesDate: date().required(),
}).required();
