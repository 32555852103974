import { date, mixed, object, string } from "yup";

export default object({
  invoiceNumber: string().nullable(),
  contractor: string().nullable(),
  companyId: string().nullable(),
  description: string().nullable(),
  paymentDueFrom: date().nullable(),
  paymentDueTo: date().nullable(),
  paymentStatus: mixed().nullable(),
}).required();
