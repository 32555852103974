import { CompanyIntegrationTypes } from "src/api/graphql/types-and-hooks";
import { mixed, object, string } from "yup";

export default object({
  type: mixed<CompanyIntegrationTypes>().oneOf(Object.values(CompanyIntegrationTypes)).required(),
  apiKey: string().required(),
  secretKey: string().when("type", ([type], schema) => {
    return type === CompanyIntegrationTypes.Wfirma ? schema.required() : schema.nullable();
  }),
}).required();
