import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Toaster } from "react-hot-toast";

import Root from "./Root";

// providers
import { client } from "./api/apollo/client";

import { AuthenticationProvider } from "./common/AuthProvider/AuthProvider";

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <div className="h-[100dvh] sm:h-screen max-h-screen w-screen max-w-screen overflow-hidden bg-bg-light-gray">
          <Root />
        </div>
        <Toaster />
      </AuthenticationProvider>
    </ApolloProvider>
  );
}

export default App;
