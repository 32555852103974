import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AuthWrapper from "src/components/ui/AuthWrapper";

import { getRegisterPath } from "src/common/router/routerPaths";

import EyeIcon from "src/assets/images/eye.svg";

import resetPasswordSchema from "./resetPassword.schema";
import { useResetPassword } from "./resetPassword.hooks";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { loading, handleResetPassword } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: searchParams.get("code") || null,
    },
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: "onChange",
  });

  return (
    <AuthWrapper>
      <div className="mt-12 flex flex-col items-center space-y-4 w-full">
        <Text className="2xl:text-28 xl:text-24 text-20 text-white font-sf-pro-medium">Witaj</Text>
        <Text className="2xl:text-20 xl:text-18 text-14 text-text-secondary">Wpisz nowe hasło dla swojego konta</Text>
        <div className="flex flex-col space-y-4 max-w-[75%] sm:max-w-[50%] w-full">
          <AppInput
            iconEnd={EyeIcon}
            id="password"
            inputType={InputTypes.PASSWORD}
            placeholder="Hasło"
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !errors?.password && "border-white/20 focus:border-white/60"
            }`}
            error={!!errors?.password}
            errorMessage={errors?.password?.message}
          />
          <AppInput
            iconEnd={EyeIcon}
            id="passwordConfirmation"
            inputType={InputTypes.PASSWORD}
            placeholder="Powtórz hasło"
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !errors?.passwordConfirmation && "border-white/20 focus:border-white/60"
            }`}
            error={!!errors?.passwordConfirmation}
            errorMessage={errors?.passwordConfirmation?.message}
          />
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
            textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
            onClick={handleSubmit(handleResetPassword)}
            title="Resetuj hasło"
            isLoading={loading}
          />
        </div>
        <Text
          className="text-text-secondary cursor-pointer text-12 2xl:text-16 xl:text-14 sm:text-10"
          onClick={() => navigate(getRegisterPath())}
        >
          Nie masz konta? &nbsp;
          <Text className="font-sf-pro-medium text-white text-12 2xl:text-16 xl:text-14 sm:text-10">
            Zarejestruj się
          </Text>
        </Text>
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
