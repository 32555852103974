import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isEmpty } from "lodash";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AppDatePicker from "src/components/form/AppDatePicker";
import TaxDetailsInfo from "src/components/ui/TaxDetailsInfo";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { formatNumber } from "src/utils/currency";

import startupDataSchema from "./startupData.schema";
import FinancialDetailsWrapper from "./FinancialDetailsWrapper";
import { useCompanyAccountBalance, useCompanyTaxDetails, useEditCompany } from "./financialDetails.hooks";

const StartupData = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTaxDetailsInfoModal, setShowTaxDetailsInfoModal] = useState(false);
  const currentUser = useCurrentUser();
  const companyId = currentUser?.company?._id;

  const { t } = useTranslation();
  const { loading, companyAccountBalance, refetch } = useCompanyAccountBalance(companyId);
  const taxDetailsData = useCompanyTaxDetails(companyId);
  const { isUpdated, isUpdating, handleEditCompany } = useEditCompany();

  const {
    control,
    register,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
  } = useForm({
    values: {
      isVatPayer: companyAccountBalance?.taxDetails?.isVatPayer,
      accountBalance: companyAccountBalance?.startAccountBalance,
      vatAccountBalance: companyAccountBalance?.startVatAccountBalance,
      startAccountValuesDate: companyAccountBalance?.startAccountValuesDate,
    },
    resolver: yupResolver(startupDataSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isUpdated) {
      setShowConfirm(false);
      refetch();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (!isEmpty(taxDetailsData?.taxDetails)) {
      const { taxDetails } = taxDetailsData;
      if (!taxDetails.taxType) {
        setShowTaxDetailsInfoModal(true);
      }
    }
  }, [taxDetailsData]);

  const onConfirm = () => {
    const { accountBalance, vatAccountBalance, startAccountValuesDate } = getValues();
    const startAccountBalance = formatNumber(accountBalance);
    const startVatAccountBalance = formatNumber(vatAccountBalance);

    handleEditCompany({
      id: companyId,
      startAccountBalance,
      startVatAccountBalance,
      accountBalance: startAccountBalance,
      vatAccountBalance: startVatAccountBalance,
      startAccountValuesDate: moment(startAccountValuesDate).endOf("day").toISOString(),
    });
  };

  return (
    <>
      {showTaxDetailsInfoModal && <TaxDetailsInfo />}
      <Confirm
        show={showConfirm}
        title={t("Confirm-update-title")}
        onCancel={() => setShowConfirm(false)}
        onConfirm={onConfirm}
        loading={isUpdating}
        info={t("Startup-data-warning")}
      />
      <FinancialDetailsWrapper>
        <div className="flex flex-col space-y-5">
          <div className="flex justify-end">
            <div className="px-2">
              <AppButton
                title={t("Save")}
                onClick={handleSubmit(() => setShowConfirm(true))}
                color={ButtonColorTypes.PRIMARY}
                customClass="rounded-[8px] font-sf-pro-medium"
                textClass="text-white font-sf-pro-medium"
                disabled={!isDirty}
              />
            </div>
          </div>
          <div className="bg-white rounded-[20px] p-5">
            <div className="flex flex-col space-y-4">
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Startup-data")}</Text>
              <Text className="text-state-error">{t("Startup-data-warning")}</Text>
              {loading || taxDetailsData.loading ? (
                <Loader />
              ) : (
                <div className="flex flex-row space-x-6">
                  <div className="flex flex-col space-y-2 basis-1/2">
                    <Label>{t("Account-balance")}</Label>
                    <div className="basis-1/2">
                      <AppInput
                        id="accountBalance"
                        inputType={InputTypes.NUMBER}
                        placeholder={t("Enter-value-placeholder")}
                        register={register}
                        error={!!errors?.accountBalance}
                        disabled={!!companyAccountBalance.startAccountValuesDate || isUpdated}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2 basis-1/2">
                    <Label>{t("Vat-account-balance")}</Label>
                    <div className="basis-1/2">
                      <AppInput
                        id="vatAccountBalance"
                        inputType={InputTypes.NUMBER}
                        placeholder={t("Enter-value-placeholder")}
                        register={register}
                        error={!!errors?.vatAccountBalance}
                        disabled={!!companyAccountBalance.startAccountValuesDate || isUpdated}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2 basis-1/2">
                    <div className="flex flex-col space-y-2 basis-1/2">
                      <Label>{t("Start-account-balance-date")}</Label>
                      <div className="basis-1/2">
                        <Controller
                          control={control}
                          name="startAccountValuesDate"
                          render={({ field }) => (
                            <AppDatePicker
                              field={field}
                              placeholder={t("Start-account-balance-date")}
                              disabled={!!companyAccountBalance.startAccountValuesDate || isUpdated}
                              error={!!errors?.startAccountValuesDate}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </FinancialDetailsWrapper>
    </>
  );
};

export default StartupData;
