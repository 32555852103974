import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { CartesianGrid, XAxis, YAxis, Tooltip, Line, LineChart, ResponsiveContainer, ReferenceLine } from "recharts";

import Text from "src/components/ui/Text";
import DocumentsListModal from "src/components/ui/documentsListModal/DocumentsListModal";

import { formatDate } from "src/utils/time";
import { formatPrice } from "src/utils/currency";

import { FinancialMinimum, FinancialMinimumTypes, InvoicesListDocument } from "src/api/graphql/types-and-hooks";

type SelectedData = {
  date: string;
  documents: InvoicesListDocument[];
};

type Props = {
  data?: any[];
  endDate?: Moment;
  minimum?: FinancialMinimum[];
  documents: InvoicesListDocument[];
  startDate?: Moment;
  startValue?: number;
};

const Chart: React.FC<Props> = ({ data = [], startValue, minimum = [], startDate, endDate, documents }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [parsedData, setParsedData] = useState<any[]>();
  const [selectedData, setSelectedData] = useState<SelectedData>();
  const [financialMinimumValues, setFinancialMinimumValues] = useState<number[]>();

  const { t } = useTranslation();

  useEffect(() => {
    if (minimum.length) {
      const financialMinimumValues = minimum.filter((item) => {
        const period1 = { start: moment(item.dateFrom), end: moment(item.dateTo) };
        const period2 = { start: startDate, end: endDate };
        const hasCommonDates =
          period1.start.isBetween(period2.start, period2.end, undefined, "[]") ||
          period1.end.isBetween(period2.start, period2.end, undefined, "[]") ||
          period2.start.isBetween(period1.start, period1.end, undefined, "[]") ||
          period2.end.isBetween(period1.start, period1.end, undefined, "[]");

        return hasCommonDates || item.type === FinancialMinimumTypes.Constant;
      });
      setFinancialMinimumValues(financialMinimumValues.map((item) => item.minimum));
    } else {
      setFinancialMinimumValues([]);
    }
  }, [parsedData, minimum]);

  useEffect(() => {
    if (data.length > 0) {
      const today = moment();
      let isBefore = false;
      const newData = data.map((item) => {
        const { liquidity, ...rest } = item;
        if (moment(item.date).isSame(today, "day")) {
          isBefore = true;
          return { ...rest, prognosisLiquidity: liquidity, liquidity };
        }
        if (moment(item.date).isBefore(today)) {
          isBefore = true;
          return { ...rest, liquidity };
        }
        return { ...rest, prognosisLiquidity: liquidity };
      });

      if (isBefore) {
        setParsedData([{ liquidity: startValue }, ...newData]);
      } else {
        setParsedData([{ prognosisLiquidity: startValue }, ...newData]);
      }
    }
  }, [data]);

  const CustomTooltip = (props: any) => {
    const payload = props.payload[0]?.payload;
    const active = props.active;

    if (active && payload) {
      return (
        <div className="bg-white shadow-lg rounded-[12px] p-4 flex flex-row space-x-2 items-center">
          <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{`${t("Available-funds")}:`}</Text>
          <Text className="font-sf-pro-bold">{formatPrice(payload.liquidity || payload.prognosisLiquidity, "zł")}</Text>
        </div>
      );
    }

    return null;
  };

  const checkFinancialMinimum = (dateFrom: string, dateTo: string, isConstant: boolean, value: number) => {
    if (parsedData) {
      if (isConstant) {
        for (const item of parsedData) {
          if (item.liquidity < value || item.prognosisLiquidity < value) {
            return true;
          }
        }
      } else {
        const startDate = moment(dateFrom);
        const endDate = moment(dateTo);

        for (const item of parsedData) {
          const itemDate = moment(item.date);
          if (
            itemDate.isBetween(startDate, endDate, undefined, "[]") &&
            (item.liquidity < value || item.prognosisLiquidity < value)
          ) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  };

  const ReferenceLabel = (props: any) => {
    const { value, fill, textAnchor, viewBox, dy, dx } = props;
    const x = 54;
    const y = viewBox.y + 5;
    return (
      <text x={x} y={y} dy={dy} dx={dx} fill={fill} textAnchor={textAnchor} className="2xl:text-14 xl:text-12 text-10">
        {value}
      </text>
    );
  };

  const onDotClick = (date: string) => {
    const documentsForDate = documents.filter((d) => {
      if (d.paymentDate) {
        return moment(d.paymentDate).isSame(moment(date), "day");
      }
      return moment(d.paymentDue).isSame(moment(date), "day");
    });
    setSelectedData({ date, documents: documentsForDate });
    setShowModal(true);
  };

  const renderFinancialMinimum = () => {
    return minimum.map((item, index) => {
      if (item.type === FinancialMinimumTypes.Constant) {
        const isExceeded = checkFinancialMinimum(null, null, true, item.minimum);
        return (
          <ReferenceLine
            key={index}
            y={item.minimum}
            stroke="#A7A7A7"
            strokeWidth={1}
            label={<ReferenceLabel value={item.minimum} fill={isExceeded ? "red" : "green"} />}
            strokeDasharray="4 4"
            isFront
          />
        );
      } else {
        const period1 = { start: moment(item.dateFrom), end: moment(item.dateTo) };
        const period2 = { start: startDate, end: endDate };
        const hasCommonDates =
          period1.start.isBetween(period2.start, period2.end, undefined, "[]") ||
          period1.end.isBetween(period2.start, period2.end, undefined, "[]") ||
          period2.start.isBetween(period1.start, period1.end, undefined, "[]") ||
          period2.end.isBetween(period1.start, period1.end, undefined, "[]");

        if (hasCommonDates) {
          const isExceeded = checkFinancialMinimum(item.dateFrom, item.dateTo, false, item.minimum);
          const commonPeriod = {
            start: moment.max(period1.start, period2.start).format("YYYY-MM-DD"),
            end: moment.min(period1.end, period2.end).format("YYYY-MM-DD"),
          };
          return (
            <ReferenceLine
              segment={[
                { x: commonPeriod.start, y: item.minimum },
                { x: commonPeriod.end, y: item.minimum },
              ]}
              key={index}
              stroke="#A7A7A7"
              strokeWidth={1}
              label={<ReferenceLabel value={item.minimum} fill={isExceeded ? "red" : "green"} />}
              strokeDasharray="4 4"
            />
          );
        }
      }

      return null;
    });
  };

  return (
    <>
      {showModal && <DocumentsListModal onCancel={() => setShowModal(false)} data={selectedData} />}
      <ResponsiveContainer>
        <LineChart margin={{ top: 25, right: 30, left: 40, bottom: 5 }} data={parsedData}>
          <CartesianGrid strokeDasharray="1 1" stroke="#DFE5F1" />
          <XAxis
            dataKey="date"
            axisLine={false}
            strokeWidth={0}
            tick={{ fill: "#8E98A7" }}
            tickFormatter={(value) => value && formatDate(value, "DD")}
            className="2xl:text-14 xl:text-12 text-10"
          />
          <YAxis
            tickMargin={financialMinimumValues?.length ? 45 : 15}
            tick={{ fill: "#8E98A7" }}
            axisLine={false}
            strokeWidth={0}
            domain={([dataMin, dataMax]) => {
              const min = Math.min(dataMin, ...financialMinimumValues);
              const max = Math.max(dataMax, ...financialMinimumValues) + 1000;
              return [min > 0 ? 0 : -1 * Math.round(Math.abs(min)), Math.round(max / 500) * 500];
            }}
            className="2xl:text-14 xl:text-12 text-10"
          />
          <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
          <ReferenceLine
            x={moment().format("YYYY-MM-DD")}
            stroke="#3183FF"
            strokeWidth={1}
            label={{
              position: "top",
              value: "Prognoza",
              fill: "#3183FF",
            }}
            className="2xl:text-14 xl:text-12 text-10"
          />
          <Line
            type="monotone"
            dataKey="liquidity"
            stroke="#3183FF"
            strokeWidth="3"
            dot={{ stroke: undefined, fill: "#3183FF", r: 6 }}
            activeDot={{
              cursor: "pointer",
              stroke: "#3183FF",
              fill: "#3183FF",
              r: 8,
              onClick: (e, data: any) => onDotClick(data.payload.date),
            }}
          />
          <Line
            type="monotone"
            dataKey="prognosisLiquidity"
            stroke="#3183FF"
            strokeWidth="3"
            dot={{ stroke: undefined, fill: "#3183FF", r: 6 }}
            activeDot={{
              cursor: "pointer",
              stroke: "#3183FF",
              fill: "#3183FF",
              r: 8,
              onClick: (e, data: any) => onDotClick(data.payload.date),
            }}
            strokeDasharray="4 4"
          />
          {minimum.length && renderFinancialMinimum()}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;
