import React, { ReactNode } from "react";
import Text from "./Text";

type Props = {
  children: ReactNode;
  filters?: ReactNode;
  title: string;
};

const ListContainer: React.FC<Props> = ({ children, filters, title }) => {
  return (
    <div className="mt-4 flex flex-row h-full xl:space-x-6 space-x-4">
      {filters ? <div className="basis-2/8">{filters}</div> : undefined}
      <div className={`${filters ? "basis-6/8" : "basis-7/7 w-full"}`}>
        <div className="h-full bg-white rounded-[20px] xl:pl-6 pl-3 xl:pt-6 pt-3">
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{title}</Text>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ListContainer;
