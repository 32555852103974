import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CartesianGrid, XAxis, YAxis, Line, LineChart, ResponsiveContainer, ReferenceLine } from "recharts";

import Loader from "src/components/ui/Loader";

import { getLiquidityAnalysisPath } from "src/common/router/routerPaths";

import { useMonthlyLiquidities, useMonthlyLiquiditiesChartData } from "./cockpit.hooks";

const LiquidityChart = () => {
  const [data, setData] = React.useState([]);
  const currentMonth = moment().format("MMM YYYY");

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { liquidityLoading, monthlyLiquidities } = useMonthlyLiquidities({
    filter: {
      date: {
        gte: moment().startOf("month").subtract(3, "months").startOf("month").unix(),
        lte: moment().startOf("month").add(3, "months").endOf("month").unix(),
      },
    },
  });

  useEffect(() => {
    if (monthlyLiquidities?.length) {
      const data = useMonthlyLiquiditiesChartData(monthlyLiquidities);
      setData(data);
    }
  }, [monthlyLiquidities]);

  if (liquidityLoading) return <Loader />;

  return (
    <ResponsiveContainer>
      <LineChart
        style={{ cursor: "pointer" }}
        margin={{ top: 15 }}
        data={data}
        onClick={() => navigate(getLiquidityAnalysisPath())}
      >
        <CartesianGrid
          strokeDasharray="1 1"
          stroke="#DFE5F1"
          verticalCoordinatesGenerator={({ width }) => {
            const segment = width / 4;
            return [segment, segment * 2, segment * 3, segment * 4];
          }}
          horizontalCoordinatesGenerator={({ height }) => {
            const segment = height / 4;
            return [segment, segment * 2, segment * 3, height];
          }}
        />
        <XAxis
          dataKey="month"
          axisLine={false}
          strokeWidth={0}
          tick={{ fill: "#8E98A7" }}
          tickFormatter={(val) => `${t(moment(val, "MMM YYYY").format("MMM"))}`}
          className="2xl:text-14 xl:text-12 text-10"
        />
        <YAxis tick={{ fill: "#8E98A7" }} axisLine={false} strokeWidth={0} className="2xl:text-14 xl:text-12 text-10" />
        <ReferenceLine
          x={currentMonth}
          stroke="#3183FF"
          strokeWidth={1}
          label={{
            position: "top",
            value: "Prognoza",
            fill: "#3183FF",
          }}
          className="2xl:text-14 xl:text-12 text-10"
        />
        <Line
          type="monotone"
          dataKey="liquidity"
          strokeWidth="3"
          stroke="#3183FF"
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="prognosisLiquidity"
          strokeWidth="3"
          strokeDasharray="7"
          stroke="#3183FF"
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LiquidityChart;
