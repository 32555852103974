import React from "react";
import { customTwMerge } from "src/utils/twMerge";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ModuleWrapper: React.FC<Props> = ({ children, className = "" }) => {
  const combinedClasses = customTwMerge("flex flex-col h-screen xl:mx-6 mx-4 xl:mt-6 mt-4 mb-2", className);
  return <div className={combinedClasses}>{children}</div>;
};

export default ModuleWrapper;
