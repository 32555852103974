import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import AuthWrapper from "src/components/ui/AuthWrapper";

import { useCurrentUser, useLogout } from "src/common/AuthProvider/authProvider.hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import { OrderStatus } from "src/api/graphql/types-and-hooks";

import SelectSubscription from "./SelectSubscription";
import { useCreateOrder, useEditCompany, useOrderStatus } from "./subscription.hooks";

declare global {
  interface Window {
    onTokenizeCard: (token: string) => void;
    onGoBack: () => void;
  }
}

const Subscriptions = () => {
  const [searchParams] = useSearchParams();
  const statusCode = searchParams.get("statusCode");
  const orderId = searchParams.get("orderId");
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);

  const [step, setStep] = useState(1);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>();
  const [loading, setLoading] = useState(statusCode ? true : false);

  const logout = useLogout(false);
  const currentUser = useCurrentUser();
  const { createOrder, isCreatingOrder, order } = useCreateOrder();
  const { editCompany } = useEditCompany();
  const { getOrderStatus, status } = useOrderStatus();

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (statusCode === "SUCCESS") {
      if (intervalRef.current) clearInterval(intervalRef.current);
      const interval = setInterval(() => getOrderStatus(orderId), 2000);
      intervalRef.current = interval;
    }
  }, [statusCode]);

  useEffect(() => {
    if (order?.id) {
      if (order.redirectUri) {
        window.location.href = order.redirectUri;
      } else {
        setLoading(true);
        if (intervalRef.current) clearInterval(intervalRef.current);
        const interval = setInterval(() => getOrderStatus(order.id), 2000);
        intervalRef.current = interval;
      }
    }
  }, [order]);

  useEffect(() => {
    if (status === OrderStatus.Completed || status === OrderStatus.Cancelled) {
      if (intervalRef) clearInterval(intervalRef.current);
      setLoading(false);

      if (status === OrderStatus.Completed) {
        useToastSuccess("Płatność zakończona pomyślnie");
        logout();
      }
      if (status === OrderStatus.Cancelled) {
        useToastError("Wystąpił błąd");
        editCompany({ id: currentUser?.company._id, paymentMethod: null });
      }
    }
  }, [status]);

  window.onTokenizeCard = (token: string) => {
    if (selectedSubscriptionId) {
      createOrder({
        subscriptionId: selectedSubscriptionId,
        token,
        companyEmail: currentUser?.company?.email || localStorage.getItem("companyEmail"),
      });
    }
  };

  window.onGoBack = () => {
    setStep(1);
  };

  return (
    <AuthWrapper>
      <div className="flex flex-col 2xl:mt-8 xl:mt-6 mt-4 space-y-6 items-center w-full">
        {isCreatingOrder || loading ? (
          <Loader />
        ) : step === 1 ? (
          <SelectSubscription
            selectedSubscriptionId={selectedSubscriptionId}
            onSelectSubscription={setSelectedSubscriptionId}
            onChangeStep={() => setStep(2)}
          />
        ) : (
          <div className="flex flex-col space-y-8 items-center w-full">
            <div className="flex flex-col space-y-2 items-center">
              <Text className="text-white 2xl:text-32 xl:text-30 text-28">Metoda płatności</Text>
              <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">
                Skonfiguruj metodę płatności i opłać wybrany pakiet
              </Text>
            </div>
            <div className="mt-20 w-full">
              <iframe src="/payment-card-form.html" className="w-full min-h-[300px]" id="iframeId"></iframe>
            </div>
          </div>
        )}
      </div>
    </AuthWrapper>
  );
};

export default Subscriptions;
