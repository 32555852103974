import { date, mixed, number, object, string } from "yup";

import {
  InvoiceGenres,
  InvoiceSubtypes,
  InvoiceTypes,
  PaymentStatus,
  TaxSettlementPeriodType,
} from "src/api/graphql/types-and-hooks";

export default object({
  invoiceNumber: string().required(),
  type: mixed<InvoiceTypes>().oneOf(Object.values(InvoiceTypes)),
  genre: mixed<InvoiceGenres>().oneOf(Object.values(InvoiceGenres)),
  subtype: mixed<InvoiceSubtypes>().oneOf(Object.values(InvoiceSubtypes)).nullable(),
  paymentStatus: mixed<PaymentStatus>().oneOf(Object.values(PaymentStatus)).required(),
  paymentDate: date().when("paymentStatus", ([paymentStatus], schema) => {
    return paymentStatus === PaymentStatus.Paid ? schema.required() : schema.nullable();
  }),
  total: number().nullable(),
  paymentDue: date().required(),
  settlementPeriodType: mixed<TaxSettlementPeriodType>().oneOf(Object.values(TaxSettlementPeriodType)).required(),
  settlementPeriod: date().required(),
}).required();
