import moment from "moment";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import AppSelect from "src/components/form/AppSelect";
import AppDatePicker from "src/components/form/AppDatePicker";
import FiltersWrapper from "src/components/ui/FiltersWrapper";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { PaymentStatus } from "src/api/graphql/types-and-hooks";

import invoiceFiltersSchema from "./invoiceFilters.schema";

type Props = {
  defaultFilters?: any;
  onChange: (data: any) => void;
};

const InvoiceFilters: React.FC<Props> = ({ onChange, defaultFilters }) => {
  const { t } = useTranslation();
  const paymentStatusOptions = (Object.keys(PaymentStatus) as Array<keyof typeof PaymentStatus>).map((key) => {
    let label = "";
    switch (key) {
      case "Paid":
        label = t("Paid");
        break;
      case "Unpaid":
        label = t("Unpaid");
        break;
    }
    return {
      value: PaymentStatus[key],
      label,
    };
  });

  const { control, register, handleSubmit, reset } = useForm({
    values: defaultFilters,
    resolver: yupResolver(invoiceFiltersSchema),
    reValidateMode: "onSubmit",
  });

  const parseData = (data: any) => {
    const { invoiceNumber, contractor, companyId, description, paymentDueFrom, paymentDueTo, paymentStatus } = data;
    const filters = {};

    if (invoiceNumber) {
      filters["invoiceNumber"] = { regex: invoiceNumber };
    }

    if (contractor) {
      filters["billTo"] = { name: { regex: contractor } };
    }

    if (companyId) {
      filters["billTo"] = { companyId: { regex: companyId } };
    }

    if (description) {
      filters["description"] = { regex: description };
    }

    const paymentDue = {};
    if (paymentDueFrom || paymentDueTo) {
      if (paymentDueFrom) paymentDue["gte"] = moment(paymentDueFrom).unix();
      if (paymentDueTo) paymentDue["lte"] = moment(paymentDueTo).unix();

      filters["paymentDue"] = paymentDue;
    }

    if (paymentStatus) {
      filters["paymentStatus"] = { eq: paymentStatus };
    }

    onChange(filters);
  };

  const resetForm = () => {
    reset({
      invoiceNumber: null,
      contractor: null,
      companyId: null,
      description: null,
      paymentDueFrom: null,
      paymentDueTo: null,
      paymentStatus: null,
    });

    onChange({});
  };

  return (
    <FiltersWrapper>
      <div className="xl:mt-4 mt-2 xl:mr-5 mr-3">
        <div className="flex flex-col 2xl:space-y-4 space-y-2 border-b 2xl:pb-5 pb-3">
          <AppInput
            id="invoiceNumber"
            inputType={InputTypes.TEXT}
            placeholder={`${t("Number")}/${t("Name")}`}
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <AppInput
            id="contractor"
            inputType={InputTypes.TEXT}
            placeholder={t("Contractor")}
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <AppInput
            id="companyId"
            inputType={InputTypes.TEXT}
            placeholder="NIP"
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <AppInput
            id="description"
            inputType={InputTypes.TEXT}
            placeholder="Opis"
            register={register}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
          />
          <div className="flex flex-col space-y-2">
            <Text>Termin płatności</Text>
            <div className="flex 2xl:flex-row 2xl:space-x-3 flex-col">
              <div className="w-full">
                <Controller
                  control={control}
                  name="paymentDueFrom"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data od" />}
                />
              </div>
              <div className="w-full 2xl:mt-0 mt-2">
                <Controller
                  control={control}
                  name="paymentDueTo"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data do" />}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <Text>Status płatności</Text>
            <div className="flex flex-row space-x-3">
              <div className="w-full">
                <Controller
                  control={control}
                  name="paymentStatus"
                  render={({ field }) => (
                    <AppSelect options={paymentStatusOptions} field={field} placeholder="Wybierz..." />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:mt-5 mt-3">
          <AppButton
            customClass="rounded-[10px]"
            title="Filtruj"
            onClick={handleSubmit(parseData)}
            color={ButtonColorTypes.PRIMARY}
            textClass="text-white font-sf-pro-medium"
          />
        </div>
        <div className="mt-3 flex justify-center cursor-pointer" onClick={resetForm}>
          <Text className="text-text-secondary">Wyczyść</Text>
        </div>
      </div>
    </FiltersWrapper>
  );
};

export default InvoiceFilters;
