import React from "react";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "./AppButton";

type Props = {
  customClass?: string;
  onClick: (file: File) => void;
  loading?: boolean;
  textClass?: string;
  fileType?: string;
};

const AppUploadInput: React.FC<Props> = ({
  customClass,
  onClick,
  loading,
  textClass,
  fileType = "application/pdf,image/*",
}) => {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        accept={fileType}
        type="file"
        required
        className="hidden"
        onChange={({
          target: {
            validity,
            files: [file],
          },
        }) => {
          if (validity.valid) {
            onClick(file);
          }
        }}
      />
      <AppButton
        title={t("Send-file")}
        onClick={() => inputRef.current?.click()}
        color={ButtonColorTypes.PRIMARY}
        customClass={customClass}
        isLoading={loading}
        textClass={textClass}
      />
    </>
  );
};
export default AppUploadInput;
