import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import Loader from "src/components/ui/Loader";
import AuthWrapper from "src/components/ui/AuthWrapper";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";
import { getChangePaymentMethodPath, getSettingsOrdersPath } from "src/common/router/routerPaths";

import { OrderStatus } from "src/api/graphql/types-and-hooks";

import VisaIcon from "src/assets/images/visa.png";
import MasterCardIcon from "src/assets/images/mastercard.png";

import SelectPackage from "./SelectPackage";
import { useCompanyPaymentMethod, useCreatePackageOrder, useOrderStatus } from "./packageBuy.hooks";

declare global {
  interface Window {
    onTokenizeCard: (token: string) => void;
    onGoBack: () => void;
  }
}

enum CardTypes {
  VISA = "visa",
  MASTERCARD = "mastercard",
}

const Packages = () => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const statusCode = searchParams.get("statusCode");
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);

  const [step, setStep] = useState(1);
  const [selectedPackageId, setSelectedPackageId] = useState<string>();
  const [loading, setLoading] = useState(statusCode ? true : false);

  const currentUser = useCurrentUser();
  const companyId = currentUser?.company?._id;

  const { paymentMethod, paymentMethodLoading } = useCompanyPaymentMethod(companyId);
  const { createPackageOrder, isCreatingOrder, order } = useCreatePackageOrder();
  const { getOrderStatus, status } = useOrderStatus();

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (!paymentMethod && !paymentMethodLoading) {
      setShowModal(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (statusCode === "SUCCESS") {
      if (intervalRef.current) clearInterval(intervalRef.current);
      const interval = setInterval(() => getOrderStatus(orderId), 2000);
      intervalRef.current = interval;
    }
  }, [statusCode]);

  useEffect(() => {
    if (order?.id) {
      if (order.redirectUri) {
        window.location.href = order.redirectUri;
      } else {
        setLoading(true);
        if (intervalRef.current) clearInterval(intervalRef.current);
        const interval = setInterval(() => getOrderStatus(order.id), 2000);
        intervalRef.current = interval;
      }
    }
  }, [order]);

  useEffect(() => {
    if (status === OrderStatus.Completed || status === OrderStatus.Cancelled) {
      if (intervalRef) clearInterval(intervalRef.current);
      setLoading(false);

      if (status === OrderStatus.Completed) {
        useToastSuccess("Płatność zakończona pomyślnie");
        navigate(getSettingsOrdersPath());
      }
      if (status === OrderStatus.Cancelled) {
        useToastError("Wystąpił błąd");
      }
    }
  }, [status]);

  const Card = () => {
    const cardType = paymentMethod.maskedCardNumber.startsWith("4") ? CardTypes.VISA : CardTypes.MASTERCARD;

    return (
      <div className="flex min-w-[400px] rounded-[20px] bg-white shadow-md items-center px-5 border-4 border-brand-secondary">
        <div className="flex basis-1/5">
          <img src={cardType === CardTypes.VISA ? VisaIcon : MasterCardIcon} className="w-14" />
        </div>
        <div className="flex flex-row basis-3/5">
          <div className="flex flex-col justify-between">
            <Text className="text-14 font-sf-pro-bold">*** *** *** {paymentMethod.maskedCardNumber.slice(-4)}</Text>
            <Text className="text-14 font-sf-pro-bold">{cardType === CardTypes.VISA ? "Visa" : "Mastercard"}</Text>
          </div>
        </div>
      </div>
    );
  };

  const handleBuyPackage = async () => {
    if (!selectedPackageId) return;
    createPackageOrder({ packageId: selectedPackageId });

    if (intervalRef.current) clearInterval(intervalRef.current);
    const interval = setInterval(() => getOrderStatus(orderId), 2000);
    intervalRef.current = interval;
  };

  const renderModal = () => {
    return (
      <Modal
        show={true}
        onCancel={() => navigate(getSettingsOrdersPath())}
        onConfirm={() => navigate(getChangePaymentMethodPath())}
        confirmText={t("Add-payment-method")}
        confirmTextClass="text-white"
        cancelTextClass="text-text-secondary"
        loading={loading}
      >
        <div className="flex flex-col h-full 2xl:min-w-[600px] xl:min-w-[450px] min-w-[300px]">
          <Text className="2xl:text-36 xl:text-32 text-28 text-white font-sf-pro-medium">{t("No-payment-method")}</Text>
          <Text className="2xl:text-16 xl:text-14 text-12 text-text-secondary 2xl:mt-3 xl:mt-2 mt-1">
            {t("Buy-package-payment-info")}
          </Text>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {showModal ? renderModal() : undefined}
      <AuthWrapper>
        <div className="flex flex-col 2xl:mt-8 xl:mt-6 mt-4 space-y-6 items-center w-full">
          {isCreatingOrder || loading || paymentMethodLoading ? (
            <Loader />
          ) : step === 1 ? (
            <SelectPackage
              selectedPackageId={selectedPackageId}
              onSelectPackage={setSelectedPackageId}
              onChangeStep={() => setStep(2)}
            />
          ) : (
            <div className="flex flex-col space-y-8 items-center w-full">
              <div className="flex flex-col space-y-6 items-center">
                <Text className="text-white 2xl:text-32 xl:text-30 text-28">Metoda płatności</Text>
                <Card />
                <AppButton
                  title={t("Buy-package")}
                  color={ButtonColorTypes.PRIMARY}
                  onClick={handleBuyPackage}
                  customClass="rounded-[12px]"
                  isLoading={loading}
                  textClass="text-white font-sf-pro-medium"
                  disabled={!selectedPackageId}
                />
              </div>
            </div>
          )}
        </div>
      </AuthWrapper>
    </>
  );
};

export default Packages;
