import React, { useRef } from "react";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useToastError } from "src/common/hooks/useToast";
import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import DeleteIcon from "src/assets/images/delete.svg";
import AddBlueIcon from "src/assets/images/add-blue.svg";

import { useCreateBudgetCategory } from "../budget.hooks";

type Props = {
  type: BudgetCategoryTypes;
  parent?: string;
  onCancel: () => void;
  onAddCategory: () => void;
};

const AddNewSubcategory: React.FC<Props> = ({ type, onAddCategory, parent = null, onCancel }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { budgetId } = useParams();
  const { t } = useTranslation();
  const { handleCreateBudgetCategory, loading } = useCreateBudgetCategory();

  const onAddNewCategory = async () => {
    if (isEmpty(inputRef.current?.value)) {
      useToastError(t("Enter-group-name"));
    } else {
      await handleCreateBudgetCategory({ name: inputRef.current?.value, budget: budgetId, type, parent });
      onAddCategory();
    }
  };

  return (
    <div className="flex flex-row items-center justify-center cursor-pointer space-x-1 mr-2">
      <input
        ref={inputRef}
        type="text"
        className="2xl:text-16 xl:text-14 text-10 border pl-2 border-[#DFE5F1] 2xl:h-8 xl:h-7 h-6 focus:border-[#233145] focus:outline-none rounded-[7px] w-full"
        placeholder="Wpisz nazwę"
      />
      <img src={DeleteIcon} onClick={onCancel} className="2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3" />
      <img
        src={AddBlueIcon}
        onClick={onAddNewCategory}
        className={`2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3 ${loading && "animate-bounce"}`}
      />
    </div>
  );
};

export default AddNewSubcategory;
