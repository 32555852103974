import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Text from "src/components/ui/Text";
import Guide from "src/components/ui/Guide";
import Loader from "src/components/ui/Loader";
import Calendar from "src/components/ui/Calendar";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleWrapper from "src/components/ui/ModuleWrapper";
import CalendarWeekly from "src/components/ui/CalendarWeekly";
import CalendarMonthly from "src/components/ui/CalendarMonthly";
import TimeRangeToolbar, { CalendarView } from "src/components/ui/TimeRangeToolbar";

import { getInvoiceNewPath } from "src/common/router/routerPaths";
import { MonthlyCalendarHints } from "src/common/consts/Hints";

import { SortOptions } from "src/api/graphql/types-and-hooks";

import Summary from "./Summary";

import DailyEvent from "../../components/ui/CalendarDailyEvent";
import { useInvoices } from "../invoice/invoice.hooks";

const PaymentCalendar = () => {
  const [momentDate, setMomentDate] = useState(moment());
  const [calendarView, setCalendarView] = useState<CalendarView>(CalendarView.Day);
  const [paymentDue, setPaymentDue] = useState<{ gte: number; lte: number }>({
    gte: moment().startOf("day").unix(),
    lte: moment().endOf("day").unix(),
  });
  const [showHints, setShowHints] = useState(false);

  const navigate = useNavigate();
  const { invoices, loading } = useInvoices({
    take: 0,
    skip: 0,
    filter: { paymentDue: paymentDue },
    sort: { paymentDue: SortOptions.Desc },
  });

  useEffect(() => {
    updatePaymentDue();
  }, [momentDate]);

  const renderDailyEvents = () => {
    if (loading) return <Loader />;
    if (!invoices?.length)
      return (
        <div className="flex justify-center">
          <Text>Brak płatności</Text>
        </div>
      );
    return invoices?.map((invoice) => {
      return <DailyEvent key={invoice._id} document={invoice} />;
    });
  };

  const updatePaymentDue = () => {
    if (calendarView === CalendarView.Day) {
      setPaymentDue({
        gte: moment(momentDate).startOf("day").unix(),
        lte: moment(momentDate).endOf("day").unix(),
      });
    }
    if (calendarView === CalendarView.Week) {
      setPaymentDue({
        gte: moment(momentDate).startOf("isoWeek").unix(),
        lte: moment(momentDate).endOf("isoWeek").unix(),
      });
    }
    if (calendarView === CalendarView.Month) {
      setPaymentDue({
        gte: moment(momentDate).startOf("month").unix(),
        lte: moment(momentDate).endOf("month").unix(),
      });
    }
  };

  return (
    <>
      {showHints ? <Guide steps={MonthlyCalendarHints} onGuideEnd={() => setShowHints(false)} /> : undefined}
      <ModuleWrapper>
        <ModuleHeader
          title="Payments-calendar"
          onAdd={() => navigate(getInvoiceNewPath({ fromCalendar: true }))}
          addButtonTitle="Add-transaction"
        />
        <div className="flex flex-col h-full space-y-6">
          <div
            className={`flex flex-row ${
              calendarView === CalendarView.Day && "space-x-6"
            } min-h-[380px] h-[60%] overflow-auto`}
          >
            {calendarView === CalendarView.Day && (
              <div className="flex flex-col space-y-4 basis-1/4 bg-white 2xl:py-4 py-2 rounded-[16px] xl:p-6 p-3">
                <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">Filtry</Text>
                <Calendar onDateChange={setPaymentDue} />
              </div>
            )}
            <div
              className={`xl:p-6 p-3 bg-white rounded-[16px] w-full flex-grow h-0 min-h-full overflow-y-auto ${
                calendarView === CalendarView.Day && "basis-3/4"
              }`}
            >
              <TimeRangeToolbar
                onCalendarViewChange={setCalendarView}
                calendarView={calendarView}
                onDateChange={setMomentDate}
                calendarViews={[
                  { value: CalendarView.Day, label: "Dzień" },
                  { value: CalendarView.Week, label: "Tydzień" },
                  { value: CalendarView.Month, label: "Miesiąc" },
                ]}
                onShowHints={() => setShowHints(true)}
              />
              {calendarView === CalendarView.Month && (
                <CalendarMonthly momentDate={momentDate.clone()} documents={invoices} />
              )}
              {calendarView === CalendarView.Week && (
                <div className="h-[80%] max-h-[80%]">
                  <CalendarWeekly momentDate={momentDate.clone()} documents={invoices} />
                </div>
              )}
              {calendarView === CalendarView.Day && (
                <div className="flex flex-col space-y-4">{renderDailyEvents()}</div>
              )}
            </div>
          </div>
          <div className="flex flex-row space-x-4 xl:min-h-[250px] min-h-[200px] h-[35%] max-h-[35%]">
            <Summary documents={invoices} calendarView={calendarView} />
          </div>
        </div>
      </ModuleWrapper>
    </>
  );
};

export default PaymentCalendar;
