export default {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  FIVE_POINT_FIVE: 5.5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  EIGHT_POINT_FIVE: 8.5,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  TWELVE_POINT_FIVE: 12.5,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  NINETEEN: 19,
};
