import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AddUserToCompanyInput } from "src/api/graphql/types-and-hooks";
import { useAddUserToCompanyMutation } from "src/api/graphql/generated/gql-hooks";

import { getLoginPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import { getFormErrors } from "src/utils/getFormErrors";

export const useAddUserToCompany = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<any>({});
  const [addUserToCompany, { loading }] = useAddUserToCompanyMutation({
    onCompleted: () => {
      useToastSuccess("Pomyślnie zarejestrowano konto");
      navigate(getLoginPath());
    },
    onError: (error) => {
      useToastError(
        error?.message === "Max-users-reached" ? t("Max-users-reached") : "Wystąpił błąd podczas rejestracji",
      );
      if (error) {
        setErrors(getFormErrors(error?.graphQLErrors || []));
      }
    },
  });

  return {
    errors,
    handleAddUserToCompany: async (addUserToCompanyInput: AddUserToCompanyInput) => {
      await addUserToCompany({
        variables: {
          addUserToCompanyInput,
        },
      });
    },
    loading,
  };
};
