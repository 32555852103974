import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useCurrentUser, useLogout } from "src/common/AuthProvider/authProvider.hooks";
import {
  getBudgetsPath,
  getCockpitPath,
  getInvoicesPath,
  getFeedbackPath,
  getSettingsAccountPath,
  getPaymentsCalendarPath,
  getLiquidityAnalysisPath,
  getFinancialDetailsLegalFormPath,
} from "src/common/router/routerPaths";

import { UserPermissionResourcesEnum, UserRoles } from "src/api/graphql/types-and-hooks";

import MenuCockpitIcon from "src/assets/images/cockpit.svg";
import MenuCockpitWhiteIcon from "src/assets/images/cockpit-white.svg";
import MenuInvoiceIcon from "src/assets/images/invoice.svg";
import MenuInvoiceWhiteIcon from "src/assets/images/invoice-white.svg";
import MenuLogoutIcon from "src/assets/images/logout.svg";
import MenuSettingsIcon from "src/assets/images/settings.svg";
import MenuSettingsWhiteIcon from "src/assets/images/settings-white.svg";
import LogoImage from "src/assets/images/logo-white.png";
import MenuCalendarIcon from "src/assets/images/calendar.svg";
import MenuCalendarWhiteIcon from "src/assets/images/calendar-white.svg";
import MenuLiquiditiyAnalysisIcon from "src/assets/images/liquidity-analysis.svg";
import MenuLiquiditiyAnalysisWhiteIcon from "src/assets/images/liquidity-analysis-white.svg";
import MenuBudgetIcon from "src/assets/images/budget.svg";
import MenuBudgetWhiteIcon from "src/assets/images/budget-white.svg";
import MenuHelpIcon from "src/assets/images/help.svg";
import MenuHelpWhiteIcon from "src/assets/images/help-white.svg";
import MenuFinancialDetailsIcon from "src/assets/images/financial-details.svg";
import MenuFinancialDetailsWhiteIcon from "src/assets/images/financial-details-white.svg";

import Text from "./Text";
import Confirm from "./Confirm";

type MenuLinkProps = {
  icon: any;
  title: string;
  route?: string;
  activeIcon?: any;
  isRouteActive?: boolean;
};

const MenuLink: React.FC<MenuLinkProps> = ({ title, icon, activeIcon, route, isRouteActive }) => {
  if (isRouteActive) {
    return (
      <Link to={route}>
        <div className="2xl:h-11 xl:h-10 h-8 flex flex-row justify-between items-center xl:mx-6 mx-4 p-2 bg-brand-primary rounded-[10px] border-none outline-none">
          <Text className="font-sf-pro-medium text-text-white">{title}</Text>
          <img src={activeIcon} className="xl:w-5 w-4 xl:h-5 h-4" />
        </div>
      </Link>
    );
  }

  return (
    <Link to={route}>
      <div className="flex flex-row xl:mx-6 mx-4 space-x-3.5 items-center">
        <img src={icon} className="xl:w-5 w-4 xl:h-5 h-4" />
        <Text className="text-dark-blue">{title}</Text>
      </div>
    </Link>
  );
};

const Sidebar = () => {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const { t } = useTranslation();
  const logout = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const permissionsEnabled = currentUser?.company?.subscription?.subscription?.permissionsEnabled;

  const hasPermissions = (resource: UserPermissionResourcesEnum) => {
    if (!permissionsEnabled || currentUser?.role === UserRoles.Admin) return true;

    const hasPermission = currentUser?.permissions.some((permission) => permission.resource === resource);
    return hasPermission;
  };

  return (
    <>
      {showLogoutConfirm && (
        <Confirm
          show={showLogoutConfirm}
          onConfirm={logout}
          onCancel={() => setShowLogoutConfirm(false)}
          confirmText={t("Logout")}
          title={t("Logout-confirm")}
        />
      )}
      <div className="h-screen border-r-[1.5px] border-light-gray-2">
        <div className="2xl:h-[91px] xl:h-[81px] h-[71px] border-b-[1.5px] border-light-gray-2 flex items-center bg-blue">
          <div className="xl:ml-6 xl:mx-0 mx-3">
            <img
              src={LogoImage}
              className="2xl:w-[120px] xl:w-[110px] w-[100px] cursor-pointer"
              onClick={() => navigate(getCockpitPath())}
            />
          </div>
        </div>
        <div className="my-5 flex flex-col 2xl:space-y-8 xl:space-y-6 space-y-4">
          {hasPermissions(UserPermissionResourcesEnum.Cockpit) ? (
            <MenuLink
              title={t("Cockpit")}
              icon={MenuCockpitIcon}
              activeIcon={MenuCockpitWhiteIcon}
              route={getCockpitPath()}
              isRouteActive={location.pathname.includes("/cockpit")}
            />
          ) : undefined}
          {hasPermissions(UserPermissionResourcesEnum.Transactions) ? (
            <MenuLink
              title={t("Transactions")}
              icon={MenuInvoiceIcon}
              activeIcon={MenuInvoiceWhiteIcon}
              route={getInvoicesPath()}
              isRouteActive={location.pathname.includes("/invoice")}
            />
          ) : undefined}
          {hasPermissions(UserPermissionResourcesEnum.PaymentsCalendar) ? (
            <MenuLink
              title={t("Payments-calendar")}
              icon={MenuCalendarIcon}
              activeIcon={MenuCalendarWhiteIcon}
              route={getPaymentsCalendarPath()}
              isRouteActive={location.pathname.includes("/payments-calendar")}
            />
          ) : undefined}
          {hasPermissions(UserPermissionResourcesEnum.LiquidityAnalysis) ? (
            <MenuLink
              title={t("Liquidity-analysis")}
              icon={MenuLiquiditiyAnalysisIcon}
              activeIcon={MenuLiquiditiyAnalysisWhiteIcon}
              route={getLiquidityAnalysisPath()}
              isRouteActive={location.pathname.includes("/liquidity-analysis")}
            />
          ) : undefined}
          {hasPermissions(UserPermissionResourcesEnum.Budget) ? (
            <MenuLink
              title={t("Budget")}
              icon={MenuBudgetIcon}
              activeIcon={MenuBudgetWhiteIcon}
              route={getBudgetsPath()}
              isRouteActive={location.pathname.includes("/budget")}
            />
          ) : undefined}
          {hasPermissions(UserPermissionResourcesEnum.FinancialDetails) ? (
            <MenuLink
              title={t("Financial-details")}
              icon={MenuFinancialDetailsIcon}
              activeIcon={MenuFinancialDetailsWhiteIcon}
              route={getFinancialDetailsLegalFormPath()}
              isRouteActive={location.pathname.includes("/financial-details")}
            />
          ) : undefined}
        </div>
        <div className="border-b-[1.5px] border-light-gray-2 mx-4"></div>
        <div className="mt-5 flex flex-col 2xl:space-y-8 xl:space-y-6 space-y-4">
          {hasPermissions(UserPermissionResourcesEnum.Account) ? (
            <MenuLink
              title="Moje konto"
              icon={MenuSettingsIcon}
              activeIcon={MenuSettingsWhiteIcon}
              route={getSettingsAccountPath()}
              isRouteActive={location.pathname.includes("/settings")}
            />
          ) : undefined}
          <MenuLink
            title={t("Help")}
            icon={MenuHelpIcon}
            activeIcon={MenuHelpWhiteIcon}
            route={getFeedbackPath()}
            isRouteActive={location.pathname.includes("/help")}
          />
          <div onClick={() => setShowLogoutConfirm(true)}>
            <MenuLink title="Wyloguj" icon={MenuLogoutIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
