import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Pill from "src/components/ui/Pill";
import TableRow from "src/components/ui/TableRow";

import { formatDate } from "src/utils/time";
import { formatPrice } from "src/utils/currency";

import { InvoiceTypes, InvoicesListDocument, PaymentStatus } from "src/api/graphql/types-and-hooks";

import CostIcon from "src/assets/images/unpaid.svg";
import IncomeIcon from "src/assets/images/paid.svg";

import Text from "../Text";

type Props = {
  data: InvoicesListDocument;
  onClick?: () => void;
};

const DocumentsListModalTableRow: React.FC<Props> = ({
  data: { type, total, subtotal, paymentDue, invoiceNumber, paymentStatus, billTo, billFrom, genre },
  onClick,
}) => {
  const { t } = useTranslation();

  let name = type === InvoiceTypes.Income ? billTo?.name : billFrom?.name || "-";
  if (name.length > 30) {
    name = `${name.substring(0, 30)}...`;
  }

  const getPaymentInfo = () => {
    const isOverdue = moment(paymentDue).isBefore(moment(), "day");

    if (paymentStatus === PaymentStatus.Unpaid) {
      let title = "";
      if (type === InvoiceTypes.Income) {
        title = `${t(`${isOverdue ? "Overdue" : "Unpaid"}`)}`;
      }
      if (type === InvoiceTypes.Cost) {
        title = `${t(`${isOverdue ? "Overdue" : "To-pay"}`)}`;
      }
      return <Pill title={title} titleColor="text-red" bgColor="bg-light-red" customClass="max-w-[160px]" />;
    }
    return <Pill title={t("Paid")} titleColor="text-green" bgColor="bg-light-green" customClass="max-w-[160px]" />;
  };

  return (
    <TableRow onClick={onClick}>
      <td>
        <div className="ml-1 flex flex-row items-center pr-1">
          <img src={type === InvoiceTypes.Cost ? CostIcon : IncomeIcon} />
          <div className="ml-2 flex flex-col">
            <Text className="2xl:text-14 xl:text-12 text-10 text-white">{invoiceNumber}</Text>
            <Text className="2xl:text-12 x:text-10 text-8 text-text-secondary">{t(genre)}</Text>
          </div>
        </div>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-white pr-1">{name}</Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">
          {formatDate(paymentDue, "DD.MM.YYYY")}
        </Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 font-sf-pro-medium text-text-secondary">
          {formatPrice(subtotal, "zł")}
        </Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 font-sf-pro-medium text-text-secondary">
          {formatPrice(total, "zł")}
        </Text>
      </td>
      <td>
        <div className="min-w-[70px]">{getPaymentInfo()}</div>
      </td>
    </TableRow>
  );
};

export default DocumentsListModalTableRow;
