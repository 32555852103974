import React from "react";

import Text from "src/components/ui/Text";
import TableRow from "src/components/ui/TableRow";

import { formatDate } from "src/utils/time";
import { formatPrice } from "src/utils/currency";

import DeleteIcon from "src/assets/images/delete.svg";

type Props = {
  index: number;
  data: any;
  onClick?: () => void;
  onRemove?: (index: number) => void;
};

const FinancialMinimumTableRow: React.FC<Props> = ({
  index,
  data: { minimum, dateFrom, dateTo },
  onClick,
  onRemove,
}) => {
  return (
    <TableRow onClick={onClick}>
      <td>
        <Text className="text-white ml-2">{index}</Text>
      </td>
      <td>
        <Text className="text-white">{formatPrice(minimum, "zł")}</Text>
      </td>
      <td>
        <Text className="text-text-secondary">{dateFrom ? formatDate(dateFrom, "DD.MM.YYYY") : "-"}</Text>
      </td>
      <td>
        <Text className="text-text-secondary">{dateTo ? formatDate(dateTo, "DD.MM.YYYY") : "-"}</Text>
      </td>
      <td>
        <img src={DeleteIcon} className="cursor-pointer" onClick={() => onRemove(index - 1)} />
      </td>
    </TableRow>
  );
};

export default FinancialMinimumTableRow;
