import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import CloudTax, { CloudTaxColorEnum } from "src/components/ui/CloudTax";

import { TaxTypes } from "src/api/graphql/types-and-hooks";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { useIncomeTax } from "./cockpit.hooks";

type Props = {
  taxType: TaxTypes;
  companyTaxesLoading: boolean;
};

const IncomeTax: React.FC<Props> = ({ taxType, companyTaxesLoading }) => {
  const currentUser = useCurrentUser();
  const taxesCalculationEnabled = currentUser?.company?.subscription?.subscription?.taxesCalculationEnabled;

  const { t } = useTranslation();
  const { getIncomeTax, loading, incomeTaxValue } = useIncomeTax();
  const lastMonthIncomeTax = useIncomeTax();

  useEffect(() => {
    if (taxesCalculationEnabled) {
      getIncomeTax({
        filter: {
          date: { gte: moment().startOf("month").unix(), lte: moment().endOf("month").unix() },
        },
      });
      lastMonthIncomeTax.getIncomeTax({
        filter: {
          date: {
            gte: moment().subtract(1, "month").startOf("month").unix(),
            lte: moment().subtract(1, "month").endOf("month").unix(),
          },
        },
      });
    }
  }, [taxesCalculationEnabled]);

  return (
    <CloudTax
      className={!taxesCalculationEnabled && "opacity-50"}
      color={CloudTaxColorEnum.green}
      title={`${t("Tax")} ${taxType ? taxType : ""}`}
      value={taxesCalculationEnabled ? incomeTaxValue : "-"}
      previousTaxValue={taxesCalculationEnabled ? lastMonthIncomeTax.incomeTaxValue : "-"}
      loading={companyTaxesLoading || loading || lastMonthIncomeTax?.loading}
    />
  );
};

export default IncomeTax;
