import { useNavigate } from "react-router-dom";

import { getLoginPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import { ForgotPasswordInput, ResetPasswordInput } from "src/api/graphql/types-and-hooks";
import { useForgotPasswordMutation, useResetPasswordMutation } from "src/api/graphql/generated/gql-hooks";

export const useForgotPassword = () => {
  const [forgotPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: () => useToastSuccess("Email z linkiem został wysłany"),
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    handleForgotPassword: async (forgotPasswordInput: ForgotPasswordInput) => {
      await forgotPassword({ variables: { forgotPasswordInput } });
    },
    loading,
  };
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const [resetPassword, { loading }] = useResetPasswordMutation({
    onCompleted: () => {
      useToastSuccess("Pomyślnie zresetowano hasło");
      navigate(getLoginPath());
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    handleResetPassword: async (resetPasswordInput: ResetPasswordInput) => {
      await resetPassword({ variables: { resetPasswordInput } });
    },
    loading,
  };
};
