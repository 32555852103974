import React from "react";
import moment from "moment";

import Text from "src/components/ui/Text";
import TableRow from "src/components/ui/TableRow";

import { formatPrice } from "src/utils/currency";
import { InvoiceGenres, InvoiceTypes, InvoicesListDocument } from "src/api/graphql/types-and-hooks";
import AppDatePicker from "src/components/form/AppDatePicker";

type Props = {
  data: InvoicesListDocument;
  isSplitPayment: boolean;
  onClick?: () => void;
  onSplitPaymentChange?: (id: string, checked: boolean) => void;
  paymentDate?: Date;
  onPaymentDateChange?: (id: string, date: Date) => void;
};

const ChangeDocumentsPaymentStatusTableRow: React.FC<Props> = ({
  data: { _id, invoiceNumber, billTo, billFrom, paymentDue, subtotal, total, type, genre },
  isSplitPayment,
  onClick,
  onSplitPaymentChange,
  paymentDate,
  onPaymentDateChange,
}) => {
  let name = type === InvoiceTypes.Income ? billTo?.name : billFrom?.name || "-";
  if (name.length > 30) {
    name = `${name.substring(0, 30)}...`;
  }

  const renderCheckbox = () => {
    return genre === InvoiceGenres.Car || genre === InvoiceGenres.Invoice ? (
      <div className="flex justify-center">
        <input
          className="form-checkbox w-[18px] h-[18px] rounded-[4px] cursor-pointer checked:bg-blue ring-0 focus:ring-0 border border-[#DFE5F1] 2xl:ml-0 ml-1"
          type="checkbox"
          checked={isSplitPayment}
          readOnly
          onClick={() => onSplitPaymentChange(_id, !isSplitPayment)}
        />
      </div>
    ) : (
      <div className="w-[18px] h-[18px]"></div>
    );
  };

  return (
    <TableRow onClick={onClick}>
      <td>
        <Text className="ml-1 2xl:text-14 xl:text-12 text-10 text-text-secondary">{invoiceNumber}</Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{name}</Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">
          {paymentDue ? moment(paymentDue).format("DD-MM-YYYY") : "-"}
        </Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{formatPrice(subtotal, "zł")}</Text>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{formatPrice(total, "zł")}</Text>
      </td>
      <td>
        <AppDatePicker
          field={{
            value: paymentDate,
            dateFrom: new Date(),
            dateTo: new Date(),
            onChange: (date: string) => onPaymentDateChange(_id, new Date(date)),
          }}
          inputClasses="text-white 2xl:text-14 xl:text-12 text-10 font-sf-pro-regular pl-2 text-left w-full focus:border-white"
          placeholder="Data płatności"
        />
      </td>
      <td>{renderCheckbox()}</td>
    </TableRow>
  );
};

export default ChangeDocumentsPaymentStatusTableRow;
