import React, { useEffect, useState } from "react";
import Select from "react-select";

import Text from "../ui/Text";

export type Option = {
  value: string;
  label: string;
  options?: Option[];
};

type Props = {
  dark?: boolean;
  field: any;
  error?: boolean;
  color?: string;
  options: Option[];
  disabled?: boolean;
  textColor?: string;
  placeholder?: string;
  indicatorColor?: string;
};

const AppSelect: React.FC<Props> = ({
  dark = false,
  error,
  field,
  color = "transparent",
  options = [],
  disabled = false,
  textColor = "#233145",
  placeholder,
  indicatorColor = "hsl(0, 0%, 80%)",
}) => {
  const [value, setValue] = useState<Option | null>(null);

  useEffect(() => {
    if (field.value) {
      const option = findOption(options);
      setValue(option);
    } else {
      setValue(null);
    }
  }, [field.value, options]);

  function findOption(options: Option[]): Option | null {
    for (const option of options) {
      if (option.value === field.value) {
        return option;
      } else if (option.options) {
        const nestedOption = findOption(option.options);
        if (nestedOption) {
          return nestedOption;
        }
      }
    }
    return null;
  }

  const formatGroupLabel = (data: any) => (
    <div className="flex flex-row justify-between">
      <Text>{data.label}</Text>
      <Text>{data.options.length}</Text>
    </div>
  );

  return (
    <Select
      isDisabled={disabled}
      formatGroupLabel={formatGroupLabel}
      value={value}
      onChange={(value: any) => field.onChange(value.value)}
      options={options}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral50: "#8E98A7",
          primary: "#233145",
        },
      })}
      components={{
        IndicatorSeparator: () => null,
      }}
      classNames={{
        valueContainer: () => "2xl:text-16 xl:text-14 text-10 font-sf-pro-regular",
      }}
      styles={{
        control: (base) => ({
          ...base,
          backgroundColor: color,
          border: `1px solid ${error ? "#FF5252" : "#DFE5F1"}`,
          borderRadius: "10px",
          opacity: disabled ? 0.5 : 1,
          minHeight: "28px",
          height: "28px",
          "&:hover": {
            outline: "none",
          },
          "@media (min-width: 1280px)": {
            minHeight: "36px",
            height: "36px",
          },
          "@media (min-width: 1536px)": {
            minHeight: "40px",
            height: "40px",
          },
        }),
        singleValue: (styles) => {
          return {
            ...styles,
            color: dark ? "#fff" : textColor,
          };
        },
        option: (styles, { isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected ? "#3183FF" : "#fff",
            fontSize: "10px",
            "@media (min-width: 1280px)": {
              fontSize: "14px",
            },
            "@media (min-width: 1536px)": {
              fontSize: "16px",
            },
          };
        },
        groupHeading: (base) => ({
          ...base,
          marginLeft: "-10px",
        }),
        group: (base) => ({
          ...base,
          marginLeft: "10px",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: indicatorColor,
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          minHeight: "28px",
          height: "28px",
          "@media (min-width: 1280px)": {
            minHeight: "36px",
            height: "36px",
          },
          "@media (min-width: 1536px)": {
            minHeight: "40px",
            height: "40px",
          },
        }),
      }}
    />
  );
};

export default AppSelect;
