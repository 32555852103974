import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import TableRow from "src/components/ui/TableRow";

import { formatPrice } from "src/utils/currency";
import { InvoiceTypes } from "src/api/graphql/types-and-hooks";

import CostIcon from "src/assets/images/unpaid.svg";
import IncomeIcon from "src/assets/images/paid.svg";

import { Document } from "./DocumentsTab";
import Text from "src/components/ui/Text";

type Props = {
  data: Document;
  isSelected?: boolean;
  onClick: () => void;
};

const DocumentTableRow: React.FC<Props> = ({
  data: { invoiceNumber, billTo, billFrom, paymentDue, subtotal, total, type, genre, category },
  onClick,
  isSelected = false,
}) => {
  const { t } = useTranslation();

  const renderCheckbox = () => {
    return (
      <input
        className="form-checkbox w-[18px] h-[18px] rounded-[4px] cursor-pointer checked:bg-blue ring-0 focus:ring-0 border border-[#DFE5F1]"
        type="checkbox"
        checked={isSelected}
        readOnly
      />
    );
  };

  let name = type === InvoiceTypes.Income ? billTo?.name : billFrom?.name || "-";
  if (name.length > 30) {
    name = `${name.substring(0, 30)}...`;
  }

  return (
    <TableRow onClick={onClick}>
      <td>
        <div className="ml-1 flex flex-row items-center space-x-2">
          {renderCheckbox()}
          <img
            src={type === InvoiceTypes.Cost ? CostIcon : IncomeIcon}
            className="2xl:w-7 xl:w-5 w-4 2xl:h-7 xl:h-5 h-4"
          />
          <div className="ml-3 flex flex-col">
            <Text className="2xl:text-14 xl:text-12 text-10">{invoiceNumber}</Text>
            <Text className="2xl:text-12 xl:text-10 text-8  text-text-secondary">{t(genre)}</Text>
          </div>
        </div>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10">{name}</Text>
      </td>
      <td>
        {category ? (
          <div className="flex flex-col justify-center">
            <Text className="font-sf-pro-medium text-text-secondary">{category.name}</Text>
            {category?.parent ? (
              <Text className="font-sf-pro-regular 2xl:text-12 xl:text-10 text-8 text-text-secondary">
                {category.parent?.name}
              </Text>
            ) : undefined}
          </div>
        ) : (
          <>
            <Text className="font-sf-pro-medium text-[#00000033]">Nieprzypisane</Text>
          </>
        )}
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">
          {paymentDue ? moment(paymentDue).format("DD-MM-YYYY") : "-"}
        </Text>
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">{formatPrice(subtotal, "zł")}</Text>
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">{formatPrice(total, "zł")}</Text>
      </td>
    </TableRow>
  );
};

export default DocumentTableRow;
