import * as pdfjsLib from "pdfjs-dist";

export const getPdfPagesCount = async (file: Blob): Promise<number> => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js`;

  return new Promise((resolve) => {
    if (file.type !== "application/pdf") {
      resolve(0);
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = function () {
      const typedArray = new Uint8Array(this.result as ArrayBuffer);
      const loadingTask = pdfjsLib.getDocument({ data: typedArray });

      loadingTask.promise
        .then((pdf: pdfjsLib.PDFDocumentProxy) => {
          resolve(pdf.numPages);
        })
        .catch(() => resolve(0));
    };

    fileReader.readAsArrayBuffer(file);
  });
};
