import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import { useCurrentUser } from "../AuthProvider/authProvider.hooks";

export const useToast = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const initials = `${currentUser?.firstName?.charAt(0)} ${currentUser?.lastName?.charAt(0)}`;

  const showToast = (message: string) => {
    toast.custom(
      ({ visible, id }) => (
        <div
          className={`${
            visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="rounded-full bg-gray-200 2xl:w-[42px] xl:w-[38px] w-[34px] 2xl:h-[42px] xl:h-[38px] h-[34px] flex justify-center items-center">
                <Text className="font-sf-pro-bold">{initials}</Text>
              </div>
              <div className="ml-3 flex-1">
                <p className="mt-1 text-sm text-gray-500">{message}</p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {t("Close")}
            </button>
          </div>
        </div>
      ),
      { position: "top-right", duration: 5000 },
    );
  };

  return { showToast };
};
