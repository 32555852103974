import React, { useEffect, useRef, useState } from "react";

import LogoImage from "src/assets/images/logo.png";
import LoginImage from "src/assets/images/login.png";

import Text from "./Text";

type Props = {
  children: React.ReactNode;
};

const AuthWrapper: React.FC<Props> = ({ children }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const formRef = useRef(null);

  const checkOverflow = () => {
    const element = formRef.current;
    if (element) {
      setIsOverflow(element.scrollHeight > element.clientHeight);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(checkOverflow);
    const config = { attributes: true, childList: true, subtree: true };

    if (formRef.current) {
      observer.observe(formRef.current, config);
    }

    window.addEventListener("resize", checkOverflow);
    return () => {
      if (formRef.current) {
        observer.disconnect();
      }
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <div className="flex flex-col sm:flex-row min-h-[100dvh] sm:h-screen">
      <div
        ref={formRef}
        className={`flex flex-1 flex-col items-center w-full basis-3/5 bg-gradient-to-r from-bg-secondary to-bg-primary ${
          isOverflow ? "overflow-y-auto py-12" : "justify-center"
        }`}
      >
        <div className="flex justify-center">
          <img src={LogoImage} className="xl:w-[106px] w-[90px]" />
        </div>
        {children}
      </div>
      <div className="hidden sm:flex flex-col basis-2/5 bg-brand-primary justify-center min-h-[550px]">
        <div className="flex flex-col justify-end items-end">
          <img src={LoginImage} className="2xl:w-[594px] xl:w-[450px] w-[350px]" />
        </div>
        <div className="flex flex-col items-center">
          <Text className="font-sf-pro-medium text-white 2xl:text-42 xl:text-36 text-26">Zapanuj nad</Text>
          <Text className="font-sf-pro-medium text-white 2xl:text-42 xl:text-36 text-26">płynnością finansową</Text>
          <Text className="font-sf-pro-medium text-white 2xl:text-42 xl:text-36 text-26">
            z <Text className="2xl:text-42 xl:text-36 text-26 text-brand-secondary">flike</Text>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
