import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";

import { formatDate } from "src/utils/time";
import { customTwMerge } from "src/utils/twMerge";

import { SortOptions } from "src/api/graphql/types-and-hooks";

import CheckIcon from "src/assets/images/check.svg";
import DeleteIcon from "src/assets/images/delete.svg";

import { useDeleteNotification, useEditNotification, useNotifications } from "./notification.hooks";

type Props = {
  isAnimatingIn: boolean;
  isAnimatingOut: boolean;
};

const NotificationsSidebar: React.FC<Props> = ({ isAnimatingIn, isAnimatingOut }) => {
  const { t } = useTranslation();
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | null>(null);

  const { notifications, loading } = useNotifications({ filter: {}, sort: { createdAt: SortOptions.Desc } });
  const { isDeleted, handleDeleteNotification } = useDeleteNotification();
  const { isUpdated, handleEditNotification } = useEditNotification();
  const combinedClasses = customTwMerge(
    "bg-white border-l border-t border-b rounded-tl-[20px] rounded-bl-[20px] p-4 relative z-10 right-0 h-full overflow-auto flex flex-col",
    isAnimatingIn && "animate-slide-in",
    isAnimatingOut && "animate-slide-out",
  );

  useEffect(() => {
    if (isDeleted || isUpdated) {
      setSelectedNotificationId(null);
    }
  }, [isDeleted, isUpdated]);

  const onDeleteNotification = (id: string) => {
    setSelectedNotificationId(id);
    handleDeleteNotification(id);
  };

  const onUpdateNotification = (id: string) => {
    setSelectedNotificationId(id);
    handleEditNotification({ id, seen: true });
  };

  const renderNotifications = () => {
    if (!notifications) return null;
    return notifications.map((notification) => {
      return (
        <div key={notification._id} className="border-b py-4 bg-bg-light-gray2">
          {selectedNotificationId === notification?._id ? (
            <Loader />
          ) : (
            <div className="flex flex-row justify-between space-x-2 pl-2">
              <div className="flex flex-col space-y-1 max-w-[80%]">
                {!notification?.seen ? <div className="w-3 h-3 rounded-full bg-red"></div> : undefined}
                <Text className="2xl:text-14 xl:text-12 text-10">{notification.description}</Text>
                <Text className="2xl:text-12 xl:text-10 text-8 text-text-secondary">
                  {formatDate(notification.createdAt, "DD-MM-YYYY HH:mm")}
                </Text>
              </div>
              <div className="flex flex-row space-x-1 pr-1">
                {!notification?.seen ? (
                  <img
                    src={CheckIcon}
                    className="w-3 cursor-pointer"
                    onClick={() => onUpdateNotification(notification?._id)}
                  />
                ) : undefined}
                <img
                  src={DeleteIcon}
                  className="w-4 cursor-pointer"
                  onClick={() => onDeleteNotification(notification?._id)}
                />
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={combinedClasses}>
      {loading ? (
        <div className="h-full flex items-center">
          <Loader />
        </div>
      ) : (
        <>
          <Text>{t("Notifications-list")}</Text>
          <div className="mt-6 flex-col space-y-2">{renderNotifications()}</div>
        </>
      )}
    </div>
  );
};

export default NotificationsSidebar;
