import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import AuthWrapper from "src/components/ui/AuthWrapper";

import { useToastError } from "src/common/hooks/useToast";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getLoginPath, getSettingsOrdersPath } from "src/common/router/routerPaths";

import { useEditCompany, useUpdateCompanyPaymentMethod } from "./paymentMethod.hooks";

declare global {
  interface Window {
    onTokenizeCard: (token: string) => void;
    onGoBack: () => void;
  }
}

const ChangePaymentMethod = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [searchParams] = useSearchParams();
  const statusCode = searchParams.get("statusCode");

  const [loading, setLoading] = useState(statusCode ? true : false);

  const { updateCompanyPaymentMethod, data } = useUpdateCompanyPaymentMethod();
  const { editCompany, isUpdatingCompany } = useEditCompany();

  useEffect(() => {
    if (statusCode === "SUCCESS" && currentUser) {
      setLoading(false);
      handleCompanyUpdate();
    }
    if (statusCode === "WARNING_CONTINUE_3DS" || statusCode === "WARNING_CONTINUE_CVV") {
      setLoading(false);
      useToastError("Wystąpił błąd podczas autoryzacji");

      localStorage.removeItem("tokenIv");
      localStorage.removeItem("tokenContent");
      localStorage.removeItem("maskedCardNumber");
    }
  }, [statusCode, currentUser]);

  useEffect(() => {
    if (data) {
      if (!data.success) useToastError("Wystąpił błąd");

      if (data?.token && data?.maskedCardNumber) {
        localStorage.setItem("tokenIv", data.token?.iv);
        localStorage.setItem("tokenContent", data?.token.content);
        localStorage.setItem("maskedCardNumber", data.maskedCardNumber);
      }

      if (data?.redirectUri) {
        window.location.href = data.redirectUri;
      }
    }
  }, [data]);

  const handleCompanyUpdate = () => {
    const iv = localStorage.getItem("tokenIv");
    const content = localStorage.getItem("tokenContent");
    const maskedCardNumber = localStorage.getItem("maskedCardNumber");

    if (iv && content && maskedCardNumber) {
      editCompany({ id: currentUser?.company?._id, paymentMethod: { token: { iv, content }, maskedCardNumber } });
    }
  };

  window.onTokenizeCard = (token: string) => {
    if (!currentUser) navigate(getLoginPath());
    else {
      setLoading(true);
      updateCompanyPaymentMethod(token);
    }
  };

  window.onGoBack = () => {
    navigate(getSettingsOrdersPath());
  };

  return (
    <AuthWrapper>
      <div className="flex flex-col 2xl:mt-8 xl:mt-6 mt-4 space-y-6 items-center w-full">
        {loading || isUpdatingCompany ? (
          <Loader />
        ) : (
          <div className="flex flex-col space-y-8 items-center w-full">
            <div className="flex flex-col space-y-2 items-center">
              <Text className="text-white 2xl:text-32 xl:text-30 text-28">Metoda płatności</Text>
              <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">Skonfiguruj metodę płatności</Text>
            </div>
            <div className="mt-20 w-full">
              <iframe src="/change-payment-method-form.html" className="w-full min-h-[300px]" id="iframeId"></iframe>
            </div>
          </div>
        )}
      </div>
    </AuthWrapper>
  );
};

export default ChangePaymentMethod;
