import React from "react";

import { customTwMerge } from "src/utils/twMerge";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Label: React.FC<Props> = ({ children, className = "" }) => {
  const combinedClasses = customTwMerge(
    "2xl:text-18 xl:text-16 text-12 text-text-secondary font-sf-pro-regular",
    className,
  );

  return <span className={combinedClasses}>{children}</span>;
};

export default Label;
