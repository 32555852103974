import React, { useState } from "react";
import { get } from "lodash";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { BudgetCategory, BudgetCategoryTree, BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import { formatPrice } from "src/utils/currency";
import { formatDate, getMonthsWithWeeks, getWeekStartEnd } from "src/utils/time";

import AddBlue from "src/assets/images/add-blue.svg";
import EditIcon from "src/assets/images/edit.svg";
import ExpandIcon from "src/assets/images/expand.svg";
import DeleteIcon from "src/assets/images/delete.svg";
import PlusBlueIcon from "src/assets/images/plus-blue.svg";

import EditCategory from "./EditCategory";
import AddNewCategory from "./AddNewCategory";
import AddTransaction from "./addTransactions/AddTransaction";
import AddNewSubcategory from "./AddNewSubcategory";
import EditCategoryTreeValue from "./EditCategoryTreeValue";
import { useDeleteBudgetCategory } from "../budget.hooks";

type Category = Pick<BudgetCategory, "_id" | "name">;
type Children = Pick<BudgetCategoryTree, "value" | "months"> & { category: Category };
type CategoryTree = Pick<BudgetCategoryTree, "value" | "months"> & { category: Category; children: Children[] };
type MonthWithWeeks = { month: string; weeks: number[] };

type Props = {
  budgetName: string;
  budgetDateTo: string;
  budgetDateFrom: string;
  categoriesTree: CategoryTree[];
  isLoading?: boolean;
  type: BudgetCategoryTypes;
};

const CostsTab: React.FC<Props> = ({ budgetName, budgetDateTo, budgetDateFrom, categoriesTree, isLoading, type }) => {
  const monthsWithWeeks: MonthWithWeeks[] = getMonthsWithWeeks(budgetDateFrom, budgetDateTo);
  const { budgetId } = useParams();

  const [showConfirm, setShowConfirm] = useState(false);
  const [expandedMonths, setExpandedMonths] = React.useState<string[]>([]);
  const [editedValuePath, setEditedValuePath] = useState<string>("");
  const [editedCategoryId, setEditedCategoryId] = useState<string>(null);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState<string>(null);
  const [newCategoryParentId, setNewCategoryParentId] = useState<string>(null);
  const [showTransactionModal, setShowTransactionModal] = useState<boolean>(false);
  const [showAddNewCategoryInput, setShowAddNewCategoryInput] = useState<boolean>(false);

  const { isDeleting, handleDeleteBudgetCategory } = useDeleteBudgetCategory();

  const { t } = useTranslation();

  const onExpandMonth = (month: string) => {
    if (expandedMonths.includes(month)) {
      setExpandedMonths(expandedMonths.filter((selectedMonth) => selectedMonth !== month));
    } else {
      setExpandedMonths([...expandedMonths, month]);
    }
  };

  const onDeleteBudgetCategory = async () => {
    await handleDeleteBudgetCategory({ budgetId, category: categoryIdToDelete, categoryType: type });
    setShowConfirm(false);
    setCategoryIdToDelete(null);
  };

  const renderGroupName = (id: string, name: string, customClass: string, isParent = false) => {
    return editedCategoryId === id ? (
      <EditCategory
        categoryId={editedCategoryId}
        categoryName={name}
        onEditCategory={() => setEditedCategoryId(null)}
      />
    ) : (
      <div className={`flex flex-row justify-between group ${isParent ? "h-[25px]" : "h-[20px]"}`}>
        <Text className={`${customClass} hover:text-blue truncate`}>{name}</Text>
        <div className="flex flex-row items-center space-x-1 mr-1 invisible group-hover:visible">
          <img
            src={EditIcon}
            className="cursor-pointer 2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3"
            onClick={() => setEditedCategoryId(id)}
          />
          <img
            src={DeleteIcon}
            className="cursor-pointer 2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3"
            onClick={() => {
              setCategoryIdToDelete(id);
              setShowConfirm(true);
            }}
          />
          {isParent && (
            <img
              src={AddBlue}
              className="cursor-pointer 2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3"
              onClick={() => setNewCategoryParentId(id)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderValue = (value: number, customClass = "", path = "", containerClass = "", canEdit = true) => {
    return path !== "" && editedValuePath === path ? (
      <EditCategoryTreeValue
        defaultValue={get(categoriesTree, editedValuePath)}
        path={editedValuePath}
        type={type}
        onEdited={() => setEditedValuePath(null)}
        key={path}
      />
    ) : (
      <div
        className={`relative flex flex-row items-center w-full group ${
          canEdit && "cursor-pointer"
        } h-[20px] ${containerClass}`}
        key={path}
        onClick={() => canEdit && setEditedValuePath(path)}
      >
        {canEdit && (
          <div className="absolute flex justify-end w-full">
            <img src={EditIcon} className="invisible group-hover:visible 2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3" />
          </div>
        )}
        <div className="flex justify-center w-full h-full items-center">
          <Text className={customClass}>{formatPrice(value, "zł")}</Text>
        </div>
      </div>
    );
  };

  const renderGroups = () => {
    return (
      <div className="basis-1/6 shrink-0 flex flex-col pt-2">
        <div className="flex items-center h-[20px]">
          <Text>{t("Groups")}</Text>
        </div>
        {categoriesTree.map((category) => {
          return (
            <div
              className="2xl:mt-2.5 xl:mt-2 mt-1.5 flex-flex-col 2xl:space-y-2 xl:space-y-1.5 space-y-1 2xl:last:mb-2.5 xl:last:mb-2 last:mb-1.5"
              key={category.category._id}
            >
              {renderGroupName(
                category.category._id,
                category.category.name,
                "font-sf-pro-medium 2xl:text-20 xl:text-18 text-16",
                true,
              )}
              {newCategoryParentId === category.category._id && (
                <AddNewSubcategory
                  type={type}
                  parent={newCategoryParentId}
                  onCancel={() => setNewCategoryParentId(null)}
                  onAddCategory={() => setNewCategoryParentId(null)}
                />
              )}
              {category.children.map((c) => {
                return (
                  <div key={`${category.category._id}-${c.category._id}`}>
                    {renderGroupName(
                      c.category._id,
                      c.category.name,
                      "2xl:text-16 xl:text-14 text-12 text-text-secondary",
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSummary = () => {
    return (
      <div className="basis-1/8 shrink-0 flex flex-col bg-[#EDF1F7] pt-2">
        <div className="flex items-center justify-center h-[20px]">
          <Text>{t("Summary")}</Text>
        </div>
        {categoriesTree.map((category) => {
          return (
            <div
              className="2xl:mt-2.5 xl:mt-2 mt-1.5 flex flex-col items-center 2xl:space-y-2 xl:space-y-1.5 space-y-1"
              key={`Summary-${category.category._id}`}
            >
              {renderValue(
                category.value,
                "2xl:text-18 xl:text-16 text-14 font-sf-pro-medium",
                `${category.category._id}`,
                "h-[25px]",
                false,
              )}
              {category.children.map((child) =>
                renderValue(child.value, "2xl:text-16 xl:text-14 text-12", `${child.category._id}`, "", false),
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderWeeks = (month: MonthWithWeeks) => {
    return expandedMonths.includes(month.month)
      ? month.weeks.map((week) => {
          return (
            <div className="min-w-[200px] flex flex-col pt-2" key={Math.random()}>
              <div className="flex justify-center h-[20px] items-end">
                <Text className="2xl:text-12 xl:text-10 text-8 text-center text-text-secondary">
                  {getWeekStartEnd(month.month, week)}
                </Text>
              </div>
              <div className="bg-[#9A9EA90F] border-r border-[#E6E6E6]">
                {categoriesTree.map((category, categoryIndex: number) => {
                  const categoryMonthIndex = category.months.findIndex((cat) => cat.month === month.month);
                  const weekIndex = category.months[categoryMonthIndex].weeks.findIndex((cat) => cat.week === week);
                  const value = category.months[categoryMonthIndex].weeks[weekIndex]?.value;
                  return (
                    <div
                      className="2xl:mt-2.5 xl:mt-2 mt-1.5 flex flex-col 2xl:space-y-2 xl:space-y-1.5 space-y-1 items-center w-full"
                      key={`Month-${category.category._id}`}
                    >
                      {renderValue(
                        value,
                        "",
                        `[${categoryIndex}].months.[${categoryMonthIndex}].weeks.[${weekIndex}].value`,
                        "h-[25px]",
                        false,
                      )}
                      {category.children.map((child, childIndex: number) => {
                        const childMonthIndex = child.months.findIndex((cat) => cat.month === month.month);
                        const childWeekIndex = child.months[childMonthIndex].weeks.findIndex(
                          (cat) => cat.week === week,
                        );
                        const value = child.months[childMonthIndex].weeks[childWeekIndex]?.value;
                        return renderValue(
                          value,
                          "2xl:text-14 xl:text-12 text-10 text-text-secondary",
                          `[${categoryIndex}].children.[${childIndex}].months.[${childMonthIndex}].weeks.[${childWeekIndex}].value`,
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      : undefined;
  };

  const renderMonths = () => {
    return monthsWithWeeks.map((month) => {
      return (
        <div className="flex flex-row" key={month.month}>
          <div className="min-w-[200px] flex flex-col pt-2">
            <div
              className="flex flex-row group cursor-pointer items-center h-[20px]"
              onClick={() => onExpandMonth(month.month)}
            >
              <div className="flex justify-center w-full">
                <Text className="text-center text-text-secondary">
                  {`${t(formatDate(month.month, "MMMM"))} ${formatDate(month.month, "YYYY")}`}
                </Text>
              </div>
              <div className="r-0 invisible group-hover:visible">
                <img src={ExpandIcon} className="flex justify-end 2xl:w-5 2xl:h-5 xl:h-4 xl:w-4 h-3 w-3" />
              </div>
            </div>
            <div className="border-r border-[#E6E6E6]">
              {categoriesTree.map((category, categoryIndex: number) => {
                const monthIndex = category.months.findIndex((cat) => cat.month === month.month);
                const value = category.months[monthIndex]?.value;
                return (
                  <div
                    className="2xl:mt-2.5 xl:mt-2 mt-1.5 flex flex-col 2xl:space-y-2 xl:space-y-1.5 space-y-1 items-center w-full"
                    key={`Month-${category.category._id}`}
                  >
                    {renderValue(value, "", `[${categoryIndex}].months.[${monthIndex}].value`, "h-[25px]", false)}
                    {category.children.map((child, childIndex: number) => {
                      const childMonthIndex = child.months.findIndex((cat) => cat.month === month.month);
                      const childMonthValue = child.months[childMonthIndex]?.value;

                      return (
                        <div
                          className={`relative flex flex-row items-center w-full group cursor-pointer h-[20px]`}
                          key={`${categoryIndex}].children.[${childIndex}].months.[${childMonthIndex}].value`}
                          onClick={() => onExpandMonth(month.month)}
                        >
                          <div className="flex justify-center w-full h-full items-center">
                            <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">
                              {formatPrice(childMonthValue, "zł")}
                            </Text>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          {renderWeeks(month)}
        </div>
      );
    });
  };

  return (
    <>
      {showConfirm ? (
        <Confirm
          show={showConfirm}
          onConfirm={onDeleteBudgetCategory}
          onCancel={() => setShowConfirm(false)}
          loading={isDeleting}
        />
      ) : undefined}
      {showTransactionModal ? (
        <AddTransaction
          onCancel={() => setShowTransactionModal(false)}
          transactionType={type}
          budgetDateFrom={budgetDateFrom}
          budgetDateTo={budgetDateTo}
        />
      ) : undefined}
      <div className="flex flex-row justify-between border-b pb-2">
        <div className="flex flex-row space-x-12">
          <Text className="2xl:text-20 xl:text-18 text-16">{budgetName}</Text>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{`${formatDate(
            budgetDateFrom,
            "DD.MM.YYYY",
          )} - ${formatDate(budgetDateTo, "DD.MM.YYYY")}`}</Text>
        </div>
        {/* <div>
          <AppButton
            title={t(type === BudgetCategoryTypes.Cost ? "Add-cost" : "Add-income")}
            color={ButtonColorTypes.PRIMARY}
            textClass="text-white"
            customClass="rounded-[12px]"
            onClick={() => setShowTransactionModal(true)}
          />
        </div> */}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col pb-2">
          {categoriesTree.length ? (
            <div className="flex flex-row h-full max-w-full grow-0">
              {renderGroups()}
              {renderSummary()}
              <div className="flex flex-row max-w-screen overflow-auto">{renderMonths()}</div>
            </div>
          ) : undefined}
          {!showAddNewCategoryInput ? (
            <div
              className="min-w-[90px] w-[11%] 2xl:py-8 xl:py-6 py-4 rounded-[16px] border border-dashed border-blue flex flex-row items-center justify-center space-x-2 cursor-pointer"
              onClick={() => setShowAddNewCategoryInput(true)}
            >
              <img src={PlusBlueIcon} />
              <Text className="text-blue">{t("New-group")}</Text>
            </div>
          ) : (
            <div className="w-[16%] mt-4">
              <AddNewCategory type={type} onAddCategory={() => setShowAddNewCategoryInput(false)} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CostsTab;
