import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "src/components/ui/Card";
import Text from "src/components/ui/Text";
import Table from "src/components/ui/Table";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import Pagination from "src/components/ui/Pagination";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import {
  getSelectPackagePath,
  getSettingsOrdersPath,
  getChangeSubscriptionPath,
  getChangePaymentMethodPath,
} from "src/common/router/routerPaths";

import { formatPrice } from "src/utils/currency";
import { formatDate } from "src/utils/time";

import { GetOrdersOptionsInput, SortOptions } from "src/api/graphql/types-and-hooks";

import OrdersTableRow from "./OrdersTableRow";
import SettingsWrapper from "./SettingsWrapper";
import {
  useOrders,
  useCompanySubscription,
  useCompanyOcrPagesLeft,
  useRemovePaymentMethod,
  useCompanyPaymentMethod,
  useCancelCompanySubscription,
  useResumeCompanySubscription,
} from "./settings.hooks";

const HEADERS = ["Nr. zamówienia", "Data zakupu", "Nazwa", "Typ", "Status", "", ""];

const Orders = () => {
  const [showConfirm, setShowConfirm] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const companyId = currentUser?.company?._id;

  const { paymentMethod, paymentMethodLoading } = useCompanyPaymentMethod(companyId);
  const { newSubscription, subscription, companySubscriptionLoading } = useCompanySubscription(companyId);
  const { isCancelingSubscription, cancelCompanySubscription, isCancelled } = useCancelCompanySubscription();
  const { isResumingSubscription, resumeCompanySubscription } = useResumeCompanySubscription();
  const { removePaymentMethod, isRemovingPaymentMethod } = useRemovePaymentMethod();
  const [filteringData, setFilteringData] = useState<GetOrdersOptionsInput>({
    take: 5,
    skip: 0,
    filter: { company: { eq: companyId } },
    sort: { createdAt: SortOptions.Desc },
  });
  const { loading, orders, total } = useOrders({
    ...filteringData,
    filter: { ...filteringData.filter, company: { eq: companyId } },
  });
  const { ocrPagesLeft, companyOcrPagesLeftLoading } = useCompanyOcrPagesLeft(companyId);

  useEffect(() => {
    if (isCancelled) setShowConfirm(false);
  }, [isCancelled]);

  const renderRows = () => {
    if (!orders?.length) return null;
    return orders.map((order) => {
      return <OrdersTableRow data={order} key={order._id} />;
    });
  };

  const currentSubscription = () => {
    const isLoading = loading || companySubscriptionLoading || isResumingSubscription;
    return (
      <div className="w-full h-full rounded-[20px] bg-white shadow-md">
        <div className="flex flex-row h-full p-5">
          {isLoading ? (
            <div className="absolute w-[90%]">
              <Loader />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-between basis-1/2">
                <div className="flex flex-col">
                  <Text className="2xl:text-18 xl:text-16 text-14 font-sf-pro-bold space-x-4">
                    {subscription?.subscription.name}
                  </Text>
                  <div className="flex xl:mt-[16px] mt-[8px] space-x-4">
                    <Text className="2xl:text-28 xl:text-24 text-20 font-sf-pro-bold">
                      {formatPrice(subscription?.subscription.subtotal, "zł")}
                      <Text className="text-text-secondary ml-2">{t("Net")}</Text>
                    </Text>
                  </div>
                  {subscription?.isTrial && (
                    <Text className="text-text-secondary 2xl:text-14 xl:text-12 text-10">
                      Okres próbny do {formatDate(subscription?.trialDateEnd, "DD.MM.YYYY")}
                    </Text>
                  )}
                </div>
              </div>
              <div className="basis-1/2 justify-end">
                <div className="flex flex-col items-end space-y-1">
                  {!subscription?.dateEnd ? (
                    <>
                      <div className="2xl:w-[100px] xl:w-[80px] w-[60px]">
                        <AppButton
                          title={t("Cancel")}
                          color={ButtonColorTypes.DANGER}
                          textClass="text-white"
                          customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-5 min-w-[50px]"
                          onClick={() => setShowConfirm(true)}
                        />
                      </div>
                      <div className="2xl:w-[100px] xl:w-[80px] w-[60px]">
                        <AppButton
                          title={t("Change")}
                          color={ButtonColorTypes.PRIMARY}
                          textClass="text-white"
                          customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-5 min-w-[60px]"
                          onClick={() => navigate(getChangeSubscriptionPath())}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="2xl:w-[100px] xl:w-[80px] w-[60px]">
                      <AppButton
                        title={t("Resume")}
                        color={ButtonColorTypes.PRIMARY}
                        textClass="text-white"
                        customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-5 min-w-[60px]"
                        onClick={resumeCompanySubscription}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  // render information about subscription
  const renderInfo = () => {
    if (subscription?.dateEnd) {
      return (
        <div className="flex flex-row">
          <Text className="text-state-error">{t("Subscription-end-info")}</Text>
        </div>
      );
    }
    if (!paymentMethod) {
      return (
        <div className="flex flex-row">
          <Text className="text-state-error">{t("No-payment-method-info")}</Text>
        </div>
      );
    }

    return newSubscription && !subscription?.dateEnd ? (
      <div className="flex flex-row">
        <Text className="text-state-error">{t("New-subscription-info")}</Text> &nbsp;
        <Text className="text-state-error font-sf-pro-bold">{newSubscription?.name}</Text>
      </div>
    ) : undefined;
  };

  const handleRemovePaymentMethod = () => {
    removePaymentMethod();
  };

  return (
    <>
      {showConfirm && (
        <Confirm
          show={showConfirm}
          title={t("Cancel-subscription-confirm")}
          onConfirm={cancelCompanySubscription}
          onCancel={() => setShowConfirm(false)}
          loading={isCancelingSubscription}
        />
      )}
      <SettingsWrapper>
        <div className="flex flex-col">
          <div className="flex 2xl:h-10 xl:h-8 h-6"></div>
          <div className="bg-white rounded-[20px] p-5 flex flex-col xl:space-y-6 space-y-4">
            {renderInfo()}
            <div className="flex flex-row space-x-6">
              <div className="flex flex-col basis-1/2 space-y-4">
                <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Current-subscription")}</Text>
                {currentSubscription()}
              </div>
              <div className="flex flex-col h-[80px] basis-1/2 space-y-4">
                <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Payment-method")}</Text>
                {paymentMethod ? (
                  <Card
                    maskedCardNumber={paymentMethod?.maskedCardNumber}
                    isLoading={paymentMethodLoading || isRemovingPaymentMethod}
                    onRemovePaymentMethod={handleRemovePaymentMethod}
                  />
                ) : (
                  <div className="max-w-[200px] w-full">
                    <AppButton
                      title={t("Add-payment-method")}
                      color={ButtonColorTypes.PRIMARY}
                      textClass="text-white"
                      customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-6"
                      onClick={() => navigate(getChangePaymentMethodPath())}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">
                {t("Invoice-data-automatic-reading")}
              </Text>
              {companyOcrPagesLeftLoading ? (
                <Loader />
              ) : (
                <div className="flex flex-col space-y-2">
                  <Text>{t("Pages-left")}</Text>
                  <div className="flex flex-row space-x-6 items-center">
                    <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{ocrPagesLeft}</Text>
                    <AppButton
                      title={t("Buy-more")}
                      color={ButtonColorTypes.PRIMARY}
                      textClass="text-white"
                      customClass="rounded-[12px] 2xl:h-8 xl:h-7 h-6 max-w-[160px]"
                      onClick={() => navigate(getSelectPackagePath())}
                    />
                  </div>
                </div>
              )}
            </div>
            <>
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Orders-history")}</Text>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Table headers={HEADERS} rows={renderRows()} />
                  <Pagination
                    total={total}
                    filteringData={filteringData}
                    route={getSettingsOrdersPath()}
                    onChange={setFilteringData}
                    take={5}
                  />
                </>
              )}
            </>
          </div>
        </div>
      </SettingsWrapper>
    </>
  );
};

export default Orders;
