import {
  useCompanySubscriptionQuery,
  useSubscriptionsQuery,
  useUpdateCompanySubscriptionMutation,
} from "src/api/graphql/generated/gql-hooks";
import { GetSubscriptionsOptionsInput } from "src/api/graphql/types-and-hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useSubscriptions = (options: GetSubscriptionsOptionsInput) => {
  const { loading, data } = useSubscriptionsQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const subscriptions = data?.userSubscriptions?.documents || [];
  const total = data?.userSubscriptions?.total;

  return {
    loading,
    subscriptions,
    total,
  };
};

export const useCompanySubscription = (companyId: string) => {
  const { data, loading: companySubscriptionLoading } = useCompanySubscriptionQuery({ variables: { companyId } });

  const subscription = data?.company?.subscription;

  return {
    subscription,
    companySubscriptionLoading,
  };
};

export const useUpdateCompanySubscription = () => {
  const [updateCompanySubscription, { data, loading: isUpdating }] = useUpdateCompanySubscriptionMutation({
    onError: () => useToastError("Wystąpił błąd"),
    onCompleted: () => useToastSuccess("Zaktualizowano subskrypcję"),
  });

  return {
    updateCompanySubscription: async (subscription: string) => {
      await updateCompanySubscription({
        variables: { updateCompanySubscriptionInput: { subscription } },
      });
    },
    isUpdated: !!data,
    isUpdating,
  };
};
