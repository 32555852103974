import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import { useToastError } from "src/common/hooks/useToast";

import SaveBlueIcon from "src/assets/images/save-blue.svg";

import { useEditBudgetCategoryTreeValue } from "../budget.hooks";

type Props = {
  defaultValue: number;
  type: BudgetCategoryTypes;
  path: string;
  onEdited: () => void;
};

const EditCategoryTreeValue: React.FC<Props> = ({ path, type, defaultValue, onEdited }) => {
  const inputRef = useRef<HTMLInputElement>();

  const { t } = useTranslation();
  const { budgetId } = useParams();
  const { handleEditCategoryTreeValue, loading } = useEditBudgetCategoryTreeValue();

  const onEditCategoryTreeValue = async () => {
    const value = inputRef.current?.value;
    if (!value || Number(value) < 0) {
      useToastError(t("Please-enter-valid-value"));
    }

    await handleEditCategoryTreeValue({ id: budgetId, path, type, value: Number(value) });
    onEdited();
  };

  return (
    <div className="flex flex-row justify-between shadow-md mx-2 bg-transparent border-b border-[#8E98A7]">
      <input
        ref={inputRef}
        type="text"
        className="2xl:text-16 xl:text-14 text-12 pl-4 w-[80%] bg-transparent focus:outline-none text-center"
        defaultValue={defaultValue}
      />
      <img
        src={SaveBlueIcon}
        className={`cursor-pointer ${loading && "animate-bounce"}`}
        onClick={onEditCategoryTreeValue}
      />
    </div>
  );
};

export default EditCategoryTreeValue;
