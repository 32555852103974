import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { forEach, size } from "lodash";

import Label from "src/components/ui/Label";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { UpdateCompanyInput, UpdateUserInput } from "src/api/graphql/types-and-hooks";

import SettingsWrapper from "./SettingsWrapper";
import accountSchema from "./account.schema";
import { useEditCompany, useEditUser, useGetUser } from "./settings.hooks";

const Account = () => {
  const { t } = useTranslation();
  const loggedUser = useCurrentUser();
  const { user } = useGetUser(loggedUser?._id);
  const { editUser, isUpdatingUser } = useEditUser();
  const { editCompany, isUpdatingCompany } = useEditCompany();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    values: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      companyName: user?.company?.name,
      companyId: user?.company?.companyId,
      companyPostalCode: user?.company?.postalCode,
      companyCity: user?.company?.city,
      companyAddress: user?.company?.address,
      companyCountry: user?.company?.country,
      companyEmail: user?.company?.email,
      companyPhone: user?.company?.phone,
    },
    resolver: yupResolver(accountSchema),
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    const userInput: UpdateUserInput = { id: loggedUser?._id };
    const companyInput: UpdateCompanyInput = { id: user?.company?._id };

    forEach(dirtyFields, (value, key) => {
      if (["firstName", "lastName", "phone"].includes(key)) {
        userInput[key] = data[key].trim();
      } else {
        if (key === "companyName") companyInput.name = data[key].trim();
        if (key === "companyPostalCode") companyInput.postalCode = data[key].trim();
        if (key === "companyCity") companyInput.city = data[key].trim();
        if (key === "companyAddress") companyInput.address = data[key].trim();
        if (key === "companyCountry") companyInput.country = data[key].trim();
        if (key === "companyEmail") companyInput.email = data[key].trim();
        if (key === "companyPhone") companyInput.phone = data[key].trim();
      }
    });

    if (size(userInput) > 1) {
      editUser(userInput);
    }

    if (size(companyInput) > 1) {
      editCompany(companyInput);
    }
  };

  return (
    <SettingsWrapper>
      <div className="flex flex-col space-y-5">
        <div className="flex justify-end">
          <div className="px-2">
            <AppButton
              title={t("Save")}
              onClick={handleSubmit(onSubmit)}
              color={ButtonColorTypes.PRIMARY}
              customClass="rounded-[8px]"
              textClass="text-white font-sf-pro-medium"
              disabled={!isDirty}
              isLoading={isUpdatingUser || isUpdatingCompany}
            />
          </div>
        </div>
        <div className="bg-white rounded-[20px] px-5 pt-4 pb-5">
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col space-y-3">
              <Label>{t("Account-information")}</Label>
              <div className="flex flex-row space-x-6">
                <div className="basis-1/2">
                  <AppInput
                    id="firstName"
                    inputType={InputTypes.TEXT}
                    placeholder={t("First-name")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.firstName}
                  />
                </div>
                <div className="basis-1/2">
                  <AppInput
                    id="lastName"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Last-name")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.lastName}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-6">
                <div className="basis-1/2">
                  <AppInput
                    id="email"
                    inputType={InputTypes.TEXT}
                    placeholder={t("E-mail")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.email}
                    disabled={true}
                  />
                </div>
                <div className="basis-1/2">
                  <AppInput
                    id="phone"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Phone-number")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.phone}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-3">
              <Label>{t("Company-information")}</Label>
              <div className="flex flex-row space-x-6">
                <div className="basis-2/3">
                  <AppInput
                    id="companyName"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Company-name")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyName}
                  />
                </div>
                <div className="basis-1/3">
                  <AppInput
                    id="companyId"
                    inputType={InputTypes.TEXT}
                    placeholder={t("VAT-ID")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyId}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-6">
                <div className="basis-1/3">
                  <AppInput
                    id="companyPostalCode"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Postal-code")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyPostalCode}
                  />
                </div>
                <div className="basis-1/3">
                  <AppInput
                    id="companyCity"
                    inputType={InputTypes.TEXT}
                    placeholder={t("City")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyCity}
                  />
                </div>
                <div className="basis-1/3">
                  <AppInput
                    id="companyAddress"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Address")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyAddress}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-6">
                <div className="basis-1/3">
                  <AppInput
                    id="companyCountry"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Country")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyCountry}
                  />
                </div>
                <div className="basis-1/3">
                  <AppInput
                    id="companyEmail"
                    inputType={InputTypes.TEXT}
                    placeholder={t("E-mail")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyEmail}
                  />
                </div>
                <div className="basis-1/3">
                  <AppInput
                    id="companyPhone"
                    inputType={InputTypes.TEXT}
                    placeholder={t("Phone-number")}
                    register={register}
                    inputClasses="font-sf-pro-medium"
                    error={!!errors?.companyPhone}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsWrapper>
  );
};

export default Account;
