import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import ModuleWrapper from "src/components/ui/ModuleWrapper";
import Cloud, { CloudColorEnum } from "src/components/ui/Cloud";

import { getInvoicesPath } from "src/common/router/routerPaths";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import VatTax from "./VatTax";
import IncomeTax from "./IncomeTax";
import AccountBalance from "./AccountBalance";
import LiquidityChart from "./LiquidityChart";
import CostsAndIncomesChart from "./CostsAndIncomesChart";
import TransactionsCondition from "./TransactionsCondition";
import { useCompanyTaxDetails, useUnpaidInvoices } from "./cockpit.hooks";

const Cockpit = () => {
  const currentUser = useCurrentUser();
  const companyId = currentUser?.company?._id;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyTaxesLoading, taxDetails } = useCompanyTaxDetails(companyId);
  const { unpaidInvoices, unpaidInvoicesLoading } = useUnpaidInvoices();

  const getInvoiceWordForm = (count: number) => {
    if (count === 1) {
      return "faktura";
    } else if (count > 1 && count < 5) {
      return "faktury";
    } else {
      return "faktur";
    }
  };

  return (
    <ModuleWrapper>
      <div className="flex flex-row justify-between items-center mb-4">
        <Text className="2xl:text-28 xl:text-24 text-20 font-sf-pro-bold">{t("Cockpit")}</Text>
        <Text className="2xl:text-28 xl:text-24 text-20 font-sf-pro-bold">{moment().format("DD-MM-YYYY")}</Text>
      </div>
      <div className="flex flex-col h-full 2xl:space-y-6 space-y-4">
        <div className="flex flex-row space-x-4 2xl:min-h-[170px] xl:min-h-[150px] min-h-[110px]">
          <div className="basis-2/6 bg-white rounded-[20px]">
            <AccountBalance />
          </div>
          <div className="basis-2/6">
            <TransactionsCondition />
          </div>
          <div className="basis-2/6 bg-white rounded-[20px]"></div>
        </div>
        <div className="flex flex-row xl:space-x-10 space-x-5 2xl:min-h-[125px] xl:min-h-[110px] min-h-[85px]">
          <div className="basis-1/4">
            <VatTax
              isVatPayer={taxDetails?.isVatPayer}
              vatTaxType={taxDetails?.vatType}
              companyTaxesLoading={companyTaxesLoading}
            />
          </div>
          <div className="basis-1/4">
            <IncomeTax taxType={taxDetails?.taxType} companyTaxesLoading={companyTaxesLoading} />
          </div>
          <div className="basis-1/4">
            <Cloud
              color={CloudColorEnum.red}
              title={`${t("Overdue-obligations")}`}
              value={unpaidInvoices?.unpaidCosts}
              loading={unpaidInvoicesLoading}
              subtitle={`${unpaidInvoices?.unpaidCostsCount} ${getInvoiceWordForm(unpaidInvoices?.unpaidCostsCount)}`}
              onClick={() => unpaidInvoices?.unpaidCostsCount && navigate(getInvoicesPath({ showUnpaidCosts: true }))}
            />
          </div>
          <div className="basis-1/4">
            <Cloud
              color={CloudColorEnum.red}
              title={`${t("Overdue-receivables")}`}
              value={unpaidInvoices?.unpaidIncomes}
              loading={unpaidInvoicesLoading}
              subtitle={`${unpaidInvoices?.unpaidIncomesCount} ${getInvoiceWordForm(
                unpaidInvoices?.unpaidIncomesCount,
              )}`}
              onClick={() =>
                unpaidInvoices?.unpaidIncomesCount && navigate(getInvoicesPath({ showUnpaidIncomes: true }))
              }
            />
          </div>
        </div>
        <div className="flex flex-row space-x-4 min-h-[270px] h-[53%] max-h-[600px]">
          <div className="basis-3/5 bg-white rounded-[20px] p-5">
            <div className="mb-2">
              <Text className="2xl:text-18 xl:text-16 text-14 font-sf-pro-bold">{t("Incomes-and-costs-balance")}</Text>
            </div>
            <CostsAndIncomesChart />
          </div>
          <div className="flex flex-col space-y-2 basis-2/5 bg-white rounded-[20px] p-5">
            <Text className="2xl:text-18 xl:text-16 text-14 font-sf-pro-bold">{t("Liquidity-analysis")}</Text>
            <LiquidityChart />
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default Cockpit;
