import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRegisterMutation } from "src/api/graphql/generated/gql-hooks";
import { RegisterInput } from "src/api/graphql/types-and-hooks";

import { getVerifyAccountPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import { getFormErrors } from "src/utils/getFormErrors";

export const useRegister = (onChangeStep: (step: number) => void) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [handleRegister, { data, loading }] = useRegisterMutation({
    onCompleted: () => {
      useToastSuccess("Pomyślnie zarejestrowano konto");
      navigate(getVerifyAccountPath());
    },
    onError: (error) => {
      useToastError("Wystąpił błąd podczas rejestracji");
      if (error) {
        setErrors(getFormErrors(error?.graphQLErrors || []));
      }
    },
    errorPolicy: "all",
  });

  useEffect(() => {
    if (errors?.companyId) onChangeStep(2);
    if (errors?.email) onChangeStep(1);
  }, [errors]);

  return {
    data,
    errors,
    loading,
    handleRegister: async (input: RegisterInput) => {
      await handleRegister({
        variables: {
          registerInput: input,
        },
      });
    },
  };
};
