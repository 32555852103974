import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Modal from "src/components/ui/Modal";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppCheckbox from "src/components/form/AppCheckbox";
import AppDatePicker from "src/components/form/AppDatePicker";

import budgetAddSchema from "./budgetAdd.schema";
import { useCreateBudget } from "./budget.hooks";
import Text from "src/components/ui/Text";

type Props = {
  onCancel: () => void;
};

const BudgetAdd: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      withDefaultCategories: false,
      importExistingInvoices: false,
    },
    resolver: yupResolver(budgetAddSchema),
    reValidateMode: "onChange",
  });

  const { isCreating, handleCreateBudget } = useCreateBudget();

  const onSubmit = (data: any) => {
    const { period, ...input } = data;

    Object.assign(input, { dateFrom: period.startDate, dateTo: period.endDate });
    handleCreateBudget(input);
  };

  return (
    <Modal
      show={true}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmText="Save"
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
      loading={isCreating}
    >
      <div className="flex flex-col h-full">
        <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Create-new-budget")}</Text>
        <Text className="text-text-secondary mt-3">{t("Create-new-budget-subtitle")}</Text>
        <div className="mt-6 flex flex-col space-y-2 w-2/3">
          <Text className="text-white">{t("Budget-name")}</Text>
          <AppInput
            id="name"
            register={register}
            inputType={InputTypes.TEXT}
            inputClasses={`text-white ${!formErrors.name?.message && "focus:border-white"}`}
            placeholder="Nazwa"
            error={!!formErrors.name?.message}
          />
        </div>
        <div className="mt-4 flex flex-col space-y-2 w-2/3">
          <Text className="text-white">{t("Set-date-range")}</Text>
          <Controller
            control={control}
            name="period"
            render={({ field }) => (
              <AppDatePicker
                field={field}
                placeholder="Data od - do"
                range={true}
                asSingle={false}
                containerClasses="hover:border-white"
                inputClasses="text-white"
                error={!!formErrors.period?.message}
              />
            )}
          />
        </div>
        <div className="mt-4 flex flex-col space-y-2">
          <Text className="text-white">{t("Quick-selection")}</Text>
          <div className="flex flex-row space-x-2">
            <div
              className="w-[150px] h-[35px] rounded-[20px] border border-[#3183FF] flex items-center justify-center cursor-pointer"
              onClick={() =>
                setValue("period", {
                  startDate: moment().startOf("month").toDate(),
                  endDate: moment().endOf("month").toDate(),
                })
              }
            >
              <Text className="text-white font-sf-pro-bold">Bieżący miesiąc</Text>
            </div>
            <div
              className="w-[150px] h-[35px] rounded-[20px] border border-[#3183FF] flex items-center justify-center cursor-pointer"
              onClick={() =>
                setValue("period", {
                  startDate: moment().startOf("quarter").toDate(),
                  endDate: moment().endOf("quarter").toDate(),
                })
              }
            >
              <Text className="text-white font-sf-pro-bold">Bieżący kwartał</Text>
            </div>
            <div
              className="w-[150px] h-[35px] rounded-[20px] border border-[#3183FF] flex items-center justify-center cursor-pointer"
              onClick={() =>
                setValue("period", {
                  startDate: moment().startOf("year").toDate(),
                  endDate: moment().endOf("year").toDate(),
                })
              }
            >
              <Text className="text-white font-sf-pro-bold">Bieżący rok</Text>
            </div>
          </div>
        </div>
        <div className="2xl:mt-8 xl:mt-6 mt-4 flex flex-col space-y-4">
          <AppCheckbox id="withDefaultCategories" register={register} label={t("Create-sample-budget-categories")} />
          <AppCheckbox id="importExistingInvoices" register={register} label={t("Import-existing-invoices")} />
        </div>
      </div>
    </Modal>
  );
};

export default BudgetAdd;
