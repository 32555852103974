import { boolean, date, object, string } from "yup";

export default object({
  name: string().required(),
  withDefaultCategories: boolean().required(),
  importExistingInvoices: boolean().required(),
  period: object({
    startDate: date().required(),
    endDate: date().required(),
  })
    .default(undefined)
    .required(),
}).required();
