import { useNavigate } from "react-router-dom";

import {
  CompanyCar,
  InvoiceTypes,
  FilterOperators,
  UpdateInvoiceInput,
  CreateInvoiceInput,
  GetInvoicesOptionsInput,
  UpdateInvoicesPaymentStatusDocumentsInput,
} from "src/api/graphql/types-and-hooks";
import {
  useInvoiceQuery,
  useInvoicesQuery,
  useCompanyCarsQuery,
  useCreateInvoiceMutation,
  useDeleteInvoiceMutation,
  useUpdateInvoiceMutation,
  useReadInvoiceDataLazyQuery,
  useCompanyOcrPagesLeftQuery,
  useCompanyAccountBalanceQuery,
  useUpdateInvoicesPaymentStatusMutation,
  useCheckLiquidityAganistFinancialMinimumLazyQuery,
} from "src/api/graphql/generated/gql-hooks";

import { useToastError, useToastSuccess } from "src/common/hooks/useToast";
import { getInvoiceDetailsPath, getInvoicesPath } from "src/common/router/routerPaths";

import { client } from "src/api/apollo/client";

import { addToDate, dateToTimestamp } from "src/utils/time";

export const useInvoices = (options: GetInvoicesOptionsInput) => {
  const { loading, data } = useInvoicesQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const invoices = data?.invoices?.documents || [];
  const total = data?.invoices?.total;

  return {
    loading,
    invoices,
    total,
  };
};

export const useInvoice = (invoiceId: string) => {
  const { loading, data } = useInvoiceQuery({
    variables: { invoiceId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const invoice = data?.invoice;

  return {
    loading,
    invoice,
  };
};

export const useDeleteInvoice = () => {
  const navigate = useNavigate();
  const [deleteInvoice, { loading }] = useDeleteInvoiceMutation({
    onCompleted: () => {
      useToastSuccess("Faktura została usunięta");
      navigate(getInvoicesPath());
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    isDeleting: loading,
    handleDeleteInvoice: async (id: string) => {
      await deleteInvoice({ variables: { deleteInvoiceInput: { id } } });
    },
  };
};

export const useCreateInvoice = () => {
  const navigate = useNavigate();
  const { checkLiquitiyAgainstFinancialMinimum } = useCheckLiqudityAgainstFinancialMinimums();
  const [handleCreateInvoice, { loading }] = useCreateInvoiceMutation({
    onCompleted: ({ createInvoice }) => {
      useToastSuccess("Dokument został utworzony");
      checkLiquitiyAgainstFinancialMinimum({
        gte: dateToTimestamp(createInvoice.paymentDue),
        lte: dateToTimestamp(addToDate(createInvoice.paymentDue, 3, "months")),
      });
      navigate(getInvoiceDetailsPath({ invoiceId: createInvoice.id }));
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    isCreating: loading,
    handleCreateInvoice: async (createInvoiceInput: CreateInvoiceInput) => {
      await handleCreateInvoice({ variables: { createInvoiceInput } });
    },
  };
};

export const useEditInvoice = (invoiceId: string) => {
  const navigate = useNavigate();
  const { checkLiquitiyAgainstFinancialMinimum } = useCheckLiqudityAgainstFinancialMinimums();
  const [editInvoice, { loading: isUpdating }] = useUpdateInvoiceMutation({
    onCompleted: ({ updateInvoice }) => {
      useToastSuccess("Zapisano");
      if (updateInvoice?.type === InvoiceTypes.Cost) {
        checkLiquitiyAgainstFinancialMinimum({
          gte: dateToTimestamp(updateInvoice.paymentDue),
          lte: dateToTimestamp(addToDate(updateInvoice.paymentDue, 3, "months")),
        });
      }
      navigate(getInvoiceDetailsPath({ invoiceId }));
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    handleEditInvoice: async (updateInvoiceInput: UpdateInvoiceInput) => {
      await editInvoice({
        variables: {
          updateInvoiceInput,
        },
      });
    },
    isUpdating,
  };
};

export const useReadDataFromInvoice = () => {
  const [readDataFromInvoice, { loading, data }] = useReadInvoiceDataLazyQuery({
    onError: () => useToastError("Wystąpił błąd"),
    onCompleted: () => client.refetchQueries({ include: ["CompanyOcrPagesLeft"] }), // refetch company ocr pages after reading invoice
    fetchPolicy: "cache-and-network",
  });
  const invoiceData = data?.readInvoiceData;

  return {
    handleReadDataFromInvoice: async (file: Blob) => {
      await readDataFromInvoice({ variables: { file } });
    },
    isReading: loading,
    invoiceData,
  };
};

export const useUpdateInvoicesPaymentStatus = () => {
  const [updateInvoicesPaymentStatus, { loading }] = useUpdateInvoicesPaymentStatusMutation({
    onCompleted: () => useToastSuccess(),
    onError: () => useToastError(),
    refetchQueries: ["Invoices"],
  });

  return {
    handleUpdateInvoicesPaymentStatus: async (documents: UpdateInvoicesPaymentStatusDocumentsInput[]) => {
      await updateInvoicesPaymentStatus({
        variables: {
          updateInvoicesPaymentsStatusInput: { documents },
        },
      });
    },
    loading,
  };
};

export const useCompanyCars = (companyId: string) => {
  const { loading, data } = useCompanyCarsQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const cars = (data?.company?.cars || []) as CompanyCar[];

  return {
    loading,
    cars: cars.filter((car) => !car.deletedAt),
  };
};

export const useCompanyAccountBalance = (companyId: string) => {
  const { loading, data } = useCompanyAccountBalanceQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const companyAccountBalance = data?.company;

  return {
    loading,
    companyAccountBalance,
  };
};

export const useCheckLiqudityAgainstFinancialMinimums = () => {
  const [checkLiquitiyAgainstFinancialMinimum] = useCheckLiquidityAganistFinancialMinimumLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  return {
    checkLiquitiyAgainstFinancialMinimum: (period: FilterOperators) => {
      checkLiquitiyAgainstFinancialMinimum({ variables: { options: { filter: { period } } } });
    },
  };
};

export const useCompanyOcrPagesLeft = (companyId: string) => {
  const { loading, data } = useCompanyOcrPagesLeftQuery({
    variables: { companyId },
    onError: () => useToastError(),
    fetchPolicy: "cache-and-network",
  });

  const ocrPagesLeft = data?.company?.ocrPagesLeft + data?.company?.additionalOcrPages;

  return {
    companyOcrPagesLeftLoading: loading,
    ocrPagesLeft,
  };
};
