import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { formatDate, getWeekStartEnd } from "src/utils/time";
import { BudgetCategory, BudgetCategoryTree } from "src/api/graphql/types-and-hooks";

import Chart, { ChartData } from "./Chart";
import Text from "src/components/ui/Text";

type Category = Pick<BudgetCategory, "_id" | "name">;
type Children = Pick<BudgetCategoryTree, "value" | "months"> & { category: Category };
type CategoryTree = Pick<BudgetCategoryTree, "value" | "months"> & { category: Category; children: Children[] };

type Props = {
  budgetName: string;
  budgetDateTo: string;
  budgetDateFrom: string;
  costsCategoriesTree: Partial<CategoryTree>[];
  incomesCategoriesTree: Partial<CategoryTree>[];
};

const ChartTab: React.FC<Props> = ({
  budgetName,
  budgetDateTo,
  budgetDateFrom,
  costsCategoriesTree,
  incomesCategoriesTree,
}) => {
  const [chartWeeksData, setChartWeeksData] = useState<ChartData[]>([]);
  const [chartMonthsData, setChartMonthsData] = useState<ChartData[]>([]);
  const [activeView, setActiveView] = useState<"week" | "month">("month");

  const { t } = useTranslation();

  useEffect(() => {
    getChartMonthsData();
    getChartWeeksData();
  }, [costsCategoriesTree, incomesCategoriesTree]);

  const getChartMonthsData = () => {
    const chartData: ChartData[] = [];
    const todayMonth = moment().month();

    costsCategoriesTree.forEach((category) => {
      category.months.forEach((month, index) => {
        const dataIndex = chartData.findIndex((data) => month.month === data.month);
        if (dataIndex < 0) {
          const momentDate = moment(month.month);
          chartData[index] = {
            name: `${t(momentDate.format("MMM"))} ${momentDate.format("YY")}`,
            costs: 0,
            incomes: 0,
            month: month.month,
            isPrognosis: todayMonth < momentDate.month(),
          };
        }

        chartData[index].costs += month.value;
      });
    });

    incomesCategoriesTree.forEach((category) => {
      category.months.forEach((month, index) => {
        const dataIndex = chartData.findIndex((data) => month.month === data.month);
        if (dataIndex < 0) {
          const momentDate = moment(month.month);
          chartData[index] = {
            name: `${t(momentDate.format("MMM"))} ${momentDate.format("YY")}`,
            costs: 0,
            incomes: 0,
            month: month.month,
            isPrognosis: todayMonth < momentDate.month(),
          };
        }

        chartData[index].incomes += month.value;
      });
    });

    setChartMonthsData(chartData);
  };

  const getChartWeeksData = () => {
    const chartData: ChartData[] = [];
    const todayWeek = moment().week();

    // Iterate over the costs categories
    costsCategoriesTree.forEach((category) => {
      category.months.forEach((month) => {
        month.weeks.forEach((week) => {
          const weekStartEnd = getWeekStartEnd(month.month, week.week);
          const dataIndex = chartData.findIndex((data) => data.name === weekStartEnd);
          if (dataIndex < 0) {
            chartData.push({
              name: weekStartEnd,
              costs: week.value,
              incomes: 0,
              month: month.month,
              isPrognosis: week.week > todayWeek,
            });
          } else {
            chartData[dataIndex].costs += week.value;
          }
        });
      });
    });

    // Iterate over the incomes categories
    incomesCategoriesTree.forEach((category) => {
      category.months.forEach((month) => {
        month.weeks.forEach((week) => {
          const weekStartEnd = getWeekStartEnd(month.month, week.week);
          const dataIndex = chartData.findIndex((data) => data.name === weekStartEnd);

          if (dataIndex < 0) {
            chartData.push({
              name: weekStartEnd,
              costs: 0,
              incomes: week.value,
              month: month.month,
              isPrognosis: week.week > todayWeek,
            });
          } else {
            chartData[dataIndex].incomes += week.value;
          }
        });
      });
    });

    setChartWeeksData(chartData);
  };

  return (
    <>
      <div className="flex flex-row border-b pb-2 justify-between">
        <div className="flex flex-row space-x-12">
          <Text className="2xl:text-20 xl:text-18 text-16">{budgetName}</Text>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{`${formatDate(
            budgetDateFrom,
            "DD.MM.YYYY",
          )} - ${formatDate(budgetDateTo, "DD.MM.YYYY")}`}</Text>
        </div>
      </div>
      <div className="2xl:mt-6 mt-4 p-5 flex flex-col h-full bg-white rounded-[20px] overflow-y-auto">
        <div className="flex flex-row justify-between items-center">
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">Bilans przychodów i kosztów</Text>
          <div className="flex flex-row space-x-4">
            <AppButton
              title={t("Week")}
              color={activeView === "week" ? ButtonColorTypes.PRIMARY : ButtonColorTypes.SECONDARY}
              customClass={`rounded-[8px] ${activeView !== "week" && "border border-[#F6F7FB]"}`}
              textClass={`${activeView !== "week" ? "text-text-secondary" : "text-white"}`}
              onClick={() => setActiveView("week")}
            />
            <AppButton
              title={t("Month")}
              color={activeView === "month" ? ButtonColorTypes.PRIMARY : ButtonColorTypes.SECONDARY}
              customClass={`rounded-[8px] ${activeView !== "month" && "border border-[#F6F7FB]"}`}
              textClass={`${activeView !== "month" ? "text-text-secondary" : "text-white"}`}
              onClick={() => setActiveView("month")}
            />
          </div>
        </div>
        <div className="2xl:h-[450px] xl:h-[400px] h-[350px] w-full 2xl:mt-14 xl:mt-12 mt-10">
          <Chart data={activeView === "month" ? chartMonthsData : chartWeeksData} />
        </div>
      </div>
    </>
  );
};

export default ChartTab;
