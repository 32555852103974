import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import TableRow from "src/components/ui/TableRow";

import { formatPrice } from "src/utils/currency";
import { BudgetDocument, InvoiceCompany, InvoiceTypes } from "src/api/graphql/types-and-hooks";

import CostIcon from "src/assets/images/unpaid.svg";
import IncomeIcon from "src/assets/images/paid.svg";

type Company = Pick<InvoiceCompany, "name">;

type Document = Pick<
  BudgetDocument,
  "_id" | "invoiceNumber" | "paymentDue" | "subtotal" | "total" | "paymentStatus" | "type" | "genre"
> & { billTo?: Company; billFrom?: Company };

type Props = {
  data: Document;
  onClick?: () => void;
};

const AddDocumentsToGroupTableRow: React.FC<Props> = ({
  data: { invoiceNumber, billTo, billFrom, paymentDue, subtotal, total, type, genre },
  onClick,
}) => {
  const { t } = useTranslation();
  let name = type === InvoiceTypes.Income ? billTo?.name : billFrom?.name || "-";
  if (name.length > 30) {
    name = `${name.substring(0, 30)}...`;
  }

  return (
    <TableRow onClick={onClick}>
      <td>
        <div className="ml-1 flex flex-row items-center space-x-4">
          <img src={type === InvoiceTypes.Cost ? CostIcon : IncomeIcon} />
          <div className="ml-3 flex flex-col">
            <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{invoiceNumber}</Text>
            <Text className="2xl:text-12 xl:text-10 text-8 text-text-secondary">{t(genre)}</Text>
          </div>
        </div>
      </td>
      <td>
        <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{name}</Text>
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">
          {paymentDue ? moment(paymentDue).format("DD-MM-YYYY") : "-"}
        </Text>
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">{formatPrice(subtotal, "zł")}</Text>
      </td>
      <td>
        <Text className="font-sf-pro-medium text-text-secondary">{formatPrice(total, "zł")}</Text>
      </td>
    </TableRow>
  );
};

export default AddDocumentsToGroupTableRow;
