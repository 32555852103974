import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { formatDate } from "src/utils/time";

import { InvoiceTypes, PaymentStatus } from "src/api/graphql/types-and-hooks";

import CostIcon from "src/assets/images/unpaid.svg";
import IncomeIcon from "src/assets/images/paid.svg";

import Text from "./Text";
import { customTwMerge } from "src/utils/twMerge";

type Props = {
  document: any;
};

const WeeklyEvent: React.FC<Props> = ({ document: { type, paymentDue, paymentStatus, invoiceNumber, billTo } }) => {
  const { t } = useTranslation();
  const isOverdue = paymentDue && moment(paymentDue).isBefore(moment(), "day");
  const combinedPaymentClasses = customTwMerge(
    "2xl:text-14 xl:text-12 text-8 text-text-secondary",
    isOverdue && "text-red",
    paymentStatus === PaymentStatus.Paid && "text-green",
  );

  const getBgColor = () => {
    if (isOverdue && paymentStatus !== PaymentStatus.Paid) return "bg-light-red";
    if (paymentStatus === PaymentStatus.Paid) return "bg-light-green";

    return "bg-[#9A9EA90F]";
  };

  return (
    <div className={`flex flex-col justify-between space-y-2 rounded-[12px] m-3 xl:p-3 p-2 ${getBgColor()}`}>
      <div className="flex flex-col">
        <Text className="2xl:text-16 xl:text-14 text-10 truncate">{invoiceNumber}</Text>
        <Text className="2xl:text-14 xl:text-12 text-8 text-text-secondary">
          {formatDate(paymentDue, "DD.MM.YYYY")}
        </Text>
      </div>
      <Text className="2xl:text-14 xl:text-12 text-8 text-text-secondary truncate">{billTo?.name || "-"}</Text>
      <div className="flex flex-row space-x-2 items-center">
        {<img className="xl:w-7 xl:h-7 w-4 h-4" src={type === InvoiceTypes.Cost ? CostIcon : IncomeIcon} />}
        <Text className={combinedPaymentClasses}>{t(paymentStatus === PaymentStatus.Paid ? "Paid" : "Unpaid")}</Text>
      </div>
    </div>
  );
};

export default WeeklyEvent;
