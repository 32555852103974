import React from "react";
import Text from "../ui/Text";

type Props = {
  label: string;
  register: any;
  id: string;
  checked?: boolean;
};

const AppCheckbox: React.FC<Props> = ({ label, register, id, checked = false }) => {
  return (
    <>
      <div className="flex flex-row space-x-4">
        <input
          {...register(id)}
          id={id}
          className="form-checkbox 2xl:w-[18px] xl:w-[16px] w-[14px] 2xl:h-[18px] xl:h-[16px] h-[14px] rounded-[4px] cursor-pointer checked:bg-blue ring-0 focus:ring-0 border border-[#DFE5F1]"
          type="checkbox"
          defaultChecked={checked}
        />
        <Text className="text-text-secondary">{label}</Text>
      </div>
    </>
  );
};

export default AppCheckbox;
