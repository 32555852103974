import moment from "moment";

import { InvoiceTypes, InvoicesListDocument, PaymentStatus } from "src/api/graphql/types-and-hooks";

export const useIncomesAndCostsStatistic = () => {
  const getStatisticData = async (documents: InvoicesListDocument[]) => {
    const statisticData = documents.reduce(
      (acc, document) => {
        const isOverdue = document.paymentDue && moment(document.paymentDue).isBefore(moment(), "day");

        if (document.type === InvoiceTypes.Income) {
          acc.incomes += document.total;

          if (isOverdue && document.paymentStatus !== PaymentStatus.Paid) {
            acc.incomesOverdue += 1;
          } else if (document.paymentStatus === PaymentStatus.Paid) {
            acc.incomesPaid += 1;
          } else {
            acc.incomesToPaid += 1;
          }
        } else {
          acc.costs += document.total;

          if (isOverdue && document.paymentStatus !== PaymentStatus.Paid) {
            acc.costsOverdue += 1;
          } else if (document.paymentStatus === PaymentStatus.Paid) {
            acc.costsPaid += 1;
          } else {
            acc.costsToPaid += 1;
          }
        }
        acc.total += document.total;
        return acc;
      },
      {
        incomes: 0,
        costs: 0,
        total: 0,
        costsPaid: 0,
        incomesPaid: 0,
        costsToPaid: 0,
        incomesToPaid: 0,
        costsOverdue: 0,
        incomesOverdue: 0,
      },
    );
    return statisticData;
  };

  return { getStatisticData };
};
