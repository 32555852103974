import React from "react";
import Text from "./Text";

type Props = {
  children: JSX.Element;
};

const FiltersWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className="h-full bg-white rounded-[20px] xl:pl-7 pl-3 xl:pt-6 pt-3">
      <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">Filtry</Text>
      {children}
    </div>
  );
};

export default FiltersWrapper;
