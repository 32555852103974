import React, { useRef } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useToastError } from "src/common/hooks/useToast";

import PlusWhiteIcon from "src/assets/images/plus-white.svg";
import { useEditBudgetCategory } from "../budget.hooks";

type Props = {
  categoryName: string;
  categoryId: string;
  onEditCategory: () => void;
};

const EditCategory: React.FC<Props> = ({ categoryId, categoryName, onEditCategory }) => {
  const inputRef = useRef<HTMLInputElement>();

  const { t } = useTranslation();
  const { loading, handleEditBudgetCategory } = useEditBudgetCategory();

  const onEdit = async () => {
    if (isEmpty(inputRef.current?.value)) {
      useToastError(t("Enter-group-name"));
    } else {
      await handleEditBudgetCategory({ name: inputRef.current?.value, id: categoryId });
      onEditCategory();
    }
  };

  return (
    <div className="w-[90%] py-4 rounded-[16px] border border-dashed border-blue flex flex-col items-center justify-center space-y-2 cursor-pointer">
      <input
        ref={inputRef}
        type="text"
        className="2xl:text-16 xl:text-14 text-12 border border-[#DFE5F1] w-[80%] 2xl:h-11 xl:h-10 h-9 rounded-[7px] pl-2 focus:border-[#233145] focus:outline-none"
        placeholder="Wpisz nazwę"
        defaultValue={categoryName}
      />
      <div className="w-[70%]">
        <AppButton
          title={t("Save")}
          color={ButtonColorTypes.PRIMARY}
          icon={PlusWhiteIcon}
          customClass="rounded-[12px]"
          textClass="font-sf-pro-medium text-white"
          isLoading={loading}
          onClick={onEdit}
        />
      </div>
    </div>
  );
};

export default EditCategory;
