import currency from "currency.js";

export const formatPrice = (value: number, currencySymbol: string) => {
  {
    return currency(value, {
      pattern: "# !",
      negativePattern: "-# !",
      symbol: currencySymbol,
      separator: " ",
      decimal: ",",
    }).format();
  }
};

export const formatNumber = (value: number) => {
  return currency(value).value;
};

export const addPrices = (values: number[]) => {
  const [first, ...rest] = values;
  return rest.reduce((acc, curr) => acc.add(currency(curr)), currency(first)).value;
};

export const subtractPrices = (values: number[]) => {
  const [first, ...rest] = values;
  return rest.reduce((acc, curr) => acc.subtract(currency(curr)), currency(first)).value;
};

export const multiplyPrices = (values: number[]) => {
  const [first, ...rest] = values;
  return rest.reduce((acc, curr) => acc.multiply(currency(curr)), currency(first)).value;
};

export const dividePrices = (values: number[]) => {
  const [first, ...rest] = values;
  return rest.reduce((acc, curr) => acc.divide(currency(curr)), currency(first)).value;
};
