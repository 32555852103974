import React, { useState } from "react";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import Text from "src/components/ui/Text";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AuthWrapper from "src/components/ui/AuthWrapper";

import { getLoginPath } from "src/common/router/routerPaths";

import EyeIcon from "src/assets/images/eye.svg";
import RegisterStep1Image from "src/assets/images/register-step-1.png";
import RegisterStep2Image from "src/assets/images/register-step-2.png";

import { useRegister } from "./register.hooks";
import registerUserSchema from "./registerUser.schema";
import registerCompanySchema from "./registerCompany.schema";

const Register = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const { handleRegister, loading, errors } = useRegister((changedStep: number) => setStep(changedStep));
  const {
    register: registerUserForm,
    handleSubmit: handleUserFormSubmit,
    getValues: getUserFormValues,
    formState: { errors: userFormErrors },
  } = useForm({
    resolver: yupResolver(registerUserSchema),
    reValidateMode: "onChange",
  });
  const {
    register: registerCompanyForm,
    handleSubmit: handleCompanyFormSubmit,
    getValues: getCompanyFormValues,
    formState: { errors: companyFormErrors },
  } = useForm({
    resolver: yupResolver(registerCompanySchema),
    reValidateMode: "onChange",
  });

  const parseData = () => {
    handleRegister({
      email: getUserFormValues("email"),
      firstName: getUserFormValues("firstName"),
      lastName: getUserFormValues("lastName"),
      phone: getUserFormValues("phone"),
      password: getUserFormValues("password"),
      passwordConfirmation: getUserFormValues("passwordConfirmation"),
      companyName: getCompanyFormValues("companyName"),
      companyId: getCompanyFormValues("companyId"),
      companyEmail: getCompanyFormValues("companyEmail"),
      companyPhone: getCompanyFormValues("companyPhone"),
      companyPostalCode: getCompanyFormValues("companyPostalCode"),
      companyCity: getCompanyFormValues("companyCity"),
      companyAddress: getCompanyFormValues("companyAddress"),
    });
  };

  const renderUserForm = () => {
    return (
      <>
        <img src={RegisterStep1Image} className="max-w-[60%]" />
        <div className="mt-5 flex flex-col space-y-4 max-w-[85%] sm:max-w-[50%] w-full">
          <AppInput
            id="email"
            inputType={InputTypes.TEXT}
            placeholder="E-mail"
            register={registerUserForm}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !userFormErrors?.email && !get(errors, "email") && "border-white/20 focus:border-white/60"
            }`}
            error={!!userFormErrors?.email || !!get(errors, "email")}
            errorMessage={get(errors, "email")}
          />
          <div className="flex flex-row space-x-4">
            <div className="basis-1/2">
              <AppInput
                id="firstName"
                inputType={InputTypes.TEXT}
                placeholder="Imię"
                register={registerUserForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !userFormErrors?.firstName && "border-white/20 focus:border-white/60"
                }`}
                error={!!userFormErrors?.firstName}
              />
            </div>
            <div className="basis-1/2">
              <AppInput
                id="lastName"
                inputType={InputTypes.TEXT}
                placeholder="Nazwisko"
                register={registerUserForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !userFormErrors?.lastName && "border-white/20 focus:border-white/60"
                }`}
                error={!!userFormErrors?.lastName}
              />
            </div>
          </div>
          <AppInput
            id="phone"
            inputType={InputTypes.TEXT}
            placeholder="Numer telefonu"
            register={registerUserForm}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !userFormErrors?.phone && "border-white/20 focus:border-white/60"
            }`}
            error={!!userFormErrors?.phone}
          />
          <div className="flex flex-row space-x-4">
            <div className="basis-1/2">
              <AppInput
                iconEnd={EyeIcon}
                id="password"
                inputType={InputTypes.PASSWORD}
                placeholder="Hasło"
                register={registerUserForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !userFormErrors?.password && "border-white/20 focus:border-white/60"
                }`}
                error={!!userFormErrors?.password}
                errorMessage={userFormErrors?.password?.message}
              />
            </div>
            <div className="basis-1/2">
              <AppInput
                iconEnd={EyeIcon}
                id="passwordConfirmation"
                inputType={InputTypes.PASSWORD}
                placeholder="Potwórz hasło"
                register={registerUserForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !userFormErrors?.passwordConfirmation && "border-white/20 focus:border-white/60"
                }`}
                error={!!userFormErrors?.passwordConfirmation}
                errorMessage={userFormErrors?.passwordConfirmation?.message}
              />
            </div>
          </div>
        </div>
        <div className="w-full max-w-[85%] sm:max-w-[50%] mt-12">
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
            textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
            onClick={handleUserFormSubmit(() => setStep(2))}
            title="Dalej"
          />
        </div>
      </>
    );
  };

  const renderCompanyForm = () => {
    return (
      <>
        <img src={RegisterStep2Image} className="max-w-[60%]" />
        <div className="flex flex-col space-y-4 max-w-[85%] sm:max-w-[50%] w-full">
          <AppInput
            id="companyName"
            inputType={InputTypes.TEXT}
            placeholder="Nazwa firmy"
            register={registerCompanyForm}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !companyFormErrors?.companyName && "border-white/20 focus:border-white/60"
            }`}
            error={!!companyFormErrors?.companyName}
          />
          <AppInput
            id="companyId"
            inputType={InputTypes.TEXT}
            placeholder="NIP"
            register={registerCompanyForm}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !companyFormErrors?.companyId && !get(errors, "companyId") && "border-white/20 focus:border-white/60"
            }`}
            error={!!companyFormErrors?.companyId || !!get(errors, "companyId")}
            errorMessage={get(errors, "companyId")}
          />
          <div className="flex flex-row space-x-4">
            <div className="basis-1/2">
              <AppInput
                id="companyEmail"
                inputType={InputTypes.TEXT}
                placeholder="E-mail"
                register={registerCompanyForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !companyFormErrors?.companyEmail &&
                  !get(errors, "companyEmail") &&
                  "border-white/20 focus:border-white/60"
                }`}
                error={!!companyFormErrors?.companyEmail || !!get(errors, "companyEmail")}
                errorMessage={get(errors, "companyEmail")}
              />
            </div>
            <div className="basis-1/2">
              <AppInput
                id="companyPhone"
                inputType={InputTypes.TEXT}
                placeholder="Numer telefonu"
                register={registerCompanyForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !companyFormErrors?.companyPhone && "border-white/20 focus:border-white/60"
                }`}
                error={!!companyFormErrors?.companyPhone}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="basis-1/3">
              <AppInput
                id="companyPostalCode"
                inputType={InputTypes.TEXT}
                placeholder="Kod pocztowy"
                register={registerCompanyForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !companyFormErrors?.companyPostalCode && "border-white/20 focus:border-white/60"
                }`}
                error={!!companyFormErrors?.companyPostalCode}
              />
            </div>
            <div className="basis-2/3">
              <AppInput
                id="companyCity"
                inputType={InputTypes.TEXT}
                placeholder="Miasto"
                register={registerCompanyForm}
                inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
                  !companyFormErrors?.companyCity && "border-white/20 focus:border-white/60"
                }`}
                error={!!companyFormErrors?.companyCity}
              />
            </div>
          </div>
          <AppInput
            id="companyAddress"
            inputType={InputTypes.TEXT}
            placeholder="Ulica i numer"
            register={registerCompanyForm}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !companyFormErrors?.companyAddress && "border-white/20 focus:border-white/60"
            }`}
            error={!!companyFormErrors?.companyAddress}
          />
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
            textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
            onClick={handleCompanyFormSubmit(parseData)}
            title="Zarejestruj się"
            isLoading={loading}
          />
        </div>
      </>
    );
  };

  return (
    <AuthWrapper>
      <div className="flex flex-col mt-8 space-y-6 items-center w-full">
        <div className="flex flex-col space-y-2 items-center">
          <Text className="text-white 2xl:text-28 xl:text-24 text-20">Witaj</Text>
          <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">
            Załóż konto i korzystaj z potencjału swojej firmy
          </Text>
        </div>
        {step === 1 && renderUserForm()}
        {step === 2 && renderCompanyForm()}
        <Text
          className="text-text-secondary text-center cursor-pointer text-12 2xl:text-16 xl:text-14 sm:text-10"
          onClick={() => navigate(getLoginPath())}
        >
          Masz już konto? &nbsp;
          <Text className="font-sf-pro-medium text-white text-12 2xl:text-16 xl:text-14 sm:text-10">Zaloguj się</Text>
        </Text>
      </div>
    </AuthWrapper>
  );
};

export default Register;
