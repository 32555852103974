import React, { useState } from "react";
import { UseFormRegister } from "react-hook-form";

import { customTwMerge } from "src/utils/twMerge";

import ErrorIcon from "src/assets/images/error.svg";

import Text from "../ui/Text";

export enum InputTypes {
  TEXT = "text",
  EMAIL = "email",
  NUMBER = "number",
  PASSWORD = "password",
}

type Props = {
  id: string;
  inputType: InputTypes;
  register: UseFormRegister<any>;
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  iconEnd?: string;
  inputClasses?: string;
};

const AppInput: React.FC<Props> = ({
  id,
  inputType,
  register,
  error,
  errorMessage,
  placeholder,
  disabled,
  iconEnd,
  inputClasses,
}) => {
  const [type, setType] = useState(inputType);
  const combinedClasses = customTwMerge(
    "w-full 2xl:h-10 xl:h-9 h-7 rounded-[10px] bg-transparent font-sf-pro-regular pl-3 2xl:text-16 xl:text-14 text-10 border focus:border-[#233145] focus:outline-none placeholder-text-secondary",
    inputClasses,
    disabled && "opacity-50",
    error && "border-state-error focus:border-state-error",
  );

  const showValue = () => {
    if (type === InputTypes.PASSWORD) {
      setType(InputTypes.TEXT);
    } else {
      setType(InputTypes.PASSWORD);
    }
  };

  return (
    <>
      <div className="relative">
        <input
          {...register(id)}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          className={combinedClasses}
        />
        {iconEnd && !error ? (
          <div className="absolute inset-y-0 right-5 flex items-center cursor-pointer">
            <img
              onClick={showValue}
              role="presentation"
              src={iconEnd}
              className="2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4"
            />
          </div>
        ) : undefined}
        {error ? (
          <div className="absolute inset-y-0 right-5 flex items-center">
            <img
              onClick={showValue}
              role="presentation"
              src={ErrorIcon}
              className="2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4"
            />
          </div>
        ) : undefined}
      </div>
      {errorMessage ? <Text className="text-state-error">{errorMessage}</Text> : undefined}
    </>
  );
};

export default AppInput;
