import React from "react";
import Datepicker, { DateRangeType } from "react-tailwindcss-datepicker";

import { customTwMerge } from "src/utils/twMerge";

import Text from "../ui/Text";

type Props = {
  field: any;
  range?: boolean;
  error?: boolean;
  errorMessage?: string;
  maxDate?: Date;
  minDate?: Date;
  asSingle?: boolean;
  placeholder?: string;
  inputClasses?: string;
  containerClasses?: string;
  disabled?: boolean;
};

const AppDatePicker: React.FC<Props> = ({
  field,
  error,
  errorMessage,
  range = false,
  minDate,
  maxDate,
  asSingle = true,
  placeholder,
  inputClasses,
  containerClasses,
  disabled = false,
}) => {
  const value = {
    startDate: asSingle ? field.value : field.value?.startDate,
    endDate: asSingle ? field.value : field.value?.endDate,
  };
  const combinedContainerClasses = customTwMerge(
    "border border-bg-light-gray3 h-full w-full rounded-[10px] relative flex justify-space-between hover:border-[#233145]",
    containerClasses,
    error && "border-state-error focus:border-state-error",
    disabled && "opacity-50",
  );
  const combinedInpuptClasses = customTwMerge(
    "bg-transparent 2xl:text-16 xl:text-14 text-10 font-sf-pro-regular pl-2 text-left w-full focus:ring-0 focus:outline-none focus:border-none",
    inputClasses,
  );

  return (
    <div className="2xl:h-10 xl:h-9 h-7">
      <Datepicker
        useRange={range}
        value={value}
        onChange={(date: DateRangeType) => field.onChange(asSingle ? date?.startDate : date)}
        primaryColor={"sky"}
        placeholder={placeholder}
        asSingle={asSingle}
        containerClassName={combinedContainerClasses}
        inputClassName={combinedInpuptClasses}
        startWeekOn="mon"
        i18n={"pl"}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />
      {errorMessage && <Text className="text-state-error 2xl:text-14 xl:text-12 text-10">{errorMessage}</Text>}
    </div>
  );
};

export default AppDatePicker;
