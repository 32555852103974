import { useNavigate } from "react-router-dom";

import { getBudgetDetailsPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

import {
  CreateBudgetInput,
  GetBudgetsOptionsInput,
  AddSingleTransactionInput,
  DeleteBudgetCategoryInput,
  CreateBudgetCategoryInput,
  UpdateBudgetCategoryInput,
  AddDocumentsToCategoryInput,
  UpdateCategoryTreeValueInput,
  GetBudgetCategoriesOptionsInput,
} from "src/api/graphql/types-and-hooks";
import {
  useBudgetQuery,
  useBudgetsQuery,
  useCreateBudgetMutation,
  useDeleteBudgetMutation,
  useBudgetCategoriesQuery,
  useCreateBudgetCategoryMutation,
  useDeleteBudgetCategoryMutation,
  useUpdateBudgetCategoryMutation,
  useAddDocumentsToCategoryMutation,
  useUpdateCategoryTreeValueMutation,
  useAddSingleBudgetTransactionMutation,
} from "src/api/graphql/generated/gql-hooks";

export const useBudgets = (options: GetBudgetsOptionsInput) => {
  const { loading, data } = useBudgetsQuery({
    variables: { options },
    onError: () => useToastError(),
    fetchPolicy: "cache-and-network",
  });
  const budgets = data?.budgets?.documents || [];

  return {
    loading,
    budgets: [...budgets].sort((a, b) => Number(b.isDefault) - Number(a.isDefault)),
  };
};

export const useBudget = (budgetId: string) => {
  const { loading, data } = useBudgetQuery({
    variables: { budgetId },
    onError: () => useToastError(),
    fetchPolicy: "no-cache",
  });
  const budget = data?.budget;

  return {
    loading,
    budget,
  };
};

export const useCreateBudget = () => {
  const navigate = useNavigate();
  const [handleCreateBudget, { loading }] = useCreateBudgetMutation({
    onCompleted: (data) => {
      useToastSuccess();
      navigate(getBudgetDetailsPath({ budgetId: data.createBudget.id }));
    },
    onError: () => useToastError(),
  });

  return {
    isCreating: loading,
    handleCreateBudget: async (createBudgetInput: CreateBudgetInput) => {
      await handleCreateBudget({ variables: { createBudgetInput } });
    },
  };
};

export const useDeleteBudget = () => {
  const [deleteBudget, { loading }] = useDeleteBudgetMutation({
    onCompleted: () => {
      useToastSuccess("Usunięto");
    },
    refetchQueries: ["Budgets"],
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    isDeleting: loading,
    handleDeleteBudget: async (id: string) => {
      await deleteBudget({ variables: { deleteBudgetInput: { id } } });
    },
  };
};

export const useDeleteBudgetCategory = () => {
  const [deleteBudgetCategory, { loading }] = useDeleteBudgetCategoryMutation({
    onCompleted: () => {
      useToastSuccess("Usunięto");
    },
    refetchQueries: ["Budget"],
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    isDeleting: loading,
    handleDeleteBudgetCategory: async (deleteBudgetCategoryInput: DeleteBudgetCategoryInput) => {
      await deleteBudgetCategory({ variables: { deleteBudgetCategoryInput } });
    },
  };
};

export const useBudgetCategories = (options: GetBudgetCategoriesOptionsInput) => {
  const { loading, data } = useBudgetCategoriesQuery({
    variables: { options },
    onError: () => useToastError(),
    fetchPolicy: "no-cache",
  });
  const categories = data?.budgetCategories?.documents || [];

  return {
    loading,
    categories,
  };
};

export const useAddDocumentsToCategory = () => {
  const [addDocumentsToCategory, { loading }] = useAddDocumentsToCategoryMutation({
    onCompleted: () => {
      useToastSuccess();
    },
    refetchQueries: ["BudgetDocuments", "Budget"],
    onError: () => useToastError(),
  });

  return {
    loading,
    handleAddDocumentsToCategory: async (addDocumentsToCategoryInput: AddDocumentsToCategoryInput) => {
      await addDocumentsToCategory({
        variables: { addDocumentsToCategoryInput },
      });
    },
  };
};

export const useCreateBudgetCategory = () => {
  const [createBudgetCategory, { loading }] = useCreateBudgetCategoryMutation({
    onCompleted: () => {
      useToastSuccess();
    },
    refetchQueries: ["Budget"],
    onError: () => useToastError(),
  });

  return {
    loading,
    handleCreateBudgetCategory: async (createBudgetCategoryInput: CreateBudgetCategoryInput) => {
      await createBudgetCategory({
        variables: { createBudgetCategoryInput },
      });
    },
  };
};

export const useEditBudgetCategory = () => {
  const [editBudgetCategory, { loading }] = useUpdateBudgetCategoryMutation({
    onCompleted: () => {
      useToastSuccess();
    },
    refetchQueries: ["Budget"],
    onError: () => useToastError(),
  });

  return {
    loading,
    handleEditBudgetCategory: async (updateBudgetCategoryInput: UpdateBudgetCategoryInput) => {
      await editBudgetCategory({
        variables: { updateBudgetCategoryInput },
      });
    },
  };
};

export const useEditBudgetCategoryTreeValue = () => {
  const [editCategoryTreeValue, { loading }] = useUpdateCategoryTreeValueMutation({
    onCompleted: () => {
      useToastSuccess();
    },
    refetchQueries: ["Budget"],
    onError: () => useToastError(),
  });

  return {
    loading,
    handleEditCategoryTreeValue: async (updateCategoryTreeValueInput: UpdateCategoryTreeValueInput) => {
      await editCategoryTreeValue({
        variables: { updateCategoryTreeValueInput },
      });
    },
  };
};

export const useAddSingleBudgetTransaction = () => {
  const [addSingleBudgetTransaction, { loading, data }] = useAddSingleBudgetTransactionMutation({
    onCompleted: () => {
      useToastSuccess();
    },
    refetchQueries: ["Budget"],
    onError: () => useToastError(),
  });

  return {
    loading,
    isAdded: !!data,
    addSingleBudgetTransaction: async (addSingleTransactionInput: AddSingleTransactionInput) => {
      await addSingleBudgetTransaction({
        variables: { addSingleTransactionInput },
      });
    },
  };
};
