import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Text from "src/components/ui/Text";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AuthWrapper from "src/components/ui/AuthWrapper";

import RegisterStep3Image from "src/assets/images/register-step-3.png";

import { useVerifyAccount } from "./verifyAccount.hooks";
import verifyAccountSchema from "./verifyAccount.schema";

const VerifyAccount = () => {
  const { handleVerifyAccount, loading } = useVerifyAccount();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(verifyAccountSchema),
    reValidateMode: "onChange",
  });

  return (
    <AuthWrapper>
      <div className="flex flex-col items-center mt-12 space-y-4">
        <Text className="2xl:text-28 xl:text-24 text-20 text-white font-sf-pro-medium">Dobrze Cię widzieć!</Text>
        <Text className="2xl:text-20 xl:text-18 text-14 text-text-secondary max-w-[80%] sm:max-w-[50%] w-full text-center">
          Wysłaliśmy do Ciebie wiadomość. Zanim przejdziesz dalej, zweryfikuj swoje konto wpisując kod aktywacyjny
          poniżej.
        </Text>
        <img src={RegisterStep3Image} className="max-w-[60%]" />
        <div className="max-w-[75%] sm:max-w-[50%] w-full">
          <AppInput
            id="code"
            inputType={InputTypes.TEXT}
            placeholder="Kod aktywacyjny"
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !errors?.code && "border-white/20 focus:border-white/60"
            }`}
            error={!!errors?.code}
          />
        </div>
        <div className="w-full max-w-[75%] sm:max-w-[50%]">
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
            textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
            onClick={handleSubmit(handleVerifyAccount)}
            title="Zweryfikuj konto"
            isLoading={loading}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default VerifyAccount;
