import React, { useEffect, useState } from "react";
import { times } from "lodash";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

import ChevronLeftIcon from "src/assets/images/chevron-left.svg";
import ChevronRightIcon from "src/assets/images/chevron-right.svg";

import AppButton, { ButtonColorTypes } from "../form/AppButton";

import Text from "./Text";

const DAYS = ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Ndz"];

type Props = {
  onDateChange: (date: { gte: number; lte: number }) => void;
};

const Calendar: React.FC<Props> = ({ onDateChange }) => {
  const { t } = useTranslation();

  const [momentDate, setMomentDate] = useState(moment());
  const [selectedDay, setSelectedDay] = useState<Moment>(moment());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [firstMonthDay, setFirstMonthDay] = useState<number>();

  useEffect(() => {
    onDateChange({
      gte: selectedDay.clone().startOf("day").unix(),
      lte: selectedDay.clone().endOf("day").unix(),
    });
  }, [selectedDay]);

  useEffect(() => {
    setFirstMonthDay(momentDate.clone().startOf("month").weekday());

    const monthDate = momentDate.clone().startOf("month");
    const monthDays = [];
    times(monthDate.daysInMonth(), () => {
      monthDays.push(monthDate.format("DD"));
      monthDate.add(1, "day");
    });
    setDaysInMonth(monthDays);
  }, [momentDate]);

  const renderDays = () => {
    return Array.from(Array(42).keys()).map((day, i) => {
      const isSelected =
        selectedDay.clone().date() === i + 1 - firstMonthDay + 1 && momentDate.clone().month() === selectedDay.month();
      if (i + 1 >= firstMonthDay && i + 1 < daysInMonth?.length + firstMonthDay) {
        return (
          <div
            key={day}
            className="flex justify-center items-center 2xl:w-[36px] xl:w[32px] w-[28px] 2xl:h-[38px] xl:h-[34px] h-[30px] cursor-pointer"
            onClick={() => setSelectedDay(momentDate.clone().date(i + 1 - firstMonthDay + 1))}
          >
            <div
              className={`${
                isSelected &&
                "bg-blue rounded-[16px] 2xl:w-[30px] xl:w-[24px] 2xl:h-[30px] xl:h-[24px] h-[20px] w-[20px] items-center flex justify-center mt-1"
              }`}
            >
              <Text className={`2xl:text-14 xl:text-12 text-10 ${isSelected ? "text-white" : "text-black"}`}>
                {daysInMonth[day + 1 - firstMonthDay]}
              </Text>
            </div>
          </div>
        );
      }
      if (i + 1 <= firstMonthDay) {
        return <div key={day} className="w-8 h-9"></div>;
      }
      return undefined;
    });
  };

  return (
    <div className="border border-[#DFE5F1] rounded-[16px] bg-white">
      <div className="border-b border-[Black/10%]">
        <div className="flex flex-row space-x-2 mx-4 xl:my-4 my-2">
          <Text>{selectedDay.format("DD")}</Text>
          <Text className="text-[#1C1C1C33]">/</Text>
          <Text>{selectedDay.format("MM")}</Text>
          <Text className="text-[#1C1C1C33]">/</Text>
          <Text>{selectedDay.format("YYYY")}</Text>
        </div>
      </div>
      <div className="basis-1/2 flex flex-row space-x-4 items-center justify-between px-4 mt-4">
        <div>
          <AppButton
            title={t("Today")}
            onClick={() => {
              setMomentDate(moment());
              setSelectedDay(moment());
            }}
            color={ButtonColorTypes.SECONDARY}
            customClass="bg-[#1C1C1C0D] rounded-[8px]"
          />
        </div>
        <div className="flex flex-row space-x-2">
          <img
            src={ChevronLeftIcon}
            className="cursor-pointer"
            onClick={() => setMomentDate(momentDate.clone().subtract(1, "month"))}
          />
          <Text>{`${t(momentDate.format("MMMM"))}`}</Text>
          <img
            src={ChevronRightIcon}
            className="cursor-pointer"
            onClick={() => setMomentDate(momentDate.clone().add(1, "month"))}
          />
        </div>
      </div>
      <div className="grid grid-cols-7 p-2">
        {DAYS.map((day) => (
          <div key={day} className="flex flex-row justify-center items-center w-[36px] h-[38px]">
            <Text key={day} className="2xl:text-14 xl:text-12 text-10 text-text-secondary">
              {day}
            </Text>
          </div>
        ))}
        {renderDays()}
      </div>
    </div>
  );
};

export default Calendar;
