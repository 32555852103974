import { number, object, string } from "yup";

export default object({
  email: string().email().required(),
  firstName: string().required(),
  lastName: string().required(),
  phone: number().required(),
  companyName: string().required(),
  companyId: string().required(),
  companyEmail: string().required(),
  companyPhone: string().required(),
  companyPostalCode: string().required(),
  companyCity: string().required(),
  companyAddress: string().required(),
  companyCountry: string().required(),
}).required();
