import React, { LegacyRef, forwardRef } from "react";
import moment from "moment";
import { pl } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { customTwMerge } from "src/utils/twMerge";

registerLocale("pl", pl);

export enum PickerType {
  Month = "MONTH",
  Quarter = "QUARTER",
  Year = "YEAR",
}

type Props = {
  field: any;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputClasses?: string;
  containerClasses?: string;
  type: PickerType;
};

const AppMonthAndQuarterPicker: React.FC<Props> = ({ field, type, error = false, disabled = false }) => {
  const combinedClasses = customTwMerge(
    "w-full 2xl:h-10 xl:h-9 h-7 rounded-[10px] bg-transparent font-sf-pro-regular pl-3 2xl:text-16 xl:text-14 text-10 border focus:border-[#233145] focus:outline-none placeholder-text-secondary",
    disabled && "opacity-50",
    error && "border-state-error focus:border-state-error",
  );

  const dateFormat = type === PickerType.Month ? "MMM yyyy" : type === PickerType.Year ? "yyyy" : "Q, yyyy";
  const Input = forwardRef(({ value, onClick, onChange }: any, ref: LegacyRef<HTMLInputElement>) => (
    <input
      className={combinedClasses}
      onChange={onChange}
      value={value}
      onClick={onClick}
      ref={ref}
      placeholder="Wybierz..."
      disabled={disabled}
    />
  ));
  Input.displayName = "ExampleCustomInput";

  return (
    <DatePicker
      selected={field?.value ? moment(field?.value).toDate() : null}
      onChange={(date) => field.onChange(date)}
      dateFormat={dateFormat}
      showQuarterYearPicker={type === PickerType.Quarter}
      showMonthYearPicker={type === PickerType.Month}
      showYearPicker={type === PickerType.Year}
      customInput={<Input />}
      locale={"pl"}
    />
  );
};

export default AppMonthAndQuarterPicker;
