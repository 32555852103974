import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import Table from "src/components/ui/Table";
import AppSelect, { Option } from "src/components/form/AppSelect";

import { useToastError } from "src/common/hooks/useToast";

import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import addDocumentsToGroupSchema from "./addDocumentsToGroup.schema";
import AddDocumentsToGroupTableRow from "./AddDocumentsToGroupTableRow";
import { useAddDocumentsToCategory } from "../budget.hooks";

type Props = {
  documents: any[];
  onCancel: () => void;
  categories: any[];
  type: BudgetCategoryTypes;
};

const HEADERS = ["Numer", "Kontrahent", "Termin płatności", "Kwota netto", "Kwota brutto"];

const AddDocumentsToGroup: React.FC<Props> = ({ onCancel, documents, categories = [], type }) => {
  const [subCategoryOptions, setSubCategoryOptions] = useState<Option[]>([]);
  const [rootCategoryOptions, setRootCategoryOptions] = useState<Option[]>([]);

  const { t } = useTranslation();
  const { loading, handleAddDocumentsToCategory } = useAddDocumentsToCategory();
  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(addDocumentsToGroupSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (categories?.length) {
      const rootCategoriesOptions = categories
        .filter((c) => !c.parent?._id)
        .map((c) => ({ label: c.name, value: c._id }));
      setRootCategoryOptions(rootCategoriesOptions);
    }
  }, [categories]);

  useEffect(() => {
    if (watch("category")) {
      const category = categories.find((c) => c._id === watch("category"));
      if (category?.isLeaf) return setSubCategoryOptions([]);

      const subCategoriesOptions = categories
        .filter((c) => c.parent?._id === watch("category"))
        .map((c) => ({ label: c.name, value: c._id }));
      setSubCategoryOptions(subCategoriesOptions);
    }
  }, [watch("category")]);

  const renderRows = () => {
    if (!documents) return null;
    return documents.map((d, i) => {
      return <AddDocumentsToGroupTableRow data={d} key={i} />;
    });
  };

  const onSubmit = async (data: any) => {
    const { subCategory } = data;
    const documentIds = documents.map((d) => d._id);

    if (!subCategory) {
      useToastError("Grupa musi zawierać podkategorię");
      return;
    }

    await handleAddDocumentsToCategory({
      categoryId: subCategory,
      documentIds,
      type,
    });
    onCancel();
  };

  return (
    <Modal
      show={true}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmText="Save"
      confirmTextClass="text-white"
      cancelTextClass="text-text-secondary"
      loading={loading}
    >
      <div className="flex flex-col h-full xl:min-w-[700px] min-w-[500px]">
        <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Assign-to-group")}</Text>
        <Text className="text-text-secondary mt-3">{t("Budget-assign-documents-to-group-subtitle")}</Text>
        <div className="mt-6 flex flex-col space-y-1">
          <Text className="2xl:text-18 xl:text-16 text-14 text-white font-sf-pro-medium">
            {t("Selected-documents")}
          </Text>
          <div className="shrink-0 max-h-[250px] overflow-auto">
            <Table headers={HEADERS} rows={renderRows()} dark />
          </div>
        </div>
        <div className="2xl:mt-6 mt-4 flex flex-col space-y-1">
          <div className="flex flex-row space-x-4">
            <div className={`${subCategoryOptions?.length ? "w-full" : "w-1/2"}`}>
              <Text className="2xl:text-18 xl:text-16 text-14 text-white font-sf-pro-medium">
                {t("Assign-to-group")}
              </Text>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <AppSelect options={rootCategoryOptions} field={field} placeholder="Wybierz..." dark />
                  )}
                />
              </div>
            </div>
            {subCategoryOptions?.length && (
              <div className="w-full">
                <span> &nbsp; </span>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="subCategory"
                    render={({ field }) => (
                      <AppSelect options={subCategoryOptions} field={field} placeholder="Wybierz..." dark />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddDocumentsToGroup;
