import { boolean, mixed, object } from "yup";

import {
  CitTaxAmountTypes,
  FlatRateTaxAmountTypes,
  PitTaxTypeTypes,
  TaxTypes,
  VatTaxTypes,
} from "src/api/graphql/types-and-hooks";

export default object({
  taxType: mixed<TaxTypes>().oneOf(Object.values(TaxTypes)).required(),
  pitTaxType: mixed<PitTaxTypeTypes>()
    .oneOf(Object.values(PitTaxTypeTypes))
    .when("taxType", (taxType, schema) => {
      return taxType[0] === TaxTypes.Pit ? schema.required() : schema.nullable();
    }),
  flatRateTaxAmount: mixed<FlatRateTaxAmountTypes>()
    .oneOf(Object.values(FlatRateTaxAmountTypes))
    .when("pitTaxType", (pitTaxType, schema) => {
      return pitTaxType[0] === PitTaxTypeTypes.FlatRate ? schema.required() : schema.nullable();
    }),
  citTaxAmount: mixed<CitTaxAmountTypes>()
    .oneOf(Object.values(CitTaxAmountTypes))
    .when("taxType", (taxType, schema) => {
      return taxType[0] === TaxTypes.Cit ? schema.required() : schema.nullable();
    }),
  isVatPayer: boolean().default(false),
  vatType: mixed<VatTaxTypes>()
    .oneOf(Object.values(VatTaxTypes))
    .when("isVatPayer", (isVatPayer, schema) => {
      return isVatPayer[0] ? schema.required() : schema.nullable();
    }),
}).required();
