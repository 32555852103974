import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Pill from "src/components/ui/Pill";
import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useGetInvoiceFile } from "src/common/hooks/useGetInvoiceFile";
import { useCreateThumbnail } from "src/common/hooks/useCreateThumbnail";

import { formatDate } from "src/utils/time";
import { formatPrice } from "src/utils/currency";
import { handlePrintDocument } from "src/utils/printDocument";
import { handleDownloadDocument } from "src/utils/downloadDocument";

import { InvoiceCompany } from "src/api/graphql/types-and-hooks";
import { InvoiceGenres, InvoiceSubtypes, PaymentStatus } from "src/api/graphql/generated/gql-hooks";

import { getInvoiceEditPath } from "src/common/router/routerPaths";

import PrintIcon from "src/assets/images/print.svg";
import InfaktIcon from "src/assets/images/infakt.svg";
import DownloadIcon from "src/assets/images/download.svg";
import TrashRedIcon from "src/assets/images/trash-red.svg";
import MagnifierIcon from "src/assets/images/magnifier.svg";

import { useDeleteInvoice, useInvoice } from "./invoice.hooks";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const { createThumbnail } = useCreateThumbnail();
  const { invoice, loading } = useInvoice(invoiceId);
  const { handleDeleteInvoice } = useDeleteInvoice();
  const { handleGetInvoiceInvoice } = useGetInvoiceFile();

  const [file, setFile] = useState<Blob>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const fileName = invoice?.filePath?.split("/")[2] || "-";

  useEffect(() => {
    if (file) {
      createThumbnail(file, "thumbnail");
    }
  }, [file]);

  useEffect(() => {
    if (invoice?.filePath) {
      (async () => {
        const invoiceFile = await handleGetInvoiceInvoice(invoiceId);
        setFile(invoiceFile);
      })();
    }
  }, [invoice?.filePath]);

  const openInvoice = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  const onPrintInvoice = () => {
    handlePrintDocument(file);
  };

  const onDownloadInvoice = () => {
    handleDownloadDocument(file, fileName);
  };

  const getInvoicePaymentStatus = () => {
    const isOverdue = moment(invoice?.paymentDue).isBefore(moment(), "day");

    if (invoice?.paymentStatus === PaymentStatus.Unpaid)
      return (
        <Pill
          title={t(`${isOverdue ? "Overdue" : "Unpaid"}`)}
          titleColor="text-red"
          bgColor="bg-light-red"
          customClass="2xl:h-10 xl:h-8 h-6"
        />
      );
    return (
      <Pill title={t("Paid")} titleColor="text-green" bgColor="bg-light-green" customClass="2xl:h-10 xl:h-8 h-6" />
    );
  };

  const loanDetails = () => {
    return (
      <div className="flex flex-row w-full">
        <div className="basis-3/12 flex flex-col space-y-1">
          <Label>{t("Capital")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-14">{formatPrice(invoice?.capital, "zł")}</Text>
        </div>
        <div className="basis-3/12 flex flex-col space-y-1">
          <Label>{t("Interest")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-14">{formatPrice(invoice?.interest, "zł")}</Text>
        </div>
      </div>
    );
  };

  const taxDetails = () => {
    let settlementPeriodFormat = "YYYY";
    switch (invoice?.settlementPeriodType) {
      case "MONTH":
        settlementPeriodFormat = "MM.YYYY";
        break;
      case "QUARTER":
        settlementPeriodFormat = "Q, YYYY";
        break;
    }
    return (
      <div className="flex flex-row 2xl:max-w-[80%] xl:max-w-[90%] w-full">
        <div className="basis-3/12 flex flex-col space-y-1">
          <Label>{t("Tax-amount")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-14">{formatPrice(invoice?.total, "zł")}</Text>
        </div>
        <div className="basis-3/12 flex flex-col space-y-1">
          <Label>{t("Due-date")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-14">{formatDate(invoice?.paymentDue, "DD.MM.YYYY")}</Text>
        </div>
        <div className="basis-3/12 flex flex-col space-y-1">
          <Label>{t("Settlement-period-type")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-14">{t(invoice?.settlementPeriodType)}</Text>
        </div>
        <div className="basis-3/12 flex flex-col space-y-1">
          <Label>{t("Settlement-period")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-14">
            {formatDate(invoice?.settlementPeriod, settlementPeriodFormat)}
          </Text>
        </div>
      </div>
    );
  };

  const renderBillFromAndTo = ({ name, address, postcode, city, country, companyId }: InvoiceCompany) => {
    return (
      <>
        <Text className="2xl:text-24 xl:text-20 text-16 mb-2 font-sf-pro-bold ">{name}</Text>
        {address ? <Text className="2xl:text-18 xl:text-16 text-12 text-text-secondary">{address}</Text> : undefined}
        {postcode || city ? (
          <Text className="2xl:text-18 xl:text-16 text-12 text-text-secondary">{`${postcode} ${city}`}</Text>
        ) : undefined}
        {country ? <Text className="2xl:text-18 xl:text-16 text-12 text-text-secondary">{country}</Text> : undefined}
        {companyId ? (
          <Text className="2xl:text-18 xl:text-16 text-12 text-text-secondary">NIP: {companyId}</Text>
        ) : undefined}
      </>
    );
  };

  const invoiceDetails = () => {
    return (
      <>
        <div
          id="thumbnail"
          className="relative basis-1/8 h-[190px] xl:max-w-[140px] cursor-pointer drop-shadow-xl"
          onClick={openInvoice}
        >
          <div className="absolute flex w-full h-full justify-center items-center">
            <img src={MagnifierIcon} />
          </div>
        </div>
        <div className="basis-7/8">
          <div className="flex flex-row space-x-1">
            <div className="basis-3/12 flex flex-col space-y-1">
              <Label>{t("Seller")}</Label>
              {renderBillFromAndTo(invoice?.billFrom)}
            </div>
            <div className="basis-3/12 flex flex-col space-y-1">
              <Label>{t("Buyer")}</Label>
              {renderBillFromAndTo(invoice?.billTo)}
            </div>
            <div className="basis-6/12 flex flex-col space-y-2">
              <Label>{t("Description")}</Label>
              <Text className="ml-2 2xl:text-18 xl:text-16 text-12">{invoice?.description || "-"}</Text>
            </div>
          </div>
          <div className="xl:mt-12 mt-6 flex flex-row">
            <div className="flex flex-col space-y-2 basis-1/5">
              <Label>{t("Issue-date")}</Label>
              <Text className="2xl:text-18 xl:text-16 text-12">
                {invoice?.issueDate ? formatDate(invoice.issueDate, "DD.MM.YYYY") : "-"}
              </Text>
            </div>
            <div className="flex flex-col space-y-2 basis-1/5">
              <Label>{t("Sale-date")}</Label>
              <Text className="2xl:text-18 xl:text-16 text-12">
                {invoice?.saleDate ? formatDate(invoice.saleDate, "DD.MM.YYYY") : "-"}
              </Text>
            </div>
            <div className="flex flex-col space-y-2 basis-1/5">
              <Label>{t("Due-date")}</Label>
              <Text className="2xl:text-18 xl:text-16 text-12">
                {invoice?.paymentDue ? formatDate(invoice.paymentDue, "DD.MM.YYYY") : "-"}
              </Text>
            </div>
            <div className="flex flex-col space-y-2 basis-1/5">
              <Label>{t("Net-amount")}</Label>
              <Text className="2xl:text-18 xl:text-16 text-12">{formatPrice(invoice?.subtotal, "zł")}</Text>
              {[InvoiceSubtypes.Insurance].includes(invoice?.subtype) ? (
                <>
                  <Label>{t("Capital")}</Label>
                  <Text className="2xl:text-18 xl:text-16 text-12">{formatPrice(invoice?.capital, "zł")}</Text>
                </>
              ) : undefined}
            </div>
            <div className="flex flex-col space-y-2 basis-1/5">
              <Label>{t("Gross-amount")}</Label>
              <Text className="2xl:text-18 xl:text-16 text-12">{formatPrice(invoice?.total, "zł")}</Text>
              {[InvoiceSubtypes.Leasing, InvoiceSubtypes.Insurance].includes(invoice?.subtype) ? (
                <>
                  <Label>{t("Interest")}</Label>
                  <Text className="2xl:text-18 xl:text-16 text-12">{formatPrice(invoice?.interest, "zł")}</Text>
                </>
              ) : undefined}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Confirm
        show={showConfirm}
        onConfirm={() => handleDeleteInvoice(invoiceId)}
        onCancel={() => setShowConfirm(false)}
      />
      <div className="m-6 flex flex-col h-full 2xl:space-y-6 xl:space-y-4 space-y-2">
        <div className="2xl:h-12 xl:h-10 h-8 flex flex-row justify-between">
          <div>
            <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary mr-2.5">{t("Transactions")}</Text>
            <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary">/</Text>
            <Text className="2xl:text-28 xl:text-24 text-20 ml-2.5 font-sf-pro-bold">{t("Details")}</Text>
          </div>
          <div className="flex flex-row space-x-4 justify-center items-center">
            <div className="px-2">
              <AppButton
                title={t("Edit")}
                color={ButtonColorTypes.PRIMARY}
                textClass="text-white font-sf-pro-medium"
                customClass="rounded-[8px]"
                onClick={() => navigate(getInvoiceEditPath({ invoiceId }))}
              />
            </div>
            {invoice?.paymentStatus !== PaymentStatus.Paid ? (
              <div className="px-2 bg-white">
                <AppButton
                  title={t("Remove")}
                  color={ButtonColorTypes.SECONDARY}
                  textClass="text-red font-sf-pro-medium mt-1"
                  customClass="rounded-[8px]"
                  icon={TrashRedIcon}
                  onClick={() => setShowConfirm(true)}
                />
              </div>
            ) : undefined}
          </div>
        </div>
        {loading ? (
          <div className="mt-12">
            <Loader />
          </div>
        ) : (
          <div className="h-full bg-white xl:p-6 p-4 rounded-[20px]">
            <div className="flex xl:pb-5 pb-2 justify-between border-b-[1.5px] border-light-gray-2">
              <div className="flex flex-row space-x-8 items-center">
                <Text className="2xl:text-28 xl:text-24 text-20 font-sf-pro-bold">{invoice?.invoiceNumber}</Text>
                {invoice?.externalService ? <img src={InfaktIcon} className="2xl:w-14 xl:w-12 w-10" /> : undefined}
                {invoice?.car ? (
                  <div className="flex flex-col space-y-1">
                    <Label>{t("Car")}</Label>
                    <Text className="2xl:text-18 xl:text-16 text-12">{invoice?.car?.name}</Text>
                  </div>
                ) : undefined}
                <div className="min-w-[109px]">{getInvoicePaymentStatus()}</div>
                {invoice?.paymentStatus === PaymentStatus.Paid ? (
                  <div className="flex flex-col space-y-1">
                    <Label>{t("Payment-date")}</Label>
                    <Text className="2xl:text-18 xl:text-16 text-12">
                      {formatDate(invoice?.paymentDate, "DD.MM.YYYY")}
                    </Text>
                  </div>
                ) : undefined}
              </div>
              {file ? (
                <div className="flex flex-row space-x-2">
                  <img
                    className="cursor-pointer xl:h-10 xl:w-10 w-8 h-8"
                    src={PrintIcon}
                    onClick={onPrintInvoice}
                    title={t("Print")}
                  />
                  <img
                    className="cursor-pointer xl:h-10 xl:w-10 w-8 h-8"
                    src={DownloadIcon}
                    onClick={onDownloadInvoice}
                    title={t("Download")}
                  />
                </div>
              ) : undefined}
            </div>
            <div className="pt-5 flex flex-row space-x-7">
              {invoice?.genre === InvoiceGenres.Tax && taxDetails()}
              {invoice?.genre === InvoiceGenres.Loan && loanDetails()}
              {invoice?.genre === InvoiceGenres.Invoice && invoiceDetails()}
              {invoice?.genre === InvoiceGenres.Car && invoiceDetails()}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceDetails;
