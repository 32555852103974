import { object, string } from "yup";

export default object({
  companyName: string().required(),
  companyId: string()
    .matches(/^[0-9]*$/)
    .required(),
  companyEmail: string().required(),
  companyPhone: string()
    .matches(/^[0-9]*$/)
    .required(),
  companyPostalCode: string().required(),
  companyCity: string().required(),
  companyAddress: string().required(),
}).required();
