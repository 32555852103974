import {
  useCompanyCarsQuery,
  useUpdateCompanyMutation,
  useCompanyLegalFormQuery,
  useCompanyTaxDetailsQuery,
  useAddCarToCompanyMutation,
  useCompanyAccountBalanceQuery,
} from "src/api/graphql/generated/gql-hooks";
import {
  Company,
  CompanyCar,
  CompanyTaxDetails,
  UpdateCompanyInput,
  AddCarToCompanyInput,
  CompanyLegalFormTypes,
} from "src/api/graphql/types-and-hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useEditCompany = () => {
  const [editCompany, { loading, data }] = useUpdateCompanyMutation({
    onCompleted: () => useToastSuccess(),
    onError: () => useToastError(),
  });

  return {
    handleEditCompany: async (updateCompanyInput: UpdateCompanyInput) => {
      await editCompany({
        variables: {
          updateCompanyInput,
        },
      });
    },
    isUpdating: loading,
    isUpdated: !!data,
  };
};

export const useCompanyLegalForm = (companyId: string) => {
  const { loading, data } = useCompanyLegalFormQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const legalForm = data?.company?.legalForm || ("" as CompanyLegalFormTypes);

  return {
    loading,
    legalForm,
  };
};

export const useCompanyTaxDetails = (companyId: string) => {
  const { loading, data } = useCompanyTaxDetailsQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const taxDetails = data?.company?.taxDetails || ({} as CompanyTaxDetails);

  return {
    loading,
    taxDetails,
  };
};

export const useCompanyCars = (companyId: string) => {
  const { loading, data } = useCompanyCarsQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const cars = (data?.company?.cars || []) as CompanyCar[];

  return {
    loading,
    cars: cars.filter((car) => !car.deletedAt),
  };
};

export const useAddCarToCompany = () => {
  const [addCarToCompany, { loading, data }] = useAddCarToCompanyMutation({
    onCompleted: () => useToastSuccess(),
    onError: () => useToastError(),
    refetchQueries: ["CompanyCars"],
  });

  return {
    handleAddCarToCompany: async (addCarToCompanyInput: AddCarToCompanyInput) => {
      await addCarToCompany({
        variables: {
          addCarToCompanyInput,
        },
      });
    },
    isAdding: loading,
    isAdded: !!data,
  };
};

export const useCompanyAccountBalance = (companyId: string) => {
  const { loading, data, refetch } = useCompanyAccountBalanceQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const companyAccountBalance = data?.company || ({} as Partial<Company>);

  return {
    refetch,
    loading,
    companyAccountBalance,
  };
};
