import React from "react";

type Props = {
  children: React.ReactNode;
  customStyles?: Record<string, any>;
  onClick?: () => void;
};

const TableRow: React.FC<Props> = ({ children, onClick }) => {
  return (
    <tr className={`border-b-[1.5px] 2xl:h-12 xl:h-10 h-8 ${onClick && "cursor-pointer"}`} onClick={onClick}>
      {children}
    </tr>
  );
};

export default TableRow;
