import React from "react";

import { customTwMerge } from "src/utils/twMerge";

type Props = {
  onClick?: () => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
};

const Text: React.FC<Props> = ({ children, className = "", onClick, title = undefined }) => {
  const combinedClasses = customTwMerge("font-sf-pro-regular 2xl:text-16 xl:text-14 text-10", className);

  return (
    <span className={combinedClasses} onClick={onClick} title={title}>
      {children}
    </span>
  );
};

export default Text;
