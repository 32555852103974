import { array, boolean, mixed, object, string } from "yup";

import { UserPermissionResourcesEnum } from "src/api/graphql/types-and-hooks";

export default object({
  email: string().email().required(),
  permissions: array().of(
    object().shape({
      resource: mixed<UserPermissionResourcesEnum>().oneOf(Object.values(UserPermissionResourcesEnum)).required(),
      label: string(),
      checked: boolean(),
    }),
  ),
}).required();
