import React from "react";
import { UseFormRegister } from "react-hook-form";

import ErrorIcon from "src/assets/images/error.svg";
import { customTwMerge } from "src/utils/twMerge";

type Props = {
  id: string;
  register: UseFormRegister<any>;
  error?: boolean;
  placeholder?: string;
  cols?: number;
  rows?: number;
};

const AppTextArea: React.FC<Props> = ({ id, register, error, placeholder, cols, rows }) => {
  const combinedClasses = customTwMerge(
    "w-full h-full 2xl:text-16 xl:text-14 text-12 border rounded-[10px] bg-transparent font-sf-pro-regular p-4 placeholder-text-secondary focus:border-[#233145] focus:outline-none",
    error && "border-state-error focus:border-state-error",
  );

  return (
    <div className="relative">
      <textarea {...register(id)} className={combinedClasses} cols={cols} rows={rows} placeholder={placeholder} />
      {error && (
        <div className="absolute inset-y-0 right-5 flex items-center">
          <img role="presentation" src={ErrorIcon} />
        </div>
      )}
    </div>
  );
};

export default AppTextArea;
