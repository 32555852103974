import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import AppSwitch from "src/components/form/AppSwitch";

import { BudgetCategoryTypes } from "src/api/graphql/types-and-hooks";

import { useBudgetCategories } from "../../budget.hooks";
import AddSingleTransaction from "./AddSingleTransaction";

enum TransactionSubtypes {
  Single = "Single",
  Divided = "Divided",
  Cyclical = "Cyclical",
}

type Props = {
  onCancel: () => void;
  transactionType: BudgetCategoryTypes;
  budgetDateFrom: string;
  budgetDateTo: string;
};

const AddTransaction: React.FC<Props> = ({ onCancel, transactionType, budgetDateFrom, budgetDateTo }) => {
  const [step, setStep] = useState(1);
  const [transactionSubtype, setTransactionSubtype] = useState<TransactionSubtypes>(TransactionSubtypes.Single);

  const { budgetId } = useParams();
  const { t } = useTranslation();
  const { categories } = useBudgetCategories({
    filter: { budget: { eq: budgetId }, type: { eq: transactionType } },
    take: 0,
    skip: 0,
  });

  const selectTransactionSubtype = () => {
    return (
      <Modal
        show={true}
        onCancel={onCancel}
        onConfirm={() => setStep(2)}
        confirmText="Next"
        confirmTextClass="text-white"
        cancelTextClass="text-text-secondary"
      >
        <div className="flex flex-col h-full xl:max-w-[500px] max-w-[350px]">
          <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">
            {t(transactionType === BudgetCategoryTypes.Cost ? "Add-cost" : "Add-income")}
          </Text>
          <Text className="text-text-secondary mt-1 text-12">{t("Add-transaction-modal-subtitle")}</Text>
          <div className="2xl:mt-10 xl:mt-8 mt-6 flex items-center justify-center">
            <AppSwitch
              bgColor="bg-[#F4F4F41A]"
              activeColor="bg-white"
              textClass="text-white 2xl:text-14 xl:text-12 text-10"
              activeTextClass="2xl:text-14 xl:text-12 text-10 text-blue font-sf-pro-bold"
              options={[
                { value: TransactionSubtypes.Single, label: t("Single") },
                { value: TransactionSubtypes.Divided, label: t("Divided") },
                { value: TransactionSubtypes.Cyclical, label: t("Cyclical") },
              ]}
              onChange={(val) => setTransactionSubtype(val as TransactionSubtypes)}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {step === 1 && selectTransactionSubtype()}
      {step === 2 && transactionSubtype === TransactionSubtypes.Single && (
        <AddSingleTransaction
          categories={categories}
          onClose={onCancel}
          onCancel={() => setStep(1)}
          transactionType={transactionType}
          dateFrom={budgetDateFrom}
          dateTo={budgetDateTo}
        />
      )}
    </>
  );
};

export default AddTransaction;
