import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Table from "src/components/ui/Table";
import { CalendarView } from "src/components/ui/TimeRangeToolbar";

import { InvoicesListDocument } from "src/api/graphql/types-and-hooks";

import { formatPrice } from "src/utils/currency";

import { useIncomesAndCostsStatistic } from "src/common/hooks/useIncomesAndCostsStatistic";

import CostIcon from "src/assets/images/unpaid.svg";
import IncomeIcon from "src/assets/images/paid.svg";

import SummaryTableRow from "./SummaryTableRow";

type Props = {
  documents?: InvoicesListDocument[];
  calendarView?: string;
  isLoading?: boolean;
};

const HEADERS = ["Numer/nazwa", "Kontrahent", "Termin płatności", "Kwota netto", "Kwota brutto", "Rozliczenie"];

const Summary: React.FC<Props> = ({ documents = [], calendarView, isLoading }) => {
  const { t } = useTranslation();
  const [statisticData, setStatisticData] = React.useState<any>(null);
  const { getStatisticData } = useIncomesAndCostsStatistic();

  useEffect(() => {
    if (documents.length) {
      (async () => {
        setStatisticData(await getStatisticData(documents));
      })();
    }
  }, [documents]);

  const renderRows = () => {
    if (!documents) return null;
    return documents.map((d: InvoicesListDocument) => {
      return <SummaryTableRow key={d._id} data={d} />;
    });
  };

  const getSummaryType = () => {
    if (calendarView === CalendarView.Day) return "Daily-summary";
    if (calendarView === CalendarView.Week) return "Weekly-summary";
    return "Monthly-summary";
  };

  const renderStatistics = () => {
    if (!documents.length || !statisticData)
      return (
        <div className="flex justify-center">
          <Text>Brak płatności</Text>
        </div>
      );
    const { incomes, costs, total, incomesPaid, incomesOverdue, incomesToPaid, costsPaid, costsOverdue, costsToPaid } =
      statisticData;
    const incomesPercentage = ((incomes / total) * 100).toFixed(2);
    const costsPercentage = ((costs / total) * 100).toFixed(2);

    return (
      <div className="flex flex-col flex-grow h-0 min-h-full overflow-y-auto">
        <div className="flex flex-row divide-x">
          <div className="flex flex-col basis-1/2 space-y-1">
            <div className="flex flex-row space-x-2 items-center mt-2">
              <img src={IncomeIcon} />
              <Text className="font-sf-pro-medium">{t("Incomes")}</Text>
            </div>
            <Text className="font-sf-pro-bold">{`${incomesPercentage}%`}</Text>
            <Text className="text-green">{formatPrice(incomes, "zł")}</Text>
          </div>
          <div className="basis-1/2 justify-end">
            <div className="flex flex-col justify-end space-y-1">
              <div className="flex flex-row space-x-2 mt-2 justify-end">
                <Text className="font-sf-pro-medium">{t("Costs")}</Text>
                <img src={CostIcon} />
              </div>
              <Text className="font-sf-pro-bold text-right">{`${costsPercentage}%`}</Text>
              <Text className="text-right text-red">{formatPrice(costs, "zł")}</Text>
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-2 xl:mt-4 mt-2">
          <div
            className="border-b border-4 border-green rounded-[20px]"
            style={{ width: `${incomesPercentage}%` }}
          ></div>
          <div className="border-b border-4 border-red rounded-[20px]" style={{ width: `${costsPercentage}%` }}></div>
        </div>
        <div className="flex flex-col xl:space-y-4 space-y-2 mt-1 mb-1">
          <div className="flex flex-row justify-between items-center">
            <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold text-green">{incomesPaid}</Text>
            <Text className="font-sf-pro-medium">Zapłacone</Text>
            <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold text-red">{costsPaid}</Text>
          </div>
          <div className="flex flex-row justify-between items-center">
            <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold text-green">{incomesToPaid}</Text>
            <Text className="font-sf-pro-medium">Do zapłaty</Text>
            <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold text-red">{costsToPaid}</Text>
          </div>
          <div className="flex flex-row justify-between items-center">
            <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold text-green">{incomesOverdue}</Text>
            <Text className="font-sf-pro-medium">Po terminie</Text>
            <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold text-red">{costsOverdue}</Text>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-row space-x-4 p-4 w-full grow-0 bg-white rounded-[16px]">
      <div className="basis-3/4 flex flex-col">
        <Text className="2xl:text-20 xl:text-18 text-14 font-sf-pro-bold">{t(`${getSummaryType()}`)}</Text>
        <div className="payment-calendar-summary-hints w-full flex-grow h-0 min-h-full overflow-y-auto">
          <Table headers={HEADERS} rows={renderRows()} isLoading={isLoading} noDataMsg="Brak płatności" />
        </div>
      </div>
      <div className="payment-calendar-summary-hints-2 basis-1/4">
        <Text className="text-text-secondary font-sf-pro-medium">Statystyki faktur</Text>
        {renderStatistics()}
      </div>
    </div>
  );
};

export default Summary;
