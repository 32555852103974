import { boolean, date, mixed, number, object, string } from "yup";

import { InvoiceGenres, InvoiceTypes, PaymentStatus } from "src/api/graphql/types-and-hooks";

export default object({
  invoiceNumber: string().required(),
  type: mixed<InvoiceTypes>().oneOf(Object.values(InvoiceTypes)),
  genre: mixed<InvoiceGenres>().oneOf(Object.values(InvoiceGenres)),
  paymentStatus: mixed<PaymentStatus>().oneOf(Object.values(PaymentStatus)).required(),
  capital: number().nullable(),
  interest: number().nullable(),
  paymentDue: date().required(),
  paymentDate: date().when("paymentStatus", ([paymentStatus], schema) => {
    return paymentStatus === PaymentStatus.Paid ? schema.required() : schema.nullable();
  }),
}).required();
