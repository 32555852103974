import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell, Tooltip } from "recharts";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";

import { getBudgetDetailsPath } from "src/common/router/routerPaths";

import { formatPrice } from "src/utils/currency";

import { useBudgetCostsAndIncomesBalance } from "./cockpit.hooks";
import { useBudgets } from "../budget/budget.hooks";

const CostsAndIncomesChart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { budgets } = useBudgets({
    filter: { name: { eq: moment().format("YYYY") }, isDefault: true },
  });
  const { loading, costsAndIncomesPerMonth } = useBudgetCostsAndIncomesBalance(moment().format("YYYY"), true);

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload) {
      const costLabel = payload[0]?.payload?.isPrognosis ? "Prognosis-income" : "Income";
      const incomeLabel = payload[0]?.payload?.isPrognosis ? "Prognosis-cost" : "Cost";
      return (
        <div className="bg-white shadow-lg rounded-[12px] p-4">
          <div className="flex flex-row space-x-2">
            <Text className="text-text-secondary">{`${t(costLabel)}:`}</Text>
            <Text className="font-sf-pro-bold">{formatPrice(payload[0]?.value, "zł")}</Text>
          </div>
          <div className="flex flex-row space-x-2">
            <Text className="text-text-secondary">{`${t(incomeLabel)}:`}</Text>
            <Text className="font-sf-pro-bold">{formatPrice(payload[1]?.value, "zł")}</Text>
          </div>
        </div>
      );
    }

    return null;
  };

  if (loading) return <Loader />;

  return (
    <ResponsiveContainer>
      <BarChart
        style={{ cursor: "pointer" }}
        data={costsAndIncomesPerMonth}
        margin={{ top: 15 }}
        onClick={() => budgets[0] && navigate(getBudgetDetailsPath({ budgetId: budgets[0]._id }))}
      >
        <CartesianGrid
          strokeDasharray="0 0"
          stroke="#F6F7FB"
          verticalCoordinatesGenerator={({ width }) => {
            const segment = width / 4;
            return [segment, segment * 2, segment * 3, segment * 4];
          }}
          horizontalCoordinatesGenerator={({ height }) => {
            const segment = height / 4;
            return [segment, segment * 2, segment * 3, height];
          }}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "#f4f4f4" }} />
        <XAxis
          tick={{ fill: "#8E98A7" }}
          dataKey="month"
          tickLine={false}
          axisLine={{ stroke: "#F6F7FB" }}
          tickMargin={15}
          height={70}
          tickFormatter={(value) => t(value)}
          className="2xl:text-14 xl:text-12 text-10"
        />
        <YAxis
          tick={{ fill: "#8E98A7" }}
          tickLine={false}
          axisLine={{ stroke: "#F6F7FB" }}
          className="2xl:text-14 xl:text-12 text-10"
        />
        <Bar dataKey="totalIncomes" radius={25} barSize={10}>
          {costsAndIncomesPerMonth?.map((entry, index) => (
            <Cell key={index} fill={entry.isPrognosis ? "#3183FF80" : "#3183FF"} />
          ))}
        </Bar>
        <Bar dataKey="totalCosts" radius={25} barSize={10}>
          {costsAndIncomesPerMonth?.map((entry, index) => (
            <Cell key={index} fill={entry.isPrognosis ? "#ABE85F80" : "#ABE85F"} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CostsAndIncomesChart;
